export default {
	setRealizedItems(state, data) {
		let realized_items = new Array();
		let realized_trades = new Array();
		let trading_data=data.data;
		for (var i in trading_data) {
			let one = new Array();
			one.push(new Date(trading_data[i].created).getTime());
			one.push(trading_data[i].total_market);
			realized_items.push(one);
		}
		realized_trades.push({
            name: data.name, 
            data:  realized_items
        });
		state.realized_items = realized_trades;
	},
	setUnRealizedItems(state, data) {
		let unrealized_items = new Array();
		let unrealized_trades = new Array();
		let unrealized_data=data.data;
		for (var i in unrealized_data) {
			let one = new Array();
			one.push(new Date(unrealized_data[i].created).getTime());
			one.push(unrealized_data[i].total_market);
			unrealized_items.push(one);
		}
		unrealized_trades.push({
            name: data.name, 
            data:  unrealized_items
        });
		state.unrealized_items = unrealized_trades;
	},
	setAllItems(state, data) {
		let all_performaces = new Array();
		let performaces = new Array();
		let all_data=data.data;
		for (var i in all_data) {
			let one = new Array();
			one.push(new Date(all_data[i].created).getTime());
			one.push(all_data[i].total_market);
			all_performaces.push(one);
		}
		performaces.push({
            name: data.name, 
            data:  all_performaces
		});
		state.all_items = performaces;
	},
	setTimeline(state, timeline) {
		state.timeline = timeline
	},
	setMovingAvg(state, moving_avg) {
		state.moving_avg = moving_avg
	},
	setPeriodicity(state, periodicity) {
		state.periodicity = periodicity
	},
	setMinValue(state, min_value) {
		state.min_value = min_value
	},
	setMaxValue(state, max_value) {
		state.max_value = max_value
	},
	setEquitiesPerformanceItems(state, data) {
		let stock_items = new Array();
		let stock_performance = new Array();
		let stock_data=data.data;
		for (var i in stock_data) {
			let one = new Array();
			one.push(new Date(stock_data[i].created).getTime());
			one.push(stock_data[i].total_market);
			stock_items.push(one);
		}
		stock_performance.push({
            name: "Equities", 
            data:  stock_items
        });
		state.stock_items = stock_performance;
	},
	setBondsPerformanceItems(state, data) {
		let bond_items = new Array();
		let bond_performance = new Array();
		let bond_data=data.data;
		for (var i in bond_data) {
			let one = new Array();
			one.push(new Date(bond_data[i].created).getTime());
			one.push(bond_data[i].total_market);
			bond_items.push(one);
		}
		bond_performance.push({
            name: "Bonds", 
            data:  bond_items
        });
		state.bond_items = bond_performance;
	},
	setFundsPerformanceItems(state, data) {
		let fund_items = new Array();
		let fund_performance = new Array();
		let fund_data=data.data;
		for (var i in fund_data) {
			let one = new Array();
			one.push(new Date(fund_data[i].created).getTime());
			one.push(fund_data[i].total_market);
			fund_items.push(one);
		}
		fund_performance.push({
            name: "Funds", 
            data:  fund_items
        });
		state.fund_items = fund_performance;
	},
	setTradingItems(state, data) {
		let trading_items = new Array();
		let trading_performance = new Array();
		let trading_data=data.data;
		for (var i in trading_data) {
			let one = new Array();
			one.push(new Date(trading_data[i].created).getTime());
			one.push(trading_data[i].total_market);
			trading_items.push(one);
		}
		trading_performance.push({
            name: "Trading", 
            data:  trading_items
        });
		state.trading_items = trading_performance;
	},
	setInterestItems(state, data) {
		let interest_items = new Array();
		let interest_performance = new Array();
		let interest_data=data.data;
		for (var i in interest_data) {
			let one = new Array();
			one.push(new Date(interest_data[i].created).getTime());
			one.push(interest_data[i].total_market);
			interest_items.push(one);
		}
		interest_performance.push({
            name: "Interest", 
            data:  interest_items
        });
		state.interest_items = interest_performance;
	},
}