export default {
    setItems(state, payload) {
        state.items = payload
    },
    setAssets(state, payload) {
        state.assets_data = payload
    },
    setTotalHolding(state, payload) {
        state.holding_data = payload
    },
    setQuantity(state, payload) {
        state.quantity = payload
    },
    setCurrency(state, payload) {
        state.currency = payload
    },
    setCurrencyChart(state, payload) {
        state.currency_chart_data = payload
    },
    setCashSymbols(state, payload) {
        state.cash_symbols = payload
    },
    setStockSymbols(state, payload) {
        state.stock_symbols = payload
    },
}