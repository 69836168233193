let today = new Date();
let dates = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);
let ytd_date = new Date(today.getFullYear(), 0, 1);
let ytd_dates = ytd_date.getFullYear() + '-' + ("0" + (ytd_date.getMonth() + 1)).slice(-2) + '-' + ("0" + ytd_date.getDate()).slice(-2);
export default {
        isVisibleNews: "1",
        isVisibleOrderEntry: 0,
        user_access_type: 0,
        filter_currency: "USD",
        filter_currency_rate: 1,
        filter_currency_usd_rate: 1,
        counties_list: [],
        regions_list: [],
        profiles: [],
        portfolios: [],
        trading_portfolios: [],
        all_portfolios: [],
        filter_fortfolio: [],
        is_current_theme: "",
        active_tab: "TickerDetails",
        // start_filter_date: "",
        // end_filter_date: "",
        start_filter_date:(localStorage.getItem("start_filter_date"))?localStorage.getItem("start_filter_date"):ytd_dates,
        end_filter_date: (localStorage.getItem("end_filter_date")) ? localStorage.getItem("end_filter_date") : dates,
        countries_with_security: [],
        currency_with_security: [],
        second_countries_with_security: [],
        second_currency_with_security: [],
        ticker_with_security: [],
        is_transaction_update: 0,
        is_maximize: 0,
        exchange_with_security: [],
        type_with_security: [],
        group_with_security: [],
        sector_with_security: [],
        area_with_security: [],
        gcategory_with_security: [],
        rating_with_security: [],
        company_with_security: [],
        category_with_security: [],
        peer_with_security: [],
        default_security_set: {
                security_id: 11,
                conid: 12087792,
                iex_symbol: 'EURUSD',
                market_id: 6,
        },
        rfq_settings: {
                is_rfq_order: false,
                rfq_form_type: null
        },
        bl_active_tab1: null,


}