<template>
  <div
    class="custome-select-wrap portfolio-wrap"
    @click="handleClick"
    id="portfolio-selection"
  >
    <vue-multi-select
      class="portfolio-select"
      ref="portfolioMultiSelect"
      v-model="values"
      :search="portfolioSearch"
      :filters="portfolioFilters"
      :options="options"
      :btnLabel="btnLabel"
      @open="open"
      @close="close"
      :selectOptions="data"
    >
      <template v-slot:option="{ option }">
        <input
          type="checkbox"
          :checked="option.selected"
          :value="option.id"
          name="filter_portfolio_id[]"
        />
        <span class="portfolio-ellipsis">{{ option.name }}</span>
      </template>
    </vue-multi-select>
    <div>
      <v-dialog
        v-model="settingDialog"
        content-class="overflow-visible"
        max-width="400"
      >
        <v-card class="custome-modal setting-wrap ">
          <v-card-title class="headline">
            <div
              class="box-title d-flex justify-content-between align-items-center"
            >
              <h4>Settings</h4>
              <a href="javascript:void(0)" @click="settingDialog = false">
                <font-awesome-icon class="close-icon" icon="times" />
              </a>
            </div>
          </v-card-title>

          <b-tabs content-class="mt-1" fill>
            <b-tab title="Preferences" active>
              <b-form @submit="onChangeSetting" class="setting-form">
                <v-card-text class="justify-content-start">
                  <b-row>
                    <b-col class="padding-x" sm="12" md="12">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div class="subtitle text-left font-weight-bold">
                          Light Theme
                        </div>
                        <template>
                          <v-switch
                            class="dark-theme-switch"
                            v-model="isDarkActive"
                            @change="changeTheme"
                          ></v-switch>
                        </template>
                        <div class="subtitle text-left font-weight-bold">
                          Dark Theme
                        </div>
                      </div>
                    </b-col>
                    <b-col
                      class="padding-x mail-select-show custome-select-wrap regions-wrap"
                      sm="12"
                      md="12"
                    >
                      <div class="subtitle text-left">Mailing Preferences</div>
                      <vue-multi-select
                        class="regions-select bottom-open-select"
                        v-model="mail_values"
                        :btnLabel="mailLabel"
                        :selectOptions="mail_preferences_data"
                        @open="open"
                        @close="close"
                        @input="onChangeCurrency"
                      />
                    </b-col>
                    <b-col
                      class="padding-x mail-select-show custome-select-wrap regions-wrap"
                      sm="12"
                      md="12"
                    >
                      <div class="subtitle text-left">Country Selection</div>
                      <vue-multi-select
                        class="regions-select bottom-open-select"
                        v-model="selection_values"
                        :btnLabel="selectionLabel"
                        :selectOptions="country_preferences_data"
                        @open="open"
                        @close="close"
                      />
                    </b-col>
                    <b-col
                      class="padding-x country-select-show custome-select-wrap regions-wrap max-height-regions"
                      sm="12"
                      md="12"
                      v-if="selection_values[0].id == 2"
                    >
                      <div class="subtitle text-left">Regions</div>
                      <vue-multi-select
                        class="regions-select"
                        ref="multiSelect"
                        v-model="regions_values"
                        search
                        showLabels="false"
                        :options="regions_options"
                        :btnLabel="regionsbtnLabel"
                        @open="open"
                        @close="close"
                        :selectOptions="regions"
                        labelValue="id"
                        labelName="region"
                        @selectionChanged="onChangeRegion"
                      >
                        <template v-slot:option="{ option }">
                          <input
                            type="checkbox"
                            :checked="option.selected"
                            :value="option.id"
                            name="region_ids[]"
                          />
                          <span>{{ option.region }}</span>
                        </template>
                      </vue-multi-select>
                    </b-col>
                    <b-col
                      class="padding-x country-select-show custome-select-wrap regions-wrap max-height-regions"
                      sm="12"
                      md="12"
                      v-if="selection_values[0].id == 2"
                    >
                      <div class="subtitle text-left">Countries</div>
                      <vue-multi-select
                        class="regions-select mb-10"
                        ref="multiSelect"
                        v-model="country_values"
                        search
                        showLabels="false"
                        :options="countries_options"
                        :btnLabel="CountriesbtnLabel"
                        @open="open"
                        @close="close"
                        :selectOptions="countries"
                      >
                        <template v-slot:option="{ option }">
                          <input
                            type="checkbox"
                            :checked="option.selected"
                            :value="option.id"
                            name="country_ids[]"
                          />
                          <span>{{ option.country_name }}</span>
                        </template>
                      </vue-multi-select>
                    </b-col>
                  </b-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-content-end">
                  <b-button class="primary-btn" type="submit" id="update-btn"
                    >Update</b-button
                  >
                </v-card-actions>
              </b-form>
            </b-tab>
            <b-tab title="Profile">
              <b-form @submit="onChangePassword" class="setting-form">
                <v-card-text class="justify-content-start">
                  <b-row>
                    <b-col class="padding-x" sm="12">
                      <b-form-group id="nameInput-1">
                        <v-text-field
                          id="nameInput"
                          class="form-control"
                          type="text"
                          label="Name"
                          v-model="passwordform.name"
                        ></v-text-field>
                      </b-form-group>
                    </b-col>
                    <b-col class="padding-x" sm="12">
                      <b-form-group id="emailInput-1">
                        <v-text-field
                          id="emailInput"
                          class="form-control"
                          type="email"
                          label="Email"
                          v-model="passwordform.email"
                          disabled
                        ></v-text-field>
                      </b-form-group>
                    </b-col>
                    <b-col class="padding-x" sm="12">
                      <b-form-group id="oldPasswordInput-1">
                        <v-text-field
                          v-model="passwordform.old_password"
                          id="oldPasswordInput"
                          class="form-control"
                          type="password"
                          label="Old Password"
                        ></v-text-field>
                      </b-form-group>
                    </b-col>
                    <b-col class="padding-x" sm="12">
                      <b-form-group id="newPasswordInput-1">
                        <v-text-field
                          v-model="passwordform.new_password"
                          id="newPasswordInput"
                          class="form-control"
                          type="password"
                          label="New Password"
                        ></v-text-field>
                      </b-form-group>
                    </b-col>
                    <b-col class="padding-x" sm="12">
                      <b-form-group id="confirmPasswordInput-1">
                        <v-text-field
                          v-model="passwordform.new_password_confirmation"
                          id="confirmPasswordInput"
                          class="form-control mb-10"
                          type="password"
                          label="Confirm Password"
                        ></v-text-field>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-content-end">
                  <b-button
                    class="primary-btn"
                    type="submit"
                    id="update-password-btn"
                    >Update</b-button
                  >
                </v-card-actions>
              </b-form>
            </b-tab>
          </b-tabs>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import vueMultiSelect from "vue-multi-select";
import "vue-multi-select/dist/lib/vue-multi-select.css";
import axios from "./../axios";
import themeconfig from '../themeconfig'
export default {
  name: "Portfolio",
  components: {
    vueMultiSelect,
  },
  data() {
    return {
      btnLabel: (values) => {
        
        let selectedPortfolio = [];
        values.map((val) => {
          selectedPortfolio.push(val.id)
        });
        // console.log("selectedPortfolio=>",selectedPortfolio);
        // let uniqueValues = new Set(values.map(v => v.id));
        // console.log("before uniqueValues=>",uniqueValues);
        // uniqueValues = [...uniqueValues];
        // console.log("selectedPortfolio=>",uniqueValues);
        // console.log("selectedPortfolio.length=>",selectedPortfolio.length);
        return selectedPortfolio.length == 1 ? values[0].name : `${selectedPortfolio.length} Portfolios`;

        //return values.length == 1 ? values[0].name : `${values.length} Portfolios`;
      },
      regionsbtnLabel: (regions_values) =>
        regions_values.length == 1
          ? regions_values[0].region
          : `${regions_values.length} Regions`,
      CountriesbtnLabel: (country_values) =>
        country_values.length == 1
          ? country_values[0].country_name
          : `${country_values.length} Countries`,
      mailLabel: (mail_values) =>
        mail_values.length == 1 ? mail_values[0].name : `Mailing Preferences`,
      selectionLabel: (selection_values) =>
        selection_values.length == 1
          ? selection_values[0].name
          : `Country Selection`,
      name: "Custody Clients",
      mail_values: [{ id: 0, name: "" }],
      selection_values: [{ id: 0, name: "" }],
      isDarkActive: localStorage.getItem("isDarkActive") == 1 ? true : false,
      //isDarkActive:false,
      isDarkLbl:
        localStorage.getItem("isDarkActive") == 1
          ? "Dark Theme"
          : "Light Theme",
      values: [],
      regions_values: [],
      country_values: [],
      data: [],
      label: "",
      showLabels: false,
      settingDialog: false,
      filters: [
        {
          nameAll: "Select all",
          nameNotAll: "Deselect all",
          func() {
            return true;
          },
        },
      ],
      portfolioFilters: [
        {
          nameAll: "Select all",
          nameNotAll: "Deselect all",
          func() {
            return true;
          },
        },
      ],
      options: {
        multi: true,
        groups: false,
        labelList: "elements",
        showLabels: false,
      },
      regions_options: {
        multi: true,
        groups: false,
        labelList: "elements",
        showLabels: false,
        labelValue: "id",
        labelName: "region",
      },
      countries_options: {
        multi: true,
        groups: false,
        labelList: "elements",
        showLabels: false,
        labelValue: "id",
        labelName: "country_name",
      },
      passwordform: {
        name: "",
        email: "",
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      settingform: {
        report_send_time: [],
        is_report_country: [],
        region_ids: [],
        country_ids: [],
      },
      mail_preferences_data: [
        { id: 1, name: "Realtime" },
        { id: 2, name: "Daily" },
        { id: 3, name: "Weekly" },
        { id: 0, name: "No Reports" },
      ],
      country_preferences_data: [
        { id: 1, name: "All" },
        { id: 2, name: "Selected" },
      ],
      portfolioSearch: false,
    };
  },
  computed: {
    profiles() {
      return this.$store.getters["dashboard/getProfiles"];
    },
    countries() {
      return this.$store.getters["dashboard/getCounties"];
    },
    regions() {
      return this.$store.getters["dashboard/getRegions"];
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
  },
  sockets: {
    connect: function() {
      //console.log("Portfolio Socket Connected");
    },
  },
  methods: {
    open() {},
    close() {},
    onSubmit() {
      console.log("submit");
    },
    async onChangeCurrency() {
      // alert(this.mail_values);
    },
    async loadBodyClass() {
      var myBody = document.querySelector("body");
      var is_current_theme = "1";
      if (myBody.classList.contains("dark-theme")) {
        localStorage.setItem("isDarkActive", 1);
        is_current_theme = "1";
      } else {
        localStorage.setItem("isDarkActive", 2);
        is_current_theme = "2";
      }
      await this.$store.dispatch(
        "dashboard/updateCurrentTheme",
        is_current_theme
      );
    },
    changeTheme() {
      var myBody = document.querySelector("body");
      myBody.classList.toggle("dark-theme");
      this.loadBodyClass();
    },
    async onChangePassword(event) {
      event.preventDefault();
      this.loading = true;
      let validate = 1;
      if (validate) {
        let payload = this.passwordform;
        await axios
          .post("profile/change-password", payload)
          .then(async (response) => {
            this.loading = false;
            if (response.data.status == 1) {
              this.settingDialog = false;
              this.$swal({
                title: "Profile",
                text: response.data.message,
              });
              this.passwordform.old_password = "";
              this.passwordform.new_password = "";
              this.passwordform.new_password_confirmation = "";
              await this.$store.dispatch("dashboard/fetchProfile");
            } else {
              this.$swal({
                showDenyButton: true,
                denyButtonText: `Ok`,
                showConfirmButton: false,
                title: "Error",
                text: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
      this.loading = true;
    },
    async onChangeSetting(event) {
      event.preventDefault();
      this.loading = true;
      let validate = 1;
      if (validate) {
        let AllCountries = this.country_values;
        var countriesAry = new Array();
        if (AllCountries.length > 0) {
          for (var c in AllCountries) {
            countriesAry.push(AllCountries[c].id);
          }
        }

        let AllRegions = this.regions_values;
        var regionsAry = new Array();
        if (AllRegions.length > 0) {
          for (var r in AllRegions) {
            regionsAry.push(AllRegions[r].id);
          }
        }

        this.settingform.report_send_time = this.mail_values[0].id;
        this.settingform.is_report_country = this.selection_values[0].id;
        this.settingform.region_ids = regionsAry;
        this.settingform.country_ids = countriesAry;

        let payload = this.settingform;
        await axios
          .post("profile/settings", payload)
          .then(async (response) => {
            this.loading = false;
            if (response.data.status == 1) {
              this.settingDialog = false;
              this.$swal({
                title: "Preferences",
                text: response.data.message,
              });
              this.settingform.report_send_time = [];
              this.settingform.is_report_country = [];
              this.settingform.region_ids = [];
              this.settingform.country_ids = [];
              await this.$store.dispatch("dashboard/fetchProfile");
            } else {
              this.$swal({
                showDenyButton: true,
                denyButtonText: `Ok`,
                showConfirmButton: false,
                title: "Error",
                text: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
      this.loading = true;
    },
    sendSocketMessage: function(event, data) {
      console.log(event, data);
      this.$socket.emit(event, data);
    },
    loadProfileData() {
      axios.get("/profile").then(async (response) => {
        if(response.data.data.portfolios != undefined
          && response.data.data.portfolios != null 
          && response.data.data.portfolios.length > 0){
            this.data = response.data.data.portfolios;
        }
        else{
          this.data = [];
          document.getElementsByClassName('helperContainer')[0].classList.add("d-none");
          document.getElementsByClassName('selectList')[0].style.marginBottom=0;
        }
        
        if(response.data.data.selected_portfolio != undefined 
          && response.data.data.selected_portfolio != null 
          && response.data.data.selected_portfolio.length > 0){
          this.values = response.data.data.selected_portfolio;
        }
        else{
          this.values = null;
        }
        
        let user_id = response.data.data.profile.id;
        let user_name = response.data.data.profile.name;
        localStorage.setItem("login-id", user_id);
        localStorage.setItem("login-name", user_name);
        localStorage.setItem(
          "stream-channel",
          "ib_price_stream_" + response.data.data.profile.id
        );

        localStorage.setItem(
          "login-type",
          response.data.data.profile.access_type
        );
        
        if (this.values != null && this.values.length > 0) {
          let portfolioKeys = [];
          for (let p_key in this.data) {
            portfolioKeys.push(this.data[p_key].id);
          }
          //this.filter_portfolio=portfolioKeys;
          //await this.$store.dispatch("dashboard/updateFilterFortfolio", portfolioKeys);
        }
        
        if (this.values != null && this.data.length > 5) {
          this.portfolioSearch = true;
        }

        this.appendLinks();
        let counterparties = response.data.data.counterparties;
        this.$store.dispatch("chart/fetchCounterparties", counterparties);
        this.$store.dispatch(
          "dashboard/fetchUserAccessType",
          response.data.data.profile.access_type
        );
      });
    },
    async loadSettingData() {
      var countriesObj = [];
      var regionsObj = [];
      this.country_values = [];

      let AllCountries = this.countries;
      let AllRegions = this.regions;
      let Allprofiles = this.profiles;

      let select_country_ids = new Array();

      if (
        typeof Allprofiles.country_ids != typeof undefined &&
        Allprofiles.country_ids != ""
      ) {
        select_country_ids = Allprofiles.country_ids.split(",").map(Number);
      }

      let select_region_ids = new Array();
      if (
        typeof Allprofiles.regions_ids != typeof undefined &&
        Allprofiles.regions_ids != ""
      ) {
        select_region_ids = Allprofiles.regions_ids.split(",").map(Number);
      }

      let is_report_country = Allprofiles.is_report_country;
      this.passwordform.name = Allprofiles.name;
      this.passwordform.email = Allprofiles.email;
      this.settingform.report_send_time = Allprofiles.report_send_time;
      this.settingform.is_report_country = Allprofiles.is_report_country;

      // mail values
      let mail_send_time = this.getMailingPreferencesSelected(
        Allprofiles.report_send_time
      );
      let mailObj = [
        { id: Allprofiles.report_send_time, name: mail_send_time },
      ];
      this.mail_values = mailObj;

      // country selection values
      let country_selection = this.getCountryPreferencesSelected(
        Allprofiles.is_report_country
      );
      let selectionObj = [{ id: is_report_country, name: country_selection }];
      this.selection_values = selectionObj;

      if (this.settingform.is_report_country == 2) {
        for (var i in AllCountries) {
          if (select_country_ids.includes(AllCountries[i].id)) {
            let myObj = { id: AllCountries[i].id };
            countriesObj.push(myObj);
          }
        }

        for (var j in AllRegions) {
          if (select_region_ids.includes(AllRegions[j].id)) {
            let myregionObj = { id: AllRegions[j].id };
            regionsObj.push(myregionObj);
          }
        }
      }

      this.country_values = countriesObj;
      this.regions_values = regionsObj;
    },
    appendLinks() {
      var checkBoxContainer = document.getElementsByClassName(
        "checkBoxContainer"
      );
      if(this.values != null && this.data != null){
        if (this.values.length < 5 && this.data.length < 5) {
          var helperButton = document.getElementsByClassName("helperButton")[0];
          helperButton.classList.add("d-none");
        }
        if (this.values.length == 1 && this.data.length == 1) {
          var selectList = document.getElementsByClassName("selectList")[0];
          selectList.classList.add("d-none");
        } else {
          var btn_div = document.createElement("div");
          btn_div.innerHTML =
            '<a class="submit_portfolio" href="javascript:;">OK</a>';
          checkBoxContainer[0].appendChild(btn_div);
        }
      }
      var basMainUrl = themeconfig.get_vue_app_login_url();

      // Create UL/LI Setting
      var ul = document.createElement("ul");
      ul.classList.add("user-details");

      // var li = document.createElement("li");
      // li.innerHTML =
      //   '<a class="dark-theme-active" v-bind:class="{ active: isDarkActive }">Dark Theme</a>';
      // ul.appendChild(li);

      var li3 = document.createElement("li");
      li3.innerHTML = '<a href="' + basMainUrl + '">Backoffice</a>';
      ul.appendChild(li3);

      var li5 = document.createElement("li");
      li5.innerHTML =
        '<a target="_blank" href="' +
        basMainUrl +
        'download-statement">Statements</a>';
      ul.appendChild(li5);

      var li2 = document.createElement("li");
      li2.innerHTML =
        '<a href="javascript:;" class="terminal_setting">Settings</a>';
      // li2.innerHTML =
      //   '<a target="_blank" href="' + basMainUrl + 'profile">Settings</a>';
      ul.appendChild(li2);

      var li4 = document.createElement("li");
      li4.innerHTML =
        '<a href="javascript:;" class="terminal_logout">Logout</a>';
      ul.appendChild(li4);

      var isAddedLinks = document.getElementsByClassName(
        ".checkBoxContainer ul.user-details"
      );
      if (!(isAddedLinks.length > 0)) {
        checkBoxContainer[0].appendChild(ul);
      }
      setTimeout(() => {
        this.loadBodyClass();
      }, 20);
    },
    handleClick(e) {
      this.settingDialog = false;
      if (e.target.matches(".submit_portfolio")) {
        let changePorfolio = this.values;
        if (changePorfolio.length > 0) {
          let portfolioKeys = [];
          for (let p in changePorfolio) {
            portfolioKeys.push(changePorfolio[p].id);
          }

          this.$store.dispatch(
            "dashboard/updateFilterFortfolio",
            portfolioKeys
          );
          this.$refs.portfolioMultiSelect.closeMultiSelect();
          // console.log('allchangeP',portfolioKeys);
          // console.log('filter_portfolio',this.filter_portfolio);
        } else {
          this.$swal({
            showDenyButton: true,
            denyButtonText: `Ok`,
            showConfirmButton: false,
            title: "Error",
            text: "Please select at least one portfolio.",
          });
          this.$refs.portfolioMultiSelect.openMultiSelect();
        }
      } else if (e.target.matches(".terminal_logout")) {
        localStorage.removeItem("user-token");
        localStorage.removeItem("login-type");
        localStorage.removeItem("login-id");
        localStorage.removeItem("current_security_id");
        localStorage.removeItem("search_security_data");
        window.location = themeconfig.get_vue_app_login_url() + "logout";
      } else if (e.target.matches(".dark-theme-active")) {
        var myBody = document.querySelector("body");
        myBody.classList.toggle("dark-theme");
        this.loadBodyClass();
        location.reload();
      } else if (e.target.matches(".terminal_setting")) {
        this.loadSettingData();
        this.settingDialog = true;
        var checkboxLayer = document.querySelector(".checkboxLayer");
        checkboxLayer.classList.remove("show");
      } else {
        return;
      }
    },
    onChangeRegion() {
      let allchangeR = this.regions_values;
      var regionKeys = [];
      for (var r in allchangeR) {
        regionKeys.push(allchangeR[r].id);
      }

      let AllCountries = this.countries;
      var countriesObj = [];
      for (var c in AllCountries) {
        if (regionKeys.includes(AllCountries[c].region_id)) {
          let myObj = {
            id: AllCountries[c].id,
            country_name: AllCountries[c].country_name,
            region_id: AllCountries[c].region_id,
          };
          countriesObj.push(myObj);
        }
      }
      this.country_values = countriesObj;
    },
  },
  async mounted() {
    await this.loadSettingData();
    await this.loadProfileData();
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.custome-select-wrap {
  order: 2;
  .select {
    .btn-select {
      height: 40px !important;
      display: flex;
      align-items: center;
      border: var(--select-btn) solid 1px;
      background-color: transparent;
      padding: 7px;
      line-height: 1;
      margin-top: 0;
      color: var(--select-btn);
      border-radius: 0;
      font-size: var(--font-family) !important;
      .buttonLabel {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > span:first-child {
          text-transform: capitalize;
          font-size: 14px !important;
          font-weight: 400;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 120px;
          line-height: 20px !important;
          text-align: left;
          white-space: nowrap;
        }
      }
      .caret {
        border-top: 7px solid var(--select-btn);
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        border-left: 7px solid transparent;
        border-bottom: 0 dotted;
      }
      &:focus {
        outline: none;
        color: var(--portfolio-hover-text2);
        background: var(--portfolio-hover-bg);
        .caret {
          border-top: 7px solid var(--portfolio-hover-caret);
        }
      }
      &:hover {
        color: var(--portfolio-hover-text2);
        background: var(--portfolio-hover-bg);
        .caret {
          border-top: 7px solid var(--portfolio-hover-caret);
        }
      }
    }
    .checkboxLayer {
      border-radius: 0;
      border: 1px solid var(--primary);
      &.show {
        left: inherit !important;
        right: 0;
        z-index: 9999;
      }
      .helperContainer {
        display: flex;
        flex-direction: column;
        padding: 0;
        .line {
          padding: 0;
          max-height: inherit;
          .inputFilter {
            border-radius: 0;
            width: 100%;
            padding: 7px 5px;
            border: none;
            border-bottom: 1px groove;
            outline: none;
            height: 36px;
            color: var(--portfolio-text);
            margin-bottom: 0;
            &:focus {
              box-shadow: none;
              outline: none;
            }
          }
          .clearButton {
            height: 35px;
            width: 35px;
            font-size: 25px;
            line-height: 25px;
            top: 0;
            border: none;
            color: var(--white);
            border-radius: 0;
            background: var(--portfolio-text);
            &:hover {
              opacity: 0.9;
            }
            &:focus {
              box-shadow: none;
              outline: none;
            }
          }
          .helperButton {
            display: inline-block;
            font-size: 0.9em;
            text-decoration: none;
            margin: 10px 10px 5px 7px;
            color: var(--blue-dark);
            border: none;
            line-height: 0.92em;
            border-bottom: 1px solid transparent;
            min-height: auto;
            &:hover {
              background: transparent;
              color: var(--portfolio-text);
              text-decoration: underline;
            }
            &:focus {
              box-shadow: none;
              outline: none;
            }
          }
          &:first-child {
            order: 2;
          }
        }
      }
      .tab {
        margin: 0;
        border: none;
        .tab-item {
          width: 100%;
          span {
            color: var(--black);
            margin: 0 0 10px;
            display: block;
            padding: 5px 0 0 0;
            font-weight: bold;
            text-shadow: none;
            font-size: 14px;
            line-height: 1;
            white-space: nowrap;
            text-align: center;
            border-bottom: none;
          }
        }
      }
      .checkBoxContainer {
        padding: 0 0;
        min-height: inherit;
        .selectList {
          padding: 0;
          .selectItem {
            display: flex;
            align-items: center;
            justify-content: left;
            padding: 4px;

            &:hover {
              background: var(--portfolio-hover-text);
              span {
                color: var(--white);
              }
            }
            span {
              margin-left: 5px;
              font-size: 14px;
              font-weight: 400;
              color: var(--portfolio-hover-text);
              &.right {
                display: none;
              }
            }
          }
        }
      }
    }
    .historyButton {
      display: none;
    }
    .dark-theme-active {
      display: none;
    }
    &.header-currency {
      margin-right: 10px;
      .btn-select {
        .buttonLabel {
          > span {
            &:first-child {
              width: 50px !important;
            }
          }
        }
      }
      .checkboxLayer {
        &.show {
          width: 92px;
          min-width: 92px;
        }
      }
    }
    &.regions-select {
      width: 100%;
      .btn-select {
        width: 100%;
        height: 50px !important;
        display: flex;
        align-items: center;
        background-color: var(--setting-form-control-bg);
        border: 1px solid transparent;
        color: var(--setting-form-control-text);
        padding: 6px 12px;
        line-height: 1;
        margin-top: 0;
        border-radius: 0;
        font-size: var(--font-family) !important;
        .buttonLabel {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > span:first-child {
            width: auto;
            text-transform: capitalize;
            font-size: 13px !important;
            font-weight: 500;
            line-height: 16px !important;
            text-align: left;
            padding: 20px 0 0;
            @media only screen and (min-width: 1799px) {
              font-size: 14px !important;
            }
            @media only screen and (max-width: 1399px) {
              font-size: 12px !important;
            }
          }
        }
        .caret {
          border-top: 7px solid var(--setting-form-control-text);
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
          border-left: 7px solid transparent;
          border-bottom: 0 dotted;
        }
        &:focus {
          outline: none;
          border: 1px solid var(--portfolio-hover-text) !important;
          outline: 0 !important;
          box-shadow: 0 0 6px 2px rgba(var(--primary-rgb), 0.15) !important;
          .caret {
            border-top: 7px solid var(--portfolio-hover-text);
          }
        }
        &:hover {
          .caret {
            border-top: 7px solid var(--table-hover-bg);
          }
        }
      }
      .checkboxLayer {
        left: 0;
        right: inherit;
        width: 100%;
        bottom: 100%;
        .checkBoxContainer {
          max-height: 180px;
          .selectList {
            .selectItem {
              font-size: 13px !important;
              @media only screen and (min-width: 1799px) {
                font-size: 14px !important;
              }
              @media only screen and (max-width: 1399px) {
                font-size: 12px !important;
              }
              &:hover {
                background: var(--portfolio-hover-text) !important;
              }
            }
          }
        }
      }
    }
    &.bottom-open-select {
      .checkboxLayer {
        width: 100%;
        bottom: inherit;
        top: 100%;
      }
    }
  }

  &.currency-wrap {
    order: 1;
    .select {
      .checkboxLayer {
        .checkBoxContainer {
          .selectList {
            .selectItem {
              span {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
.setting-wrap {
  .setting-form {
    padding: 10px 15px;
    .mb-10 {
      margin-bottom: 12px !important;
    }
    .row {
      .padding-x {
        padding-top: 0.5rem !important;
        padding-bottom: 0 !important;
        fieldset {
          margin-bottom: 0;
        }
      }
    }
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-card__text {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    .v-card__actions {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      display: block;
    }
    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 8px;
      .v-radio {
        > .v-label {
          font-family: var(--font-family) !important;
          font-weight: 400 !important;
          font-size: 13px !important;
          color: var(--modal-title) !important;
          margin-bottom: 0px !important;
        }
      }
      .v-messages {
        display: none !important;
      }
    }
    .primary--text {
      &.v-icon {
        color: var(--radio-active-bg) !important;
        caret-color: var(--radio-active-bg) !important;
      }
    }
    .dark-theme-switch {
      margin-top: 0;
      padding-top: 0;
      .v-input__slot {
        margin-bottom: 0 !important;
      }
      .v-label {
        font-family: var(--font-family) !important;
        font-weight: 400 !important;
        font-size: 13px !important;
        color: var(--modal-title) !important;
        margin-bottom: 0px !important;
      }
      .v-messages {
        display: none !important;
      }
      .v-input--selection-controls__input {
        margin: 0 16px;
      }
    }
    h5 {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 16px;
      color: var(--modal-title);
      margin-bottom: 10px;
    }
    .form-group {
      width: 100% !important;
      margin: 0;

      .form-control {
        color: var(--setting-form-control-text) !important;
        background-color: var(--setting-form-control-bg) !important;
        label {
          top: 10px !important;
        }
      }
      .v-input {
        .v-input__slot input {
          max-height: 35px;
          line-height: 17px;
          color: var(--setting-form-control-text) !important;
        }
      }
      .v-input--is-focused {
        border: 1px solid var(--setting-form-control-focus) !important;
        outline: 0 !important;
        box-shadow: 0 0 6px 2px
          rgba(var(--setting-form-control-focus-rgb), 0.15) !important;
      }
    }
    .primary-btn {
      margin-right: 0;
      color: var(--update-btn-text);
      background-color: var(--update-btn-bg);
      border: 1px solid var(--update-btn-bg);
      line-height: 1;
      display: block;
      margin-bottom: 0;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      white-space: nowrap;
      padding: 5px 12px;
      font-size: 13px;
      border-radius: 0;
      width: 80px;
      margin: 0;
      height: 30px;
      text-transform: capitalize;
      &:hover,
      &:focus {
        outline: none !important;
        box-shadow: none !important;
        color: var(--update-btn-bg);
        background-color: var(--update-btn-text);
        border-color: var(--update-btn-bg);
      }
    }
  }
  .tabs {
    .nav-tabs {
      border-bottom: 1px solid var(--watchlist-inner-tab-bg);
      padding: 0 !important;
      margin: 10px 15px 0;
      .nav-item {
        width: 50% !important;
        padding: 0 !important;
        background: transparent !important;
        a {
          width: 100%;
          padding: 0.25rem 0.5rem;
          font-size: 14px;
          font-weight: 400;
          color: var(--setting-tab-text);
          border: none;
          border-radius: 0;
          background: transparent;
          &.active,
          &:hover {
            background-color: var(--watchlist-inner-tab-bg);
            border: none;
            color: var(--watchlist-inner-tab-text);
            border-radius: 0;
            border-bottom: 1px solid var(--watchlist-inner-tab-bg);
          }
          &:hover {
            background-color: var(--watchlist-inner-tab-bg);
            color: var(--watchlist-inner-tab-text);
          }
        }
      }
    }
  }
  .subtitle {
    font-size: 13px !important;
    font-weight: 400 !important;
    color: var(--subtitle-text) !important;
  }
}
.regions-wrap {
  > .subtitle {
    position: absolute;
    top: 10px;
    z-index: 99;
    left: 28px;
    color: var(--grey) !important;
  }
  &.max-height-regions {
    .select {
      .checkboxLayer {
        .checkBoxContainer {
          max-height: 230px;
        }
      }
    }
  }
}
.portfolio-wrap {
  .portfolio-select {
    .checkboxLayer {
      .checkBoxContainer {
        max-height: inherit !important;
        .selectList {
          padding: 0;
          max-height: 250px;
          overflow-y: auto;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
