var theme = 'emfi';
var vue_app_api_endpoint = 'http://login.emfi.local/api/workstation/';
var vue_app_login_url = 'http://login.emfi.local/';
// var vue_app_front_url = 'http://web.emfi.local/';
// var vue_app_socket_url ='http://localhost:3000';

export default {
   getTheme: function () {
      return this.getThemeoption();
   },
   get_vue_app_api_endpoint: function () {
      return this.setDomainBaseTheme('get_vue_app_api_endpoint');
   },
   get_vue_app_login_url: function () {
    return this.setDomainBaseTheme('get_vue_app_login_url');
   },
  //  get_vue_app_front_url: function () {
  //   return vue_app_front_url;
  //  },
  //  get_vue_app_socket_url: function () {
  //   return vue_app_socket_url;
  //  },
   getThemeTitle: function () {
    return theme.toUpperCase() +' | Workstation';
   },
   setTheme: function (val) {
    theme = val;
   },
   getThemeoption: function (){
    //  console.log("in file",theme);
     let options = {
          "emfi":{
            "theme_logo":require("@/assets/images/emfi-logo.svg"),
            "theme_class":"theme-emfi",
            "theme_favicon":"https://www.emfi.uk/favicon.ico",
            "chart_primary_color":"#001a34",
            "chart_secondary_color":"",
            "chart_for_color":"#333333",
            "chart_grid_border_color":"#dbdbdb"
          },
          "fix":{
            "theme_logo":require("@/assets/images/emfi-logo.svg"),
            "theme_class":"theme-emfi",
            "theme_favicon":"https://www.emfi.uk/favicon.ico",
            "chart_primary_color":"#001a34",
            "chart_secondary_color":"",
            "chart_for_color":"#333333",
            "chart_grid_border_color":"#dbdbdb"
          },
          "mmg":{
            "theme_logo":require("@/assets/images/mmg-images/mmg-logo.png"),
            "theme_class":"theme-mmg",
            "theme_favicon":require("@/assets/images/mmg-images/favicon.png"),
            "chart_primary_color":"#2e4b34",
            "chart_secondary_color":"",
            "chart_for_color":"#333333",
            "chart_grid_border_color":"#dbdbdb"
          },
          "iam":{
            "theme_logo":require("@/assets/images/iam-images/iam-logo.jpg"),
            "theme_class":"theme-iam",
            "theme_favicon":require("@/assets/images/iam-images/favicon.png"),
            "chart_primary_color":"#534741",
            "chart_secondary_color":"",
            "chart_for_color":"#333333",
            "chart_grid_border_color":"#dbdbdb"
          },
          "catfp":{
            "theme_logo":require("@/assets/images/catfp-images/cat-financial-products.png"),
            "theme_class":"theme-catfp",
            "theme_favicon":require("@/assets/images/catfp-images/favicon.png"),
            "chart_primary_color":"#aa1736",
            "chart_secondary_color":"",
            "chart_for_color":"#333333",
            "chart_grid_border_color":"#dbdbdb"
          },
          "ugm":{
            "theme_logo":require("@/assets/images/ugm-images/ugm-logo.png"),
            "theme_class":"theme-ugm",
            "theme_favicon":require("@/assets/images/ugm-images/favicon.png"),
            "chart_primary_color":"#593b26",
            "chart_secondary_color":"",
            "chart_for_color":"#333333",
            "chart_grid_border_color":"#dbdbdb"
          },
          "snowdenlane":{
            "theme_logo":require("@/assets/images/slp-images/slp-logo.svg"),
            "theme_class":"theme-slp",
            "theme_favicon":require("@/assets/images/slp-images/favicon.png"),
            "chart_primary_color":"#e2641f",
            "chart_secondary_color":"",
            "chart_for_color":"#444444",
            "chart_grid_border_color":"#dbdbdb"
          },
          "dolfin":{
            "theme_logo":require("@/assets/images/dolfin-images/dolfin-logo.svg"),
            "theme_class":"theme-dolfin",
            "theme_favicon":require("@/assets/images/dolfin-images/favicon.png"),
            "chart_primary_color":"#546b99",
            "chart_secondary_color":"",
            "chart_for_color":"#444444",
            "chart_grid_border_color":"#dbdbdb"
          },
          "fiig":{
            "theme_logo":require("@/assets/images/fiig-images/fiig-logo-white.svg"),
            "theme_class":"theme-fiig",
            "theme_favicon":require("@/assets/images/fiig-images/favicon.png"),
            "chart_primary_color":"#20a5de",
            "chart_secondary_color":"",
            "chart_for_color":"#113f60",
            "chart_grid_border_color":"#dbdbdb"
          },
          "inoncapital":{
            "theme_logo":require("@/assets/images/inon-images/inon-logo.png"),
            "theme_class":"theme-inon",
            "theme_favicon":require("@/assets/images/inon-images/favicon.png"),
            "chart_primary_color":"#113f60",
            "chart_secondary_color":"",
            "chart_for_color":"#113f60",
            "chart_grid_border_color":"#dbdbdb"
          },
          "corumag":{
            "theme_logo":require("@/assets/images/corumag-images/corumch-logo-white.svg"),
            "theme_class":"theme-corumag",
            "theme_favicon":require("@/assets/images/corumag-images/favicon.png"),
            "chart_primary_color":"#bc3846",
            "chart_secondary_color":"",
            "chart_for_color":"#bc3846",
            "chart_grid_border_color":"#dbdbdb"
          }
      };
     return options[theme];
   },
  //  set theme based on domain
  setDomainBaseTheme: function (returnURL='') {
    const hoststr = window.location.host;
    // const hoststr = "https://catfp-workstation.emfi.uk/";
    // const hoststr = 'https://demoworkstation.emfi.uk';
    // const hoststr = 'https://workstation.emfi.uk';
    // console.log("hoststr=>",hoststr);
    //check is local or not
    const islive = hoststr.includes("workstation.emfi.uk");
    let themename = 'emfi';
    let host = '';
    let parts='';
    if(islive){
      //check http include ot not
      const ishttpinclude = hoststr.includes("http");
      if(ishttpinclude){
        const strhttp = "http://";
        const strhttps = "https://";
        const ishttp = hoststr.includes(strhttp);
        const ishttps = hoststr.includes(strhttps);
        if(ishttps){
          host = hoststr.split(strhttps);
        }
        if(ishttp){
          host =hoststr.split(strhttp);
        }
        // console.log("host",host);
        parts = host[1].split('-');
      }else{
        host = hoststr;
        // console.log("host",host);
        parts = host.split('-');
      }
      // console.log("parts",parts);
    
      themename = parts[0];
      // console.log("domain subdomainStr 0 parts",parts[0]);
      if(parts[0] == 'workstation.emfi.uk' || parts[0] =='demoworkstation.emfi.uk'){
        // console.log("call in live or demo =>",parts[0]);
        themename = 'emfi';
        //live
        if(parts[0] == 'workstation.emfi.uk'){
          vue_app_api_endpoint = process.env.VUE_APP_API_ENDPOINT.replace('[[BASEURL]]','login.emfi.uk');
          vue_app_login_url = process.env.VUE_APP_LOGIN_URL.replace('[[BASEURL]]','login.emfi.uk');
        }
        //demo
        if(parts[0] =='demoworkstation.emfi.uk'){
          vue_app_api_endpoint = process.env.VUE_APP_API_ENDPOINT.replace('[[BASEURL]]','demo.emfi.uk');
          vue_app_login_url = process.env.VUE_APP_LOGIN_URL.replace('[[BASEURL]]','demo.emfi.uk');
        }
      }else{
        //all branding 
        // console.log("call here=>",themename);
        let setURL = themename+'.emfi.uk';
        vue_app_api_endpoint = process.env.VUE_APP_API_ENDPOINT.replace('[[BASEURL]]',setURL);
        vue_app_login_url = process.env.VUE_APP_LOGIN_URL.replace('[[BASEURL]]',setURL);
        
      }
    }else{
      // console.log("call inn local setting");
      vue_app_api_endpoint = process.env.VUE_APP_API_ENDPOINT.replace('[[BASEURL]]','login.emfi.local');
      vue_app_login_url = process.env.VUE_APP_LOGIN_URL.replace('[[BASEURL]]','login.emfi.local');
    }
    // console.log("in themeconfig vue_app_api_endpoint",vue_app_api_endpoint);
    // console.log("in themeconfig vue_app_login_url",vue_app_login_url);
    // console.log("domain parts",themename);
    // themename = 'corumag';
    this.setTheme(themename);
    if(returnURL == 'get_vue_app_login_url'){
      return vue_app_login_url;
    }
    if(returnURL == 'get_vue_app_api_endpoint'){
      return vue_app_api_endpoint;
    }
     return true;
 },
}