<template>
  <div class="funds-wrap">
    <v-card class="custome-card" id="funds_list">
      <v-data-table
        id="funds-scroll-table"
        :headers="headers"
        :items="items"
        :search="search"
        hide-default-footer
        class="custome-table fund-table"
        disable-pagination
        item-key="id"
        fixed-header
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :style="{ height: CompFundsHeight + 'px' }"
        @click:row="order_sidebar"
      >
        <template v-slot:body.prepend>
          <tr class="table-dropdown sticky-header">
            <td width="18%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.security_name"
                  @change="fetchSecurities(1)"
                ></v-text-field>
              </div>
            </td>
            <td width="7.77%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.isin_code"
                  @change="fetchSecurities(1)"
                ></v-text-field>
              </div>
            </td>
            <td width="7.77%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.currency"
                  :items="currency_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="7.77%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.country"
                  :items="countries_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="7.77%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.investment_area"
                  :items="area_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="10%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.global_category"
                  :items="gcategory_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="5.27%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.mstar_rating"
                  :items="rating_list"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="12.27%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.company_name"
                  :items="company_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="7.77%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.category"
                  :items="category_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="7.77%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.peer_group"
                  :items="peers_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="7.77%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="number"
                  hide-details="auto"
                  v-model="search_table.last_price"
                ></v-text-field>
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:[`item.security_name`]="{ item }">
          <span class="teble-ellipsis-wrap security_w">
            {{ item.security_name }}
          </span>
        </template>
        <template v-slot:[`item.isin_code`]="{ item }">
          <span class="teble-ellipsis-wrap identifier_wrap">
            {{ item.isin_code }}
          </span>
        </template>
        <template v-slot:[`item.country_name`]="{ item }">
          <span class="teble-ellipsis-wrap country-name-wrap">
            {{ item.country_name }}
          </span>
        </template>
        <template v-slot:[`item.currency`]="{ item }">
          <span class="teble-ellipsis-wrap currency-wrap">
            {{ item.currency }}
          </span>
        </template>
        <template v-slot:[`item.investment_area`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ item.investment_area }}
          </span>
        </template>
        <template v-slot:[`item.global_category`]="{ item }">
          <span class="teble-ellipsis-wrap global-category">
            {{ item.global_category }}
          </span>
        </template>
        <template v-slot:[`item.mstar_rating`]="{ item }">
          <!-- <span class="teble-ellipsis-wrap"> -->
          <v-rating
            :v-model="parseFloat(item.mstar_rating)"
            :length="item.mstar_rating"
            color="yellow lighten-3"
            background-color="grey lighten-1"
            x-small
            readonly
          ></v-rating> 
          <!-- </span> -->
        </template>
        <template v-slot:[`item.company_name`]="{ item }">
          <span class="teble-ellipsis-wrap company-name-wrap">
            {{ item.company_name }}
          </span>
        </template>

        <template v-slot:[`item.category`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ item.category }}
          </span>
        </template>
        <template v-slot:[`item.peer_group`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ item.peer_group }}
          </span>
        </template>
        <template v-slot:[`item.last_price`]="{ item }">
          <span
            class="teble-ellipsis-wrap fund-ellipsis ask-price"
            :class="item.market_id == 2 ? ' ' : 'hover-td-values fund-hover'"
          >
            <div class="value-none m-auto text-center">
              {{ formatFundPrice(item.last_price, 2) }}
            </div>
            <div class="hover-wrap" v-if="item.market_id != 2">
              <template>
                <b-button
                  v-if="is_maximize == 1"
                  @click.stop="order_sidebar(item)"
                  variant="danger"
                  >Sell</b-button
                >
                <b-button
                  v-if="is_maximize == 0"
                  @click.stop="selectItem(item, '1')"
                  variant="danger"
                  >Sell</b-button
                >
              </template>
              <template>
                <b-button
                  v-if="is_maximize == 1"
                  @click.stop="order_sidebar(item)"
                  variant="success"
                  >Buy</b-button
                >
                <b-button
                  v-if="is_maximize == 0"
                  @click.stop="selectItem(item, '1')"
                  variant="success"
                  >Buy</b-button
                >
              </template>
            </div>
          </span>
        </template>
      </v-data-table>
    </v-card>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
    <template>
      <v-navigation-drawer
        class="order-form-sidebar"
        width="26%"
        v-model="drawer"
        absolute
        temporary
        right
      >
        <div class="height-50 bb-4 align-items-start">
          <TickerDetails formName="fund_order_form" />
        </div>
        <div class="height-50 SideBarTradingChart" v-if="is_maximize == 1 && active_tab=='Funds'">
          <!-- <TradingChart ChartOrigin="Funds" /> -->
          <SideBarTradingChart ChartOrigin="Funds" CompisTradingChartOpen='isTradingChartOpen' :TraddingChartHeight="400" :TraddingChartWidth="300" v-if="isTradingChartOpen"/>
        </div>
      </v-navigation-drawer>
      <a class="filter-sticky-icon rightside" @click="opensidebarDrawer()">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a>
    </template>
    <template>
      <v-navigation-drawer
        class="filter-sidebar"
        width="26%"
        v-model="ScannerDrawer"
        absolute
        temporary
      >
        <div class="sidebar-header">
          <h3>Scanner ({{ total_record }})</h3>
          <a class="cancle-btn" @click="clearScanner()">Clear all</a>
        </div>
        <form>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.currency_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.currency_val"
              :items="currency_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Currencies"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.country_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.country_val"
              :items="countries_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Countries"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.area_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.area_val"
              :items="area_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Area"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.global_category_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.global_category_val"
              :items="gcategory_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Global Category"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.rating_oprator"
              :items="number_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <!-- <v-select
              class="form-control multi-select"
              v-model="search_table.rating_val"
              :items="rating_data"
              chips
              multiple
              label="Rating"
            ></v-select> -->
            <v-autocomplete
              v-model="search_table.rating_val"
              :items="rating_list"
              dense
              filled
              clearable
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Morningstar"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.company_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.company_val"
              :items="company_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Company"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.category_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.category_val"
              :items="category_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Category"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.peer_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.peer_val"
              :items="peers_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Peer Group"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>
          <!-- <div class="form-group">
            <v-autocomplete
              v-model="search_table.nav_oprator"
              :items="number_oprator_list"
              dense
              filled
              clearable
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="text"
              hide-details="auto"
              v-model="search_table.nav_val"
              label="NAV"
            ></v-text-field>
          </div> -->
          <div class="btn-group justify-content-end">
            <a class="submit_portfolio" @click="fetchSecurities(1)">Scan</a>
          </div>
        </form>
      </v-navigation-drawer>
      <a class="filter-sticky-icon" @click="ScannerDrawer = true">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a>
    </template>
  </div>
</template>
<script>
import TickerDetails from "@/components/TickerDetails.vue";
import SideBarTradingChart from "@/components/SideBarTradingChart.vue";
export default {
  name: "Funds",
  components: { TickerDetails: TickerDetails, SideBarTradingChart: SideBarTradingChart },
  data() {
    return {
      traddingHeight:397,
      isTradingChartOpen:false,
      dialog: false,
      drawer: null,
      ScannerDrawer: null,
      headers: [
        {
          text: "Name",
          align: "left security_name",
          value: "security_name",
          width: "18%",
        },
        {
          text: "Identifier",
          align: "left",
          value: "isin_code",
          width: "7.77%",
        },
        {
          text: "Currency",
          align: "left",
          value: "currency",
          width: "7.77%",
        },
        {
          text: "Country",
          align: "left",
          value: "country_name",
          width: "7.77%",
        },
        {
          text: "Area",
          align: "left",
          value: "investment_area",
          width: "7.77%",
        },
        {
          text: "Global Category",
          align: "left",
          value: "global_category",
          width: "10%",
        },
        {
          text: "Morningstar",
          align: "center",
          value: "mstar_rating",
          width: "5.27%",
        },
        {
          text: "Company",
          align: "left",
          value: "company_name",
          width: "9.77%",
        },
        {
          text: "Category",
          align: "left",
          value: "category",
          width: "7.77%",
        },
        {
          text: "Peer Group",
          align: "left",
          value: "peer_group",
          width: "7.77%",
        },
        {
          text: "NAV",
          align: "center",
          width: "7.77%",
          value: "last_price",
          class: "sticky-header",
          filter: (value) => {
            if (!this.search_table.last_price) return true;
            if (value) {
              return String(value).search(this.search_table.last_price) > -1;
            }
          },
        },
      ],
      rating_length: 5,
      search: "",
      loading: false,
      sortBy: "security_name",
      sortDesc: false,
      menu2: false,
      item_details: [],
      search_table: {
        security_name: null,
        name_oprator: "equal",
        isin_oprator: "equal",
        currency_oprator: "equal",
        country_oprator: "equal",
        area_oprator: "equal",
        global_category_oprator: "equal",
        rating_oprator: "equal",
        company_oprator: "equal",
        category_oprator: "equal",
        peer_oprator: "equal",
        security_val: null,
        isin_val: null,
        currency_val: null,
        country_val: null,
        area_val: null,
        global_category_val: null,
        rating_val: null,
        company_val: null,
        category_val: null,
        peer_val: null,
        nav_oprator: "greater_than",
        nav_val: 0,
      },
      text_oprator_list: [
        { text: "Equal", value: "equal" },
        { text: "Not Equal", value: "not_equal" },
      ],
      number_oprator_list: [
        { text: "Equal", value: "equal" },
        { text: "Not Equal", value: "not_equal" },
        { text: "Greater Than", value: "greater_than" },
        { text: "Less Than", value: "less_than" },
      ],
      rating_list: [
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 },
      ],
    };
  },
  computed: {
    active_tab: {
      get() {
        let at = this.$store.getters["dashboard/getActiveTab"];
        return at ? at:'Funds';
      },
    },
    countries_data() {
      let countries = this.$store.getters["dashboard/getCountriesSecurity"];
      let countries_data = [];
      for (var i in countries) {
        if (countries[i].market_id == "8") {
          countries_data.push({
            text: countries[i].country_name,
            value: countries[i].country_id,
          });
        }
      }
      return countries_data;
    },
    currency_data() {
      let currencies = this.$store.getters["dashboard/getCurrencySecurity"];
      let currency_data = [];
      for (var i in currencies) {
        if (currencies[i].market_id == "8") {
          currency_data.push({
            text: currencies[i].currency,
            value: currencies[i].currency,
          });
        }
      }
      return currency_data;
    },
    ticker_data() {
      let tickers = this.$store.getters["dashboard/getTickerSecurity"];
      let tickers_data = [];
      for (var i in tickers) {
        if (tickers[i].market_id == "8") {
          tickers_data.push({
            text: tickers[i].ticker_name,
            value: tickers[i].ticker_id,
          });
        }
      }
      return tickers_data;
    },
    area_data() {
      let areas = this.$store.getters["dashboard/getArea"];
      let areas_data = [];
      for (var i in areas) {
        areas_data.push({
          text: areas[i].investment_area,
          value: areas[i].investment_area,
        });
      }
      return areas_data;
    },
    gcategory_data() {
      let gcategory = this.$store.getters["dashboard/getGcategory"];
      let gcategory_data = [];
      for (var i in gcategory) {
        gcategory_data.push({
          text: gcategory[i].global_category,
          value: gcategory[i].global_category,
        });
      }
      return gcategory_data;
    },
    rating_data() {
      let rating = this.$store.getters["dashboard/getRating"];
      let rating_data = [];
      for (var i in rating) {
        rating_data.push({
          text: rating[i].mstar_rating,
          value: rating[i].mstar_rating,
        });
      }
      return rating_data;
    },
    company_data() {
      let company = this.$store.getters["dashboard/getCompany"];
      let company_data = [];
      for (var i in company) {
        company_data.push({
          text: company[i].company_name,
          value: company[i].company_name,
        });
      }
      return company_data;
    },
    category_data() {
      let category = this.$store.getters["dashboard/getCategory"];
      let category_data = [];
      for (var i in category) {
        category_data.push({
          text: category[i].category,
          value: category[i].category,
        });
      }
      return category_data;
    },
    peers_data() {
      let peers = this.$store.getters["dashboard/getPeers"];
      let peers_data = [];
      for (var i in peers) {
        peers_data.push({
          text: peers[i].peer_group,
          value: peers[i].peer_group,
        });
      }
      return peers_data;
    },
    items() {
      let items = this.$store.getters["funds/getItems"];
      return items;
    },
    default_market_id() {
      return this.$store.getters["funds/getDefaultMarketId"];
    },
    next_page_index() {
      return this.$store.getters["funds/getNextPage"];
    },
    record_length() {
      return this.$store.getters["funds/getLengthRecord"];
    },
    start_record() {
      return this.$store.getters["funds/getStartRecord"];
    },
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
    total_record() {
      let total_record = new Intl.NumberFormat("en-US").format(
        this.$store.getters["funds/getTotalRecord"]
      );
      return total_record;
    },
    is_fund_scanner() {
      return this.$store.getters["funds/getIsFundFilter"];
    },
  },
  watch: {
    async CompEquitiesHeight(new_val) {
      if (new_val > 0) {
        this.$store.dispatch("dashboard/updateActiveTab", "Funds");
        await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 0);
      }
    },
    async is_fund_scanner(is_fund_scanner) {
      var fundClear = document.getElementById("funds-clear-btn");
      if (is_fund_scanner == 1) {
        fundClear.classList.remove("d-none");
      } else {
        fundClear.classList.add("d-none");
      }
    },
  },
  props: ["CompisFundsOpen","CompFundsHeight"],
  methods: {
    opensidebarDrawer(){
      // console.log("call opensidebarDrawer :: component mounted====>");
      // this.active_tab = this.active_tab ? this.active_tab :'Funds';
      this.drawer = this.drawer ? false :true;
      this.isTradingChartOpen= true;
    },
    async successAlert(msg) {
      this.$swal({
        title: "",
        showCancelButton: false,
        text: msg,
      });
    },
    async filterClear() {},
    async erroeAlert(msg) {
      this.$swal({
        title: "Oops...",
        text: msg,
      });
    },
    async fetchSecurities(is_filter) {
      this.loading = true;
      if (is_filter == 1) {
        await this.resetPageIndex();
      }
      await this.$store.dispatch("funds/fetchItems", {
        market_id: this.default_market_id,
        page: this.next_page_index,
        start: this.start_record,
        length: this.record_length,
        is_filter: is_filter,
        security_name: this.search_table.security_name,
        isin_code: this.search_table.isin_code,
        currency: this.search_table.currency,
        country: this.search_table.country,
        investment_area: this.search_table.investment_area,
        global_category: this.search_table.global_category,
        mstar_rating: this.search_table.mstar_rating,
        company_name: this.search_table.company_name,
        category: this.search_table.category,
        peer_group: this.search_table.peer_group,
        name_oprator: this.search_table.name_oprator,
        security_val: this.search_table.security_val,
        isin_oprator: this.search_table.isin_oprator,
        isin_val: this.search_table.isin_val,
        currency_oprator: this.search_table.currency_oprator,
        currency_val: this.search_table.currency_val,
        country_oprator: this.search_table.country_oprator,
        country_val: this.search_table.country_val,
        area_oprator: this.search_table.area_oprator,
        area_val: this.search_table.area_val,
        global_category_oprator: this.search_table.global_category_oprator,
        global_category_val: this.search_table.global_category_val,
        rating_oprator: this.search_table.rating_oprator,
        rating_val: this.search_table.rating_val,
        company_oprator: this.search_table.company_oprator,
        company_val: this.search_table.company_val,
        category_oprator: this.search_table.category_oprator,
        category_val: this.search_table.category_val,
        peer_oprator: this.search_table.peer_oprator,
        peer_val: this.search_table.peer_val,
        nav_oprator: this.search_table.nav_oprator,
        nav_val: this.search_table.nav_val,
      });
      this.loading = false;
    },
    async clearScanner() {
      this.search_table.security_name = null;
      this.search_table.isin_code = null;
      this.search_table.currency = null;
      this.search_table.country = null;
      this.search_table.investment_area = null;
      this.search_table.global_category = null;
      this.search_table.mstar_rating = null;
      this.search_table.company_name = null;
      this.search_table.category = null;
      this.search_table.peer_group = null;
      this.search_table.name_oprator = "equal";
      this.search_table.security_val = null;
      this.search_table.isin_oprator = "equal";
      this.search_table.isin_val = null;
      this.search_table.currency_oprator = "equal";
      this.search_table.currency_val = null;
      this.search_table.country_oprator = "equal";
      this.search_table.country_val = null;
      this.search_table.area_oprator = "equal";
      this.search_table.area_val = null;
      this.search_table.global_category_oprator = "equal";
      this.search_table.global_category_val = null;
      this.search_table.rating_oprator = "equal";
      this.search_table.rating_val = null;
      this.search_table.company_oprator = "equal";
      this.search_table.company_val = null;
      this.search_table.category_oprator = "equal";
      this.search_table.category_val = null;
      this.search_table.peer_oprator = "equal";
      this.search_table.peer_val = null;
      this.search_table.nav_oprator = "greater_than";
      this.search_table.nav_val = 0;
      this.ScannerDrawer = false;
      await this.fetchSecurities(1);
    },
    async selectItem(security_items, is_order) {
      let securityId = security_items.id;
      let ib_conid = security_items.ib_conid;
      if (securityId > 0) {
        if (is_order == "1") {
          await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 1);
        } else {
          await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 0);
        }
        await this.$store.dispatch("chart/updateDefaultChart", {
          security_id: securityId,
        });

        await this.$store.dispatch("chart/updateConId", {
          ib_conid: ib_conid,
        });
        // this.loading = true;
        await this.updateFormQuantity("0");
        await this.loadSecurity(securityId, []);
        // this.loading = false;
      }
    },
    async order_sidebar(security_items) {
      if (this.is_maximize == 1) {
        // this.drawer = !this.drawer;
        this.opensidebarDrawer();
      }
      await this.selectItem(security_items, 0);
    },
    async resetPageIndex() {
      await this.$store.dispatch("funds/resetPageIndex", {
        next_page_index: 1,
        start_record: 0,
      });
    },
  },
  async mounted() {
    if(this.CompisFundsOpen){
      // console.log("call Funds component mounted order");
      await this.fetchSecurities(0);
      let currenctDom = this;
      // Detect when scrolled to bottom.
      setTimeout(() => {
        var x = document.getElementsByClassName("lm_header");
        for (let i = 0; i < x.length; i++) {
          let isFind = x[i].querySelectorAll("li[title='Funds']").length;
          if (isFind > 0) {
            var filterClearBtn = document.createElement("BUTTON");
            filterClearBtn.innerHTML =
              ' <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><path d="M 1 0 C 0.448 0 0 0.448 0 1 L 0 4 C 0 4.255 0.10625 4.5015 0.28125 4.6875 L 14.6875 20 L 29.3125 20 L 43.71875 4.6875 C 43.75375 4.6505 43.7845 4.6035 43.8125 4.5625 C 43.8555 4.4995 43.9095 4.447 43.9375 4.375 C 43.9665 4.301 43.95775 4.203 43.96875 4.125 C 43.97575 4.077 44 4.049 44 4 L 44 1 C 44 0.448 43.553 0 43 0 L 1 0 z M 16 22 L 16 39 C 16 39.352 16.199 39.66375 16.5 39.84375 L 26.5 45.84375 C 26.659 45.93875 26.822 46 27 46 C 27.17 46 27.347 45.962 27.5 45.875 C 27.814 45.697 28 45.36 28 45 L 28 22 L 16 22 z M 32.90625 31.96875 A 1.0001 1.0001 0 0 0 32.78125 32 A 1.0001 1.0001 0 0 0 32.28125 33.71875 L 39.5625 41 L 32.28125 48.28125 A 1.016466 1.016466 0 1 0 33.71875 49.71875 L 41 42.4375 L 48.28125 49.71875 A 1.016466 1.016466 0 1 0 49.71875 48.28125 L 42.4375 41 L 49.71875 33.71875 A 1.0001 1.0001 0 0 0 48.875 32 A 1.0001 1.0001 0 0 0 48.28125 32.28125 L 41 39.5625 L 33.71875 32.28125 A 1.0001 1.0001 0 0 0 32.90625 31.96875 z"/></svg>';
            filterClearBtn.classList = "filter-button maximize-filter-btn d-none";
            filterClearBtn.id = "funds-clear-btn";
            x[i].appendChild(filterClearBtn);
            filterClearBtn.onclick = async function() {
              await currenctDom.clearScanner();
            };
          }
        }
      }, 1000);

      const listElm = document.querySelector("#funds-scroll-table");
      listElm.addEventListener("scroll", function() {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (currenctDom.next_page_index != 0) {
            currenctDom.fetchSecurities(0);
          }
        } else {
          this.loading = false;
        }
      });
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";

.funds-wrap {
  padding: 0;
  #funds-scroll-table {
    overflow: auto;
  }
}
.v-dialog {
  border-radius: 0 !important;
  background: var(--modal-bg);
  .custome-modal {
    &.v-card {
      border-radius: 0;
      background: transparent;
      box-shadow: none !important;
      .v-card__title {
        padding: 10px 15px;
        border-bottom: 1px solid var(--modal-title);
        .box-title {
          width: 100%;
          h4 {
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 18px;
            color: var(--modal-title);
            margin-bottom: 0;
          }
        }
        .table-button-groups {
          .btn {
            height: 30px;
            font-size: 13px !important;
          }
        }
      }
      .v-card__text {
        padding: 0 0 10px;
        ul {
          list-style: none;
          padding: 0 !important;
          margin: 0 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          li {
            width: 100%;
            background-color: transparent !important;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: 5px 8px;
            span {
              &.text-title {
                color: var(--modal-list-text);
                font-size: 13px;
                font-weight: 500;
                width: 40%;
                text-align: left;
              }
              &.text-value {
                color: var(--modal-list-text2);
                text-align: left;
                font-size: 13px;
                font-weight: 500;
              }
            }
            &:nth-of-type(even) {
              background-color: rgba(var(--modal-even-hover-rgba)) !important;
            }
          }
        }
      }
      .v-card__actions {
        border-top: 1px solid var(--modal-title);
        .close-btn {
          color: var(--submit-portfolio-text);
          background-color: var(--submit-portfolio-bg);
          border: 1px solid var(--submit-portfolio-bg);
          line-height: 1;
          display: block;
          margin-bottom: 0;
          font-weight: 400;
          text-align: center;
          cursor: pointer;
          white-space: nowrap;
          padding: 5px 12px;
          font-size: 13px;
          border-radius: 0;
          width: 80px;
          margin: 0;
          height: 30px;
          text-transform: capitalize;
          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
            color: var(--submit-portfolio-bg);
            background-color: var(--submit-portfolio-text);
            border-color: var(--submit-portfolio-bg) !important;
          }
        }
      }
    }
    .calender-custome {
      .daterangepicker {
        position: relative !important;
        margin: 0;
        display: block !important;
      }
    }
  }
}
#app {
  .funds-wrap {
    .teble-ellipsis-wrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      &.security_w {
        width: 340px !important;
        @media only screen and (max-width: 1699px) {
          width: 250px !important;
        }
        @media only screen and (max-width: 1399px) {
          width: 230px !important;
        }
        @media only screen and (max-width: 1350px) {
          width: 210px !important;
        }
      }
    }
    .custome-table {
      background: transparent !important;
      border-radius: 0;
      border: 0;
      table {
        > tbody {
          > tr {
            td {
              position: relative;
            }
            .hover-wrap {
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              margin: 0 auto;
              left: 50%;
              right: inherit;
              display: none;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding-right: 0;
              @media only screen and (max-width: 1599px) {
                width: 100%;
              }
              .btn {
                font-size: 14px;
                font-weight: 400;
                height: inherit;
                padding: 1px 6px;
                min-width: inherit;
                width: 48%;
                border-radius: 0;
                line-height: 14px;
                height: 22px;
                margin: 0;
                border: none;
                text-align: center;
                @media only screen and (max-width: 1799px) {
                  width: 48%;
                  font-size: 12px;
                  height: 20px;
                }
                @media only screen and (max-width: 1599px) {
                  width: 48%;
                }
                &:first-child {
                  margin-left: 0;
                }
                &:focus {
                  box-shadow: none;
                  outline: none;
                }
              }
              &:focus {
                box-shadow: none;
                outline: none;
              }
            }
            &:hover,
            &:focus {
              background-color: transparent !important;
              &:nth-of-type(even) {
                background-color: rgba(var(--even-hover-rgba)) !important;
              }
              td {
                color: var(--dark) !important;
                .v-icon {
                  color: var(--dark) !important;
                  &:before {
                    color: var(--dark) !important;
                  }
                }
              }
            }
            &:hover {
              .hover-td-values {
                .value-none {
                  visibility: hidden !important;
                  opacity: 0;
                }
                .hover-wrap {
                  display: flex;
                }
              }
            }
          }
        }
      }
      .v-data-table__wrapper {
        background: transparent;
        border-radius: 0;
        border: 0;
      }
      &.fund-table {
        table {
          > tbody {
            > tr {
              .fund-hover {
                margin: 0 auto !important;
                &.ml-0 {
                  margin-left: 0 !important;
                }
                &.fund-ellipsis {
                  width: 100px !important;
                  @media only screen and (max-width: 1799px) {
                    width: 80px !important;
                  }
                }
                .fund-hover-wrap {
                  top: 50%;
                  transform: translateY(-50%) !important;
                  margin: 0 0 !important;
                  left: inherit;
                  right: 0;
                  justify-content: flex-end;
                  .margin-lr {
                    margin: 0 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .lm_maximised .text-mx-area {
      display: block !important;
      width: 20% !important;
      .d-none {
        width: 20% !important;
        display: block !important;
      }
    }
    .lm_maximised .text-mx-category {
      display: block !important;
      width: 20% !important;
      .d-none {
        width: 20% !important;
        display: block !important;
      }
    }
    .lm_maximised .text-mx-rating {
      display: block !important;
      width: 20% !important;
      .d-none {
        width: 20% !important;
        display: block !important;
      }
    }
    .ask-price {
      color: var(--success) !important;
    }
  }
  .lm_maximised {
    .funds-wrap {
      .teble-ellipsis-wrap {
        width: 125px !important;
        @media only screen and (max-width: 1599px) {
          width: 100px !important;
        }
        @media only screen and (max-width: 1399px) {
          width: 75px !important;
        }
        &.currency-wrap {
          width: 75px !important;
          @media only screen and (max-width: 1599px) {
            width: 75px !important;
          }
        }
        &.identifier_wrap {
          width: 130px !important;
          @media only screen and (max-width: 1599px) {
            width: 100px !important;
          }
          @media only screen and (max-width: 1399px) {
            width: 85px !important;
          }
          @media only screen and (max-width: 1280px) {
            width: 80px !important;
          }
        }
        &.company-name-wrap {
          width: 290px !important;
          @media only screen and (max-width: 1599px) {
            width: 205px !important;
          }
          @media only screen and (max-width: 1399px) {
            width: 205px !important;
          }
          @media only screen and (max-width: 1299px) {
            width: 200px !important;
          }
        }
        &.country-name-wrap {
          width: 130px !important;
          @media only screen and (max-width: 1599px) {
            width: 100px !important;
          }
          @media only screen and (max-width: 1399px) {
            width: 85px !important;
          }
        }
        &.e-w-70 {
          width: 110px !important;
          @media only screen and (max-width: 1599px) {
            width: 75px !important;
          }
        }
        &.global-category {
          width: 170px !important;
          @media only screen and (max-width: 1599px) {
            width: 140px !important;
          }
          @media only screen and (max-width: 1399px) {
            width: 120px !important;
          }
        }
        &.security_w {
          width: 320px !important;
          @media only screen and (max-width: 1599px) {
            width: 220px !important;
          }
          @media only screen and (max-width: 1399px) {
            width: 215px !important;
          }
          @media only screen and (max-width: 1299px) {
            width: 200px !important;
          }
        }
      }
    }
  }
}
.fund-table {
  table {
    > thead,
    > tbody {
      > tr {
        > th,
        td {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9),
          &:nth-child(10),
          &:nth-child(12),
          &:nth-child(13),
          &:nth-child(14),
          &:nth-child(15),
          &:nth-child(16),
          &:nth-child(17),
          &:nth-child(18) {
            display: none;
          }
        }
      }
    }
  }
}
.lm_maximised {
  .fund-table {
    table {
      > thead,
      > tbody {
        > tr {
          > th,
          td {
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9),
            &:nth-child(10),
            &:nth-child(12),
            &:nth-child(13),
            &:nth-child(14),
            &:nth-child(15),
            &:nth-child(16),
            &:nth-child(17),
            &:nth-child(18) {
              display: table-cell;
            }
            &:nth-child(3) {
              width: 3.77% !important;
              min-width: 3.77% !important;
            }
            &:nth-child(8) {
              width: 16.27% !important;
              min-width: 16.27% !important;
            }
          }
        }
      }
    }
  }
}
</style>
