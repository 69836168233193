<template>
  <div class="sso-login-wrapper">
    <Header />
    <transition name="fade">
      <div class="lds-roller full-page" v-show="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <span class="loading-text">Loading...</span>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import router from "../router/index.js";
export default {
  name: "SSOLogin",
  data() {
    return {
      loading: true,
    };
  },
  components: {
    Header,
  },
  mounted() {
    let loginToken = this.$route.query.token;
    if (loginToken) {
      localStorage.setItem("user-token", loginToken);
      router.push("/");
      window.location = "/";
    } else {
      router.push("/login");
    }
  },
  methods: {
    loader_hide() {
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";

h1 {
  font-size: 100px;
}
.sso-login-wrapper {
  .lds-roller {
    height: 100%;
    background: var(--loading-bg) !important;
    display: block;
    position: absolute;
    height: 100%;
    top: 50%;
    bottom: inherit;
    overflow: hidden;
    z-index: 9;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    width: 80px;
    height: 110px;
    span {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
      &:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: var(--primary);
        margin: -4px 0 0 -4px;
      }
      &:nth-child(1):after {
        top: 63px;
        left: 63px;
      }
      &:nth-child(2) {
        animation-delay: -0.072s;
      }
      &:nth-child(2):after {
        top: 68px;
        left: 56px;
      }
      &:nth-child(3):after {
        top: 71px;
        left: 48px;
      }
      &:nth-child(4) {
        animation-delay: -0.144s;
      }
      &:nth-child(4):after {
        top: 72px;
        left: 40px;
      }
      &:nth-child(5) {
        animation-delay: -0.18s;
      }
      &:nth-child(5):after {
        top: 71px;
        left: 32px;
      }
      &:nth-child(6) {
        animation-delay: -0.216s;
      }
      &:nth-child(6):after {
        top: 68px;
        left: 24px;
      }
      &:nth-child(7) {
        animation-delay: -0.252s;
      }
      &:nth-child(7):after {
        top: 63px;
        left: 17px;
      }
      &:nth-child(8) {
        animation-delay: -0.288s;
      }
      &:nth-child(8):after {
        top: 56px;
        left: 12px;
      }
    }
  }
  .lds-roller .lds-roller div .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
