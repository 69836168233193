<template>
  <div class="tradding-chart-wrap">
    <div id="chart" v-if="chart_type !== 'candlestick'">
      <div class="toolbar position-right">
        <b-dropdown
          id="time-drp"
          right
          :text="this.time_drp_placeholder"
          variant="primary"
          class="custome-dropdown margin-r-10"
        >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setTimeSelectedOption('Month')"
            v-if="this.time_drp_placeholder != 'Month'"
            >Month</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setTimeSelectedOption('Quarter')"
            v-if="this.time_drp_placeholder != 'Quarter'"
            >Quarter</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setTimeSelectedOption('Year')"
            v-if="this.time_drp_placeholder != 'Year'"
            >Year</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setTimeSelectedOption('YTD')"
            v-if="this.time_drp_placeholder != 'YTD'"
            >YTD</b-dropdown-item
          >
        </b-dropdown>
        <b-dropdown
          id="price-type-drp"
          right
          :text="this.price_drp_placeholder"
          variant="primary"
          class="custome-dropdown"
          v-if="market_id == 5"
        >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setPriceTypeOption('Price')"
            v-if="this.price_drp_placeholder != 'Price'"
            >Price</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setPriceTypeOption('Yield')"
            v-if="this.price_drp_placeholder != 'Yield'"
            >Yield</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setPriceTypeOption('Spread')"
            v-if="this.price_drp_placeholder != 'Yield'"
            >Spread</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <apexchart
        v-if="isTrendingchartOpen"
        type="area"
        :height="TraddingChartHeight"
        :width="TraddingChartWidth"
        ref="chart"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
    <div id="candlechart" v-else>
      <div class="toolbar position-right">
        <b-dropdown
          id="time-drp"
          right
          :text="selectedCandleDropdown.text"
          variant="primary"
          class="custome-dropdown margin-r-10"
        >
          <b-dropdown-item 
            v-for="item of filterCandleDropdown"
            href="javascript:void(0)"
            @click="setCandleTimeSelectedOption(item.value)"
            :key="item.value"
          >{{ item.text }}</b-dropdown-item>          
        </b-dropdown>
      </div>
      <apexchart
        type="candlestick"
        :height="TraddingChartHeight"
        :width="TraddingChartWidth"
        ref="candlechart"
        :options="candleChartOptions"
        :series="candleSeries"
      ></apexchart>
    </div>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import themeconfig from '../themeconfig.js'
export default {
  name: "TradingChart",
  components: { apexchart: VueApexCharts },
  props: ["CompisTradingChartOpen","TraddingChartHeight", "TraddingChartWidth", "regenerateTradeChart","ChartOrigin"],
  data() {
    return {
      isTrendingchartOpen:false,
      loading: false,
      series: [
        {
          name: "",
          type: "area",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          id: "area-datetime",
          type: "area",
          foreColor:"",
            
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
          //redrawOnParentResize: true,
          // redrawOnWindowResize: true,
        },
        annotations: {
          yaxis: [
            {
              y: 0,
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        // colors: () => {
        //     let colors = this.setThemeoption();
        //     console.log("data color",colors);
        //     return localStorage.getItem("isDarkActive") == 1 ? ["#ffffff"] : ["#001a34"];
        //   },
        colors:[],
        // colors:
        //   localStorage.getItem("isDarkActive") == 1 ? ["#ffffff"] : ["#001a34"],
        // colors: ["#ffffff"],
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat",
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 6,
          labels: {
            formatter: (val) =>
              this.market_id == "6"
                ? val.toFixed(5)
                : new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                  }).format(val.toFixed(2)),
          },
        },
        grid: {
          // borderColor: '#333333',
          borderColor:"",
        },
        // grid: {
        //   // borderColor: '#333333',
        //   borderColor:
        //     localStorage.getItem("isDarkActive") == 1 ? "#333333" : "#dbdbdb",
        // },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.3,
            stops: [0, 100],
          },
        },
        legend: {
          show: false,
        },
      },
      candleSeries:[
        {
          name: "",
          type: "area",
          data: [],
        },
      ],
      candleChartOptions:{
        chart: {
          type: 'candlestick',
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            enabled: true,
            type: 'xy',
            autoScaleYaxis: true,
          },
          animations: {
            enabled: false,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: false,
              delay: 150
            },
            dynamicAnimation: {
              enabled: false,
              speed: 350
            }
          }
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          tooltip: {
            enabled: true
          }
        },
        tooltip: {
          custom: function({ seriesIndex, dataPointIndex, w }) {
            const o = w.globals.seriesCandleO[seriesIndex][dataPointIndex]
            const h = w.globals.seriesCandleH[seriesIndex][dataPointIndex]
            const l = w.globals.seriesCandleL[seriesIndex][dataPointIndex]
            const c = w.globals.seriesCandleC[seriesIndex][dataPointIndex]
            return (
              '<div class="apexcharts-tooltip-candlestick p-2">' +
              '<div>Open: <span class="value">' +
              o +
              '</span></div>' +
              '<div>High: <span class="value">' +
              h +
              '</span></div>' +
              '<div>Low: <span class="value">' +
              l +
              '</span></div>' +
              '<div>Close: <span class="value">' +
              c +
              '</span></div>' +
              '</div>'
            )
          }
        }
      },
      candleDropdown:[
        { value: '5d', text: 'Five Days' },
        { value: '1m', text: 'One Month' },
        { value: '3m', text: 'Three Months' },
        { value: '6m', text: 'Six Months' },
        { value: 'ytd', text: 'Year-to-date' },
        { value: '1y', text: 'One Year' },
        { value: '2y', text: 'Two Years' },
        { value: '5y', text: 'Five Years' },
        { value: 'max', text: 'Max' },
      ],
      selectedCandleDropdown: { value: '1m', text: 'One Month' },
      // candleTimeline: "1m",
      selection: "one_month",
      time_drp_placeholder: "Month",
      price_drp_placeholder: "Price",
    };
  },
  computed: {
    filterCandleDropdown: function(){
      return this.candleDropdown.filter(option => option.value.indexOf(this.candleTimeline));
    },
    items: {
      get() {
        let chart_type = this.$store.getters["chart/getChartType"];
        let AllItems = this.$store.getters["chart/getAllItems"];
        let price_type = this.$store.getters["chart/getPriceType"];
        let market_id = this.$store.getters["chart/getMarketId"];
        let items = new Array();
        if(AllItems != undefined && AllItems != null && AllItems.length > 0 && chart_type == 'area'){
          for (let i in AllItems) {
            let one = new Array();
            one.push(new Date(AllItems[i].created).getTime());
            if (price_type == "3") {
              //one.push(AllItems[i].spread_price.toFixed(2));
              one.push(
                new Intl.NumberFormat("en-US").format(
                  AllItems[i].spread_price.toFixed(2)
                )
              );
            } else if (price_type == "2") {
              //one.push(AllItems[i].yield_price.toFixed(2));
              one.push(
                new Intl.NumberFormat("en-US").format(
                  AllItems[i].yield_price.toFixed(2)
                )
              );
            } else {
              if (market_id == "6") {
                one.push(AllItems[i].price.toFixed(5));
              } else {
                one.push(AllItems[i].price.toFixed(2));
                //one.push(new Intl.NumberFormat("en-US").format(AllItems[i].price.toFixed(2)));
              }
            }
            items.push(one);
          }
        }
        return items;
      },
    },
    candlestickItems:{
      get(){
        let items = this.$store.getters["chart/getItems"];
        return items;
      }
    },
    chart_type:{
      get(){
        return this.$store.getters["chart/getChartType"];
      }
    },
    name() {
      return this.$store.getters["chart/getName"];
    },
    security_id: {
      get() {
        return this.$store.getters["chart/getSecurityId"];
      },
    },
    timeline: {
      get() {
        return this.$store.getters["chart/getTimeline"];
      },
    },
    candleTimeline: {
      get() {
        return this.$store.getters["chart/getCandleTimeline"];
      },
    },
    isLoading: {
      get() {
        return this.$store.getters["chart/getIsLoading"];
      },
    },
    currency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
    price_type: {
      get() {
        return this.$store.getters["chart/getPriceType"];
      },
    },
    market_id: {
      get() {
        return this.$store.getters["chart/getMarketId"];
      },
    },
    is_current_theme: {
      get() {
        return this.$store.getters["dashboard/getCurrentTheme"];
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
  },
  watch: {
    // isLoading(val){
    //   if(val === true){
    //     this.loading = true;
    //   }
    // },
    async security_id(security_id,old_security_id) {
      await this.loadChartData(security_id,old_security_id);
    },
    async items(){
        await this.fetchChart();
    },
    async candlestickItems(){
        await this.fetchChart();
    },
    async is_current_theme() {
      await this.updateTradingChartOption();
      await this.fetchChart();
    },
    async TraddingChartHeight(new_val, old_val) {
      if (new_val != old_val) {
        await this.updateTradingChartOption();
        await this.fetchChart();
      }
    },
    async TraddingChartWidth(new_val, old_val) {
      if (new_val != old_val) {
        await this.updateTradingChartOption();
        await this.fetchChart();
      }
    },
    async is_maximize(is_max) {
      if(is_max==1){
        //  await this.loadChartData(this.security_id,0);
        await this.updateTradingChartOption();
        await this.fetchChart();
      }
    },
    async candleTimeline(val) {
      this.selectedCandleDropdown = this.candleDropdown.find((option) => {
        return option.value == val;
      });
    },
    
  },
  methods: {
    async setThemeoption(){
      const curretn_theme = themeconfig.getTheme();
      // console.log("current theme=>",curretn_theme);
      localStorage.getItem("isDarkActive") == 1 ? this.chartOptions.colors = ["#ffffff"] : this.chartOptions.colors = [curretn_theme.chart_primary_color];
      localStorage.getItem("isDarkActive") == 1 ? this.chartOptions.grid.borderColor = "#333333" : this.chartOptions.grid.borderColor = curretn_theme.chart_grid_border_color;
      localStorage.getItem("isDarkActive") == 1 ? this.chartOptions.chart.foreColor ="#a8a8a8" : this.chartOptions.chart.foreColor = curretn_theme.chart_for_color;
    },
    async getDefaultSecurity() {
      await this.$store.dispatch("chart/getDefaultSecurity");
      await this.loadSecurity(this.security_id, []);
    },
    async updateData(timeline,type) {
      if(type === 'candlestick'){
        await this.$store.dispatch("chart/fetchCandleTimeline", timeline);
      }
      else{
        this.selection = timeline;
        await this.$store.dispatch("chart/fetchTimeline", timeline);
      }
      await this.loadChartData(this.security_id,this.security_id,'direct');
    },
    async loadChartData(security_id,old_security_id,type){
      
      if(security_id != old_security_id 
        || (security_id == 11 && security_id == old_security_id) 
        || type === "direct"){
        this.loading = true;
        let timeline = this.$store.getters["chart/getTimeline"];
        let candleTimeline = this.$store.getters["chart/getCandleTimeline"];
        let price_type = this.$store.getters["chart/getPriceType"];
        let payload = {
          security_id,
          timeline,
          price_type,
          candleTimeline
        };
        
        await this.$store.dispatch("chart/fetchItems", payload);
        this.fetchChart();
      }
    },
    async fetchChart() {
      // console.log("call fetchChart");
      // await this.$store.dispatch("chart/fetchItems", {
      //   security_id: this.security_id,
      //   timeline: this.timeline,
      //   price_type: this.price_type,
      //   candleTimeline: this.candleTimeline,
      // });
      if(this.items.length > 0 || this.candlestickItems.length > 0){
        this.loading = true;
        if(this.chart_type === "candlestick" && this.$refs.candlechart != undefined){
          // await this.$refs.candlechart.updateSeries([
          //   {
          //     name: this.name,
          //     data: this.candlestickItems,
          //   },
          // ]);
          this.isTrendingchartOpen = true;
          this.candleSeries = [
            {
              name: this.name,
              data: this.candlestickItems,
            },
          ];
        }
        else if(this.chart_type === "area" && this.$refs.chart != undefined){
         
          //  await this.$refs.chart.updateSeries([
          //   {
          //     name: this.name,
          //     data: this.items,
          //   },
          // ]);
          // console.log("call fetchChart chart_type",this.chart_type);
          // console.log("call fetchChart is_maximize",this.is_maximize);
          // console.log("call fetchChart items",this.items);
          // console.log("call fetchChart in");
          this.isTrendingchartOpen = true;
          this.series = [
            {
              name: this.name,
              data: this.items,
            },
          ];
        }
        
      }
      
      this.loading = false;
      this.isTrendingchartOpen = true;
    },
    async updateChart() {
      // this.loading = true;
      this.$refs.chart.updateSeries([
        {
          name: this.name,
          data: this.items,
        },
      ]);
      // this.loading = false;
    },
    async setTimeSelectedOption(selectedOption) {
      this.time_drp_placeholder = selectedOption;
      let time = this.getTimeSelected(selectedOption);
      this.updateData(time,'area');
    },
    async setCandleTimeSelectedOption(selectedOption) {
      this.selectedCandleDropdown = this.candleDropdown.find((option) => {
        return option.value == selectedOption;
      });
      
      this.updateData(selectedOption,'candlestick');
    },
    async setPriceTypeOption(selectedOption) {
      this.price_drp_placeholder = selectedOption;
      await this.$store.dispatch("chart/fetchTimeline", this.timeline);
      let price_type = this.getPriceTypeSelected(selectedOption);
      await this.$store.dispatch("chart/fetchPriceType", price_type);
      await this.fetchChart();
    },
    async updateTradingChartOption() {
      const curretn_theme = themeconfig.getTheme();
      this.candleChartOptions.chart.foreColor = localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : curretn_theme.chart_for_color;
      this.candleChartOptions.colors = localStorage.getItem("isDarkActive") == 1 ? ["#ffffff"] : [curretn_theme.chart_primary_color];
      this.candleChartOptions.grid = {
        borderColor: localStorage.getItem("isDarkActive") == 1 ? "#333333" : curretn_theme.chart_grid_border_color,
      };
        
      this.chartOptions = {
        chart: {
          id: "area-datetime",
          type: "area",
          foreColor:
            localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : curretn_theme.chart_for_color,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        colors:
          localStorage.getItem("isDarkActive") == 1 ? ["#ffffff"] : [curretn_theme.chart_primary_color],
        // colors: ["#ffffff"],
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat",
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 6,
          labels: {
            formatter: (val) =>
              this.market_id == "6"
                ? val.toFixed(5)
                : new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                  }).format(val.toFixed(2)),
          },
        },
        grid: {
          // borderColor: '#333333',
          borderColor:
            localStorage.getItem("isDarkActive") == 1 ? "#333333" : curretn_theme.chart_grid_border_color,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.3,
            stops: [0, 100],
          },
        },
        legend: {
          show: false,
        },
      };
    },
  },
  async mounted() {
    if(this.CompisTradingChartOpen){
      // console.log("call TradingChart component mounted order",this.chart_type);
       this.setThemeoption();
      // console.log("Chart Origin: ",this.ChartOrigin);
      
     setTimeout(() => {
       this.loadChartData(this.security_id,this.security_id);
        // console.log("call dispatchEvent resize");
        window.dispatchEvent(new Event('resize'))
      }, 500);
    }
   
  },
  // async updated(){
  //   this.$nextTick(async function () {
  //     // Code that will run only after the
  //     // entire view has been re-rendered
  //     // console.log('items_c',this.ChartOrigin);
  //     // if(this.ChartOrigin == "Equities" && this.is_maximize==1){
  //     //   await this.fetchChart();
        
  //     // }
  //   });
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.tradding-chart-wrap {
  padding: 5px 0;
  #chart {
    position: relative;
    .toolbar {
      position: absolute;
      top: 0;
      left: 15px;
      z-index: 9;
      &.position-right {
        left: inherit;
        right: 35px;
      }
      .btn {
        color: var(--toolbar-btn-color);
        font-size: 12px;
        font-weight: 400;
        height: inherit;
        padding: 5px 8px;
        border-radius: 0;
        line-height: 12px;
         margin:1px 0 0 8px;
        border: none;
        background-color: var(--toolbar-btn-bg);
        border: 1px solid var(--toolbar-btn-color);
        cursor: pointer;
         @media only screen and (max-width: 1599px) {
            padding: 3px 8px;
          }
        &:hover,
        &:focus,        
        &.active {
          background-color: var(--toolbar-btn-color);
          border: 1px solid var(--toolbar-btn-color);
          color: var(--toolbar-btn-text);
        }
        &:first-child {
          margin-left: 0;
        }
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
    > div {
      min-height: inherit !important;
    }
    .apexcharts-canvas {
      padding-top: 10px;
      margin-right: 0;
      width: 100% !important;
      .apexcharts-zoom-icon {
        display: none;
        &.apexcharts-selected {
          svg {
            fill: var(--apexchart-icon-color);
          }
        }
      }
    }
    .apexcharts-text {
      tspan {
        font-family: var(--font-family);
        color: var(--tspan-text) !important;
      }
    }
    .apexcharts-tooltip {
      background: var(--white-light);
      color: var(--primary);
      border-radius: 0;
      box-shadow: none;
      border-color: var(--grey-light);
      font-size: 12px;
      .apexcharts-tooltip-title {
        background: transparent;
        border: none;
        padding: 6px 6px 3px;
        margin-bottom: 0;
        font-size: 12px;
      }
      .apexcharts-tooltip-y-group {
        padding: 0;
      }
      .apexcharts-tooltip-series-group {
        padding: 3px 6px 6px;
      }
      .apexcharts-tooltip-marker {
        display: none;
      }
    }
    .apexcharts-menu {
      border: 1px solid var(--grey-light);
      border-radius: 0;
      padding: 0;
      right: 5px;
      background: var(--apexcharts-menu-bg);
      .apexcharts-menu-item {
        color: var(--primary);
        &:hover {
          background: var(--primary);
          color: var(--apexcharts-menu-hover-text);
        }
      }
    }
    .apexcharts-menu-icon,
    .apexcharts-zoomin-icon,
    .apexcharts-zoomout-icon,
    .apexcharts-reset-icon {
      color: var(--dark);
      margin: 0;
      svg {
        fill: var(--dark);
      }
      &:hover,
      &:focus {
        color: var(--primary);
        svg {
          fill: var(--primary);
        }
      }
    }
  }
}
#chart {
  .toolbar {
    &.position-right {
      left: inherit;
      right: 35px;
    }
  }
}
</style>
