<template>
  <div class="cashflow-wrap">
    <v-card class="custome-card">
      <v-data-table
        id="cashflow-scroll-table"
        :headers="headers"
        :items="items"
        :search="search"
        hide-default-footer
        class="custome-table"
        disable-pagination
        :item-key="'id'"
        fixed-header
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :style="{ height: CompCashflowHeight + 'px' }"
        @click:row="loadCashFlowDetails"
      >
        <template v-slot:body.prepend>
          <tr class="table-dropdown sticky-header">
            <td width="34%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.payment_date"
                ></v-text-field>
              </div>
            </td>

            <td width="22%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.coupon_payment"
                ></v-text-field>
              </div>
            </td>
            <td width="22%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.redemption_payment"
                ></v-text-field>
              </div>
            </td>
            <td width="22%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.trailer_payment"
                ></v-text-field>
              </div>
            </td>
            <td width="22%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.total_payment"
                ></v-text-field>
              </div>
            </td>
          </tr>
        </template>
        <template v-slot:[`item.paymentDate`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ item.paymentDates }}
          </span>
        </template>
        <template v-slot:[`item.originalinterestPayment`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ item.interestPayment }}
          </span>
        </template>
        <template v-slot:[`item.originalmaturityPayment`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ item.maturityPayment }}
          </span>
        </template>
        <template v-slot:[`item.originaltotalPayment`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ item.totalPayment }}
          </span>
        </template>
      </v-data-table>
    </v-card>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
    <v-dialog content-class="right-dialog-effect" persistent v-model="cashdialog" max-width="900">
      <v-card class="custome-modal">
        <v-card-title class="headline">
          <div
            class="box-title d-flex justify-content-between align-items-center"
          >
            <h4>View Detail</h4>
            <a href="javascript:void(0);" @click="cashdialog = false">
              <font-awesome-icon class="close-icon" icon="times" />
            </a>
          </div>
        </v-card-title>
        <v-card-text>
          <v-simple-table
            class="custome-table child-table"
            fixed-header
            :height="item_detials.length > 25 ? '80vh' : ''"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="19%">
                    Portfolio
                  </th>
                  <th width="12%">
                    Date
                  </th>
                  <th width="17%">
                    Security
                  </th>
                  <th width="13%">
                    Amount
                  </th>
                  <th width="13%">
                    Coupon
                  </th>
                  <th width="13%">
                    Redemption
                  </th>
                  <th width="13%">
                    Trailer
                  </th>
                  <th width="13%">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="dessert in item_detials" :key="dessert.id">
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap portfolio_name">{{
                      dessert.trade_portfolio_title
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.paymentDate
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.securities_title
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.total_qty
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.interestPayment
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.maturityPayment
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.trailerPayment
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.totalPayment
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <template>
      <v-navigation-drawer
        class="filter-sidebar"
        width="26%"
        v-model="ScannerDrawer"
        absolute
        temporary
      >
        <h3>Scanner</h3>
      </v-navigation-drawer>
      <a class="filter-sticky-icon d-none" @click="ScannerDrawer = true">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a>
    </template>
    <template>
      <v-navigation-drawer
        class="order-form-sidebar"
        width="26%"
        v-model="drawer"
        absolute
        temporary
        right
      >
        <div class="height-50 bb-4">
          <div id="chart" class="mx-auto">
            <CashFlowsChart />
          </div>
        </div>
      </v-navigation-drawer>
      <a class="filter-sticky-icon rightside d-none" @click="drawer = true">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a>
    </template>
  </div>
</template>

<script>
// import VueApexCharts from "vue-apexcharts";
import CashFlowsChart from "@/components/CashFlowsChart.vue";
export default {
  name: "Cashflow",
  components: { CashFlowsChart: CashFlowsChart },
  data() {
    return {
      dialog_item_details:[],
      ViewDetailsDrawer:false,
      cashdialog: false,
      expanded: [],
      singleExpand: true,
      search: "",
      drawer: null,
      ScannerDrawer: null,
      headers: [
        {
          text: "Payment Date",
          align: "left",
          value: "paymentDate",
          width: "34%",
          filter: (value) => {
            if (!this.search_table.payment_date) return true;
            if (value) {
              return (
                String(this.dateMonthYearFromat(value).toLowerCase()).search(
                  this.search_table.payment_date.toLowerCase()
                ) > -1
              );
            }
          },
        },
        {
          text: "Coupon",
          align: "center",
          width: "22%",
          value: "originalinterestPayment",
          filter: (value) => {
            if (!this.search_table.coupon_payment) return true;
            if (value) {
              return (
                String(value).search(this.search_table.coupon_payment) > -1
              );
            }
          },
        },
        {
          text: "Redemption",
          align: "center",
          width: "22%",
          value: "originalmaturityPayment",
          filter: (value) => {
            if (!this.search_table.redemption_payment) return true;
            if (value) {
              return (
                String(value).search(this.search_table.redemption_payment) > -1
              );
            }
          },
        },
        {
          text: "Trailer",
          align: "center",
          width: "22%",
          value: "trailerPayment",
          filter: (value) => {
            if (!this.search_table.trailer_payment) return true;
            if (value) {
              return (
                String(value).search(this.search_table.trailer_payment) > -1
              );
            }
          },
        },
        {
          text: "Total",
          align: "center",
          width: "22%",
          value: "originaltotalPayment",
          filter: (value) => {
            if (!this.search_table.total_payment) return true;
            if (value) {
              return String(value).search(this.search_table.total_payment) > -1;
            }
          },
        },
      ],
      sortBy: "paymentDate",
      sortDesc: false,
      loading: false,
      menu2: false,
      search_table: {
        payment_date: null,
      },
    };
  },
  computed: {
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
    items() {
      return this.$store.getters["cashflows/getItems"];
    },
    item_detials() {
      return this.$store.getters["cashflows/getItemDetails"];
    },
    currency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
    cashflowChartWidth: {
      get() {
        return parseInt(this.CompCashflowWidth) / 2.9;
      },
    },
    cashflowChartHeight: {
      get() {
        return parseInt(this.CompCashflowHeight) / 2;
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
    next_page_index() {
      return this.$store.getters["cashflows/getNextPage"];
    },
    record_length() {
      return this.$store.getters["cashflows/getLengthRecord"];
    },
    start_record() {
      return this.$store.getters["cashflows/getStartRecord"];
    },
  },
  watch: {
    async currency() {
      await this.updateItems();
      await this.fetchCashflows();
    },
    async filter_portfolio() {
      await this.updateItems();
      await this.fetchCashflows();
    },
  },
  methods: {
    DetailsDrawer() {
      if (!this.ViewDetailsDrawer) {
        this.loadCashFlowDetails(this.dialog_item_details);
      } else {
        this.ViewDetailsDrawer = false;
      }
    },
    async fetchCashflows() {
      this.loading = true;
      await this.$store.dispatch("cashflows/fetchItems", {
        filter_portfolio_ids: this.filter_portfolio,
        currency_filter: this.currency,
        draw: (this.next_page_index!= undefined) ?this.next_page_index:1 ,
        start: this.start_record,
        length: this.record_length,
      });
      this.loading = false;
    },
    async loadCashFlowDetails(item) {
      this.dialog_item_details = item;
      const returnData = await this.$store.dispatch("cashflows/fetchItemDetails", {
        filter_portfolio_ids: this.filter_portfolio,
        payment_date: item.paymentDate,
        currency_filter: this.currency,
      });
      this.$emit("cashclickView", returnData);
      // if (this.is_maximize == 1) {
      //   // this.ViewDetailsDrawer = true;
      //   this.$emit("cashclickView", returnData);
      // } else {
      // //  this.cashdialog = true;
      //   this.$emit("cashclickView", returnData);
      // }
     
      // this.loading = false;
    },
    async updateItems() {
      var items_prm = [];
      await this.$store.dispatch("cashflows/updateItems", items_prm);
    },
  },
  props: ["CompisCashflowOpen","CompCashflowHeight", "CompCashflowWidth"],
  async mounted() {
    if(this.CompisCashflowOpen){
      // console.log("call Cashflow list component mounted order");
      await this.fetchCashflows();

      // Detect when scrolled to bottom.
      let currenctDom = this;
      const listElm = document.querySelector("#cashflow-scroll-table");
      listElm.addEventListener("scroll", function() {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (currenctDom.next_page_index != 0) currenctDom.fetchCashflows();
        } else {
          this.loading = false;
        }
      });
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
#app {
  .cashflow-wrap {
    padding: 0;
    #cashflow-scroll-table {
      overflow: auto;
    }
    .custome-table {
      table {
        > tbody {
          > tr {
            > td {
              &.text-center {
                .teble-ellipsis-wrap {
                  margin: 0 auto !important;
                }
              }
              .teble-ellipsis-wrap {
                width: 120px !important;
              }
              &:last-child {
                .teble-ellipsis-wrap {
                  width: 100px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
