export default {
	getItem: state => (id) => {
		return state.items.find((item) => item.id === id)
	},
	getItems: state => state.items,
	getNextPage: state => {
        return state.next_page_index
    },
	getLengthRecord: state => {
        return state.length_record
    },
	getStartRecord: state => {
		return state.start_record
	},
	getDefaultMarketId: state => {
        return state.default_market_id
	},
	getTotalRecord: state => {
		return state.total_record
	},
	getIsEquitiesFilter: state => {
		return state.is_equities_scanner
	},
	getStockSymbols: state => {
		return state.stock_symbols
	},
}