import axios from '../../axios'

export default {
	fetchItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('performance-chart', payload)
				.then((response) => {
					commit('setRealizedItems', response.data.data.realized_data)
					commit('setUnRealizedItems', response.data.data.unrealized_data)
					commit('setAllItems', response.data.data.all_performance_data)
					commit('setMaxValue', response.data.data.max_value)
					commit('setMinValue', response.data.data.min_value)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchAssetsItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('performance-chart', payload)
				.then((response) => {
					commit('setEquitiesPerformanceItems', response.data.data.equities_data)
					commit('setBondsPerformanceItems', response.data.data.bonds_data)
					commit('setFundsPerformanceItems', response.data.data.funds_data)
					commit('setMaxValue', response.data.data.max_value)
					commit('setMinValue', response.data.data.min_value)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchPNLItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('performance-chart', payload)
				.then((response) => {
					commit('setTradingItems', response.data.data.trading_data)
					commit('setInterestItems', response.data.data.interest_data)
					commit('setMaxValue', response.data.data.max_value)
					commit('setMinValue', response.data.data.min_value)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchTimeline({
		commit
	}, payload) {
		commit('setTimeline', payload)
	},
	updateMovingAvg({
		commit
	}, payload) {
		commit('setMovingAvg', payload)
	},
	updatePeriodicity({
		commit
	}, payload) {
		commit('setPeriodicity', payload)
	}
}