import axios from "../../axios"

export default {
    fetchItems ({ commit }, payload) {
		return new Promise((resolve, reject) => {
			axios.post('pnl', payload)
				.then((response) => {
					if(response.data.data.is_filter=='1'){
						commit('setupdateItems', response.data.data.data ?? [])
					} else {
						commit('setItems', response.data.data.data ?? [])
					}
					commit('setNextPageIndex', response.data.data.next_page_index ?? '0')
					commit('setStartRecord', response.data.data.startPerPage ?? '20')
					resolve(response.data.data.data ?? [])
				})
				.catch(error => { reject(error) })
		})
	},
	fetchItemUnRealized({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('pnl/detail', payload)
				.then((response) => {
					commit('setUnRealizedItem', response.data.data.data)
					commit('setUnRealizedNextPageIndex', response.data.data.next_page_index)
					// commit('setUnRealizedStartRecord', response.data.data.startPerPage)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchItemRealized({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('pnl/detail', payload)
				.then((response) => {
					commit('setRealizedItem', response.data.data.data)
					commit('setRealizedNextPageIndex', response.data.data.next_page_index)
					// commit('setRealizedStartRecord', response.data.data.startPerPage)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	updateItems({
        commit
    },payload) {
        commit('setupdateItems', payload)
		commit('setNextPageIndex', 1)
		commit('setStartRecord', 0)
	},

}