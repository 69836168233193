export default {
    getItem: state => (id) => {
        return state.items.find((item) => item.id === id)
    },
    getItems: state => {
        return state.items
    },
    getAssets: state => {
        return state.assets_data
    },
    getHoldings: state => {
        return state.holding_data
    },
    getQuantity: state => {
        return state.quantity
    },
    getCurrency: state =>{
        return state.currency
    },
    getCurrencyChartData: state => {
        return state.currency_chart_data
    },
    getCashSymbols: state => {
		return state.cash_symbols
	},
    getStockSymbols: state => {
		return state.stock_symbols
	},
}