export default {
	getItem: state => (id) => {
		return state.items.find((item) => item.id === id)
	},
	getItems: state => state.items,
	getSelectedItem: state => state.selectedItem,
	getMenus: state => state.menus,
	getRemoveResult: state => {
		return state.remove_result
	},
	getCashSymbols: state => {
		return state.cash_symbols
	},
	getStockSymbols: state => {
		return state.stock_symbols
	},
}