<template>
  <div class="cashflow-wrap">
   
    <template>
      <v-navigation-drawer
        class="filter-sidebar"
        width="70%"
        v-model="ViewDetailsDrawer"
        absolute
        temporary
        right
        :stateless="isStateless"
      >
      <v-card class="custome-modal table-t-body">
        <v-card-title class="headline p-1">
          <div class="box-title d-flex justify-content-between align-items-center">
            <h4>CashFlow Details</h4>
          </div>
        </v-card-title>
       
        <v-simple-table
            class="custome-table child-table popup-table"
            fixed-header
            :height="cashDetail.length > 25 ? '80vh' : ''"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="17%">
                    Portfolio
                  </th>
                  <th width="12%">
                    Date
                  </th>
                  <th width="17%">
                    Security
                  </th>
                  <th width="11%">
                    Amount
                  </th>
                  <th width="11%">
                    Coupon
                  </th>
                  <th width="11%">
                    Redemption
                  </th>
                  <th width="11%">
                    Trailer
                  </th>
                  <th width="11%">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="cashDetail.length ==0">
                  <td colspan="8" class="text-start">No Data Found</td>
                </tr>
                <tr v-for="dessert in cashDetail" :key="dessert.id">
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap portfolio_name">{{
                      dessert.trade_portfolio_title
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.paymentDate
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.securities_title
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.total_qty
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.interestPayment
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.maturityPayment
                    }}</span>
                  </td>
                  <td class="text-start teble-ellipsis-wrap" @click="loadCashFlowSecondaryDetails(dessert)">
                    <span class="teble-ellipsis-wrap "><a href="javascript:void(0)">{{
                      dessert.trailerPayment
                    }}</a></span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.totalPayment
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
        </v-simple-table>
        
      </v-card>
        
        <!-- <transition name="fade">
          <div class="loading full-page" v-show="loading">
            <div><span></span><span></span><span></span><span></span></div>
          </div>
        </transition> -->
      </v-navigation-drawer>
      <a class="filter-sticky-icon right-60 rightside" >
        <img alt="filter icon " src="@/assets/images/right-arrow.png" />
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: "Cashflow",
  components: { },
  data() {
    return {
      dialog_item_details:[],
      ViewDetailsDrawer:false,
      cashDetail:[],
      isStateless:false
      
    };
  },
  computed: {
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
    item_secondary_details() {
      return this.$store.getters["cashflows/getItemSecondaryDetails"];
    },
  },
  watch: {
    async CashflowDetailProp() {
      this.cashDetail = this.CashflowDetailProp;
      this.ViewDetailsDrawer = true;
    //  console.log("call CashflowDetail...",this.CashflowDetailProp);
    },
    async isSecondaryOpenProp(){
      if(this.isSecondaryOpenProp== false){
        this.isStateless = false;
      }
    }
  },
  methods: {
    
    async loadCashFlowSecondaryDetails(item) {
      this.isStateless = true;
      this.dialog_item_details = item;
    //  console.log("loadCashFlowSecondaryDetails",item);
    //   const lastDayMonth = [{"01":"31"},{"02":"28"},{"03":"31"},{"04":"30"},{"05":"31"},{"06":"30"},
    //  {"07":"31"},{"08":"31"},{"09":"30"},{"10":"31"},{"11":"30"},{"12":"31"}];
    //  let toDay = lastDayMonth[item.payment_month-1][item.payment_month];
    // console.log("toDay",toDay);
      const returnData = await this.$store.dispatch("cashflows/fetchItemSecondaryDetails", {
        filter_ids: item.id,
        toDate: item.paymentDate,
      });
      // const returnData = item;
      this.$emit("cashflowDetailclickView", returnData);
      
    },
     
  },
  props: ["CashflowDetailProp","isSecondaryOpenProp"],
  async mounted() {
    // console.log("call CashflowDetail",this.CashflowDetailProp);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
#app {
  .cashflow-wrap {
    padding: 0;
    #cashflow-scroll-table {
      overflow: auto;
    }
    .filter-sidebar{
      z-index: 99999 !important;
    }
    .custome-table {
      table {
        > tbody {
          > tr {
            > td {
              &.text-center {
                .teble-ellipsis-wrap {
                  margin: 0 auto !important;
                }
              }
              .teble-ellipsis-wrap {
                width: 120px !important;
              }
              &:last-child {
                .teble-ellipsis-wrap {
                  width: 100px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
