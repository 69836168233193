import axios from "../../axios"

export default {
	fetchItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('cash-flows', payload)
				.then((response) => {
					commit('setItems', response.data.data.data)
					commit('setNextPageIndex', response.data.data.next_page_index)
					commit('setStartRecord', response.data.data.startPerPage)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchItemDetails({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('cash-flows', payload)
				.then((response) => {
					commit('setItemDetails', response.data.data)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchItemSecondaryDetails({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('cash-flows', payload)
				.then((response) => {
					commit('setItemSecondaryDetails', response.data.data)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	updateItems({
        commit
    },payload) {
        commit('setupdateItems', payload)
		commit('setNextPageIndex', 1)
		commit('setStartRecord', 0)
	},
}