import axios from "../../axios"

export default {
	fetchItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('transactions', payload)
				.then((response) => {
					if(response.data.is_filter=='1'){
						commit('setupdateItems', response.data.data)
					} else {
						commit('setItems', response.data.data)
					}
					commit('setNextPageIndex', response.data.next_page_index)
					commit('setStartRecord', response.data.startPerPage)
					commit('setTotalRecord', response.data.total_records)
					commit('setIsTransactionScanner', response.data.is_scanner)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	tradeKill({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('trad-kill', payload)
				.then((response) => {
					commit('setKillResult', response.data)
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	details({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('transaction-details', payload)
				.then((response) => {
					// console.log('transaction_details',response.data.data.record_type);
					commit('setTransactionDetails', response.data.data.transaction_details)
					commit('setTradeComments', response.data.data.trade_comments)
					commit('setRecordType', response.data.data.record_type)
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	updateItems({
        commit
    },payload) {
        commit('setupdateItems', payload)
		commit('setNextPageIndex', 1)
		commit('setStartRecord', 0)
	},
	fetchKillCount({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.get('get-kill-count', payload)
				.then((response) => {
					commit('setKillCount', response.data.data.trades_count)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	updatePlaceOrder({
        commit
    },payload) {
        commit('setIsPlaceOrder', payload)
	},
	
}