<template>
  <div class="research-details">
    <Header />
    <b-container class="bv-example-row">
      <b-row class="mt-5">
        <b-col><h1>Research Detail Coming soon</h1></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";

export default {
  name: "ResearchDetails",
  components: {
    Header
  },
  methods: {},
};
</script>
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";

h1 {
  font-size: 100px;
}
.research-details {
}
</style>
