export default {
	getAllItems: state => state.all_items,
	getRealizedItems: state => state.realized_items,
	getUnrealizedItems: state => state.unrealized_items,
	getTimeline: state => state.timeline,
	getMovingAvg: state => state.moving_avg,
	getPeriodicity: state => state.periodicity,
	getMaxValue: state => state.max_value,
	getMinValue: state => state.min_value,
	getStockItems: state => state.stock_items,
	getBondItems: state => state.bond_items,
	getFundItems: state => state.fund_items,
	getTradingItems: state => state.trading_items,
	getInterestItems: state => state.interest_items,
}