<template>
  <section class="security-details-wrap">
    <!-- <p class="heading">{{ security_details.title }}</p> -->
    <div class="security-details maximize-security" :style="{ height: CompSecurityDetailsHeight + 'px' }" >
      <div class="basic-details" v-if="company_details && company_details.length > 0">
        <div class="company-details-box">
          <div class="logo-main" v-if="logo">
            <img class="security-logo" :src="logo" />
          </div>
          <div class="company-info" :class="{ 'w-100': this.logo == null || this.logo == undefined }">
            <ul class="basic-details-ul" :class="{ 'no-bonds': this.market_id != 5 }">
              <li v-for="(item, index) in company_details" :key="index">
                <p class="title">{{ item.label }}</p>
                <p class="value" :class="{ 'fulltext': item.label == 'Description' }"><span class="value-logo" v-if="logo && item.label == 'Company Name'"><img :src="logo" /></span>{{ item.value }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="basic-details">        
        <p class="heading">Security Details</p>
        <ul class="basic-details-ul" :class="{ 'no-bonds': this.market_id != 5 }">
          <li v-for="(item, index) in security_details.basic_details" :key="index">
            <p class="title">{{ item.label }}</p>
            <p class="value">{{ item.value }}</p>
            <p class="value bond"><span v-if="item.value2">{{ item.value2 }}</span></p>
          </li>
        </ul>
      </div>
      <div v-if="cash_flow_details && cash_flow_details.length > 0">
        <div class="basic-details">        
          <p class="heading">Cash Flow</p>
          <ul class="basic-details-ul" :class="{ 'no-bonds': this.market_id != 5 }">
            <li v-for="(item, index) in cash_flow_details" :key="index">
              <p class="title">{{ item.label }}</p>
              <p class="value">{{ item.value }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="ibbond_details && ibbond_details.length > 0">
        <p class="heading">All IB Fields</p>
        <div class="basic-details">
          <ul class="basic-details-ul no-bonds">
            <li v-for="(item, index) in ibbond_details" :key="index">
              <p class="title">{{ item.label }}</p>
              <p class="value">{{ item.value }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="insider-roster" v-if="insider_roster && insider_roster.length > 0">
        <!-- <p class="heading">Insider Roster</p> -->
        <v-data-table
          id="insider-roster-table"
          :headers="insider_roster_headers"
          :items="insider_roster"
          hide-default-footer
          class="custome-table"
          disable-pagination
          item-key="id"
          fixed-header
        >
          <template v-slot:[`item.entityName`]="{ item }">
            {{ item.entityName }}
          </template>
          <template v-slot:[`item.position`]="{ item }">
            {{ formatNumber(item.position) }}
          </template>
          <template v-slot:[`item.reportDate`]="{ item }">
            {{ item.reportDate }}
          </template>
        </v-data-table>
      </div>
      <div class="insider-summary" v-if="insider_summary && insider_summary.length > 0">
        <!-- <p class="heading">Insider Summary</p> -->
        <v-data-table
          id="insider-summary-table"
          :headers="insider_summary_headers"
          :items="insider_summary"
          hide-default-footer
          class="custome-table"
          disable-pagination
          item-key="id"
          fixed-header
        >
          <template v-slot:[`item.updated`]="{ item }">
            {{ item.updated }}
          </template>
          <template v-slot:[`item.fullName`]="{ item }">
            {{ item.fullName }}
          </template>
          <template v-slot:[`item.netTransacted`]="{ item }">
            {{ formatNumber(item.netTransacted) }}
          </template>
          <template v-slot:[`item.reportedTitle`]="{ item }">
            {{ item.reportedTitle }}
          </template>
          <template v-slot:[`item.totalBought`]="{ item }">
            {{ formatNumber(item.totalBought) }}
          </template>
          <template v-slot:[`item.totalSold`]="{ item }">
            {{ formatNumber(item.totalSold) }}
          </template>
        </v-data-table>
      </div>
      <div class="legal-documents" v-if="fund_legal_documents && fund_legal_documents.length > 0">
        <!-- <p class="heading">Legal Documents</p> -->
        <v-data-table
          id="legal-documents-table"
          :headers="legal_doc_headers"
          :items="fund_legal_documents"
          hide-default-footer
          class="custome-table fund-doc-table"
          disable-pagination
          item-key="id"
          fixed-header
        >
          <template v-slot:[`item.kind`]="{ item }">
            <a :href="item.link" target="_blank"> {{ item.kind }} </a>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date }}
          </template>
          <template v-slot:[`item.country_code`]="{ item }">
            {{ item.country_code }}
          </template>
          <template v-slot:[`item.language`]="{ item }">
            {{ item.language }}
          </template>
          <template v-slot:[`item.constraint`]="{ item }">
            {{ item.constraint }}
          </template>
        </v-data-table>
      </div>
    </div>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </section>
</template>

<script>
import axios from './../axios';

export default {
  name: "SecurityDetails",
  data() {
    return {
      legal_doc_headers: [
        { text: 'Documents', value: 'kind' },
        { text: 'Date', value: 'date' },
        { text: 'Country', value: 'country_code' },
        { text: 'Language', value: 'language' },
        { text: 'Constraint', value: 'constraint' }
      ],
      insider_roster_headers: [
        { text: 'Insider Roster', value: 'entityName' },
        { text: 'Position', value: 'position' },
        { text: 'Report Date', value: 'reportDate' }
      ],
      insider_summary_headers: [
        { text: 'Insider Summary', value: 'updated' },
        { text: 'Full Name', value: 'fullName' },
        { text: 'Net Transacted', value: 'netTransacted' },
        { text: 'Reported Title', value: 'reportedTitle' },
        { text: 'Total Bought', value: 'totalBought' },
        { text: 'Total Sold', value: 'totalSold' }
      ],
      loading: false,
      company_details: [],
      security_details: [],
      fund_legal_documents: [],
      ibbond_details: [],
      market_id: null,
      logo: null,
      cash_flow_details:[],
      insider_roster:[],
      insider_summary:[],
    };
  },
  computed: {
    security_id() {
      return this.$store.getters["chart/getSecurityId"];
    },
  },
  watch: {
    async security_id() {
      await this.fetchSecurityDetails();
    },
  },
  props: ["CompSecurityDetailsHeight"],
  methods: {
    async fetchSecurityDetails() {
      this.loading = true;
      await this.resetDataFields();

      let path = "get-securities/" + this.security_id;

      await axios.get(path)
        .then((response) => {
          if (response.data.status == 1) {
            this.security_details = response.data.data;
            if (this.security_details.market_id) {
              this.market_id = this.security_details.market_id;
            }
            if (this.security_details.fund_legal_documents) {
              this.fund_legal_documents = this.security_details.fund_legal_documents;
            }
            if (this.security_details.ibbond_details) {
              this.ibbond_details = this.security_details.ibbond_details;
            }
            if(this.security_details.company_details){
              this.company_details = this.security_details.company_details;
            }
            if(this.security_details.logo){
              this.logo = this.security_details.logo;
            }
            if(this.security_details.cash_flow_details){
              this.cash_flow_details = this.security_details.cash_flow_details;
            }
            if(this.security_details.insider_roster){
              this.insider_roster = this.security_details.insider_roster;
            }
            if(this.security_details.insider_summary){
              this.insider_summary = this.security_details.insider_summary;
            }
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
      this.loading = false;
    },
    async resetDataFields(){
      this.logo = null;
      this.company_details = [];
      this.cash_flow_details = [];
      this.insider_roster = [];
      this.insider_summary = [];
    }
  },
  async mounted() {
    await this.fetchSecurityDetails();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.security-details-wrap {
  padding: 0px;
  .heading {
    color: var(--custome-table-thead-text);
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
    text-align: left;
    height: 27px;
    padding: 0.25rem 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: var(--custome-table-thead-bg);
    @media only screen and (min-width: 1399px) {
      font-size: 14px !important;
    }
    @media only screen and (max-width: 1399px) {
      font-size: 12px !important;
    }
  }
  .security-details {
    overflow: auto;
    &.minimize-security {
      display: block;
    }
    &.maximize-security {
      // display: none;
      .company-details-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid rgba(var(--even-hover-rgba));
        .logo-main {
          display: none;
          justify-content: center;
          align-items: center;
          min-width: 25%;
          max-width: 25%;
          @media only screen and (max-width: 1399px) {
            min-width: 33.33%;
            max-width: 33.33%;
          }
        }
        .security-logo {
          width: auto;
          height: auto;
        }
        .company-info {
          min-width: 100%;
          max-width: 100%;
          border-left: 1px solid rgba(var(--even-hover-rgba));
          @media only screen and (max-width: 1399px) {
            min-width: 100%;
            max-width: 100%;
          }
        }
      }
      
    }
    .security-logo {
      height: 50px;
      text-align: left;
    }
  }
  .basic-details-ul {
    list-style: none;
    margin: 0 0 0;
    padding: 0;
    display: block;
    width: 100%;
    li {
      margin: 0;
      width: 100%;
      width: 100%;
      padding: 0.25rem 0.5rem;
      background: transparent;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 27px;
      .title {
        font-family: var(--font-family) !important;
        color: var(--dark);
        font-size: 13px !important;
        line-height: 16px;
        font-weight: 400;
        margin-bottom: 0;
        text-align: left;
        min-width: 33.33%;
        max-width: 33.33%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @media only screen and (min-width: 1399px) {
          font-size: 14px !important;
        }
        @media only screen and (max-width: 1399px) {
          font-size: 12px !important;
        }
      }
      .value {
        font-family: var(--font-family);
        color: var(--dark);
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;
        text-align: left;
        white-space: nowrap;
        max-width: 66.67%;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 16px;
        
        img {
          height: 27px;
          margin-right: 5px;
        }
        @media only screen and (min-width: 1399px) {
          font-size: 14px !important;
        }
        @media only screen and (max-width: 1399px) {
          font-size: 12px !important;
        }
        &.bond {
          display: none;
        }
      }
      &:nth-of-type(even) {
        background: rgba(var(--even-hover-rgba));
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
.fund-doc-table {
  .custome-table {
    background: transparent !important;
    border-radius: 0;
    border: 0;
    table {
      > tbody {
        > tr {
          td {
            position: relative;
            a {
              color: var(--dark) !important;
            }
          }

          &:hover,
          &:focus {
            background-color: transparent !important;
            &:nth-of-type(even) {
              background-color: rgba(var(--even-hover-rgba)) !important;
            }
            td {
              color: var(--dark) !important;
              a {
                color: var(--dark) !important;
              }
              .v-icon {
                color: var(--dark) !important;
                &:before {
                  color: var(--dark) !important;
                }
              }
            }
          }
        }
      }
    }
    .v-data-table__wrapper {
      background: transparent;
      border-radius: 0;
      border: 0;
    }
  }
  table {
    > thead,
    > tbody {
      > tr {
        > th,
        td {
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            display: none;
          }
        }
      }
    }
  }
}
#insider-summary-table {
  table {
    > thead,
    > tbody {
      > tr {
        > th,
        td {
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            display: none;
          }
        }
      }
    }
  }
}
.lm_maximised {
  .security-details {
    &.minimize-security {
      display: none;
    }
    &.maximize-security {
      // display: block;
      .company-details-box{
        .logo-main {
          display: flex;
        }
        .company-info {
          min-width: 75%;
          max-width: 75%;
          border-left: 1px solid rgba(var(--even-hover-rgba));
          @media only screen and (max-width: 1399px) {
            min-width: 66.67%;
            max-width: 66.67%;
          }
          &.w-100{
            min-width: 100%;
            max-width: 100%;
            @media only screen and (max-width: 1399px) {
              min-width: 100%;
              max-width: 100%;
            }
          }
        }
        
      }
      .basic-details-ul {
        li{
          .title {
            min-width: 33.33%;
            max-width: 33.33%;
          }
          .value {
            min-width: 33.33%;
            max-width: 33.33%;
            &.fulltext {
              overflow: auto !important;
              white-space: normal !important;
              height: auto;
              line-height: 22px;
            }
            .value-logo{
              display: none;
            }
          }
          
        }
        &.no-bonds {
          li {
            justify-content: flex-start;
            p {
              &.value {
                min-width: 66.67% !important;
                &.bond {
                  display: none !important;
                }
              }
            }
          }
        }
      }
      
    }
  }
  .fund-doc-table {
    table {
      > thead,
      > tbody {
        > tr {
          > th,
          td {
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
              display: table-cell;
            }
          }
        }
      }
    }
  }
  #insider-summary-table {
    table {
      > thead,
      > tbody {
        > tr {
          > th,
          td {
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              display: table-cell;
            }
          }
        }
      }
    }
  }
  .security-details-wrap {
    .basic-details-ul {
      &.no-bonds {
        li {
          justify-content: flex-start;
          p {
            &.value {
              min-width: 66.67% !important;
              &.bond {
                display: none !important;
              }
            }
          }
        }
      }
      li {
        justify-content: space-between;
        p {
          min-width: 33.33%;
          max-width: 33.33%;
        }
        .value {
          &.bond {
            display: block;
          }
        }
      }
    }
  }
}
</style>
