<template>
  <div class="equities-wrap">
    <v-card class="custome-card" id="equities_list">
      <v-data-table
        id="equities-scroll-table"
        :headers="headers"
        :items="items"
        :search="search"
        hide-default-footer
        class="custome-table equities-table"
        disable-pagination
        item-key="id"
        fixed-header
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :style="{ height: CompEquitiesHeight + 'px' }"
        @click:row="order_sidebar"
      >
        <template v-slot:body.prepend>
          <tr class="table-dropdown sticky-header">
            <td width="10%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.security_name"
                  @change="fetchSecurities(1)"
                ></v-text-field>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.ticker"
                  @change="fetchSecurities(1)"
                ></v-text-field>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.type"
                  :items="types_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.currency"
                  :items="currency_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.country"
                  :items="countries_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.exchange_symbol"
                  :items="exchanges_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.group"
                  :items="group_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-autocomplete
                  v-model="search_table.sector"
                  :items="sector_data"
                  dense
                  filled
                  clearable
                  class="table-input"
                  item-text="text"
                  item-value="value"
                  @change="fetchSecurities(1)"
                ></v-autocomplete>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.bid_size"
                ></v-text-field>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.bid_price"
                ></v-text-field>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.spread"
                ></v-text-field>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.ask_price"
                ></v-text-field>
              </div>
            </td>
            <td width="7.5%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.ask_size"
                ></v-text-field>
              </div>
            </td>
          </tr>
        </template>

        <template v-slot:[`item.security_name`]="{ item }">
          <span class="teble-ellipsis-wrap item-name">
            {{ item.security_name }}
          </span>
        </template>
        <template v-slot:[`item.ticker_name`]="{ item }">
          <span class="teble-ellipsis-wrap ticker-name">
            {{ item.ticker_name }}
          </span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span class="teble-ellipsis-wrap secdef-type">
            {{ item.type }}
          </span>
        </template>
        <template v-slot:[`item.currency`]="{ item }">
          <span class="teble-ellipsis-wrap currency">
            {{ item.currency }}
          </span>
        </template>
        <template v-slot:[`item.country_name`]="{ item }">
          <span class="teble-ellipsis-wrap country-name-ellip">
            {{ item.country_name }}
          </span>
        </template>
        <template v-slot:[`item.exchange_symbol`]="{ item }">
          <span class="teble-ellipsis-wrap listing-exchange">
            {{ item.exchange_symbol }}
          </span>
        </template>
        <template v-slot:[`item.group`]="{ item }">
          <span class="teble-ellipsis-wrap secdef-group">
            {{ item.group }}
          </span>
        </template>
        <template v-slot:[`item.sector`]="{ item }">
          <span class="teble-ellipsis-wrap sector">
            {{ item.sector }}
          </span>
        </template>
        <template v-slot:[`item.bid_size`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ item.bid_size }}
          </span>
        </template>
        <template v-slot:[`item.bid_price`]="{ item }">
          <span
            class="td-values bid-price"
            :class="item.market_id == 2 ? ' ' : 'hover-td-values'"
          >
            <div
              class="teble-ellipsis-wrap e-w-70 value-none m-auto text-center"
            >
              {{ formatPrice(item.bid_price, item.market_id) }}
            </div>
            <div class="hover-wrap" v-if="item.market_id != 2">
              <template>
                <b-button
                  v-if="is_maximize == 1"
                  @click.stop="order_sidebar(item)"
                  variant="danger"
                  >Sell</b-button
                >
                <b-button
                  v-if="is_maximize == 0"
                  @click.stop="selectItem(item, '1')"
                  variant="danger"
                  >Sell</b-button
                >
              </template>
            </div>
          </span>
        </template>
        <template v-slot:[`item.spread`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ formatPrice(item.spread, item.market_id,2) }}
          </span>
        </template>
        <template v-slot:[`item.ask_price`]="{ item }">
          <span
            class="td-values ask-price"
            :class="item.market_id == 2 ? ' ' : 'hover-td-values'"
          >
            <div
              class="teble-ellipsis-wrap e-w-70 value-none m-auto text-center"
            >
              {{ formatPrice(item.ask_price, item.market_id) }}
            </div>
            <div class="hover-wrap" v-if="item.market_id != 2">
              <template>
                <b-button
                  v-if="is_maximize == 1"
                  @click.stop="order_sidebar(item)"
                  variant="success"
                  >Buy</b-button
                >
                <b-button
                  v-if="is_maximize == 0"
                  @click.stop="selectItem(item, '1')"
                  variant="success"
                  >Buy</b-button
                >
              </template>
            </div>
          </span>
        </template>
        <template v-slot:[`item.ask_size`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ item.ask_size }}
          </span>
        </template>
      </v-data-table>
    </v-card>
    <transition name="fade">
      <div class="equity_loading full-page" v-show="equity_loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
    <template>
      <v-navigation-drawer
        class="order-form-sidebar"
        width="26%"
        v-model="drawer"
        absolute
        temporary
        right
      >
        <div class="height-50 bb-4 align-items-start" v-if="is_maximize == 1">
          <TickerDetails formName="equity_order_form" />
        </div>
        <div class="height-50" v-if="is_maximize == 1 && active_tab=='Equities'">
          <!-- <TradingChart ChartOrigin="Equities" /> -->
          <SideBarTradingChart ChartOrigin="Equities" CompisTradingChartOpen='isTradingChartOpen' :TraddingChartHeight="400" :TraddingChartWidth="300" v-if="isTradingChartOpen"/>
        </div>
      </v-navigation-drawer>
      <a class="filter-sticky-icon rightside" @click="opensidebarDrawer()">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a>
    </template>
    <template>
      <v-navigation-drawer
        class="filter-sidebar"
        width="26%"
        v-model="ScannerDrawer"
        absolute
        temporary
      >
        <div class="sidebar-header">
          <h3>Scanner ({{ total_record }})</h3>
          <a class="cancle-btn" @click="clearScanner()">Clear all</a>
        </div>
        <form>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.type_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.type_val"
              :items="types_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Type"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.currency_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.currency_val"
              :items="currency_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Currency"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.country_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.country_val"
              :items="countries_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Countries"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.exchange_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.exchange_val"
              :items="exchanges_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Exchange"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.group_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.group_val"
              :items="group_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Group"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.sector_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.sector_val"
              :items="sector_data"
              dense
              chips
              deletable-chips
              small-chips
              label="Sector"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>
          <!-- <div class="form-group">
            <v-autocomplete
              v-model="search_table.bid_price_oprator"
              :items="number_oprator_list"
              dense
              filled
              clearable
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="text"
              hide-details="auto"
              v-model="search_table.bid_price_val"
              label="Bid Price"
            ></v-text-field>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.ask_price_oprator"
              :items="number_oprator_list"
              dense
              filled
              clearable
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="text"
              hide-details="auto"
              v-model="search_table.ask_price_val"
              label="Ask Price"
            ></v-text-field>
          </div> -->

          <div class="btn-group justify-content-end">
            <a class="submit_portfolio" @click="fetchSecurities(1)">Scan</a>
          </div>
        </form>
      </v-navigation-drawer>
      <a class="filter-sticky-icon" @click="ScannerDrawer = true">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a>
    </template>
  </div>
</template>
<script>
import TickerDetails from "@/components/TickerDetails.vue";
import SideBarTradingChart from "@/components/SideBarTradingChart.vue";
export default {
  name: "Equities",
  components: { TickerDetails: TickerDetails, SideBarTradingChart: SideBarTradingChart },
  data() {
    return {
      traddingHeight:397,
      isTradingChartOpen:false,
      dialog: false,
      drawer: null,
      ScannerDrawer: null,
      headers: [
        {
          text: "Name",
          align: "left",
          width: "10%",
          value: "security_name",
        },
        {
          text: "Identifier",
          align: "left",
          width: "7.5%",
          value: "ticker_name",
        },
        {
          text: "Type",
          align: "left",
          width: "7.5%",
          value: "type",
        },
        {
          text: "Currency",
          align: "left",
          width: "7.5%",
          value: "currency",
        },
        {
          text: "Country",
          align: "left",
          width: "7.5%",
          value: "country_name",
        },
        {
          text: "Exchange",
          align: "left",
          width: "7.5%",
          value: "exchange_symbol",
        },
        {
          text: "Group",
          align: "left",
          width: "7.5%",
          value: "group",
        },
        {
          text: "Sector",
          align: "left",
          width: "7.5%",
          value: "sector",
        },
        {
          text: "Bid Size",
          align: "center",
          width: "7.5%",
          value: "bid_size",
          filter: (value) => {
            if (!this.search_table.bid_size) return true;
            return String(value).search(this.search_table.bid_size) > -1;
          },
        },
        {
          text: "Bid",
          align: "center",
          width: "7.5%",
          value: "bid_price",
          filter: (value) => {
            if (!this.search_table.bid_price) return true;
            return String(value).search(this.search_table.bid_price) > -1;
          },
        },
        {
          text: "Spread",
          align: "center",
          width: "7.5%",
          value: "spread",
          filter: (value) => {
            if (!this.search_table.spread) return true;
            return String(value).search(this.search_table.spread) > -1;
          },
        },
        {
          text: "Ask",
          align: "center",
          width: "7.5%",
          value: "ask_price",
          filter: (value) => {
            if (!this.search_table.ask_price) return true;
            return String(value).search(this.search_table.ask_price) > -1;
          },
        },
        {
          text: "Ask Size",
          align: "center",
          width: "7.5%",
          value: "ask_size",
          filter: (value) => {
            if (!this.search_table.ask_size) return true;
            return String(value).search(this.search_table.ask_size) > -1;
          },
        },
      ],
      search: "",
      equity_loading: false,
      sortBy: "security_name",
      sortDesc: false,
      menu2: false,
      item_details: [],
      equities_placeholder: "Select Filter",
      search_table: {
        security_name: null,
        name_oprator: "equal",
        security_val: null,
        ticker_oprator: "equal",
        ticker_val: null,
        type_oprator: "equal",
        type_val: null,
        currency_oprator: "equal",
        currency_val: null,
        country_oprator: "equal",
        country_val: null,
        exchange_oprator: "equal",
        exchange_val: null,
        group_oprator: "equal",
        group_val: null,
        sector_oprator: "equal",
        sector_val: null,
        bid_price_oprator: "greater_than",
        bid_price_val: 0,
        ask_price_oprator: "greater_than",
        ask_price_val: 0,
      },
      text_oprator_list: [
        { text: "Equal", value: "equal" },
        { text: "Not Equal", value: "not_equal" },
      ],
      number_oprator_list: [
        { text: "Equal", value: "equal" },
        { text: "Not Equal", value: "not_equal" },
        { text: "Greater Than", value: "greater_than" },
        { text: "Less Than", value: "less_than" },
      ],
    };
  },
  computed: {
    countries_data() {
      let countries = this.$store.getters["dashboard/getCountriesSecurity"];
      let countries_data = [];
      for (var i in countries) {
        if (countries[i].market_id == "1") {
          countries_data.push({
            text: countries[i].country_name,
            value: countries[i].country_id,
          });
        }
      }
      return countries_data;
    },
    types_data() {
      let types = this.$store.getters["dashboard/getTypes"];
      let types_data = [];
      for (var i in types) {
        types_data.push({
          text: types[i].type,
          value: types[i].type,
        });
      }
      return types_data;
    },
    currency_data() {
      let currencies = this.$store.getters["dashboard/getCurrencySecurity"];
      let currency_data = [];
      for (var i in currencies) {
        if (currencies[i].market_id == "1") {
          currency_data.push({
            text: currencies[i].currency,
            value: currencies[i].currency,
          });
        }
      }
      return currency_data;
    },
    ticker_data() {
      let tickers = this.$store.getters["dashboard/getTickerSecurity"];
      let tickers_data = [];
      for (var i in tickers) {
        if (tickers[i].market_id == "1") {
          tickers_data.push({
            text: tickers[i].ticker_name,
            value: tickers[i].ticker_id,
          });
        }
      }
      return tickers_data;
    },
    exchanges_data() {
      let exchanges = this.$store.getters["dashboard/getExchanges"];
      let exchanges_data = [];
      for (var i in exchanges) {
        exchanges_data.push({
          text: exchanges[i].exchange_symbol,
          value: exchanges[i].exchange_symbol,
        });
      }
      return exchanges_data;
    },
    group_data() {
      let groups = this.$store.getters["dashboard/getGroups"];
      let groups_data = [];
      for (var i in groups) {
        groups_data.push({
          text: groups[i].group,
          value: groups[i].group,
        });
      }
      return groups_data;
    },
    sector_data() {
      let sectors = this.$store.getters["dashboard/getSectors"];
      let sectors_data = [];
      for (var i in sectors) {
        sectors_data.push({
          text: sectors[i].sector,
          value: sectors[i].sector,
        });
      }
      return sectors_data;
    },
    items() {
      let items = this.$store.getters["equities/getItems"];
      return items;
    },
    default_market_id() {
      return this.$store.getters["equities/getDefaultMarketId"];
    },
    next_page_index() {
      return this.$store.getters["equities/getNextPage"];
    },
    record_length() {
      return this.$store.getters["equities/getLengthRecord"];
    },
    start_record() {
      return this.$store.getters["equities/getStartRecord"];
    },
    total_record() {
      let total_record = new Intl.NumberFormat("en-US").format(
        this.$store.getters["equities/getTotalRecord"]
      );
      return total_record;
    },
    active_tab: {
      get() {
        return this.$store.getters["dashboard/getActiveTab"];
      },
    },
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
    is_equities_scanner() {
      return this.$store.getters["equities/getIsEquitiesFilter"];
    },
  },
  watch: {
    async active_tab(active_tab_name) {
      // console.log("new_val", active_tab_name);
      // console.log("old_val", previous_tab_name);
      if (active_tab_name == "Equities") {
        //await this.equitiesStreamingActive();
      }
    },
    async CompEquitiesHeight(new_val) {
      if (new_val > 0) {
        this.$store.dispatch("dashboard/updateActiveTab", "Equities");
        await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 0);
      }
    },
    async is_equities_scanner(is_equities_scanner) {
      var equtiesClear = document.getElementById("equities-clear-btn");
      if (is_equities_scanner == 1) {
        equtiesClear.classList.remove("d-none");
      } else {
        equtiesClear.classList.add("d-none");
      }
    },
  },
  props: ["CompisEquitiesOpen","CompEquitiesHeight"],
  methods: {
    opensidebarDrawer(){
      // console.log("call opensidebarDrawer :: component mounted");
      this.drawer = this.drawer ? false :true;
      this.isTradingChartOpen= true;
    },
    async successAlert(msg) {
      this.$swal({
        title: "",
        showCancelButton: false,
        text: msg,
      });
    },
    async filterClear() {},
    async erroeAlert(msg) {
      this.$swal({
        title: "Oops...",
        text: msg,
      });
    },
    async fetchSecurities(is_filter) {
      this.equity_loading = true;
      if (is_filter == 1) {
        await this.resetPageIndex();
      }
      await this.$store.dispatch("equities/fetchItems", {
        market_id: this.default_market_id,
        page: this.next_page_index,
        start: this.start_record,
        length: this.record_length,
        is_filter: is_filter,
        security_name: this.search_table.security_name,
        currency: this.search_table.currency,
        country: this.search_table.country,
        ticker: this.search_table.ticker,
        type: this.search_table.type,
        exchange_symbol: this.search_table.exchange_symbol,
        group: this.search_table.group,
        sector: this.search_table.sector,
        name_oprator: this.search_table.name_oprator,
        security_val: this.search_table.security_val,
        ticker_oprator: this.search_table.ticker_oprator,
        ticker_val: this.search_table.ticker_val,
        type_oprator: this.search_table.type_oprator,
        type_val: this.search_table.type_val,
        currency_oprator: this.search_table.currency_oprator,
        currency_val: this.search_table.currency_val,
        country_oprator: this.search_table.country_oprator,
        country_val: this.search_table.country_val,
        exchange_oprator: this.search_table.exchange_oprator,
        exchange_val: this.search_table.exchange_val,
        group_oprator: this.search_table.group_oprator,
        group_val: this.search_table.group_val,
        sector_oprator: this.search_table.sector_oprator,
        sector_val: this.search_table.sector_val,
        bid_price_oprator: this.search_table.bid_price_oprator,
        bid_price_val: this.search_table.bid_price_val,
        ask_price_oprator: this.search_table.ask_price_oprator,
        ask_price_val: this.search_table.ask_price_val,
      });
      this.equity_loading = false;
    },
    async clearScanner() {
      this.search_table.security_name = null;
      this.search_table.ticker = null;
      this.search_table.type = null;
      this.search_table.currency = null;
      this.search_table.country = null;
      this.search_table.exchange_symbol = null;
      this.search_table.group = null;
      this.search_table.sector = null;
      this.search_table.name_oprator = "equal";
      this.search_table.security_val = null;
      this.search_table.ticker_oprator = "equal";
      this.search_table.ticker_val = null;
      this.search_table.type_oprator = "equal";
      this.search_table.type_val = null;
      this.search_table.currency_oprator = "equal";
      this.search_table.currency_val = null;
      this.search_table.country_oprator = "equal";
      this.search_table.country_val = null;
      this.search_table.exchange_oprator = "equal";
      this.search_table.exchange_val = null;
      this.search_table.group_oprator = "equal";
      this.search_table.group_val = null;
      this.search_table.sector_oprator = "equal";
      this.search_table.sector_val = null;
      this.search_table.bid_price_oprator = "greater_than";
      this.search_table.bid_price_val = 0;
      this.search_table.ask_price_oprator = "greater_than";
      this.search_table.ask_price_val = 0;
      this.ScannerDrawer = false;
      await this.fetchSecurities(1);
    },
    async selectItem(security_items, is_order) {
      let securityId = security_items.id;
      let ib_conid = security_items.ib_conid;
      let iex_symbol = security_items.iex_symbol;
      let market_id = security_items.market_id;

      if (securityId > 0) {
        if (ib_conid != undefined && ib_conid > 0) {
          await this.$store.dispatch("dashboard/updateDefaultSecuritySet", {
            secuirty_id: securityId,
            conid: ib_conid,
            iex_symbol: iex_symbol,
            market_id: market_id,
          });
        }

        if (is_order == "1") {
          await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 1);
        } else {
          await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 0);
        }
        await this.$store.dispatch("chart/updateDefaultChart", {
          security_id: securityId,
        });

        await this.$store.dispatch("chart/updateConId", {
          con_id: ib_conid,
        });
        //this.equity_loading = true;
        await this.updateFormQuantity("0");
        await this.loadSecurity(securityId, []);
        // this.equity_loading = false;
      }
    },
    async order_sidebar(security_items) {
      if (this.is_maximize == 1) {
        // this.drawer = !this.drawer;
          this.opensidebarDrawer();
      }
      await this.selectItem(security_items, 0);
    },
    async equitiesStreamingActive() {
      var user_id = localStorage.getItem("login-id");
      var user_name = localStorage.getItem("login-name");
      let securityList = [];
      let equities_list = this.items;
      for (var j in equities_list) {
        if (equities_list[j].market_id == "1") {
          securityList.push({
            security_id: equities_list[j].id,
            conid: equities_list[j].ib_conid,
            user_id,
          });
        }
      }
      var securitySet = {
        security_list: securityList,
        user_id: user_id,
      };
      await this.$socket.emit("user_join", { user_id, user_name });
      await this.$socket.emit("security_subscription", securitySet);
    },
    async resetPageIndex() {
      await this.$store.dispatch("equities/resetPageIndex", {
        next_page_index: 1,
        start_record: 0,
      });
    },
  },
  async mounted() {
    if(this.CompisEquitiesOpen){
      // console.log("call Equities component mounted order");
      await this.fetchSecurities(0);
      let currenctDom = this;
      // Detect when scrolled to bottom.
      setTimeout(() => {
        var x = document.getElementsByClassName("lm_header");
        for (let i = 0; i < x.length; i++) {
          let isFind = x[i].querySelectorAll("li[title='Equities']").length;
          if (isFind > 0) {
            var filterClearBtn = document.createElement("BUTTON");
            filterClearBtn.innerHTML =
              ' <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><path d="M 1 0 C 0.448 0 0 0.448 0 1 L 0 4 C 0 4.255 0.10625 4.5015 0.28125 4.6875 L 14.6875 20 L 29.3125 20 L 43.71875 4.6875 C 43.75375 4.6505 43.7845 4.6035 43.8125 4.5625 C 43.8555 4.4995 43.9095 4.447 43.9375 4.375 C 43.9665 4.301 43.95775 4.203 43.96875 4.125 C 43.97575 4.077 44 4.049 44 4 L 44 1 C 44 0.448 43.553 0 43 0 L 1 0 z M 16 22 L 16 39 C 16 39.352 16.199 39.66375 16.5 39.84375 L 26.5 45.84375 C 26.659 45.93875 26.822 46 27 46 C 27.17 46 27.347 45.962 27.5 45.875 C 27.814 45.697 28 45.36 28 45 L 28 22 L 16 22 z M 32.90625 31.96875 A 1.0001 1.0001 0 0 0 32.78125 32 A 1.0001 1.0001 0 0 0 32.28125 33.71875 L 39.5625 41 L 32.28125 48.28125 A 1.016466 1.016466 0 1 0 33.71875 49.71875 L 41 42.4375 L 48.28125 49.71875 A 1.016466 1.016466 0 1 0 49.71875 48.28125 L 42.4375 41 L 49.71875 33.71875 A 1.0001 1.0001 0 0 0 48.875 32 A 1.0001 1.0001 0 0 0 48.28125 32.28125 L 41 39.5625 L 33.71875 32.28125 A 1.0001 1.0001 0 0 0 32.90625 31.96875 z"/></svg>';
            filterClearBtn.classList = "filter-button maximize-filter-btn d-none";
            filterClearBtn.id = "equities-clear-btn";
            x[i].appendChild(filterClearBtn);
            filterClearBtn.onclick = async function() {
              await currenctDom.clearScanner();
            };
          }
        }
      }, 1000);

      const listElm = document.querySelector("#equities-scroll-table");
      listElm.addEventListener("scroll", async function() {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (currenctDom.next_page_index != 0) {
            await currenctDom.fetchSecurities(0);
            await currenctDom.equitiesStreamingActive();
          }
        } else {
          this.equity_loading = false;
        }
      });
    }
    

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.equities-wrap {
  padding: 0;
  #equities-scroll-table {
    overflow: auto;
  }
  .bid-price {
    color: var(--danger);
  }
  .ask-price {
    color: var(--success);
  }
  .v-data-table {
    .sticky-header {
      position: sticky;
      top: 0;
    }
    .v-data-table__wrapper {
      overflow: unset;
    }
  }
}
.v-dialog {
  border-radius: 0 !important;
  background: var(--modal-bg);
  .custome-modal {
    &.v-card {
      border-radius: 0;
      background: transparent;
      box-shadow: none !important;
      .v-card__title {
        padding: 10px 15px;
        border-bottom: 1px solid var(--modal-title);
        .box-title {
          width: 100%;
          h4 {
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 18px;
            color: var(--modal-title);
            margin-bottom: 0;
          }
        }
        .table-button-groups {
          .btn {
            height: 30px;
            font-size: 13px !important;
          }
        }
      }
      .v-card__text {
        padding: 0 0 10px;
        ul {
          list-style: none;
          padding: 0 !important;
          margin: 0 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          li {
            width: 100%;
            background-color: transparent !important;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: 5px 8px;
            span {
              &.text-title {
                color: var(--modal-list-text);
                font-size: 13px;
                font-weight: 500;
                width: 40%;
                text-align: left;
              }
              &.text-value {
                color: var(--modal-list-text2);
                text-align: left;
                font-size: 13px;
                font-weight: 500;
              }
            }
            &:nth-of-type(even) {
              background-color: rgba(var(--modal-even-hover-rgba)) !important;
            }
          }
        }
      }
      .v-card__actions {
        border-top: 1px solid var(--modal-title);
        .close-btn {
          color: var(--submit-portfolio-text);
          background-color: var(--submit-portfolio-bg);
          border: 1px solid var(--submit-portfolio-bg);
          line-height: 1;
          display: block;
          margin-bottom: 0;
          font-weight: 400;
          text-align: center;
          cursor: pointer;
          white-space: nowrap;
          padding: 5px 12px;
          font-size: 13px;
          border-radius: 0;
          width: 80px;
          margin: 0;
          height: 30px;
          text-transform: capitalize;
          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
            color: var(--submit-portfolio-bg);
            background-color: var(--submit-portfolio-text);
            border-color: var(--submit-portfolio-bg) !important;
          }
        }
      }
    }
    .calender-custome {
      .daterangepicker {
        position: relative !important;
        margin: 0;
        display: block !important;
      }
    }
  }
}
#app {
  .equities-wrap {
    .teble-ellipsis-wrap {
      width: 80px !important;
      &.item-name {
        width: 120px !important;
        @media only screen and (max-width: 1599px) {
          width: 80px !important;
        }
      }
    }
    .custome-table {
      background: transparent !important;
      border-radius: 0;
      border: 0;
      table {
        > tbody {
          > tr {
            td {
              position: relative;
            }
            .hover-wrap {
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              margin: 0 auto;
              left: 50%;
              right: inherit;
              display: none;
              justify-content: center;
              align-items: center;
              width: 80%;
              padding-right: 0;
              @media only screen and (max-width: 1599px) {
                width: 100%;
              }
              .btn {
                font-size: 14px;
                font-weight: 400;
                height: inherit;
                padding: 1px 6px;
                min-width: inherit;
                width: 85px;
                border-radius: 0;
                line-height: 14px;
                height: 22px;
                margin: 0;
                border: none;
                text-align: center;
                @media only screen and (max-width: 1799px) {
                  width: 75px;
                  font-size: 12px;
                  height: 20px;
                }
                @media only screen and (max-width: 1599px) {
                  width: 65px;
                }
                &:first-child {
                  margin-left: 0;
                }
                &:focus {
                  box-shadow: none;
                  outline: none;
                }
              }
              &:focus {
                box-shadow: none;
                outline: none;
              }
            }
            &:hover,
            &:focus {
              background-color: transparent !important;
              &:nth-of-type(even) {
                background-color: rgba(var(--even-hover-rgba)) !important;
              }
              td {
                color: var(--dark) !important;
                .v-icon {
                  color: var(--dark) !important;
                  &:before {
                    color: var(--dark) !important;
                  }
                }
              }
            }
            &:hover {
              .hover-td-values {
                .value-none {
                  visibility: hidden !important;
                  opacity: 0;
                }
                .hover-wrap {
                  display: flex;
                }
              }
            }
          }
        }
      }
      .v-data-table__wrapper {
        background: transparent;
        border-radius: 0;
        border: 0;
      }
    }
  }
  .lm_maximised {
    .equities-wrap {
      .teble-ellipsis-wrap {
        width: 110px !important;
        @media only screen and (max-width: 1599px) {
          width: 75px !important;
        }
        &.e-w-70 {
          width: 110px !important;
          @media only screen and (max-width: 1599px) {
            width: 75px !important;
          }
        }
        &.item-name {
          width: 190px !important;
          @media only screen and (max-width: 1599px) {
            width: 130px !important;
          }
        }
        &.country-name-ellip {
          width: 120px !important;
          @media only screen and (max-width: 1599px) {
            width: 100px !important;
          }
        }
        &.currency {
          width: 80px !important;
        }
        &.sector {
          width: 160px !important;
          @media only screen and (max-width: 1599px) {
            width: 110px !important;
          }
        }
      }
      .security_w {
        width: 200px !important;
        @media only screen and (min-width: 1699px) {
          width: 320px !important;
        }
      }
    }
  }
}
.equities-table {
  table {
    > thead,
    > tbody {
      > tr {
        > th,
        td {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9),
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}
.lm_maximised {
  .equities-table {
    table {
      > thead,
      > tbody {
        > tr {
          > th,
          td {
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9),
            &:last-child {
              display: table-cell;
            }
            &:nth-child(1) {
              width: 12.5% !important;
              min-width: 12.5% !important;
            }
            &:nth-child(2) {
              width: 5% !important;
              min-width: 5% !important;
              .teble-ellipsis-wrap {
                width: 75px !important;
                @media only screen and (max-width: 1599px) {
                  width: 75px !important;
                }
              }
            }
            &:nth-child(4) {
              width: 3% !important;
              min-width: 3% !important;
              @media only screen and (max-width: 1699px) {
                width: 5% !important;
                min-width: 5% !important;
              }
            }
            &:nth-child(5) {
              width: 8% !important;
              min-width: 8% !important;
              @media only screen and (max-width: 1699px) {
                width: 9% !important;
                min-width: 9% !important;
              }
            }
            &:nth-child(8) {
              width: 11.5%;
              min-width: 11.5%;
              @media only screen and (max-width: 1699px) {
                width: 10.5%;
                min-width: 10.5%;
              }
            }
          }
        }
      }
    }
  }
}
.custome-dropdown {
  z-index: 10000;
}
.dropdown-menu .dropdown-menu-right {
  z-index: 10000;
}
.dropdown-item {
  z-index: 10000;
}
</style>
