var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"admin-chat"},[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.activeUserIndex == null ||
          (_vm.activeUserIndex != null && _vm.is_maximize == true)
      ),expression:"\n        activeUserIndex == null ||\n          (activeUserIndex != null && is_maximize == true)\n      "}],staticClass:"admin-chat-list "},[_c('div',{staticClass:"chat-user-list"},[_c('ul',{staticClass:"common-list",style:({ height: _vm.CompChat2Height + 'px' })},_vm._l((_vm.onlineUsers),function(user,index){return _c('li',{key:index,on:{"click":function($event){return _vm.openChat(user.user_id, index)}}},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_c('div',{staticClass:"chat-user-box"},[_c('img',{staticClass:"chat-img-light",attrs:{"alt":"Chat","src":require("@/assets/images/chat-user.png")}}),_c('img',{staticClass:"chat-img-dark",attrs:{"alt":"Chat","src":require("@/assets/images/white-user.png")}}),_c('div',{staticClass:"chat-user-text"},[_c('h3',[_vm._v(_vm._s(user.user_name))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                      index !== _vm.activeUserIndex && user.unread_admin_count > 0
                    ),expression:"\n                      index !== activeUserIndex && user.unread_admin_count > 0\n                    "}]},[_vm._v(" "+_vm._s(user.unread_admin_count)+" messages ")])])]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(new Date(user.last_message_at).toLocaleDateString())),_c('br'),_vm._v(_vm._s(_vm.displayTime(user.last_message_at)))])])])}),0)])]),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeUserIndex != null && _vm.is_maximize == false),expression:"activeUserIndex != null && is_maximize == false"}],staticClass:"maximize-w-75 admin-chat-message"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeUserIndex == null),expression:"activeUserIndex == null"}],staticClass:"no-chat-history"},[_c('img',{attrs:{"alt":"Chat","src":require("@/assets/images/chat-bg.png")}})]),(_vm.activeUserIndex != null)?_c('div',{staticClass:"message-box"},[_c('div',{staticClass:"chat-user-details"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.backChat()}}},[_c('font-awesome-icon',{staticClass:"back-icon",attrs:{"icon":"chevron-left"}}),_vm._v(" Back")],1),_c('div',{staticClass:"chat-user-box chat-header-user"},[_c('div',{staticClass:"chat-user-text"},[_c('h3',[_vm._v(_vm._s(_vm.activeUser.user_name))])])])]),_c('div',{staticClass:"message-group admin-chat-user",style:({ height: _vm.CompChatHeight + 'px' }),attrs:{"id":"chatMessages"}},[_vm._l((_vm.messages),function(msg,index){return _c('div',{key:index,class:{
              'message-send right': _vm.profiles.id == msg.user_id,
              'message-receive left': _vm.profiles.id != msg.user_id,
            }},[(_vm.profiles.id != msg.user_id)?_c('span',{staticClass:"message-log"},[_vm._v(_vm._s(msg.user_name)+", "+_vm._s(_vm.displayDateTime(msg.created_at)))]):_vm._e(),(_vm.profiles.id == msg.user_id)?_c('span',{staticClass:"message-log"},[_vm._v(_vm._s(_vm.displayDateTime(msg.created_at)))]):_vm._e(),_c('div',{staticClass:"message-text"},[_c('p',[_vm._v(_vm._s(msg.message))])])])}),(_vm.typing == true)?_c('div',{staticClass:"typing"},[_c('div',{staticClass:"bubble"},[_c('div',{staticClass:"ellipsis dot_1"}),_c('div',{staticClass:"ellipsis dot_2"}),_c('div',{staticClass:"ellipsis dot_3"})])]):_vm._e()],2),_c('form',{staticClass:"message-form",on:{"submit":_vm.sendMessage}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"form-control chat-input",attrs:{"type":"text","placeholder":"Type to Chat"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_c('button',{staticClass:"send-btn",attrs:{"type":"submit"}},[_c('img',{staticClass:"chat-img-light",attrs:{"alt":"Chat","src":require("@/assets/images/send.png")}}),_c('img',{staticClass:"chat-img-dark",attrs:{"alt":"Chat","src":require("@/assets/images/white-send.png")}})])])]):_vm._e()])],1),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loading full-page"},[_c('div',[_c('span'),_c('span'),_c('span'),_c('span')])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }