export default {
	all_items: [],
	items: [],
	name: "EURUSD",
	security_id: 11,
	con_id: '12087792',
	timeline: "one_month",
	candleTimeline: "1m",
	bid: 0,
	ask: 0,
	rate: 1,
	app_uri: '',
	market_id: 6,
	last_price: 0,
	counterparty: [],
	added_watchlist: 0,
	price_type: 1,
	chart_type: 'area',
	isLoading: true,
}