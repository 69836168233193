<template>
  <div class="cashflow-wrap">
   
    <template>
      <v-navigation-drawer
        class="filter-sidebar"
        width="65%"
        v-model="ViewDetailsDrawer"
        absolute
        temporary
        right
      >
      <v-card class="custome-modal table-t-body">
        <v-card-title class="headline p-1">
          <div class="box-title d-flex justify-content-between align-items-center">
            <h4>Trailer Fees</h4>
          </div>
        </v-card-title>
       
        <v-simple-table
            class="custome-table child-table popup-table"
            fixed-header
            :height="cashDetail.length > 25 ? '80vh' : ''"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="25%">
                    Date
                  </th>
                  <th width="25%">
                    Market Value
                  </th>
                  <th width="25%">
                    Percentage
                  </th>
                  <th width="25%">
                    Trailer Amount
                  </th>
                 
                </tr>
              </thead>
              <tbody>
                <tr v-if="cashDetail.length ==0">
                  <td colspan="4" class="text-start">No Data Found</td>
                </tr>
                <tr v-for="dessert in cashDetail" :key="dessert.id">
                  
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.date_display
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.market_value_display
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.trailer_fee
                    }}</span>
                  </td>
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      dessert.trailer_amount_display
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
        </v-simple-table>
      </v-card>
      </v-navigation-drawer>
      
    </template>
  </div>
</template>

<script>
export default {
  name: "Cashflow",
  components: { },
  data() {
    return {
      dialog_item_details:[],
      ViewDetailsDrawer:false,
      cashDetail:[],

      
    };
  },
  computed: {
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
    
  },
  watch: {
    async ViewDetailsDrawer(){
      if(this.ViewDetailsDrawer == false){
        this.$emit("cashclickViewReturn");
      }
      
    },
    async CashflowSecondaryDetailProp() {
      this.cashDetail = this.CashflowSecondaryDetailProp;
      this.ViewDetailsDrawer = true;
    //  console.log("call CashflowSecondaryDetailProp...",this.CashflowSecondaryDetailProp);
    },
    
  },
  methods: {
     
  },
  props: ["CashflowSecondaryDetailProp"],
  async mounted() {
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
#app {
  .cashflow-wrap {
    padding: 0;
    #cashflow-scroll-table {
      overflow: auto;
    }
    .filter-sidebar{
      z-index: 99999 !important;
    }
    .custome-table {
      table {
        > tbody {
          > tr {
            > td {
              &.text-center {
                .teble-ellipsis-wrap {
                  margin: 0 auto !important;
                }
              }
              .teble-ellipsis-wrap {
                width: 120px !important;
              }
              &:last-child {
                .teble-ellipsis-wrap {
                  width: 100px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
