<template>
  <v-dialog
    v-model="show"
    content-class="overflow-visible"
    max-width="740"
  >
    <v-card class="custome-modal">
      <v-card-title class="headline">
        <div
          class="box-title d-flex justify-content-between align-items-center"
        >
          <h4>You will get alerts for {{ symbol }}</h4>
          <div>
            <a href="javascript:void(0)" v-if="priceAlertTable == true" @click="priceAlertTable = false">
              <font-awesome-icon class="ml-2" icon="plus-circle" />
            </a>
            <a href="javascript:void(0)" v-if="priceAlertTable == false" @click="priceAlertTable = true">
              <font-awesome-icon class="ml-2" icon="ellipsis-v" />
            </a>
            <a href="javascript:void(0)" @click.stop="show=false">
            <!-- @click="paDialog = false" -->
              <font-awesome-icon class="ml-2" icon="times" />
            </a>
          </div>
        </div>
      </v-card-title>
      <div v-if="priceAlertTable == true">
        <v-card-text>
          <v-simple-table
            class="custome-table child-table"
            fixed-header
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <!-- <th width="20%">
                    Title
                  </th> -->
                  <th width="50%">
                    When
                  </th>
                  <th class="text-center" width="15%">
                    Status
                  </th>
                  <th class="text-center" width="15%">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody v-if="items.length > 0">
                <tr v-for="alert of items" :key="alert.id">
                  <!-- <td class="text-left">{{ alert.rule_name }}</td> -->
                  <td class="text-left">
                    <span v-for="(condition,index) of alert.rule_conditions" :key="index">
                      {{ condition.join(' ') }}
                      <strong v-if="alert.rule_conditions.length > 1 && (index < alert.rule_conditions.length - 1)"> OR </strong> 
                    </span>
                  </td>
                  <td class="text-left">
                    <v-chip
                      class="ma-2"
                      :color="alert.rule_status == 1 ? 'green': 'red'"
                      text-color="white"
                    >
                      <span v-if="alert.rule_status == 1">Active</span>
                      <span v-else>Inactive</span>
                    </v-chip>
                    
                  </td>
                  <td class="text-center">
                    <font-awesome-icon class="ml-2" icon="play" v-if="alert.rule_status != 1" @click="resumeAlert(alert.id)" />
                    <font-awesome-icon class="ml-2" icon="edit" v-if="alert.rule_status == 1" @click="editAlert(alert.id)" />
                    <font-awesome-icon class="ml-2" icon="trash-alt" @click="deleteAlert(alert.id)" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="4">No Alerts</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </div>
      <div class="price-alert-form" v-if="priceAlertTable == false">
        <b-form @submit="submitPriceAlert">
          <v-card-text class="justify-content-start">
            <b-container>
              <!-- <b-form-row class="d-none">
                <b-col class="py-0" sm="12" md="12">
                  <b-form-group>
                    <v-text-field
                      class="form-control"
                      type="text"
                      label="Title"
                      v-model="alertForm.ruleName"
                      required
                    ></v-text-field>
                  </b-form-group>
                </b-col>
              </b-form-row> -->
              <!-- <b-form-row>
                <b-col class="py-0 d-flex justify-content-center" sm="12" md="12">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="subtitle text-left font-weight-bold mr-3">
                      Any
                    </div>
                    <div>
                      <v-switch></v-switch>
                    </div>
                    <div class="subtitle text-left font-weight-bold ml-3">
                      All
                    </div>
                  </div>
                </b-col>
              </b-form-row> -->
              <b-form-row>
                <b-col sm="12"><h6 class="text-left">Alert me when latest price</h6></b-col>
              </b-form-row>
              <b-form-row v-for="(item, index) in alertForm.ruleConditions" :key="index">
                <b-col class="py-0 d-none" sm="12" md="3">
                  {{ item.condOne }}
                </b-col>
                <b-col class="py-0" sm="12" md="5">
                  <b-form-group
                    class="select-group"
                    label="Is"
                    label-for="pa-operator-1"
                  >
                    <b-form-select
                      id="pa-operator-1"
                      v-model="item.operator"
                      :options="conditionOperators"
                      label="Is"
                      required
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col class="py-0" sm="12" md="5">
                  <b-form-group>
                    <v-text-field
                      class="form-control"
                      type="text"
                      label="Price"
                      v-model="item.condTwo"
                      required
                    ></v-text-field>
                  </b-form-group>
                </b-col>
                <b-col class="py-0" sm="12" md="2">
                  <a v-if="index == 0 && alertForm.ruleConditions.length < 2" href="javascript:void(0)" @click="addRuleCondition">
                    <font-awesome-icon class="ml-2" icon="plus-circle" />
                  </a>
                  <a v-else href="javascript:void(0)" @click="removeRuleCondition(index)">
                    <font-awesome-icon class="ml-2 text-danger" icon="minus-circle" />
                  </a>
                </b-col>
              </b-form-row>
            </b-container>
          </v-card-text>
          <v-card-actions class="d-flex justify-content-end">
            <b-col class="d-flex justify-content-end" sm="12" md="6">
              <b-button class="primary-btn" type="submit" :disabled="submitDisable == true">Submit</b-button>
            </b-col>
          </v-card-actions>
        </b-form>
      </div>
    </v-card>
    <!-- <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition> -->
  </v-dialog>
</template>
<script>
import axios from "./../axios";
export default {
  name: "PriceAlert",
  data() {
    return {
      loading: false,
      submitDisable: false,
      priceAlertTable: true,
      symbol: null,
      items: [],
      allowAdd: true,
      conditionTemplate: { 
        condOne: 'Latest Price',
        operator: null, 
        condTwo: null 
      },
      conditionOperators: [
        { value: null, text: "Select operator"},
        { value:'>=', text: "Greater than or equal to"},
        { value:'<=', text: "Less than or equal to"},
      ],
      alertForm: {
        ruleId: null,
        securityId: null,
        ruleName: null,
        ruleType: 'any',
        ruleConditions: [
          { condOne: 'Latest Price', operator: null, condTwo: null },
        ],
        note: null,
      }
    };
  },
  //props: ["paDialog","paSecurityId"],
  props: {
    value: Object
  },
  methods: {
    async submitPriceAlert(event){
      event.preventDefault();
      this.loading = true;
      this.submitDisable = true;
      this.alertForm.securityId = this.securityId;
      let payload = this.alertForm;

      let endpoint = "price-alert/ruleadd";

      if(this.alertForm.ruleId != null && this.alertForm.ruleId != undefined){
        endpoint = "price-alert/ruleupdate";
      }
      
      await axios
        .post(endpoint, payload)
        .then(async (response) => {
          this.loading = false;
          this.submitDisable = false;
          if (response.data.status == 1) {
            this.$swal({
              title: "Price Alert",
              text: response.data.message,
            });
            this.show = false;
          }
          else {
            this.$swal({
              showDenyButton: true,
              denyButtonText: `Ok`,
              showConfirmButton: false,
              title: "Error",
              text: response.data.message,
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.submitDisable = false;
          this.$swal({
            showDenyButton: true,
            denyButtonText: `Ok`,
            showConfirmButton: false,
            title: "Error",
            text: "Something went wrong! Please try again later.",
          });
        });
    },
    addRuleCondition(){
      this.alertForm.ruleConditions.push({...this.conditionTemplate});
    },
    removeRuleCondition(index){
      this.alertForm.ruleConditions.splice(index, 1);
    },
    resetForm(){
      this.priceAlertTable = true;
      this.symbol = null;
      this.items = [];
      this.submitDisable = false;
      this.alertForm = {
        ruleId: null,
        securityId: null,
        ruleName: null,
        ruleType: null,
        ruleConditions: [
          { condOne: 'Latest Price', operator: null, condTwo: null },
        ],
        note: null,
      };
    },
    async fetchRules(security_id){
      this.loading = true;
      await axios
        .get("price-alert-rules/" + security_id)
        .then((response) => {
          this.loading = false;
          if (response.data.status == 1) {
            let security_data = response.data.data.security_data;
            let price_alert_data = response.data.data.price_alert_data;
            this.items = price_alert_data;
            if(security_data.symbol != undefined){
              this.symbol = security_data.symbol;
            }
          } else {
            this.$swal({
              showDenyButton: true,
              denyButtonText: `Ok`,
              showConfirmButton: false,
              title: "Error",
              text: response.data.message,
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$swal({
            showDenyButton: true,
            denyButtonText: `Ok`,
            showConfirmButton: false,
            title: "Error",
            text: "Something went wrong! Please try again later.",
          });
        });
    },
    async editAlert(alert_id){
      let item = this.items.find((alert) => {
        return (alert.id === alert_id);
      });

      this.alertForm = {
        ruleId: item.rule_id,
        securityId: item.security_id,
        ruleName: item.rule_name,
        ruleType: 'any',
        ruleConditions: await this.parseConditions(item.rule_conditions),
        note: item.note,
      }

      this.priceAlertTable = false;
    },
    async resumeAlert(alert_id){
      let item = this.items.find((alert) => {
        return (alert.id === alert_id);
      });

      this.loading = true;

      let payload = {
        id: alert_id,
        ruleId: item.rule_id,
        securityId: item.security_id,
        action: 'resume',
      };
      await axios
        .post("price-alert/ruletoggle",payload)
        .then((response) => {
          this.loading = false;
          if (response.data.status == 1) {
            this.$swal({
              title: "Price Alert",
              text: response.data.message,
            });
            item.rule_status = !item.rule_status;
          }
          else {
            this.$swal({
              showDenyButton: true,
              denyButtonText: `Ok`,
              showConfirmButton: false,
              title: "Error",
              text: response.data.message,
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$swal({
            showDenyButton: true,
            denyButtonText: `Ok`,
            showConfirmButton: false,
            title: "Error",
            text: "Something went wrong! Please try again later.",
          });
        });
    },
    async deleteAlert(alert_id){
      this.$swal({
        title: "Remove",
        showCancelButton: true,
        text: "Are you sure ?",
      }).then(async (result) => {
        if (result.value) {
          await this.removeAlert(alert_id);
        }
      });
    },
    async removeAlert(alert_id){
      let index = this.items.findIndex((alert) => {
        return (alert.id === alert_id);
      });

      let item = this.items[index];

      this.loading = true;

      let payload = {
        id: alert_id,
        ruleId: item.rule_id,
        securityId: item.security_id,
      };
      await axios
        .post("price-alert/ruledelete",payload)
        .then((response) => {
          this.loading = false;
          if (response.data.status == 1) {
            this.$swal({
              title: "Price Alert",
              text: response.data.message,
            });
            this.items.splice(index,1);
          }
          else {
            this.$swal({
              showDenyButton: true,
              denyButtonText: `Ok`,
              showConfirmButton: false,
              title: "Error",
              text: response.data.message,
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$swal({
            showDenyButton: true,
            denyButtonText: `Ok`,
            showConfirmButton: false,
            title: "Error",
            text: "Something went wrong! Please try again later.",
          });
        });
    },
    async parseConditions(conditions){
      let main = [];
      for(let condition of conditions){
        let temp = { 
          condOne: 'Latest Price',
          operator: condition[1], 
          condTwo: condition[2] 
        };

        main.push(temp);
      }

      return main;
    },
  },
  computed: {
    show: {
      get () {
        return this.value.show
      },
      set (value) {
        this.value.show = value;
        if(value === false){
          this.value.securityId = null;
        }
        this.$emit('input', this.value)
      }
    },
    securityId: {
      get () {
        return this.value.securityId
      },
    }
  },
  watch:{
    show(val){
      if(val === false){
        this.resetForm();
      }
    },
    securityId(val){
      if(val !== undefined && val !== null){
        this.fetchRules(val);
      }
    }
  },
  async mounted() {
    // code
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.price-alert-form {
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    .v-input {
      margin: 0;
      .v-input__slot {
        &:before,
        &:after {
          display: none;
        }
        input {
          color: var(--form-control-text);
          padding: 12px 0 0;
          @media only screen and (min-width: 1799px) {
            padding: 20px 0 0;
          }
        }
      }
    }
    .v-input--is-focused {
      border: 1px solid var(--input-focus) !important;
      outline: 0 !important;
      box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
    }
    label {
      color: var(--grey);
      font-size: 13px;
      font-weight: 500;
      line-height: 1;
      margin: 0 0 0;
      text-transform: capitalize;
      top: 8px !important;
      height: auto;
      @media only screen and (min-width: 1799px) {
        font-size: 14px !important;
        top: 12px !important;
      }
      &.v-label--active {
        top: 18px !important;
        font-size: 11px;
        transform: translateY(-18px) scale(1);
        @media only screen and (min-width: 1799px) {
          font-size: 12px !important;
          top: 20px !important;
        }
      }
      &.primary--text {
        color: var(--grey) !important;
      }
    }
    div,
    select,
    input {
      width: 100%;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .form-control,
    select {
      color: var(--form-control-text);
      font-size: 13px;
      font-weight: 500;
      height: 42px;
      background-color: var(--form-control-bg);
      border: 1px solid transparent;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      box-shadow: none;
      -webkit-box-shadow: none;
      @media only screen and (min-width: 1799px) {
        font-size: 14px !important;
        height: 50px;
      }
      &:focus {
        border: 1px solid var(--input-focus) !important;
        outline: 0 !important;
        box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
      }
    }
  }
  button {
    width: 48%;
    min-height: 30px;
    font-size: 14px;
    font-weight: 500;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    text-transform: capitalize;
    @media only screen and (min-width: 1799px) {
      font-size: 15px !important;
      min-height: 38px;
    }
    &.cancel-rfq-btn {
      color: var(--submit-portfolio-text);
      background-color: var(--grey);
      border: 1px solid var(--grey);
      width: 48%;
      min-height: 30px;
      font-size: 14px;
      font-weight: 500;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      box-shadow: none;
      -webkit-box-shadow: none;
      text-transform: capitalize;
      margin-bottom: 0;
      @media only screen and (min-width: 1799px) {
        font-size: 15px !important;
        min-height: 38px;
      }
      &:hover,
      &:focus {
        color: var(--grey);
        background-color: var(--submit-portfolio-text);
        border-color: var(--grey) !important;
      }
    }
  }
  .select-group {
    position: relative;
    label {
      position: absolute;
      top: 6px !important;
      left: 12px;
      font-size: 11px !important;
      font-size: 11px;
      @media only screen and (min-width: 1799px) {
        font-size: 12px !important;
      }
    }
    .custom-select {
      height: 42px;
      padding: 16px 1.75rem 0.375rem 0.75rem;
      @media only screen and (min-width: 1799px) {
        height: 50px;
      }
    }
  }
}
</style>
