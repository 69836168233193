export default {
    setItems(state, payload) {
        state.items.push(...payload)
        //state.items = payload
    },
    setNextPageIndex(state, payload) {
        state.next_page_index = payload
    },
    setSearchItems(state, payload) {
        state.items = payload
    }
}