<template>
  <!-- <div class="sticky"> -->
  <div>
    <b-navbar toggleable="lg">
      <b-container
        fluid
        class="d-flex justify-content-between align-items-center"
      >
        <b-navbar-brand to="/">
          <img alt="EMFI" :src="themeLogo" />
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav class="justify-content-center">
          <b-navbar-nav>
            <b-nav-item :href="home_url" target="_blank">Home</b-nav-item>
            <b-nav-item :href="pricing_url" target="_blank">Pricing</b-nav-item>
            <b-nav-item :href="research_url" target="_blank"
              >Research</b-nav-item
            >
            <b-nav-item :href="services_url" target="_blank"
              >Services</b-nav-item
            >
            <b-nav-item :href="about_url" target="_blank">About</b-nav-item>
            <b-nav-item :href="contact_url" target="_blank">Contact</b-nav-item>
            <!-- <b-nav-item @click.stop="settingDialog = true">Setting</b-nav-item> -->
          </b-navbar-nav>
        </b-collapse>
        <div class="d-flex justify-content-end align-items-center">
          <Portfolio />
          <div class="custome-select-wrap currency-wrap">
            <vue-multi-select
              class="header-currency"
              v-model="currencys"
              :btnLabel="btnLabel"
              :selectOptions="data1"
              @input="onChangeCurrency"
              @open="open"
              @close="close"
            />
          </div>
        </div>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import Portfolio from "@/components/Portfolio.vue";
import vueMultiSelect from "vue-multi-select";
import "vue-multi-select/dist/lib/vue-multi-select.css";
import themeconfig from '../themeconfig.js'
export default {
  name: "NavBar",
  data() {
    return {
      themeLogo:'',
      home_url: process.env.VUE_APP_FRONT_URL + "english",
      pricing_url: process.env.VUE_APP_FRONT_URL + "english/pricing",
      research_url: process.env.VUE_APP_FRONT_URL + "english/research",
      services_url: process.env.VUE_APP_FRONT_URL + "english/services",
      about_url: process.env.VUE_APP_FRONT_URL + "english/about",
      contact_url: process.env.VUE_APP_FRONT_URL + "english/contact",
      btnLabel: (currencys) =>
        currencys[0].name != "" ? currencys[0].name : `Currency`,
      currencys: [{ name: "USD" }],
      settingDialog: false,
      switch1: false,
      data1: [
        { name: "USD" },
        { name: "EUR" },
        { name: "GBP" },
        { name: "BASE" },
      ],
    };
  },
  computed: {
    filter_currency: {
      get() {
        return this.$store.getters["dashboard/getFilterCurrency"];
      },
    },
  },
  components: {
    Portfolio,
    vueMultiSelect,
  },
  watch: {},
  async mounted(){
    this.setThemeoption();
  },
  methods: {
    setThemeoption(){
      const curretn_theme = themeconfig.getTheme();
      // console.log("logo =>",curretn_theme.theme_logo);
      this.themeLogo = curretn_theme.theme_logo;
      document.body.classList.add(curretn_theme.theme_class);
      //favicon setting
      // Get or create the favicon
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');

      // Set the attributes of the favicon
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = curretn_theme.theme_favicon;

      // Append the favicon to the `head`
      document.getElementsByTagName('head')[0].appendChild(link);
      
      const titleEl = document.querySelector('head title');
      titleEl.textContent = themeconfig.getThemeTitle();
    },
    open() {},
    close() {},
    onClick() {
      this.$refs.dropdown.hide(true);
    },
    setFocus() {
      // Note, you need to add a ref="search" attribute to your input.
      this.$refs.labelFocus.classList.add("my-directive");
    },
    async onChangeCurrency() {
      await this.$store.dispatch(
        "dashboard/updateFilterCurrecy",
        this.currencys[0].name
      );
      await this.$store.dispatch("dashboard/fetchFilterCurrencyRate", {
        currency_filter: this.currencys[0].name,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
nav {
  padding: 0;
  -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
  min-height: 70px;
  @media (max-width: 991px) {
    padding: 1rem 0;
  }
  @media (max-width: 767px) {
    padding: 1rem;
  }
  .container {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
  }
  .navbar-brand {
    // Whene use EMFI theme that time use height 53px 
    // height: 53px;
    height: 40px;
    padding: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .navbar-nav {
    align-items: center;
    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: var(--primary);
    }
    .nav-item {
      display: flex;
      align-items: center;
    }

    .nav-link {
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
      padding: 10px 15px 10px;
      color: var(--menu-text);
      text-transform: capitalize;
      &.router-link-active {
        color: var(--menu-text-active);
      }
      &:hover,
      &:focus {
        color: var(--menu-text-active);
      }
    }
  }
}
.lang-text {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  padding: 10px 15px 10px;
  color: var(--grey);
  text-transform: capitalize;
  height: 40px;
  &:hover,
  &:focus {
    color: var(--white);
  }
}
</style>
