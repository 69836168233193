export default {
    setIsVisibleNews(state, isVisibleNews) {
        state.isVisibleNews = isVisibleNews
    },
    setUserAccessType(state, user_access_type) {
        state.user_access_type = user_access_type
    },
    setIsVisibleOrderEntry(state, isVisibleOrderEntry) {
        state.isVisibleOrderEntry = isVisibleOrderEntry
    },
    setFilterCurrency(state, setFilterCurrency) {
        state.filter_currency = setFilterCurrency
    },
    setFilterCurrencyRate(state, payload) {
        state.filter_currency_rate = payload.system_filter_rate;
        state.filter_currency_usd_rate = payload.system_filter_usd_rate;
    },
    setCounties(state, payload){
        state.counties_list = payload
    },
    setRegions(state, payload){
        state.regions_list = payload
    },
    setProfile(state, payload){
        state.profiles = payload
    },
    setCurrentTheme(state, payload){
        state.is_current_theme = payload
    },
    setFilterFortfolio(state, payload){
        state.filter_fortfolio = payload
    },
    setAllPortfolio(state, payload){
        state.all_portfolios = payload
    },
    setPortfolios(state, payload){
        state.portfolios = payload
    },
    setTradingPortfolios(state, payload){
        state.trading_portfolios = payload
    },
    setStartFilterDate(state, payload){
        state.start_filter_date = payload
    },
    setEndFilterDate(state, payload){
        state.end_filter_date = payload
    },
    setActiveTab(state, payload){
        state.active_tab = payload
    },
    setBLActiveTab(state, payload){
        state.bl_active_tab1 = payload
    },
    setCountriesSecurity(state, payload){
        state.countries_with_security = payload
    },
    setCurrencySecurity(state, payload){
        state.currency_with_security = payload
    },
    setSecondCurrencySecurity(state, payload){
        state.second_currency_with_security = payload
    },
    setSecondCountriesSecurity(state, payload){
        state.second_countries_with_security = payload
    },
    setTickerSecurity(state, payload){
        state.ticker_with_security = payload
    },
    setTransactionUpdate(state, payload) {
        state.is_transaction_update = payload
    },
    setIsMaximizeUpdate(state, payload) {
        state.is_maximize = payload
    },
    setExchange(state, payload){
        state.exchange_with_security = payload
    },
    setType(state, payload){
        state.type_with_security = payload
    },
    setGroup(state, payload){
        state.group_with_security = payload
    },
    setSector(state, payload){
        state.sector_with_security = payload
    },
    setArea(state, payload){
        state.area_with_security = payload
    },
    setGcategory(state, payload){
        state.gcategory_with_security = payload
    },
    setRating(state, payload){
        state.rating_with_security = payload
    },
    setCompany(state, payload){
        state.company_with_security = payload
    },
    setCategory(state, payload){
        state.category_with_security = payload
    },
    setPeer(state, payload){
        state.peer_with_security = payload
    },
    setDefaultSecuritySet(state, payload){
		state.default_security_set = payload
	},
    setRFQSettings(state, payload) {
        state.rfq_settings = payload
    },
}