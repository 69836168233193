export default {
    items: [],
    kill_result: [],
    next_page_index: 1,
    start_record: 0,
    length_record: 40,
    kill_count:0,
    is_place_order: 0,
    transaction_details: [],
    trade_comments: [],
    record_type: "",
    total_record: 0,
    is_transaction_scanner: 0,
}