<template>
  <div class="performance-chart-wrap">
    <div id="pchart">
      <div class="toolbar position-right">
        <b-dropdown
          id="period-drp"
          right
          :text="this.period_drp_placeholder"
          variant="primary"
          class="custome-dropdown margin-r-10"
          title="Interval"
        >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setPeriodSelectedOption('Daily')"
            v-if="this.period_drp_placeholder != 'Daily'"
            >Daily</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setPeriodSelectedOption('Weekly')"
            v-if="this.period_drp_placeholder != 'Weekly'"
            >Weekly</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setPeriodSelectedOption('Monthly')"
            v-if="this.period_drp_placeholder != 'Monthly'"
            >Monthly</b-dropdown-item
          >
        </b-dropdown>
        <b-dropdown
          id="holding-first_drp"
          right
          :text="this.pnl_chart_placeholder"
          variant="primary"
          class="custome-dropdown margin-l-10"
        >
          <b-dropdown-item
            href="javascript:void(0);"
            @click="changePerformanceChart('Realized & Unrealized')"
            v-if="this.pnl_chart_placeholder != 'Realized & Unrealized'"
            >Realized & Unrealized</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0);"
            @click="changePerformanceChart('Breakdown By Assets')"
            v-if="this.pnl_chart_placeholder != 'Breakdown By Assets'"
            >Breakdown By Assets</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0);"
            @click="changePerformanceChart('Trading & Interest')"
            v-if="this.pnl_chart_placeholder != 'Trading & Interest'"
            >Trading & Interest</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <div
        id="performance-chart"
        v-if="this.pnl_chart_placeholder == 'Realized & Unrealized'"
      >
        <apexchart
          type="line"
          :height="CompPerformanceChartHeight"
          :width="CompPerformanceChartWidth"
          ref="pchart"
          :options="firstchartOptions"
          :series="first_series"
        ></apexchart>
      </div>
      <div
        id="performance-chart"
        v-if="this.pnl_chart_placeholder == 'Breakdown By Assets'"
      >
        <apexchart
          type="line"
          :height="CompPerformanceChartHeight"
          :width="CompPerformanceChartWidth"
          ref="pchart"
          :options="secondChartOptions"
          :series="second_series"
        ></apexchart>
      </div>
      <div
        id="performance-chart"
        v-if="this.pnl_chart_placeholder == 'Trading & Interest'"
      >
        <apexchart
          type="line"
          :height="CompPerformanceChartHeight"
          :width="CompPerformanceChartWidth"
          ref="pchart"
          :options="thirdChartOptions"
          :series="third_series"
        ></apexchart>
      </div>
    </div>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import themeconfig from '../themeconfig.js'
export default {
  name: "PerformanceChart",
  components: { apexchart: VueApexCharts },
  props: ["CompisPerformanceChartOpen","CompPerformanceChartHeight", "CompPerformanceChartWidth"],
  data() {
    return {
      loading: false,
      first_series: [],
      second_series: [
        {
          name: "Currencies",
          type: "bar",
          data: [],
        },
        {
          name: "Equities",
          type: "bar",
          data: [],
        },
        {
          name: "Bonds",
          type: "bar",
          data: [],
        },
        {
          name: "Funds",
          type: "bar",
          data: [],
        },
        {
          name: "Total",
          type: "line",
          data: [],
        },
      ],
      third_series: [
        {
          name: "Trading",
          type: "bar",
          data: [],
        },
        {
          name: "Interest",
          type: "bar",
          data: [],
        },
      ],
      firstchartOptions: {
        chart: {
          type: "line",
          stacked: true,
          foreColor:'',
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          //tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return new Intl.NumberFormat("en-US").format(val.toFixed(0));
            },
          },
          min: this.min_value,
          max: this.max_value,
        },
        stroke: {
          width: [0, 0, 5],
          curve: "smooth",
        },
        colors: [],
        legend: {
          show: true,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "MMM yyyy",
          },
          y: {
            formatter: function(value) {
              return new Intl.NumberFormat("en-US").format(value.toFixed(0));
            },
          },
        },
        fill: {
          opacity: 1,
          type: ["solid"],
        },
      },
      secondChartOptions: {},
      thirdChartOptions: {},
      selection: "one_year",
      pnl_chart_placeholder: "Realized & Unrealized",
      period_drp_placeholder: "Monthly",
      average_drp_placeholder: "90 Days",
    };
  },
  computed: {
    all_p_items() {
      let all_items = this.$store.getters["performance_chart/getAllItems"];
      return all_items[0] ?? [];
    },
    realized_items() {
      let realized_items = this.$store.getters[
        "performance_chart/getRealizedItems"
      ];
      return realized_items[0] ?? [];
    },
    unrealized_items() {
      let unrealized_items = this.$store.getters[
        "performance_chart/getUnrealizedItems"
      ];
      return unrealized_items[0] ?? [];
    },
    stock_items() {
      let stock_items = this.$store.getters["performance_chart/getStockItems"];
      return stock_items[0] ?? [];
    },
    bond_items() {
      let bond_items = this.$store.getters["performance_chart/getBondItems"];
      return bond_items[0] ?? [];
    },
    fund_items() {
      let fund_items = this.$store.getters["performance_chart/getFundItems"];
      return fund_items[0] ?? [];
    },
    trading_items() {
      let trading_items = this.$store.getters[
        "performance_chart/getTradingItems"
      ];
      return trading_items[0] ?? [];
    },
    interest_items() {
      let interest_items = this.$store.getters[
        "performance_chart/getInterestItems"
      ];
      return interest_items[0] ?? [];
    },
    moving_avg: {
      get() {
        return this.$store.getters["performance_chart/getMovingAvg"];
      },
    },
    currency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
    is_current_theme: {
      get() {
        return this.$store.getters["dashboard/getCurrentTheme"];
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
    periodicity: {
      get() {
        return this.$store.getters["performance_chart/getPeriodicity"];
      },
    },
    max_value: {
      get() {
        return this.$store.getters["performance_chart/getMaxValue"];
      },
    },
    min_value: {
      get() {
        return this.$store.getters["performance_chart/getMinValue"];
      },
    },
    start_filter_date: {
      get() {
        return this.$store.getters["dashboard/getStartFilterDate"];
      },
    },
    end_filter_date: {
      get() {
        return this.$store.getters["dashboard/getEndFilterDate"];
      },
    },
  },
  watch: {
    CompPerformanceChartHeight(new_val, old_val) {
      if (new_val != old_val) {
        this.fetchPerformanceChart();
      }
    },
    CompPerformanceChartWidth(new_val, old_val) {
      if (new_val != old_val) {
        //this.fetchPerformanceChart();
      }
    },
    currency() {
      this.fetchPerformanceChart();
    },
    async is_current_theme() {
      await this.updatePerformanceChartOption();
      await this.updatePerformanceSeries();
    },
    async filter_portfolio() {
      this.fetchPerformanceChart();
    },
    async start_filter_date() {
      this.fetchPerformanceChart();
    },
  },
  methods: {
    async setThemeoption(){
      const curretn_theme = themeconfig.getTheme();
      localStorage.getItem("isDarkActive") == 1 ? this.firstchartOptions.chart.foreColor = "#a8a8a8" : this.firstchartOptions.chart.foreColor = curretn_theme.chart_for_color;
      this.firstchartOptions.colors=[ 
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : curretn_theme.chart_primary_color,
          localStorage.getItem("isDarkActive") == 1 ? "#bfbfbf" : "#7f7f7f",
          localStorage.getItem("isDarkActive") == 1 ? "#404040" : "#333333"]
// localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : "#333333"
      
    },
    async setAverageSelectedOption(selectedOption) {
      this.average_drp_placeholder = selectedOption;
      let average = this.getAverageSelected(selectedOption);
      await this.$store.dispatch("performance_chart/updateMovingAvg", average);
      if (this.pnl_chart_placeholder == "Realized & Unrealized") {
        await this.fetchPerformanceChart();
      } else if (this.pnl_chart_placeholder == "Breakdown By Assets") {
        await this.fetchAsstesPerformanceChart();
      } else if (this.pnl_chart_placeholder == "Trading & Interest") {
        await this.fetchPNLPerformanceChart();
      }
    },
    async setPeriodSelectedOption(selectedOption) {
      this.period_drp_placeholder = selectedOption;
      let period = this.getPeriodSelected(selectedOption);
      await this.$store.dispatch("performance_chart/updatePeriodicity", period);
      if (this.pnl_chart_placeholder == "Realized & Unrealized") {
        await this.fetchPerformanceChart();
      } else if (this.pnl_chart_placeholder == "Breakdown By Assets") {
        await this.fetchAsstesPerformanceChart();
      } else if (this.pnl_chart_placeholder == "Trading & Interest") {
        await this.fetchPNLPerformanceChart();
      }
    },
    async changePerformanceChart(selectedOption) {
      this.pnl_chart_placeholder = selectedOption;
      if (this.pnl_chart_placeholder == "Realized & Unrealized") {
        await this.fetchPerformanceChart();
      } else if (this.pnl_chart_placeholder == "Breakdown By Assets") {
        await this.fetchAsstesPerformanceChart();
      } else if (this.pnl_chart_placeholder == "Trading & Interest") {
        await this.fetchPNLPerformanceChart();
      }
    },
    async fetchPerformanceChart() {
      this.loading = true;
      await this.$store.dispatch("performance_chart/fetchItems", {
        filter_portfolio_ids: this.filter_portfolio,
        start_date: this.start_filter_date,
        end_date: this.end_filter_date,
        currency_filter: this.currency,
        moving_avg: this.moving_avg,
        periodicity: this.periodicity,
      });
      await this.updatePerformanceChartOption();
      await this.updatePerformanceSeries();
      this.loading = false;
    },
    async fetchAsstesPerformanceChart() {
      this.loading = true;
      await this.$store.dispatch("performance_chart/fetchAssetsItems", {
        chart_type: "by_assets",
        filter_portfolio_ids: this.filter_portfolio,
        start_date: this.start_filter_date,
        end_date: this.end_filter_date,
        currency_filter: this.currency,
        moving_avg: this.moving_avg,
        periodicity: this.periodicity,
      });
      await this.updateAssetPerformanceChartOption();
      await this.updateAssetPerformanceSeries();
      this.loading = false;
    },
    async fetchPNLPerformanceChart() {
      this.loading = true;
      await this.$store.dispatch("performance_chart/fetchPNLItems", {
        chart_type: "by_pnl",
        filter_portfolio_ids: this.filter_portfolio,
        start_date: this.start_filter_date,
        end_date: this.end_filter_date,
        currency_filter: this.currency,
        moving_avg: this.moving_avg,
        periodicity: this.periodicity,
      });
      await this.updatePNLPerformancechartOption();
      await this.updatePNLPerformanceSeries();
      this.loading = false;
    },
    async updatePerformanceSeries() {
      this.$refs.pchart.updateSeries([
        {
          name: this.realized_items.name,
          type: "bar",
          data: this.realized_items.data,
        },
        {
          name: this.unrealized_items.name,
          type: "bar",
          data: this.unrealized_items.data,
        },
      ]);
    },
    async updatePerformanceChartOption() {
      const curretn_theme = themeconfig.getTheme();
      this.firstchartOptions = {
        chart: {
          type: "line",
          stacked: true,
          foreColor:
            localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : curretn_theme.chart_for_color,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          type: "datetime",
          //tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return new Intl.NumberFormat("en-US").format(val.toFixed(0));
            },
          },
          min: this.min_value,
          max: this.max_value,
        },
        stroke: {
          width: [0, 0, 2],
          curve: "smooth",
        },
        colors: [
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : curretn_theme.chart_primary_color,
          localStorage.getItem("isDarkActive") == 1 ? "#bfbfbf" : "#7f7f7f",
          localStorage.getItem("isDarkActive") == 1 ? "#404040" : "#333333",
        ],
        legend: {
          show: true,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format:
              this.periodicity && this.periodicity != "daily"
                ? "MMM yyyy"
                : "dd MMM yyyy",
          },
          y: {
            formatter: function(value) {
              return new Intl.NumberFormat("en-US").format(value.toFixed(0));
            },
          },
        },
      };
    },
    async updateAssetPerformanceChartOption() {
      const curretn_theme = themeconfig.getTheme();
      this.secondChartOptions = {
        chart: {
          type: "line",
          stacked: true,
          foreColor:
            localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : curretn_theme.chart_for_color,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          //tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return new Intl.NumberFormat("en-US").format(val.toFixed(0));
            },
          },
          min: this.min_value,
          max: this.max_value,
        },
        stroke: {
          width: [0, 0, 0, 0, 0],
          curve: "smooth",
        },
        colors: [
          localStorage.getItem("isDarkActive") == 1 ? "#ca2330" : curretn_theme.chart_primary_color,
          localStorage.getItem("isDarkActive") == 1 ? "#005842" : "#005842",
          localStorage.getItem("isDarkActive") == 1 ? "#fbc117" : "#fbc117",
        ],
        legend: {
          show: true,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "MMM yyyy",
          },
          y: {
            formatter: function(value) {
              return new Intl.NumberFormat("en-US").format(value.toFixed(0));
            },
          },
        },
      };
    },
    async updatePNLPerformancechartOption() {
      const curretn_theme = themeconfig.getTheme();
      this.thirdChartOptions = {
        chart: {
          type: "line",
          stacked: true,
          foreColor:
            localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : curretn_theme.chart_for_color,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          type: "datetime",
          //tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return new Intl.NumberFormat("en-US").format(val.toFixed(0));
            },
          },
          min: this.min_value,
          max: this.max_value,
        },
        stroke: {
          width: [0, 0, 0, 0, 0],
          curve: "smooth",
        },
        colors: [
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : curretn_theme.chart_primary_color,
          localStorage.getItem("isDarkActive") == 1 ? "#bfbfbf" : "#7f7f7f",
          localStorage.getItem("isDarkActive") == 1 ? "#404040" : "#333333",
        ],
        legend: {
          show: true,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format:
              this.periodicity && this.periodicity != "daily"
                ? "MMM yyyy"
                : "dd MMM yyyy",
          },
          y: {
            formatter: function(value) {
              return new Intl.NumberFormat("en-US").format(value.toFixed(0));
            },
          },
        },
      };
    },
    async updateAssetPerformanceSeries() {
      this.$refs.pchart.updateSeries([
        {
          name: this.stock_items.name,
          type: "bar",
          data: this.stock_items.data,
        },
        {
          name: this.bond_items.name,
          type: "bar",
          data: this.bond_items.data,
        },
        {
          name: this.fund_items.name,
          type: "bar",
          data: this.fund_items.data,
        },
      ]);
    },
    async updatePNLPerformanceSeries() {
      this.$refs.pchart.updateSeries([
        {
          name: this.trading_items.name,
          type: "bar",
          data: this.trading_items.data,
        },
        {
          name: this.interest_items.name,
          type: "bar",
          data: this.interest_items.data,
        },
      ]);
    },
  },
  async mounted() {
    if(this.CompisPerformanceChartOpen){
      // console.log("call PerformanceChart component mounted order");
      await this.setThemeoption();
      await this.fetchPerformanceChart();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.performance-chart-wrap {
  padding: 5px 0;
  #pchart {
    position: relative;
    .toolbar {
      position: absolute;
      top: 0;
      left: 15px;
      z-index: 9;
      &.position-right {
        left: inherit;
        right: 35px;
      }
      .btn {
        color: var(--toolbar-btn-color);
        font-size: 12px;
        font-weight: 400;
        height: inherit;
        padding: 5px 8px;
        border-radius: 0;
        line-height: 12px;
        margin: 1px 0 0 8px;
        border: none;
        background-color: var(--toolbar-btn-bg);
        border: 1px solid var(--toolbar-btn-color);
        cursor: pointer;
        @media only screen and (max-width: 1599px) {
          padding: 3px 8px;
        }
        &:hover,
        &:focus,
        &.active {
          background-color: var(--toolbar-btn-color);
          border: 1px solid var(--toolbar-btn-color);
          color: var(--toolbar-btn-text);
        }
        &:first-child {
          margin-left: 0;
        }
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
    > div {
      min-height: inherit !important;
    }
    .apexcharts-canvas {
      padding-top: 10px;
      margin-right: 0;
      width: 100% !important;
      .apexcharts-zoom-icon {
        display: none;
        &.apexcharts-selected {
          svg {
            fill: var(--apexchart-icon-color);
          }
        }
      }
    }
    .apexcharts-text {
      tspan {
        font-family: var(--font-family);
        color: var(--tspan-text) !important;
      }
    }
    .apexcharts-tooltip {
      background: var(--white-light);
      color: var(--primary);
      border-radius: 0;
      box-shadow: none;
      border-color: var(--grey-light);
      font-size: 12px;
      .apexcharts-tooltip-title {
        background: transparent;
        border: none;
        padding: 6px 6px 3px;
        margin-bottom: 0;
        font-size: 12px;
      }
      .apexcharts-tooltip-y-group {
        padding: 0;
      }
      .apexcharts-tooltip-series-group {
        padding: 3px 6px 6px;
      }
      .apexcharts-tooltip-marker {
        display: none;
      }
    }
    .apexcharts-menu {
      border: 1px solid var(--grey-light);
      border-radius: 0;
      padding: 0;
      right: 5px;
      background: var(--apexcharts-menu-bg);
      .apexcharts-menu-item {
        color: var(--primary);
        &:hover {
          background: var(--primary);
          color: var(--apexcharts-menu-hover-text);
        }
      }
    }
    .apexcharts-menu-icon,
    .apexcharts-zoomin-icon,
    .apexcharts-zoomout-icon,
    .apexcharts-reset-icon {
      color: var(--dark);
      margin: 0;
      svg {
        fill: var(--dark);
      }
      &:hover,
      &:focus {
        color: var(--primary);
        svg {
          fill: var(--primary);
        }
      }
    }
  }
}
</style>
