export default {
    getIsVisibleNews: state => state.isVisibleNews,
    getUserAccessType: state => state.user_access_type,
    getIsVisibleOrderEntry: state => state.isVisibleOrderEntry,
    getFilterCurrency: state => state.filter_currency,
    getFilterCurrencyRate: state => state.filter_currency_rate,
    getFilterCurrencyUSDRate: state => state.filter_currency_usd_rate,
    getCounties: state => {
        return state.counties_list
    },
    getRegions: state =>{
        return state.regions_list
    },
    getProfiles: state =>{
        return state.profiles
    },
    getCurrentTheme: state => state.is_current_theme,
    getFilterFortfolio: state =>{
        return state.filter_fortfolio
    },
    getAllPortfolio: state =>{
        return state.all_portfolios
    },
    getPortfolios: state =>{
        return state.portfolios
    },
    getTradingPortfolios: state =>{
        return state.trading_portfolios
    },
    getStartFilterDate: state => state.start_filter_date,
    getEndFilterDate: state => state.end_filter_date,
    getActiveTab: state => state.active_tab,
    getBLActiveTab: state => state.bl_active_tab1,
    getCountriesSecurity: state => {
        return state.countries_with_security
    },
    getCurrencySecurity: state =>{
        return state.currency_with_security
    },  
    getSecondCountriesSecurity: state => {
        return state.second_countries_with_security
    },
    getSecondCurrencySecurity: state =>{
        return state.second_currency_with_security
    },
    getTickerSecurity: state =>{
        return state.ticker_with_security
    },
    getIsTransactionUpdate: state => state.is_transaction_update,
    getIsMaximize: state => state.is_maximize,
    getExchanges: state =>{
        return state.exchange_with_security
    },
    getGroups: state =>{
        return state.group_with_security
    },
    getTypes: state =>{
        return state.type_with_security
    },
    getSectors: state =>{
        return state.sector_with_security
    },
    getArea: state =>{
        return state.area_with_security
    },
    getGcategory: state =>{
        return state.gcategory_with_security
    },
    getRating: state =>{
        return state.rating_with_security
    },
    getCompany: state =>{
        return state.company_with_security
    },
    getCategory: state =>{
        return state.category_with_security
    },
    getPeers: state =>{
        return state.peer_with_security
    },
    getDefaultSecuritySet: state => {
		return state.default_security_set
	},
    getRFQSettings: state => {
		return state.rfq_settings
	},
}