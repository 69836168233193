export default {
	items: [],
	next_page_index: 1,
    start_record: 0,
    length_record: 40,
    default_market_id: 6,
    total_record: 0,
    is_cash_scanner: 0,
    fxspot_currency_list: [],
    cash_symbols: [],
}