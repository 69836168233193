/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);
import watchListModule from "./watchlist"
import notificationModule from "./notification";
import transactionModule from "./transaction";
import cashflowsModule from "./cashflows";
import chartModule from "./chart";
import performanceModule from "./performance";
import holdingModule from "./holding";
import newsModule from "./news";
import dashboardModule from "./dashboard";
import performancechartModule from "./performance_chart";
import dailyHoldingchartModule from "./daily_holding_chart";
import HoldingchartModule from "./holding_chart";
import CashFlowschartModule from "./cashflows_chart";
import TransactionchartModule from "./transaction_chart";
import BondsModule from "./bonds";
import CashModule from "./cash";
import EquitiesModule from "./equities";
import FundsModule from "./funds";
import StatementModule from "./statements";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    chart: chartModule,
    watchList: watchListModule,
    notification: notificationModule,
    transaction: transactionModule,
    cashflows: cashflowsModule,
    performance: performanceModule,
    holding: holdingModule,
    news: newsModule,
    dashboard: dashboardModule,
    performance_chart: performancechartModule,
    daily_holding_chart: dailyHoldingchartModule,
    holding_chart: HoldingchartModule,
    cashflows_chart: CashFlowschartModule,
    transaction_chart: TransactionchartModule,
    bonds: BondsModule,
    cash: CashModule,
    equities: EquitiesModule,
    funds: FundsModule,
    statements: StatementModule,

  },
  strict: process.env.VUE_APP_MODE !== 'production',
});