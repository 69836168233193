<template>
  <div class="transaction-chart-wrap">
    <div id="transactionchart">
      <div class="toolbar position-right">
        <b-dropdown
          id="m-average-drp"
          right
          :text="this.average_drp_placeholder"
          variant="primary"
          class="custome-dropdown margin-r-10"
          title="Moving Average"
          v-if="false"
        >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setAverageSelectedOption('7 Days')"
            v-if="this.average_drp_placeholder != '7 Days'"
            >7 Days</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setAverageSelectedOption('15 Days')"
            v-if="this.average_drp_placeholder != '15 Days'"
            >15 Days</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setAverageSelectedOption('30 Days')"
            v-if="this.average_drp_placeholder != '30 Days'"
            >30 Days</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setAverageSelectedOption('90 Days')"
            v-if="this.average_drp_placeholder != '90 Days'"
            >90 Days</b-dropdown-item
          >
        </b-dropdown>
        <b-dropdown
          id="period-drp"
          right
          :text="this.period_drp_placeholder"
          variant="primary"
          class="custome-dropdown margin-r-10"
          title="Interval"
        >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setPeriodSelectedOption('Daily')"
            v-if="this.period_drp_placeholder != 'Daily'"
            >Daily</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setPeriodSelectedOption('Weekly')"
            v-if="this.period_drp_placeholder != 'Weekly'"
            >Weekly</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setPeriodSelectedOption('Monthly')"
            v-if="this.period_drp_placeholder != 'Monthly'"
            >Monthly</b-dropdown-item
          >
        </b-dropdown>
        <b-dropdown
          id="time-drp"
          right
          :text="this.time_drp_placeholder"
          variant="primary"
          class="custome-dropdown"
          title="Period"
        >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setTimeSelectedOption('Month')"
            v-if="this.time_drp_placeholder != 'Month'"
            >Month</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setTimeSelectedOption('Quarter')"
            v-if="this.time_drp_placeholder != 'Quarter'"
            >Quarter</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setTimeSelectedOption('Year')"
            v-if="this.time_drp_placeholder != 'Year'"
            >Year</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setTimeSelectedOption('5 Years')"
            v-if="this.time_drp_placeholder != '5 Years'"
            >5 Year</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0)"
            @click="setTimeSelectedOption('YTD')"
            v-if="this.time_drp_placeholder != 'YTD'"
            >YTD</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <div id="transaction-chart">
        <apexchart
          type="line"
          :height="CompTransactionChartHeight"
          :width="CompTransactionChartWidth"
          ref="transactionChart"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "TransectionChart",
  components: { apexchart: VueApexCharts },
  props: ["CompisTransectionChartOpen","CompTransactionChartHeight", "CompTransactionChartWidth"],
  data() {
    return {
      loading: false,
      series: [],
      chartOptions: {
        chart: {
          type: "line",
          stacked: true,
          foreColor:
            localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : "#333333",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          //tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          min: 0,
          max: this.max_value,
        },
        stroke: {
          width: [0, 0, 0, 0, 0],
          curve: "smooth",
        },
        colors: [
          // localStorage.getItem("isDarkActive") == 1 ? "#218e89" : "#218e89",
          // localStorage.getItem("isDarkActive") == 1 ? "#3b0550" : "#3b0550",
          // localStorage.getItem("isDarkActive") == 1 ? "#ff7101" : "#ff7101",
          // localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#001a34",
          //localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#001a33",
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#001a34",
          localStorage.getItem("isDarkActive") == 1 ? "#ca2330" : "#ca2330",
          localStorage.getItem("isDarkActive") == 1 ? "#005842" : "#005842",
          localStorage.getItem("isDarkActive") == 1 ? "#fbc117" : "#fbc117",
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#001a34",
        ],
        legend: {
          show: true,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "MMM yyyy",
          },
        },
      },
      selection: "one_month",
      time_drp_placeholder: "5 Years",
      period_drp_placeholder: "Monthly",
      average_drp_placeholder: "90 Days",
    };
  },
  computed: {
    all_trades() {
      let all_trades = this.$store.getters["transaction_chart/getAllTrades"];
      return all_trades[0] ?? [];
    },
    cash_trades() {
      let cash_trades = this.$store.getters["transaction_chart/getCashTrades"];
      return cash_trades[0] ?? [];
    },
    bonds_trades() {
      let bonds_trades = this.$store.getters[
        "transaction_chart/getBondsTrades"
      ];
      return bonds_trades[0] ?? [];
    },
    equities_trades() {
      let equties_trades = this.$store.getters[
        "transaction_chart/getEquitiesTrades"
      ];
      return equties_trades[0] ?? [];
    },
    funds_trades() {
      let funds_trades = this.$store.getters[
        "transaction_chart/getFundsTrades"
      ];
      return funds_trades[0] ?? [];
    },
    timeline: {
      get() {
        return this.$store.getters["transaction_chart/getTimeline"];
      },
    },
    moving_avg: {
      get() {
        return this.$store.getters["transaction_chart/getMovingAvg"];
      },
    },
    periodicity: {
      get() {
        return this.$store.getters["transaction_chart/getPeriodicity"];
      },
    },
    max_value: {
      get() {
        return this.$store.getters["transaction_chart/getMaxValue"];
      },
    },
    currency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
    is_current_theme: {
      get() {
        return this.$store.getters["dashboard/getCurrentTheme"];
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
    funds_status: {
      get() {
        return this.$store.getters["transaction_chart/getFundsStatus"];
      },
    },
    equities_status: {
      get() {
        return this.$store.getters["transaction_chart/getEquitiesStatus"];
      },
    },
    bonds_status: {
      get() {
        return this.$store.getters["transaction_chart/getBondsStatus"];
      },
    },
    cash_status: {
      get() {
        return this.$store.getters["transaction_chart/getCashStatus"];
      },
    },
  },
  watch: {
    CompTransactionChartHeight(new_val, old_val) {
      if (new_val != old_val) {
        this.fetchTransactionChart();
      }
    },
    CompTransactionChartWidth(new_val, old_val) {
      if (new_val != old_val) {
        //this.fetchTransactionChart();
      }
    },
    async is_current_theme() {
      await this.updateTransactionChartOption();
      await this.updateTransactionChartSeries();
    },
    async filter_portfolio() {
      this.fetchTransactionChart();
    },
    async equities_status() {
      this.loading = true;
      await this.getTransactionChart();
      await this.updateTransactionChartOption();
      await this.updateTransactionChartSeries();
      await this.updateToggleChartSeries();
      this.loading = false;
    },
    async bonds_status() {
      this.loading = true;
      await this.getTransactionChart();
      await this.updateTransactionChartOption();
      await this.updateTransactionChartSeries();
      await this.updateToggleChartSeries();
      this.loading = false;
    },
    async funds_status() {
      this.loading = true;
      await this.getTransactionChart();
      await this.updateTransactionChartOption();
      await this.updateTransactionChartSeries();
      await this.updateToggleChartSeries();
      this.loading = false;
    },
    async cash_status() {
      this.loading = true;
      await this.getTransactionChart();
      await this.updateTransactionChartOption();
      await this.updateTransactionChartSeries();
      await this.updateToggleChartSeries();
      this.loading = false;
    },
  },
  methods: {
    async setTimeSelectedOption(selectedOption) {
      this.time_drp_placeholder = selectedOption;
      let time = this.getTimeSelected(selectedOption);
      this.selection = time;
      await this.$store.dispatch("transaction_chart/fetchTimeline", time);
      await this.fetchTransactionChart();
      await this.updateTransactionChartOption();
      // if (time == "three_months") {
      //   await this.setPeriodSelectedOption("Weekly");
      // } else if (time == "one_year") {
      //   await this.setPeriodSelectedOption("Monthly");
      // } else {
      //   await this.setPeriodSelectedOption("Daily");
      // }
    },
    async setAverageSelectedOption(selectedOption) {
      this.average_drp_placeholder = selectedOption;
      let average = this.getAverageSelected(selectedOption);
      await this.$store.dispatch("transaction_chart/updateMovingAvg", average);
      await this.fetchTransactionChart();
      await this.updateTransactionChartOption();
    },
    async setPeriodSelectedOption(selectedOption) {
      this.period_drp_placeholder = selectedOption;
      let period = this.getPeriodSelected(selectedOption);
      await this.$store.dispatch("transaction_chart/updatePeriodicity", period);
      await this.fetchTransactionChart();
      await this.updateTransactionChartOption();
    },
    async updateTransactionChartData(timeline) {
      this.selection = timeline;
      await this.$store.dispatch("transaction_chart/fetchTimeline", timeline);
      await this.fetchTransactionChart();
      await this.updateTransactionChartOption();
    },
    async fetchTransactionChart() {
      this.loading = true;
      await this.getTransactionChart();
      await this.updateTransactionChartSeries();
      this.loading = false;
    },
    async getTransactionChart() {
      await this.$store.dispatch("transaction_chart/fetchItems", {
        filter_portfolio_ids: this.filter_portfolio,
        timeline: this.timeline,
        currency_filter: this.currency,
        moving_avg: this.moving_avg,
        periodicity: this.periodicity,
        bonds_status: this.bonds_status,
        funds_status: this.funds_status,
        equities_status: this.equities_status,
        cash_status: this.cash_status,
      });
    },
    async updateTransactionChartSeries() {
      this.$refs.transactionChart.updateSeries([
        {
          name: "Currencies",
          type: "bar",
          data: this.cash_trades.data,
        },
        {
          name: "Equities",
          type: "bar",
          data: this.equities_trades.data,
        },
        {
          name: "Bonds",
          type: "bar",
          data: this.bonds_trades.data,
        },
        {
          name: "Funds",
          type: "bar",
          data: this.funds_trades.data,
        },
        {
          name: "Total",
          type: "line",
          data: this.all_trades.data,
        },
      ]);
    },
    async updateToggleChartSeries() {
      if (this.cash_status == 0) {
        this.$refs.transactionChart.toggleSeries("Currencies");
      }
      if (this.equities_status == 0) {
        this.$refs.transactionChart.toggleSeries("Equities");
      }
      if (this.bonds_status == 0) {
        this.$refs.transactionChart.toggleSeries("Bonds");
      }
      if (this.funds_status == 0) {
        this.$refs.transactionChart.toggleSeries("Funds");
      }
      if (
        this.equities_status == 0 &&
        this.bonds_status == 0 &&
        this.cash_status == 0 &&
        this.funds_status == 0
      ) {
        this.$refs.transactionChart.toggleSeries("Total");
      }
    },
    async updateTransactionChartOption() {
      var $this = this;
      this.chartOptions = {
        chart: {
          type: "line",
          stacked: true,
          foreColor:
            localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : "#333333",
          stroke: {
            curve: "smooth",
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
          events: {
            legendClick: async function(chartContext, seriesIndex, config) {
              console.log(chartContext);
              console.log(config);
              // console.log(seriesIndex);
              // console.log($this);
              if (seriesIndex == 0) {
                let fx_status = $this.cash_status;
                fx_status = fx_status == 1 ? 0 : 1;
                await $this.$store.dispatch(
                  "transaction_chart/updateCashStatus",
                  fx_status
                );
              } else if (seriesIndex == 1) {
                let e_status = $this.equities_status;
                e_status = e_status == 1 ? 0 : 1;
                await $this.$store.dispatch(
                  "transaction_chart/updateEquitiesStatus",
                  e_status
                );
              } else if (seriesIndex == 2) {
                let bonds_status = $this.bonds_status;
                bonds_status = bonds_status == 1 ? 0 : 1;
                await $this.$store.dispatch(
                  "transaction_chart/updateBondsStatus",
                  bonds_status
                );
              } else if (seriesIndex == 3) {
                let funds_status = $this.funds_status;
                funds_status = funds_status == 1 ? 0 : 1;
                await $this.$store.dispatch(
                  "transaction_chart/updateFundsStatus",
                  funds_status
                );
              }
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          type: "datetime",
          //tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          min: 0,
          max: $this.max_value,
        },
        stroke: {
          width: [0, 0, 0, 0, 0],
          curve: "smooth",
        },
        colors: [
          // localStorage.getItem("isDarkActive") == 1 ? "#218e89" : "#218e89",
          // localStorage.getItem("isDarkActive") == 1 ? "#3b0550" : "#3b0550",
          // localStorage.getItem("isDarkActive") == 1 ? "#ff7101" : "#ff7101",
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#001a34",
          localStorage.getItem("isDarkActive") == 1 ? "#ca2330" : "#ca2330",
          localStorage.getItem("isDarkActive") == 1 ? "#005842" : "#005842",
          localStorage.getItem("isDarkActive") == 1 ? "#fbc117" : "#fbc117",
          localStorage.getItem("isDarkActive") == 1 ? "#3c3c3c" : "#3c3c3c",
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#001a34",
        ],
        legend: {
          show: true,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format:
              this.periodicity && this.periodicity != "daily"
                ? "MMM yyyy"
                : "dd MMM yyyy",
          },
        },
      };
    },
  },
  async mounted() {
    if(this.CompisTransectionChartOpen){
    //  console.log("call TransectionChart component mounted order");
      await this.fetchTransactionChart();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.transaction-chart-wrap {
  padding: 5px 0;
  #transactionchart {
    position: relative;
    .toolbar {
      position: absolute;
      top: 0;
      left: 15px;
      z-index: 9;
      &.position-right {
        left: inherit;
        right: 35px;
      }
      .btn {
        color: var(--toolbar-btn-color);
        font-size: 12px;
        font-weight: 400;
        height: inherit;
        padding: 5px 8px;
        border-radius: 0;
        line-height: 12px;
         margin:1px 0 0 8px;
        border: none;
        background-color: var(--toolbar-btn-bg);
        border: 1px solid var(--toolbar-btn-color);
        cursor: pointer;
         @media only screen and (max-width: 1599px) {
            padding: 3px 8px;
          }
        &:hover,
        &:focus,
        &.active {
          background-color: var(--toolbar-btn-color);
          border: 1px solid var(--toolbar-btn-color);
          color: var(--toolbar-btn-text);
        }
        &:first-child {
          margin-left: 0;
        }
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
    > div {
      min-height: inherit !important;
    }
    .apexcharts-canvas {
      padding-top: 10px;
      margin-right: 0;
      width: 100% !important;
      .apexcharts-zoom-icon {
        display: none;
        &.apexcharts-selected {
          svg {
            fill: var(--apexchart-icon-color);
          }
        }
      }
    }
    .apexcharts-text {
      tspan {
        font-family: var(--font-family);
        color: var(--tspan-text) !important;
      }
    }
    .apexcharts-tooltip {
      background: var(--white-light);
      color: var(--primary);
      border-radius: 0;
      box-shadow: none;
      border-color: var(--grey-light);
      font-size: 12px;
      .apexcharts-tooltip-title {
        background: transparent;
        border: none;
        padding: 6px 6px 3px;
        margin-bottom: 0;
        font-size: 12px;
      }
      .apexcharts-tooltip-y-group {
        padding: 0;
      }
      .apexcharts-tooltip-series-group {
        padding: 3px 6px 6px;
      }
      .apexcharts-tooltip-marker {
        display: none;
      }
    }
    .apexcharts-menu {
      border: 1px solid var(--grey-light);
      border-radius: 0;
      padding: 0;
      right: 5px;
      background: var(--apexcharts-menu-bg);
      .apexcharts-menu-item {
        color: var(--primary);
        &:hover {
          background: var(--primary);
          color: var(--apexcharts-menu-hover-text);
        }
      }
    }
    .apexcharts-menu-icon,
    .apexcharts-zoomin-icon,
    .apexcharts-zoomout-icon,
    .apexcharts-reset-icon {
      color: var(--dark);
      margin: 0;
      svg {
        fill: var(--dark);
      }
      &:hover,
      &:focus {
        color: var(--primary);
        svg {
          fill: var(--primary);
        }
      }
    }
  }
}
</style>
