export default {
    getItem: state => (id) => {
        return state.items.find((item) => item.id === id)
    },
    getItems: state => {
        return state.items
    },
    getNextPage: state => {
        return state.next_page_index
    }
}