export default {
	setItems(state, data) {
		let items = new Array();
		for (var i in data) {
			let one = new Array();
			one.push(data[i].created * 1000);
			one.push(data[i].total_market.toFixed(2));
			items.push(one);
		}
		state.items = items
	},
	setTimeline(state, timeline) {
		state.timeline = timeline
	},
	setIsDailyHistory(state, is_daily_history){
		state.is_daily_history=is_daily_history
	}
}