<template>
  <section class="news-wrap" @click="searchFocus">
    <b-input-group class="search-bar">
      <autocomplete
        ref="autocomplete"
        input-class="search-box search-news"
        placeholder="Search by Keyword"
        :source="searchNewsEndpoint"
        results-property="data"
        :results-display="formattedNewsDisplay"
        :request-headers="authNewsHeaders"
        @selected="selectSearchedNews"
      >
      </autocomplete>
    </b-input-group>
    <ul
      class="news-list"
      id="news-list"
      :style="{ height: CompNewsHeight + 'px' }"
    >
      <li v-for="item in items" :key="item.id">
        <a :href="item.url" target="_blank">
          <h3>{{ item.related }}: {{ item.headline }}</h3>
          <span class="date">{{ item.date }}</span>
        </a>
      </li>
    </ul>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </section>
</template>

<script>
import themeconfig from '../themeconfig'
export default {
  name: "News",
  data() {
    return {
      query: "",
      loading: false,
    };
  },
  computed: {
    items() {
      return this.$store.getters["news/getItems"];
    },
    next_page_index() {
      return this.$store.getters["news/getNextPage"];
    },
    security_id: {
      get() {
        return this.$store.getters["chart/getSecurityId"];
      },
    },
    authNewsHeaders() {
      return {
        Authorization: "Bearer " + localStorage.getItem("user-token"),
      };
    },
  },
  watch: {
    async security_id() {
      await this.searchNews();
    },
  },
  props: ["CompisNewsOpen","CompNewsHeight"],
  methods: {
    async fetchNews() {
      this.loading = true;
      await this.$store.dispatch("news/fetchItems", {
        page: this.next_page_index,
        security_id: this.security_id,
        query: this.query,
      });
      this.loading = false;
    },
    async searchNews() {
      this.loading = true;
      await this.$store.dispatch("news/fetchItemsWithSearch", {
        page: 1,
        security_id: this.security_id,
        query: this.query,
      });
      this.awaitingNewsSearch = true;
      this.loading = false;
    },
    searchNewsEndpoint(input) {
      return themeconfig.get_vue_app_api_endpoint() + "auto-news?query=" + input;
    },
    async selectSearchedNews(option) {
      this.$refs.autocomplete.clear();
      let secData = option.selectedObject;
      window.open(secData.url, "_blank");
    },
    formattedNewsDisplay(result) {
      return result.related + ": " + result.headline;
    },
    searchFocus(e) {
      document
        .getElementsByClassName("search-bar")[2]
        .classList.remove("focus");
      if (e.target.matches(".search-news")) {
        document.getElementsByClassName("search-bar")[2].classList.add("focus");
      }
    },
  },
  async mounted() {
    if(this.CompisNewsOpen){
      //  console.log("call News component mounted order");
      await this.fetchNews();
      let currentDom = this;
      // Detect when scrolled to bottom.
      const listElm = document.querySelector("#news-list");
      listElm.addEventListener("scroll", function() {
        // let listElm_clientHeight = (listElm != null || typeof listElm != typeof undefined) ? listElm.clientHeight : 0;
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (currentDom.next_page_index != 0) currentDom.fetchNews();
        } else {
          this.loading = false;
        }
      });
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.news-wrap {
  padding: 0px;
  .news-list {
    list-style: none;
    margin: 0 0 0;
    padding: 0;
    display: flex;
    justify-items: flex-start;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    height: 325px;
    li {
      margin: 0;
      padding: 0;
      width: 100%;
      a {
        width: 100%;
        padding: 0.25rem 0.5rem;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        h3 {
          color: var(--dark);
          font-size: 13px;
          font-weight: 400;
          margin-bottom: 5px;
          text-align: left;
          @media only screen and (min-width: 1799px) {
            font-size: 14px !important;
          }
          @media only screen and (max-width: 1399px) {
            font-size: 12px !important;
          }
        }
        .date {
          color: var(--grey);
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0;
          @media only screen and (min-width: 1799px) {
            font-size: 13px !important;
          }
          @media only screen and (max-width: 1399px) {
            font-size: 11px !important;
          }
        }
        &:hover {
          color: var(--white);
          background: var(--list-hover-bg);
          h3 {
            color: var(--white);
          }
          .date {
            color: var(--list-date-hover-bg);
          }
        }
      }
      &:nth-of-type(even) {
        a {
          background: rgba(var(--even-hover-rgba));
          &:hover {
            color: var(--white);
            background: var(--list-hover-bg);
          }
        }
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
