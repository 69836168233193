import axios from "../../axios"

export default {
	fetchItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/get-securities', payload)
				.then(response => {
					if(response.data.data.is_filter=='1'){
						commit('setupdateItems', response.data.data.securities)
					} else {
						commit('setItems', response.data.data.securities)
					}
					commit('setNextPageIndex', response.data.data.next_page_index)
					commit('setStartRecord', response.data.data.startPerPage)
					commit('setTotalRecord', response.data.data.total_records)
					commit('setIsFundFilter', response.data.data.is_scanner)
					resolve(response)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	resetPageIndex({ commit }, payload){
		commit('resetPageIndex', payload)
	},
}