<template>
  <div class="watchlist-wrap">
    <v-data-table
      id="watch-scroll-table"
      :headers="watch_headers"
      :items="items"
      item-key="security_id"
      class="custome-table watchlist-table"
      hide-default-footer
      disable-pagination
      fixed-header
      :style="{ height: CompWatchlistHeight + 'px' }"
    >
      <template v-slot:body.prepend>
        <tr class="table-dropdown sticky-header">
          <td width="10%">
            <div class="form-group">
              <v-text-field
                class="form-control"
                type="text"
                hide-details="auto"
                v-model="search_table.security_name"
              ></v-text-field>
            </div>
          </td>
          <td width="10%">
            <div class="form-group">
              <v-text-field
                class="form-control"
                type="text"
                hide-details="auto"
                v-model="search_table.market_name"
              ></v-text-field>
            </div>
          </td>
          <td width="10%">
            <div class="form-group">
              <v-text-field
                class="form-control"
                type="text"
                hide-details="auto"
                v-model="search_table.bid_price"
              ></v-text-field>
            </div>
          </td>
          <td width="10%">
            <div class="form-group">
              <v-text-field
                class="form-control"
                type="text"
                hide-details="auto"
                v-model="search_table.spread"
              ></v-text-field>
            </div>
          </td>
          <td width="10%">
            <div class="form-group">
              <v-text-field
                class="form-control"
                type="text"
                hide-details="auto"
                v-model="search_table.ask_price"
              ></v-text-field>
            </div>
          </td>
          <td width="10%">
            <div class="form-group">
              <v-text-field
                class="form-control"
                type="text"
                hide-details="auto"
                v-model="search_table.change"
              ></v-text-field>
            </div>
          </td>
          <td width="10%">
            <div class="form-group">
              <v-text-field
                class="form-control"
                type="text"
                hide-details="auto"
                v-model="search_table.change_percent"
              ></v-text-field>
            </div>
          </td>
          <td width="10%">
            <div class="form-group">
              <v-text-field
                class="form-control"
                type="text"
                hide-details="auto"
                v-model="search_table.low_price"
              ></v-text-field>
            </div>
          </td>
          <td width="10%">
            <div class="form-group">
              <v-text-field
                class="form-control"
                type="text"
                hide-details="auto"
                v-model="search_table.high_price"
              ></v-text-field>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:[`item.security_name`]="{ item }">
        <div class="watch-div">
          <router-link class="hover-alert" to="" title="Remove">
            <font-awesome-icon
              @click="removeWatchAlert(item.id)"
              icon="trash-alt"
            />
          </router-link>
          <span v-if="item.market_id == 1 
            && item.iex_symbol !== null 
            && item.iex_symbol !== undefined 
            && item.iex_symbol !== ''" 
            class="hover-alert" title="Price Alert">
            <font-awesome-icon
              @click="priceAlert(item.id)"
              icon="bell"
            />
          </span>
          <span
            class="teble-ellipsis-wrap security_w"
            @click="selectItem(item, '0')"
          >
            <div
              class="cash-type"
              v-if="item.market_id == '6' || item.market_id == '2'"
              title="Currencies"
            ></div>
            <div
              class="equity-type"
              v-if="item.market_id == '1'"
              title="Equites"
            ></div>
            <div
              class="bond-type"
              v-if="item.market_id == '5'"
              title="Bonds"
            ></div>
            <div
              class="fund-type"
              v-if="item.market_id == '8'"
              title="Funds"
            ></div>

            {{ item.security_name }}
          </span>
        </div>
      </template>
      <template v-slot:[`item.market_name`]="{ item }">
        <span class="teble-ellipsis-wrap class-wrap" @click="selectItem(item, '0')">
          {{ item.market_name }}
        </span>
      </template>
      <template v-slot:[`item.bid_price`]="{ item }">
        <span class="td-values bid-price hover-td-values">
          <div class="teble-ellipsis-wrap e-w-70 value-none m-auto text-center">
            {{ formatPrice(item.bid_price, item.market_id) }}
          </div>
          <div class="hover-wrap">
            <template>
              <b-button
                v-if="is_maximize == 1"
                @click.stop="order_sidebar(item)"
                variant="danger"
                >Sell</b-button
              >
              <b-button
                v-if="is_maximize == 0"
                @click="selectItem(item, '1')"
                variant="danger"
                >Sell</b-button
              >
            </template>
          </div>
        </span>
      </template>
      <template v-slot:[`item.spread`]="{ item }">
        <span class="teble-ellipsis-wrap">
          {{ formatPrice(item.spread, item.market_id,2) }}
        </span>
      </template>
      <template v-slot:[`item.ask_price`]="{ item }">
        <span class="td-values ask-price hover-td-values">
          <div class="teble-ellipsis-wrap e-w-70 value-none m-auto text-center">
            {{ formatPrice(item.ask_price, item.market_id) }}
          </div>
          <div class="hover-wrap">
            <template>
              <b-button
                v-if="is_maximize == 1"
                @click.stop="order_sidebar(item)"
                variant="success"
                >Buy</b-button
              >
              <b-button
                v-if="is_maximize == 0"
                @click="selectItem(item, '1')"
                variant="success"
                >Buy</b-button
              >
            </template>
          </div>
        </span>
      </template>
      <template v-slot:[`item.change`]="{ item }">
        <span class="teble-ellipsis-wrap">
          {{ item.change }}
        </span>
      </template>
      <template v-slot:[`item.change_percent`]="{ item }">
        <span class="teble-ellipsis-wrap"> {{ item.change_percent }}% </span>
      </template>
      <template v-slot:[`item.low_price`]="{ item }">
        <span class="teble-ellipsis-wrap">
          {{ formatPrice(item.low_price, item.market_id) }}
        </span>
      </template>
      <template v-slot:[`item.high_price`]="{ item }">
        <span class="teble-ellipsis-wrap">
          {{ formatPrice(item.high_price, item.market_id) }}
        </span>
      </template>
    </v-data-table>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
    <template>
      <v-navigation-drawer
        class="order-form-sidebar"
        width="26%"
        v-model="drawer"
        absolute
        temporary
        right
      >
        <div class="height-50 bb-4 align-items-start">
          <TickerDetails formName="watchlist_order_form" />
        </div>
        <div class="height-50" v-if="isTradingChartOpen">
          <!-- <TradingChart ChartOrigin="Watchlist" /> -->
          <SideBarTradingChart ChartOrigin="Watchlist" CompisTradingChartOpen='isTradingChartOpen' :TraddingChartHeight="400" :TraddingChartWidth="300" v-if="isTradingChartOpen"/>
        </div>
      </v-navigation-drawer>
      <a class="filter-sticky-icon rightside" @click="opensidebarDrawer()">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a>
    </template>
    <template>
      <v-navigation-drawer
        class="filter-sidebar"
        width="26%"
        v-model="ScannerDrawer"
        absolute
        temporary
      >
        <h3>Scanner</h3>
        <form>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="text"
              hide-details="auto"
              v-model="search_table.reference"
              @change="fetchTransactions(1)"
              label="Reference"
            ></v-text-field>
          </div>
          <div class="form-group">
            <v-select
              class="form-control multi-select"
              chips
              multiple
              label="Reference"
            ></v-select>
          </div>
          <div class="form-group">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="search_table.trade_date"
                  v-bind="attrs"
                  v-on="on"
                  class="form-control"
                  @change="fetchTransactions(1)"
                  label="Trade date"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_table.trade_date"
                @change="fetchTransactions(1)"
                @input="menu2 = false"
                no-title
                scrollable
                actions
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="text"
              hide-details="auto"
              v-model="search_table.security_name"
              @change="fetchTransactions(1)"
              label="Security name"
            ></v-text-field>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="text"
              hide-details="auto"
              v-model="search_table.amount"
              label="Amount"
            ></v-text-field>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="text"
              hide-details="auto"
              v-model="search_table.price"
              label="Price"
            ></v-text-field>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.status"
              dense
              filled
              clearable
              class="form-control single-select"
              item-text="text"
              item-value="value"
              @change="fetchTransactions(1)"
              label="Status"
            ></v-autocomplete>
          </div>
          <div class="btn-group">
            <a class="cancle-btn">Clear</a>
            <a class="submit_portfolio">Search</a>
          </div>
        </form>
      </v-navigation-drawer>
      <a class="filter-sticky-icon d-none" @click="ScannerDrawer = true">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a>
    </template>
    <template>
      <PriceAlert v-model="paDialog" />
    </template>
  </div>
</template>
<script>
import TickerDetails from "@/components/TickerDetails.vue";
import SideBarTradingChart from "@/components/SideBarTradingChart.vue";
import PriceAlert from "@/components/PriceAlert.vue";
export default {
  name: "Watchlist",
  components: { TickerDetails: TickerDetails, SideBarTradingChart: SideBarTradingChart, PriceAlert },
  data() {
    return {
      traddingHeight:397,
      isTradingChartOpen:false,
      query: "",
      loading: false,
      ScannerDrawer: null,
      drawer: null,
      watch_headers: [
        {
          text: "Name",
          align: "left",
          width: "10%",
          value: "security_name",
          class: "sticky-header",
          filter: (value) => {
            if (!this.search_table.security_name) return true;
            if (value) {
              return (
                String(value.toLowerCase()).search(
                  this.search_table.security_name.toLowerCase()
                ) > -1
              );
            }
          },
        },
        {
          text: "Class",
          align: "start",
          width: "10%",
          class: "sticky-header",
          value: "market_name",
          filter: (value) => {
            if (!this.search_table.market_name) return true;
            if (value) {
              return (
                String(value.toLowerCase()).search(
                  this.search_table.market_name.toLowerCase()
                ) > -1
              );
            }
          },
        },
        {
          text: "Bid",
          align: "center",
          width: "10%",
          value: "bid_price",
          class: "sticky-header",
          filter: (value) => {
            if (!this.search_table.bid_price) return true;
            if (value) {
              return String(value).search(this.search_table.bid_price) > -1;
            }
          },
        },
        {
          text: "Spread",
          align: "center",
          width: "10%",
          value: "spread",
          class: "sticky-header",
          filter: (value) => {
            if (!this.search_table.spread) return true;
            if (value) {
              return String(value).search(this.search_table.spread) > -1;
            }
          },
        },
        {
          text: "Ask",
          align: "center",
          width: "10%",
          value: "ask_price",
          class: "sticky-header",
          filter: (value) => {
            if (!this.search_table.ask_price) return true;
            if (value) {
              return String(value).search(this.search_table.ask_price) > -1;
            }
          },
        },
        {
          text: "Change",
          align: "center",
          width: "10%",
          value: "change",
          class: "sticky-header",
          filter: (value) => {
            if (!this.search_table.change) return true;
            if (value) {
              return String(value).search(this.search_table.change) > -1;
            }
          },
        },
        {
          text: "Change %",
          align: "center",
          width: "10%",
          value: "change_percent",
          class: "sticky-header",
          filter: (value) => {
            if (!this.search_table.change_percent) return true;
            if (value) {
              return (
                String(value).search(this.search_table.change_percent) > -1
              );
            }
          },
        },
        {
          text: "Low",
          align: "center",
          width: "10%",
          value: "low_price",
          class: "sticky-header",
          filter: (value) => {
            if (!this.search_table.low_price) return true;
            if (value) {
              return String(value).search(this.search_table.low_price) > -1;
            }
          },
        },
        {
          text: "High",
          align: "center",
          width: "10%",
          value: "high_price",
          class: "sticky-header",
          filter: (value) => {
            if (!this.search_table.high_price) return true;
            if (value) {
              return String(value).search(this.search_table.high_price) > -1;
            }
          },
        },
      ],
      search: "",
      sortBy: "security_name",
      sortDesc: false,
      menu2: false,
      selectedItemId: 0,
      show: "",
      searchSecurityID: 0,
      search_table: {
        security_name: null,
      },
      paDialog: {
        show: false,
        securityId: null
      }
    };
  },
  props: ["CompisWatchlistOpen","CompWatchlistHeight"],
  methods: {
    opensidebarDrawer(){
      // console.log("call opensidebarDrawer :: component mounted");
      this.drawer = this.drawer ? false :true;
      // this.drawer = !this.drawer;
      this.isTradingChartOpen= true;
    },
    async successAlert(msg) {
      this.$swal({
        title: "",
        showCancelButton: false,
        text: msg,
      });
    },
    async erroeAlert(msg) {
      this.$swal({
        icon: "error",
        title: "Oops...",
        text: msg,
      });
    },
    async fetchItems() {
      this.loading = true;
      await this.$store.dispatch("watchList/fetchItems");
      this.loading = false;
    },
    async setDefaultChart(security_id) {
      this.loading = true;
      await this.$store.dispatch("chart/updateDefaultChart", {
        security_id: security_id,
      });
      this.loading = false;
    },
    async selectItem(security_items, is_order) {
      let securityId = security_items.id;
      let ib_conid = security_items.ib_conid;
      let iex_symbol = security_items.iex_symbol;
      let market_id = security_items.market_id;

      //let security = this.$store.getters["watchList/getItem"](securityId);
      if (securityId > 0) {
        if (ib_conid != undefined && ib_conid > 0) {
          await this.$store.dispatch("dashboard/updateDefaultSecuritySet", {
            secuirty_id: securityId,
            conid: ib_conid,
            iex_symbol: iex_symbol,
            market_id: market_id,
          });
        }

        if (is_order == "1") {
          await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 1);
        } else {
          await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 0);
        }
        this.selectedItemId = securityId;
        this.next_page_index = 1;
        await this.$store.dispatch("chart/updateDefaultChart", {
          security_id: securityId,
        });

        await this.$store.dispatch("chart/updateConId", {
          con_id: ib_conid,
        });
        //this.loading = true;
        await this.updateFormQuantity("0");
        await this.loadSecurity(securityId, []);
        //this.loading = false;
      }
    },
    async order_sidebar(row_item) {
      if (this.is_maximize == 1) {
        // console.log("this.isTradingChartOpen",this.isTradingChartOpen);
        // this.drawer = !this.drawer;
        this.opensidebarDrawer();
      }else{
        this.isTradingChartOpen = false;
      }
      await this.selectItem(row_item, "0");
    },
    async removeWatchList(security_id) {
      this.loading = true;
      await this.$store.dispatch("watchList/removeWatch", {
        security_id: security_id,
      });
      this.loading = false;
    },
    async removeWatchAlert(security_id) {
      this.$swal({
        title: "Remove",
        showCancelButton: true,
        text: "Are you sure ?",
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          await this.removeWatchList(security_id);
          if (this.remove_result.status == "1") {
            await this.fetchItems();
            await this.successAlert(this.remove_result.message);
          } else {
            await this.erroeAlert(this.remove_result.message);
          }
          this.loading = false;
        }
      });
    },
    priceAlert(id){
      this.paDialog = {
        show: true,
        securityId: id
      };
    }
  },
  computed: {
    bl_active_tab: {
      get() {
        return this.$store.getters["dashboard/getBLActiveTab"];
      },
    },
    items() {
      return this.$store.getters["watchList/getItems"];
    },
    isVisibleNews: {
      get() {
        return this.$store.getters["dashboard/getIsVisibleNews"];
      },
    },
    isVisibleOrderEntry: {
      get() {
        return this.$store.getters["dashboard/getIsVisibleOrderEntry"];
      },
    },
    authHeaders() {
      return {
        Authorization: "Bearer " + localStorage.getItem("user-token"),
      };
    },
    menus() {
      return this.$store.getters["watchList/getMenus"];
    },
    remove_result() {
      return this.$store.getters["watchList/getRemoveResult"];
    },
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
  },
  async mounted() {
    if(this.CompisWatchlistOpen){
      // console.log("call Watchlist component mounted order");
      await this.fetchItems();
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.watchlist-wrap {
  padding: 0;

  #watch-scroll-table {
    overflow: auto;
  }
  .custome-table {
    background: transparent !important;
    border-radius: 0;
    border: 0;
    table {
      > tbody {
        > tr {
          td {
            position: relative;
            .hover-alert {
              padding: 0 8px 0 5px;
              &:focus {
                outline: 0 !important;
                border: none;
                color: var(--dark) !important;
                svg {
                  outline: 0 !important;
                  border: none;
                }
              }
              svg {
                &:focus {
                  outline: 0 !important;
                  border: none;
                }
              }
              &:hover {
                animation: swing 0.6s infinite ease 0s;
                color: var(--dark) !important;
              }
            }
          }
          .hover-wrap {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            margin: 0 auto;
            left: 50%;
            right: inherit;
            display: none;
            justify-content: flex-end;
            align-items: center;
            width: 80%;
            padding-right: 0;
            @media only screen and (max-width: 1599px) {
              width: 100%;
            }
            .btn {
              font-size: 14px;
              font-weight: 400;
              height: inherit;
              padding: 1px 6px;
              min-width: inherit;
              width: 85px;
              border-radius: 0;
              line-height: 14px;
              height: 22px;
              margin: 0;
              border: none;
              @media only screen and (max-width: 1799px) {
                width: 75px;
                font-size: 12px;
                height: 20px;
              }
              @media only screen and (max-width: 1599px) {
                width: 65px;
              }
              &:first-child {
                margin-left: 0;
              }
              &:focus {
                box-shadow: none;
                outline: none;
              }
            }
            &:focus {
              box-shadow: none;
              outline: none;
            }
          }
          &:hover,
          &:focus {
            background-color: transparent !important;
            &:nth-of-type(even) {
              background-color: rgba(var(--even-hover-rgba)) !important;
            }
            td {
              color: var(--dark) !important;
              > span {
                > a {
                  color: var(--dark) !important;
                }
              }
              .v-icon {
                color: var(--dark) !important;
                &:before {
                  color: var(--dark) !important;
                }
              }
            }
          }
          &:hover {
            .hover-td-values {
              .value-none {
                visibility: hidden !important;
                opacity: 0;
              }
              .hover-wrap {
                display: block;
              }
            }
          }
        }
      }
    }
    .v-data-table__wrapper {
      background: transparent;
      border-radius: 0;
      border: 0;
      overflow: unset !important;
    }
  }
  .search-tabs {
    .nav-tabs {
      border-bottom: 1px solid var(--watchlist-inner-tab-bg);
      padding-left: 0 !important;
      .nav-item {
        &.show {
          .nav-link {
            background-color: var(--watchlist-inner-tab-bg);
            border: none;
            color: var(--watchlist-inner-tab-text);
            border-radius: 0;
            border-bottom: 1px solid var(--watchlist-inner-tab-bg);
          }
        }
      }
      .nav-link {
        padding: 0.25rem 0.5rem;
        font-size: 13px;
        font-weight: 400;
        color: var(--dark);
        border: none;
        border-radius: 0;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
        }
        @media only screen and (max-width: 1399px) {
          font-size: 12px !important;
        }
        &.active,
        &:hover {
          background-color: var(--watchlist-inner-tab-bg);
          border: none;
          color: var(--watchlist-inner-tab-text);
          border-radius: 0;
          border-bottom: 1px solid var(--watchlist-inner-tab-bg);
        }
        &:hover {
          background-color: var(--watchlist-inner-tab-bg);
          color: var(--watchlist-inner-tab-text);
        }
      }
    }
  }
  .ticker-list {
    list-style: none;
    margin: 0px 0 0;
    padding: 0;
    display: flex;
    justify-items: flex-start;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    li {
      margin: 0;
      padding: 0;
      width: 100%;
      background: rgba(var(--even-hover-rgba));
      &.list-header {
        background: var(--watchlist-inner-tab-bg);
        color: var(--watchlist-inner-tab-text);
        font-size: 13px;
        font-weight: 400;
        min-height: 27px;
        div {
          padding: 1.5px 8px !important;
          line-height: 1.4;
          @media only screen and (max-width: 1799px) {
            &.asset {
              width: 45% !important;
              min-width: 45% !important;
            }
            &.ask {
              width: 30% !important;
              min-width: 30% !important;
            }
          }
        }
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
        }
        @media only screen and (max-width: 1399px) {
          font-size: 12px !important;
        }
      }
      .security-wrap {
        text-align: left;

        > div {
          display: flex;
          justify-items: flex-start;
          align-content: center;
          position: relative;
          @media only screen and (max-width: 1799px) {
            &.asset-name {
              width: 45% !important;
              min-width: 45% !important;
            }
            &.ask-price {
              width: 30% !important;
              min-width: 30% !important;
            }
          }

          > a {
            padding: 0.25rem 0.5rem;
            color: var(--dark);
            font-size: 13px;
            font-weight: 400;
            line-height: 1.4;
            &.ellipsis-wrap {
              white-space: nowrap;
              width: 80%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            @media only screen and (min-width: 1799px) {
              font-size: 14px !important;
            }
            @media only screen and (max-width: 1399px) {
              font-size: 12px !important;
            }
          }
        }
        .w-25 {
          margin: 0 auto;
          display: block;
          text-align: center;
        }
        .bid-price,
        .ask-price {
          padding: 1px 6px;
          margin: 0;
          min-width: inherit;
          font-size: 13px;
          font-weight: 400;
          border-radius: 0;
          line-height: 13px;
          color: var(--dark);
          @media only screen and (min-width: 1799px) {
            font-size: 14px !important;
          }
          @media only screen and (max-width: 1399px) {
            font-size: 12px !important;
          }
        }
      }
      .hover-wrap {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        left: 50%;
        right: inherit;
        display: none;
        justify-content: flex-end;
        align-items: center;
        padding-right: 0;
        .btn {
          font-size: 14px;
          font-weight: 400;
          height: inherit;
          padding: 1px 6px;
          min-width: 85px;
          border-radius: 0;
          line-height: 14px;
          height: 23px;
          margin: 0 !important;
          border: none;
          @media only screen and (max-width: 1799px) {
            min-width: 75px;
            font-size: 12px;
            height: 20px;
          }
          @media only screen and (max-width: 1599px) {
            font-size: 12px;
            min-width: 65px;
            height: 20px;
          }
          &:first-child {
            margin-left: 0;
          }
          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
      }
      &:nth-of-type(even) {
        background: transparent;
      }
      &:hover {
        .security-wrap {
          .bid-price,
          .ask-price {
            display: block;
          }
        }
        .hover-wrap {
          display: flex;
          background: transparent;
        }
      }
    }
  }
  .hover-alert {
    padding: 0 5px;
    &:focus {
      outline: 0 !important;
      border: none;
      svg {
        outline: 0 !important;
        border: none;
      }
    }
    svg {
      &:focus {
        outline: 0 !important;
        border: none;
      }
    }
    &:hover {
      animation: swing 0.6s infinite ease 0s;
    }
  }
  .watch-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
@keyframes swing {
  0% {
    transform: rotateZ(0);
  }
  25% {
    transform: rotateZ(25deg);
  }
  50% {
    transform: rotateZ(-15deg);
  }
  75% {
    transform: rotateZ(10deg);
  }
  100% {
    transform: rotateZ(0);
  }
}
#app {
  .watchlist-wrap {
    .watchlist-table {
      &.custome-table {
        table {
          > thead,
          > tbody {
            > tr {
              th,
              td {
                &:nth-child(4),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9) {
                  display: none;
                }
                .teble-ellipsis-wrap {
                  width: 75px !important;
                  @media only screen and (max-width: 1699px) {
                    width: 70px !important;
                  }
                  @media only screen and (max-width: 1399px) {
                    width: 70px !important;
                  }
                  @media only screen and (max-width: 1299px) {
                    width: 65px !important;
                  }
                  &.class-wrap {
                    width: 65px !important;
                     @media only screen and (max-width: 1399px) {
                    width: 60px !important;
                  }
                  }
                  &.security_w {
                    width: 140px !important;
                    @media only screen and (max-width: 1699px) {
                      width: 80px !important;
                    }
                    @media only screen and (max-width: 1399px) {
                      width: 70px !important;
                    }
                    @media only screen and (max-width: 1299px) {
                      width: 70px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .lm_maximised {
    .watchlist-wrap {
      .watchlist-table {
        &.custome-table {
          table {
            > thead,
            > tbody {
              > tr {
                > th,
                td {
                  &:nth-child(4),
                  &:nth-child(6),
                  &:nth-child(7),
                  &:nth-child(8),
                  &:nth-child(9) {
                    display: table-cell;
                  }

                  &:first-child {
                    width: 25% !important;
                    min-width: 25% !important;
                  }
                  &:nth-child(2) {
                    width: 5% !important;
                    min-width: 5% !important;
                    @media only screen and (max-width: 1299px) {
                      width: 7% !important;
                      min-width: 7% !important;
                    }
                    .teble-ellipsis-wrap {
                      width: 75px !important;
                      @media only screen and (max-width: 1699px) {
                        width: 70px !important;
                      }
                    }
                  }
                  .teble-ellipsis-wrap {
                    width: 145px !important;
                    @media only screen and (max-width: 1699px) {
                      width: 125px !important;
                    }
                    @media only screen and (max-width: 1399px) {
                      width: 115px !important;
                    }
                    @media only screen and (max-width: 1299px) {
                      width: 95px !important;
                    }
                    &.security_w {
                      width: 400px !important;
                      @media only screen and (max-width: 1699px) {
                        width: 320px !important;
                      }
                      @media only screen and (max-width: 1399px) {
                        width: 290px !important;
                      }
                      @media only screen and (max-width: 1299px) {
                        width: 260px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
