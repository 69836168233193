<template>
  <div class="transactions-wrap">
    <v-card class="custome-card" id="transaction_list">
      <b-row class="p-0 m-0">
        <b-col class="p-0 m-0">
          <v-data-table
            id="virtual-scroll-table"
            :headers="headers"
            :items="items"
            :search="search"
            hide-default-footer
            class="custome-table"
            disable-pagination
            item-key="id"
            :item-class="trOrderStatus"
            fixed-header
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :style="{ height: CompTransactionsHeight + 'px' }"
            @click:row="showModal"
          >
            <template v-slot:body.prepend>
              <tr class="table-dropdown sticky-header">
                <td width="10%">
                  <div class="form-group">
                    <v-text-field
                      class="form-control"
                      type="text"
                      hide-details="auto"
                      v-model="search_table.reference"
                      @change="fetchTransactions(1)"
                    ></v-text-field>
                  </div>
                </td>
                <td width="8.28%">
                  <div class="form-group">
                    <v-menu
                      v-model="trade_date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="search_table.trade_date"
                          v-bind="attrs"
                          v-on="on"
                          class="form-control"
                          @change="fetchTransactions(1)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="search_table.trade_date"
                        @change="fetchTransactions(1)"
                        @input="trade_date_menu = false"
                        no-title
                        scrollable
                        actions
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </td>
                <td width="12%">
                  <div class="form-group">
                    <v-menu
                      v-model="settlement_date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="search_table.settlement_date"
                          v-bind="attrs"
                          v-on="on"
                          class="form-control"
                          @change="fetchTransactions(1)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="search_table.settlement_date"
                        @change="fetchTransactions(1)"
                        @input="settlement_date_menu = false"
                        no-title
                        scrollable
                        actions
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </td>
                <td width="20%">
                  <div class="form-group">
                    <v-text-field
                      class="form-control"
                      type="text"
                      hide-details="auto"
                      v-model="search_table.security_name"
                      @change="fetchTransactions(1)"
                    ></v-text-field>
                  </div>
                </td>
                <td width="8.28%">
                  <div class="form-group">
                    <v-text-field
                      class="form-control"
                      type="text"
                      hide-details="auto"
                      v-model="search_table.identifier_name"
                      @change="fetchTransactions(1)"
                    ></v-text-field>
                  </div>
                </td>
                <td width="8.28%">
                  <div class="form-group">
                    <v-text-field
                      class="form-control"
                      type="text"
                      hide-details="auto"
                      v-model="search_table.amount"
                    ></v-text-field>
                  </div>
                </td>
                <td width="8.28%">
                  <div class="form-group">
                    <v-text-field
                      class="form-control"
                      type="text"
                      hide-details="auto"
                      v-model="search_table.price"
                    ></v-text-field>
                  </div>
                </td>
                <td width="8.28%">
                  <div class="form-group">
                    <v-text-field
                      class="form-control"
                      type="text"
                      hide-details="auto"
                      v-model="search_table.portfolio"
                      @change="fetchTransactions(1)"
                    ></v-text-field>
                  </div>
                </td>
                <td width="8.28%">
                  <div class="form-group">
                    <v-text-field
                      class="form-control"
                      type="text"
                      hide-details="auto"
                      v-model="search_table.legal_name_the_entity"
                      @change="fetchTransactions(1)"
                    ></v-text-field>
                  </div>
                </td>
                <td width="8.28%">
                  <div class="form-group">
                    <v-autocomplete
                      v-model="search_table.status"
                      :items="status_list"
                      dense
                      filled
                      clearable
                      class="table-input"
                      item-text="text"
                      item-value="value"
                      @change="fetchTransactions(1)"
                    ></v-autocomplete>
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:[`item.code`]="{ item }">
              <span class="teble-ellipsis-wrap">
                <div
                  class="cash-type"
                  v-if="item.market_id == '6' || item.market_id == '2'"
                  title="Currencies"
                ></div>
                <div
                  class="equity-type"
                  v-if="item.market_id == '1'"
                  title="Equites"
                ></div>
                <div
                  class="bond-type"
                  v-if="item.market_id == '5'"
                  title="Bonds"
                ></div>
                <div
                  class="fund-type"
                  v-if="item.market_id == '8'"
                  title="Funds"
                ></div>
                {{ item.code }}
              </span>
            </template>
            <template v-slot:[`item.trade_create`]="{ item }">
              <span class="teble-ellipsis-wrap date_w">
                {{ item.date }}
              </span>
            </template>
            <template v-slot:[`item.settlement_date`]="{ item }">
              <span class="teble-ellipsis-wrap">
                {{ item.settlement_date }}
              </span>
            </template>
            <template v-slot:[`item.security_name`]="{ item }">
              <span class="teble-ellipsis-wrap security_w">
                {{ item.security_name }}
              </span>
            </template>
            <template v-slot:[`item.identifier_name`]="{ item }">
              <span class="teble-ellipsis-wrap identifier_w">
                {{ item.identifier_name }}
              </span>
            </template>

            <template v-slot:[`item.original_quantity`]="{ item }">
              <span class="teble-ellipsis-wrap">
                {{ item.quantity }}
              </span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span class="teble-ellipsis-wrap price-head">
                {{ item.price }}
              </span>
            </template>
            <template v-slot:[`item.portfolio`]="{ item }">
              <span class="teble-ellipsis-wrap portfolio_w">
                {{ item.portfolio }}
              </span>
            </template>
            <template v-slot:[`item.legal_name_the_entity`]="{ item }">
              <span class="teble-ellipsis-wrap counterparty_w">
                {{ item.legal_name_the_entity }}
              </span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-tooltip top transition="fade-transform">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    :color="getTransactionStatusColor(item.status_full)"
                  >
                    {{ item.status }}
                  </v-chip>
                  <b-button
                    v-bind="attrs"
                    v-on="on"
                    v-if="item.is_kill == 1"
                    class="margin-r-10 status-kill-button"
                    variant="danger"
                    @click.stop="killAlert(item)"
                  >
                    <font-awesome-icon icon="times" />
                    Kill
                  </b-button>
                </template>
                <span v-html="item.status_tooltips"></span>
              </v-tooltip>
            </template> </v-data-table
        ></b-col>
        <b-col class="p-0 m-0 d-none">
          <!-- <b-col class="p-0 m-0 d-none maximize-show"> -->
          <div id="chart" class="mx-auto"></div>
        </b-col>
      </b-row>
    </v-card>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
    <v-dialog v-model="dialog" max-width="500">
      <v-card class="custome-modal">
        <v-card-title class="headline">
          <div
            class="box-title d-flex justify-content-between align-items-center"
          >
            <h4>{{ transaction_details.code }}</h4>
            <div
              class="table-button-groups p-0 d-flex justify-content-end align-items-center"
            >
              <!-- <b-button variant="primary"
                ><font-awesome-icon icon="edit" /> Edit</b-button
                v-if="typeof item_details.is_execute_btn !== 'undefined' && item_details.is_execute_btn == 1"
              > -->
              <b-button
                variant="primary"
                v-if="typeof item_details.is_execute_btn !== 'undefined' && item_details.is_execute_btn == 1"
                @click="showBookModal(item_details)"
                >
                    <font-awesome-icon icon="book" /> Book
              </b-button>
              <b-button
                variant="primary"
                v-if="typeof item_details.is_execute_btn !== 'undefined' && item_details.is_execute_btn == 1"
                :disabled="btn_load_execute == 1"
                @click="executeTrade(item_details)"
                >
                <template v-if="btn_play_execute">
                  <font-awesome-icon icon="play" /> Execute
                </template>
                <template v-if="btn_load_execute">
                  <font-awesome-icon icon="spinner" spin /> Executing..
                </template>
              </b-button>
              <b-button
                class="margin-r-10"
                variant="danger"
                v-if="item_details.is_kill == 1"
                @click="killAlert(item_details)"
              >
                <font-awesome-icon icon="times" /> Kill
              </b-button>
              <a href="javascript:void(0)" @click="dialog = false">
                <font-awesome-icon class="close-icon" icon="times" />
              </a>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <ul v-if="record_type == 'trade'">
            <li>
              <span class="text-title">
                {{ transaction_details.labelAccountName }}
              </span>
              <span class="text-value">
                {{ transaction_details.labelValueAccountName }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Created By
              </span>
              <span class="text-value">
                {{ transaction_details.created_by }}
              </span>
            </li>
            <li>
              <span class="text-title">Created At</span>
              <span class="text-value">
                {{ transaction_details.created_at }}
              </span>
            </li>
            <li v-if="transaction_details.updated_by">
              <span class="text-title">Updated By</span>
              <span class="text-value">
                {{ transaction_details.updated_by }}
              </span>
            </li>
            <li>
              <span class="text-title">Updated At</span>
              <span class="text-value">
                {{ transaction_details.updated_at }}
              </span>
            </li>
            <li>
              <span class="text-title">Reference</span>
              <span class="text-value">
                {{ transaction_details.code }}
              </span>
            </li>
            <li>
              <span class="text-title">Timestamp</span>
              <span class="text-value">
                {{ transaction_details.created_at }}
              </span>
            </li>
            <li>
              <span class="text-title">Status</span>
              <span class="text-value">
                {{ transaction_details.status }}
              </span>
            </li>
            <li>
              <span class="text-title">Direction</span>
              <span class="text-value">
                {{ transaction_details.directionValue }}
              </span>
            </li>
            <li>
              <span class="text-title">
                {{ transaction_details.labelCounterpartyName }}
              </span>
              <span class="text-value">
                {{ transaction_details.labelValueCounterpartyName }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Trade Date
              </span>
              <span class="text-value">
                {{ transaction_details.trade_date }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Settlement Date
              </span>
              <span class="text-value">
                {{ transaction_details.settlement_date }}
              </span>
            </li>
            <li>
              <span class="text-title">Security Description</span>
              <span class="text-value">
                {{ transaction_details.security_name }}
              </span>
            </li>
            <li>
              <span class="text-title">ISIN</span>
              <span class="text-value">
                {{ transaction_details.isin_code }}
              </span>
            </li>
            <li>
              <span class="text-title">Quantity</span>
              <span class="text-value">
                {{ transaction_details.quantity }}
              </span>
            </li>
            <li>
              <span class="text-title">Factor</span>
              <span class="text-value">
                {{ transaction_details.factor }}
              </span>
            </li>
            <li @v-if="transaction_details.is_price == '1'">
              <span class="text-title">Price</span>
              <span class="text-value">
                {{ transaction_details.price }}
              </span>
            </li>
            <li>
              <span class="text-title">Price Type</span>
              <span class="text-value">
                {{ transaction_details.price_type }}
              </span>
            </li>
            <li>
              <span class="text-title">Principal</span>
              <span class="text-value">
                {{ transaction_details.principal }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Accrued ({{ transaction_details.accured_days }} Days)
              </span>
              <span class="text-value">
                {{ transaction_details.accrued }}
              </span>
            </li>
            <li>
              <span class="text-title">Market Value</span>
              <span class="text-value">
                {{ transaction_details.market_value }}
              </span>
            </li>
            <li>
              <span class="text-title">Net Consideration</span>
              <span class="text-value">
                {{ transaction_details.net_consideration }}
              </span>
            </li>
          </ul>
          <ul v-if="record_type == 'transfer'">
            <li>
              <span class="text-title">Timestamp</span>
              <span class="text-value">
                {{ transaction_details.created_date }}
              </span>
            </li>
            <li>
              <span class="text-title">Status</span>
              <span class="text-value">
                {{ transaction_details.status }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Created By
              </span>
              <span class="text-value">
                {{ transaction_details.created_by }}
              </span>
            </li>
            <li v-if="transaction_details.updated_by">
              <span class="text-title">Updated By</span>
              <span class="text-value">
                {{ transaction_details.updated_by }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Account
              </span>
              <span class="text-value">
                {{ transaction_details.portfolio }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Trade Date
              </span>
              <span class="text-value">
                {{ transaction_details.trade_date }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Settlement Date
              </span>
              <span class="text-value">
                {{ transaction_details.settlement_date }}
              </span>
            </li>
            <li>
              <span class="text-title">Security Description</span>
              <span class="text-value">
                {{ transaction_details.security_name }}
              </span>
            </li>
            <li>
              <span class="text-title">ISIN</span>
              <span class="text-value">
                {{ transaction_details.isin_code }}
              </span>
            </li>
            <li>
              <span class="text-title">Direction</span>
              <span class="text-value">
                {{ transaction_details.direction }}
              </span>
            </li>

            <li>
              <span class="text-title">Quantity</span>
              <span class="text-value">
                {{ transaction_details.quantity }}
              </span>
            </li>
            <li>
              <span class="text-title">Counterparty</span>
              <span class="text-value">
                {{ transaction_details.counterparty }}
              </span>
            </li>
            <li v-if="transaction_details.trade_comment">
              <span class="text-title">Comment</span>
              <span class="text-value">
                {{ transaction_details.trade_comment }}
              </span>
            </li>
            <li v-if="transaction_details.fees > '0'">
              <span class="text-title">Execution Fees</span>
              <span class="text-value">
                {{ transaction_details.fees }}
              </span>
            </li>
            <li v-if="transaction_details.set_fees > '0'">
              <span class="text-title">Settlement Fees</span>
              <span class="text-value">
                {{ transaction_details.set_fees }}
              </span>
            </li>
            <li v-if="transaction_details.amendment_fees > '0'">
              <span class="text-title">Amendment Fees</span>
              <span class="text-value">
                {{ transaction_details.amendment_fees }}
              </span>
            </li>
          </ul>
          <ul v-if="record_type == 'cash'">
            <li>
              <span class="text-title">Timestamp</span>
              <span class="text-value">
                {{ transaction_details.created_date }}
              </span>
            </li>
            <li>
              <span class="text-title">Status</span>
              <span class="text-value">
                {{ transaction_details.status }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Created By
              </span>
              <span class="text-value">
                {{ transaction_details.created_by }}
              </span>
            </li>
            <li v-if="transaction_details.updated_by">
              <span class="text-title">Updated By</span>
              <span class="text-value">
                {{ transaction_details.updated_by }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Account
              </span>
              <span class="text-value">
                {{ transaction_details.portfolio }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Request Date
              </span>
              <span class="text-value">
                {{ transaction_details.request_date }}
              </span>
            </li>
            <li>
              <span class="text-title">
                Settlement Date
              </span>
              <span class="text-value">
                {{ transaction_details.settlement_date }}
              </span>
            </li>
            <li>
              <span class="text-title">Transaction</span>
              <span class="text-value">
                {{ transaction_details.transaction_type }}
              </span>
            </li>
            <li v-if="transaction_details.security_id > 0">
              <span class="text-title">Security</span>
              <span class="text-value">
                {{ transaction_details.security_name }}
              </span>
            </li>

            <li v-if="transaction_details.security_id > 0">
              <span class="text-title">ISIN</span>
              <span class="text-value">
                {{ transaction_details.isin_code }}
              </span>
            </li>

            <li v-if="transaction_details.sec_quantity > 0">
              <span class="text-title">Quantity</span>
              <span class="text-value">
                {{ transaction_details.sec_quantity }}
              </span>
            </li>
            <div v-if="transaction_details.transaction_type != 'FX Trade'">
              <li v-if="transaction_details.currency">
                <span class="text-title">Currency</span>
                <span class="text-value">
                  {{ transaction_details.currency }}
                </span>
              </li>
              <li v-if="transaction_details.ratio > 0">
                <span class="text-title">Ratio</span>
                <span class="text-value">
                  {{ transaction_details.ratio }}
                </span>
              </li>
              <li v-if="transaction_details.tax_amount > 0">
                <span class="text-title">Tax Amount</span>
                <span class="text-value">
                  {{ transaction_details.tax_amount }}
                </span>
              </li>
            </div>

            <li v-if="transaction_details.amount > '0'">
              <span class="text-title">Amount</span>
              <span class="text-value">
                {{ transaction_details.amount }}
              </span>
            </li>
            <li>
              <span class="text-title">Counterparty</span>
              <span class="text-value">
                {{ transaction_details.relationship_title }}
              </span>
            </li>

            <li
              v-if="
                transaction_details.transaction_type == 'FX Trade' &&
                  transaction_details.sell_currency
              "
            >
              <span class="text-title">Sell Currency</span>
              <span class="text-value">
                {{ transaction_details.sell_currency }}
              </span>
            </li>
            <li
              v-if="
                transaction_details.transaction_type == 'FX Trade' &&
                  transaction_details.convert_amount > 0
              "
            >
              <span class="text-title">Sell Amount</span>
              <span class="text-value">
                {{ transaction_details.convert_amount }}
              </span>
            </li>
            <li
              v-if="
                transaction_details.transaction_type == 'FX Trade' &&
                  transaction_details.buy_currency
              "
            >
              <span class="text-title">Buy Currency</span>
              <span class="text-value">
                {{ transaction_details.buy_currency }}
              </span>
            </li>
            <li
              v-if="
                transaction_details.transaction_type == 'FX Trade' &&
                  transaction_details.new_currency_amount > 0
              "
            >
              <span class="text-title">Buy Amount</span>
              <span class="text-value">
                {{ transaction_details.new_currency_amount }}
              </span>
            </li>
            <li
              v-if="
                transaction_details.transaction_type == 'FX Trade' &&
                  transaction_details.price > 0
              "
            >
              <span class="text-title">Price</span>
              <span class="text-value">
                {{ transaction_details.price }}
              </span>
            </li>
            <li v-if="transaction_details.fees > 0">
              <span class="text-title">Execution Fees</span>
              <span class="text-value">
                {{ transaction_details.fees }}
              </span>
            </li>
            <li v-if="transaction_details.set_fees > 0">
              <span class="text-title">Settlement Fees</span>
              <span class="text-value">
                {{ transaction_details.set_fees }}
              </span>
            </li>
          </ul>
        </v-card-text>
        <transition name="fade">
          <div class="loading full-page" v-show="transactionsDetailsLoading">
            <div><span></span><span></span><span></span><span></span></div>
          </div>
        </transition>
      </v-card>
    </v-dialog>
    <v-dialog v-model="bookingDialog" max-width="500">
      <v-card class="custome-modal">
        <v-card-title class="headline">
          <div
            class="box-title d-flex justify-content-between align-items-center"
          >
            <h4>{{ transaction_details.code }}</h4>
            <div
              class="table-button-groups p-0 d-flex justify-content-end align-items-center"
            >
              
              <a href="javascript:void(0)" @click="bookingDialog = false">
                <font-awesome-icon class="close-icon" icon="times" />
              </a>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
         <div class="booked-form-wrap">
            <b-form
              class="d-flex justify-content-lg-between align-content-center flex-wrap booked-form"
            >
              <b-form-group id="input-group-3">
                <v-text-field
                  id="input-3"
                  v-model="book_price"
                  class="form-control"
                  type="text"
                  label="Execution Price"
                  hide-details="auto"
                  required
                ></v-text-field>
              </b-form-group>
             
            </b-form>          
            <b-form
              class="d-flex justify-content-lg-between align-content-center flex-wrap booked-form"
            >
              <b-form-group id="input-group-3">
                <v-text-field
                  id="input-3"
                  v-model="book_execution_fees"
                  class="form-control"
                  type="text"
                  label="Execution Fees"
                  hide-details="auto"
                  required
                ></v-text-field>
              </b-form-group>
            </b-form>        
            <b-form
              class="d-flex justify-content-lg-between align-content-center flex-wrap booked-form"
            >
              <b-form-group id="input-group-3">
                <v-text-field
                  id="input-3"
                  v-model="book_commission"
                  class="form-control"
                  type="text"
                  label="Commission"
                  hide-details="auto"
                  required
                ></v-text-field>
              </b-form-group>
             
            </b-form>          
            <b-form
              class="d-flex justify-content-lg-between align-content-center flex-wrap booked-form"
            >
              <b-form-group id="input-group-3">
                <v-text-field
                  id="input-3"
                  v-model="book_ib_execution_fees"
                  class="form-control"
                  type="text"
                  label="EMFI Execution Fees"
                  hide-details="auto"
                  required
                ></v-text-field>
              </b-form-group>
            </b-form>          
          <div
                class="table-button-groups p-0 d-flex justify-content-end align-items-center"
              >
              <b-button
                  class="margin-r-0 margin-b-0 confirm-btn"
                  variant="primary"
                  :disabled="btn_load_book"
                  @click.prevent="submitBooking"
                >
                  <template v-if="btn_play_book">
                    Book
                  </template>
                  <template v-if="btn_load_book">
                    Booking..
                  </template>                                  
              </b-button>
          </div>
          </div>
        </v-card-text>
        <transition name="fade">
          <div class="loading full-page" v-show="transactionsDetailsLoading">
            <div><span></span><span></span><span></span><span></span></div>
          </div>
        </transition>
      </v-card>
    </v-dialog>
    <template>
      <v-navigation-drawer
        class="filter-sidebar"
        width="26%"
        v-model="drawer"
        absolute
        temporary
      >
        <div class="sidebar-header">
          <h3>Scanner ({{ total_record }})</h3>
          <a class="cancle-btn" @click="clearScanner()">Clear All</a>
        </div>
        <form>
          <!-- <div class="form-group">
            <v-select
              class="form-control multi-select"
              v-model="multiValue"
              :items="multiItems"
              chips
              multiple
              label="Reference"
            ></v-select>
          </div> -->
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.trade_date_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="search_table.trade_date_val"
                  v-bind="attrs"
                  v-on="on"
                  class="form-control"
                  label="Trade date"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_table.trade_date_val"
                @input="menu2 = false"
                no-title
                scrollable
                actions
              ></v-date-picker>
            </v-menu>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.settlement_date_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-menu
              v-model="settlement_date_picker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="search_table.settlement_date_val"
                  v-bind="attrs"
                  v-on="on"
                  class="form-control"
                  label="Settlement Date"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search_table.settlement_date_val"
                @input="settlement_date_picker = false"
                no-title
                scrollable
                actions
              ></v-date-picker>
            </v-menu>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.security_name_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="text"
              hide-details="auto"
              v-model="search_table.security_name_val"
              label="Security name"
            ></v-text-field>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.identifier_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="text"
              hide-details="auto"
              v-model="search_table.identifier_val"
              label="Identifier"
            ></v-text-field>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.amount_oprator"
              :items="number_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="number"
              hide-details="auto"
              v-model="search_table.amount_val"
              label="Amount"
            ></v-text-field>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.price_oprator"
              :items="number_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="number"
              hide-details="auto"
              v-model="search_table.price_val"
              label="Price"
            ></v-text-field>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.portfolio_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="text"
              hide-details="auto"
              v-model="search_table.portfolio_val"
              label="Account"
            ></v-text-field>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.counterparty_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-text-field
              class="form-control"
              type="text"
              hide-details="auto"
              v-model="search_table.counterparty_val"
              label="Counterparty"
            ></v-text-field>
          </div>

          <div class="form-group">
            <v-autocomplete
              v-model="search_table.status_oprator"
              :items="text_oprator_list"
              dense
              filled
              class="form-control single-select"
              item-text="text"
              item-value="value"
              label="Operator"
            ></v-autocomplete>
          </div>
          <div class="form-group">
            <v-autocomplete
              v-model="search_table.status_val"
              :items="status_list"
              dense
              chips
              deletable-chips
              small-chips
              label="Status"
              multiple
              item-text="text"
              item-value="value"
              class="form-control multi-select"
            ></v-autocomplete>
          </div>
          <div class="btn-group justify-content-end">
            <a class="submit_portfolio" @click="fetchTransactions(1)">Scan</a>
          </div>
        </form>
      </v-navigation-drawer>
      <a class="filter-sticky-icon" @click="drawer = true">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a>
    </template>
    <template>
      <v-navigation-drawer
        class="filter-sidebar"
        width="26%"
        v-model="ViewDetailsDrawer"
        absolute
        temporary
        right
      >
        <div v-if="record_type == 'trade'">
          <h3>{{ transaction_details.code }}</h3>
          <v-simple-table
            class="custome-table child-table view-details-table"
            fixed-header
            :height="transaction_details.length > 25 ? '70vh' : ''"
          >
            <tbody>
              <tr>
                <td class="text-start">
                  {{ transaction_details.labelAccountName }}
                </td>
                <td class="text-start">
                  {{ transaction_details.labelValueAccountName }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Created By
                </td>
                <td class="text-start">
                  {{ transaction_details.created_by }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Created At</td>
                <td class="text-start">
                  {{ transaction_details.created_at }}
                </td>
              </tr>
              <tr v-if="transaction_details.updated_by">
                <td class="text-start">Updated By</td>
                <td class="text-start">
                  {{ transaction_details.updated_by }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Updated At</td>
                <td class="text-start">
                  {{ transaction_details.updated_at }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Trade Reference</td>
                <td class="text-start">
                  {{ transaction_details.code }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Timestamp</td>
                <td class="text-start">
                  {{ transaction_details.created_at }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Status</td>
                <td class="text-start">
                  {{ transaction_details.status }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Direction</td>
                <td class="text-start">
                  {{ transaction_details.directionValue }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  {{ transaction_details.labelCounterpartyName }}
                </td>
                <td class="text-start">
                  {{ transaction_details.labelValueCounterpartyName }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Trade Date
                </td>
                <td class="text-start">
                  {{ transaction_details.trade_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Settlement Date
                </td>
                <td class="text-start">
                  {{ transaction_details.settlement_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Security Description</td>
                <td class="text-start">
                  {{ transaction_details.security_name }}
                </td>
              </tr>
              <tr>
                <td class="text-start">ISIN</td>
                <td class="text-start">
                  {{ transaction_details.isin_code }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Quantity</td>
                <td class="text-start">
                  {{ transaction_details.quantity }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Factor</td>
                <td class="text-start">
                  {{ transaction_details.factor }}
                </td>
              </tr>
              <tr @v-if="transaction_details.is_price == '1'">
                <td class="text-start">Price</td>
                <td class="text-start">
                  {{ transaction_details.price }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Price Type</td>
                <td class="text-start">
                  {{ transaction_details.price_type }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Principal</td>
                <td class="text-start">
                  {{ transaction_details.principal }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Accrued ({{ transaction_details.accured_days }} Days)
                </td>
                <td class="text-start">
                  {{ transaction_details.accrued }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Market Value</td>
                <td class="text-start">
                  {{ transaction_details.market_value }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Net Consideration</td>
                <td class="text-start">
                  {{ transaction_details.net_consideration }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-if="record_type == 'transfer'">
          <h3>{{ transaction_details.code }}</h3>
          <v-simple-table
            class="custome-table child-table"
            fixed-header
            :height="transaction_details.length > 25 ? '70vh' : ''"
          >
            <tbody>
              <tr>
                <td class="text-start">Timestamp</td>
                <td class="text-start">
                  {{ transaction_details.created_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Status</td>
                <td class="text-start">
                  {{ transaction_details.status }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Created By
                </td>
                <td class="text-start">
                  {{ transaction_details.created_by }}
                </td>
              </tr>
              <tr v-if="transaction_details.updated_by">
                <td class="text-start">Updated By</td>
                <td class="text-start">
                  {{ transaction_details.updated_by }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Account
                </td>
                <td class="text-start">
                  {{ transaction_details.portfolio }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Trade Date
                </td>
                <td class="text-start">
                  {{ transaction_details.trade_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Settlement Date
                </td>
                <td class="text-start">
                  {{ transaction_details.settlement_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Security Description</td>
                <td class="text-start">
                  {{ transaction_details.security_name }}
                </td>
              </tr>
              <tr>
                <td class="text-start">ISIN</td>
                <td class="text-start">
                  {{ transaction_details.isin_code }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Direction</td>
                <td class="text-start">
                  {{ transaction_details.direction }}
                </td>
              </tr>

              <tr>
                <td class="text-start">Quantity</td>
                <td class="text-start">
                  {{ transaction_details.quantity }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Counterparty</td>
                <td class="text-start">
                  {{ transaction_details.counterparty }}
                </td>
              </tr>
              <tr v-if="transaction_details.trade_comment">
                <td class="text-start">Comment</td>
                <td class="text-start">
                  {{ transaction_details.trade_comment }}
                </td>
              </tr>
              <tr v-if="transaction_details.fees > '0'">
                <td class="text-start">Execution Fees</td>
                <td class="text-start">
                  {{ transaction_details.fees }}
                </td>
              </tr>
              <tr v-if="transaction_details.set_fees > '0'">
                <td class="text-start">Settlement Fees</td>
                <td class="text-start">
                  {{ transaction_details.set_fees }}
                </td>
              </tr>
              <tr v-if="transaction_details.amendment_fees > '0'">
                <td class="text-start">Amendment Fees</td>
                <td class="text-start">
                  {{ transaction_details.amendment_fees }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-if="record_type == 'cash'">
          <h3>{{ transaction_details.code }}</h3>
          <v-simple-table
            class="custome-table child-table view-details-table"
            fixed-header
            :height="transaction_details.length > 25 ? '70vh' : ''"
          >
            <tbody>
              <tr>
                <td class="text-start">Timestamp</td>
                <td class="text-start">
                  {{ transaction_details.created_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Status</td>
                <td class="text-start">
                  {{ transaction_details.status }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Created By
                </td>
                <td class="text-start">
                  {{ transaction_details.created_by }}
                </td>
              </tr>
              <tr v-if="transaction_details.updated_by">
                <td class="text-start">Updated By</td>
                <td class="text-start">
                  {{ transaction_details.updated_by }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Account
                </td>
                <td class="text-start">
                  {{ transaction_details.portfolio }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Request Date
                </td>
                <td class="text-start">
                  {{ transaction_details.request_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Settlement Date
                </td>
                <td class="text-start">
                  {{ transaction_details.settlement_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Transaction</td>
                <td class="text-start">
                  {{ transaction_details.transaction_type }}
                </td>
              </tr>
              <tr v-if="transaction_details.security_id > 0">
                <td class="text-start">Security</td>
                <td class="text-start">
                  {{ transaction_details.security_name }}
                </td>
              </tr>

              <tr v-if="transaction_details.security_id > 0">
                <td class="text-start">ISIN</td>
                <td class="text-start">
                  {{ transaction_details.isin_code }}
                </td>
              </tr>

              <tr v-if="transaction_details.sec_quantity > 0">
                <td class="text-start">Quantity</td>
                <td class="text-start">
                  {{ transaction_details.sec_quantity }}
                </td>
              </tr>
              <div v-if="transaction_details.transaction_type != 'FX Trade'">
                <tr v-if="transaction_details.currency">
                  <td class="text-start">Currency</td>
                  <td class="text-start">
                    {{ transaction_details.currency }}
                  </td>
                </tr>
                <tr v-if="transaction_details.ratio > 0">
                  <td class="text-start">Ratio</td>
                  <td class="text-start">
                    {{ transaction_details.ratio }}
                  </td>
                </tr>
                <tr v-if="transaction_details.tax_amount > 0">
                  <td class="text-start">Tax Amount</td>
                  <td class="text-start">
                    {{ transaction_details.tax_amount }}
                  </td>
                </tr>
              </div>

              <tr v-if="transaction_details.amount > '0'">
                <td class="text-start">Amount</td>
                <td class="text-start">
                  {{ transaction_details.amount }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Counterparty</td>
                <td class="text-start">
                  {{ transaction_details.relationship_title }}
                </td>
              </tr>

              <tr
                v-if="
                  transaction_details.transaction_type == 'FX Trade' &&
                    transaction_details.sell_currency
                "
              >
                <td class="text-start">Sell Currency</td>
                <td class="text-start">
                  {{ transaction_details.sell_currency }}
                </td>
              </tr>
              <tr
                v-if="
                  transaction_details.transaction_type == 'FX Trade' &&
                    transaction_details.convert_amount > 0
                "
              >
                <td class="text-start">Sell Amount</td>
                <td class="text-start">
                  {{ transaction_details.convert_amount }}
                </td>
              </tr>
              <tr
                v-if="
                  transaction_details.transaction_type == 'FX Trade' &&
                    transaction_details.buy_currency
                "
              >
                <td class="text-start">Buy Currency</td>
                <td class="text-start">
                  {{ transaction_details.buy_currency }}
                </td>
              </tr>
              <tr
                v-if="
                  transaction_details.transaction_type == 'FX Trade' &&
                    transaction_details.new_currency_amount > 0
                "
              >
                <td class="text-start">Buy Amount</td>
                <td class="text-start">
                  {{ transaction_details.new_currency_amount }}
                </td>
              </tr>
              <tr
                v-if="
                  transaction_details.transaction_type == 'FX Trade' &&
                    transaction_details.price > 0
                "
              >
                <td class="text-start">Price</td>
                <td class="text-start">
                  {{ transaction_details.price }}
                </td>
              </tr>
              <tr v-if="transaction_details.fees > 0">
                <td class="text-start">Execution Fees</td>
                <td class="text-start">
                  {{ transaction_details.fees }}
                </td>
              </tr>
              <tr v-if="transaction_details.set_fees > 0">
                <td class="text-start">Settlement Fees</td>
                <td class="text-start">
                  {{ transaction_details.set_fees }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-if="trade_comments.length > 0">
          <h3 class="paddingtop-10">Comments</h3>
          <div class="basic-details">
            <ul
              class="basic-details-ul"
              v-for="trade_comment in trade_comments"
              :key="trade_comment.id"
            >
              <li>
                <p class="title">Date</p>
                <p class="value">{{ trade_comment.created_at }}</p>
              </li>
              <li>
                <p class="title">User</p>
                <p class="value">
                  {{ trade_comment.common_user.first_name }}
                  {{ trade_comment.common_user.last_name }}
                </p>
              </li>
              <li>
                <p class="title">Reference</p>
                <p class="value">{{ trade_comment.trade_code }}</p>
              </li>
              <li>
                <p class="title">Comment</p>
                <p class="value">{{ trade_comment.comment }}</p>
              </li>
            </ul>
          </div>
        </div>
        <transition name="fade">
          <div class="loading full-page" v-show="transactionsDetailsLoading">
            <div><span></span><span></span><span></span><span></span></div>
          </div>
        </transition>
      </v-navigation-drawer>
      <!-- <a class="filter-sticky-icon rightside" @click="DetailsDrawer()">
        <img alt="filter icon " src="@/assets/images/right-arrow.png" />
      </a> -->
    </template>
  </div>
</template>

<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import axios from "./../axios";
export default {
  name: "Transactions",
  components: {},
  data() {
    return {
      book_commission: 0,
      book_ib_execution_fees: 0,
      book_execution_fees: 0,
      btn_play_execute: true,
      btn_load_execute: false,
      btn_load_book: false,
      btn_play_book: true,
      drawer: false,
      ViewDetailsDrawer: false,
      dialog: false,
      bookingDialog:false,
      dateRangeDialog: false,
      showDropdowns: false,
      autoApply: true,
      alwaysShowCalendars: false,
      headers: [
        {
          text: "Reference",
          align: "left",
          value: "code",
          width: "10%",
        },
        {
          text: "Trade Date",
          align: "start",
          width: "12%",
          value: "trade_create",
          class: "date_w",
        },
        {
          text: "Settlement Date",
          align: "start",
          width: "8.28%",
          value: "settlement_date",
        },
        {
          text: "Name",
          align: "left",
          width: "20%",
          value: "security_name",
        },
        {
          text: "Identifier",
          align: "left",
          width: "8.28%",
          value: "identifier_name",
        },
        {
          text: "Amount",
          align: "center",
          width: "8.28%",
          value: "original_quantity",
          filter: (value) => {
            if (!this.search_table.amount) return true;
            return String(value).search(this.search_table.amount) > -1;
          },
        },
        {
          text: "Price",
          class: "price-head",
          align: "center",
          width: "8.28%",
          value: "price",
          filter: (value) => {
            if (!this.search_table.price) return true;
            return String(value).search(this.search_table.price) > -1;
          },
        },
        {
          text: "Account",
          align: "left",
          width: "8.28%",
          value: "portfolio",
        },
        {
          text: "Counterparty",
          align: "left",
          width: "8.28%",
          value: "legal_name_the_entity",
        },
        {
          text: "Status",
          class: "status-head",
          align: "center",
          width: "8.28%",
          value: "status",
        },
      ],
      status_list: [
        { text: "Placed", value: "order_placing" },
        { text: "Working", value: "order_working" },
        { text: "Filled", value: "order_filled" },
        { text: "Killed", value: "order_killed" },
        { text: "Rejected", value: "order_rejected" },
        { text: "Pending", value: "pending_matching_trade" },
        { text: "Submitted", value: "pre-matched" },
        { text: "Matched", value: "matched" },
        { text: "Settled", value: "settled" },
        { text: "Cancelled", value: "cancelled" },
        { text: "Locked", value: "locked" },
      ],
      search: "",
      loading: false,
      transactionsDetailsLoading: false,
      opens: "inline",
      sortBy: "trade_create",
      sortDesc: true,
      book_price:0.00,
      item_details: [],
      booking_details: [],
      locale: { firstDay: 0, format: "YYYY-MM-DD" },
      linkedCalendars: false,
      dateRange: "",
      ranges: this.getFilterDateRange(),
      search_table: {
        security_name: null,
        reference: null,
        trade_date: null,
        status: null,
        settlement_date_oprator: "equal",
        settlement_date_val: null,
        trade_date_oprator: "equal",
        trade_date_val: null,
        security_name_oprator: "equal",
        security_name_val: null,
        identifier_oprator: "equal",
        identifier_val: null,
        amount_oprator: "equal",
        amount_val: null,
        price_oprator: "equal",
        price_val: null,
        portfolio_oprator: "equal",
        portfolio_val: null,
        counterparty_oprator: "equal",
        counterparty_val: null,
        status_oprator: "equal",
        status_val: null,
      },
      menu2: false,
      settlement_date_picker: false,
      settlement_date_menu: false,
      trade_date_menu: false,
      text_oprator_list: [
        { text: "Equal", value: "equal" },
        { text: "Not Equal", value: "not_equal" },
      ],
      number_oprator_list: [
        { text: "Equal", value: "equal" },
        { text: "Not Equal", value: "not_equal" },
        { text: "Greater Than", value: "greater_than" },
        { text: "Less Than", value: "less_than" },
      ],
    };
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
  computed: {
    items() {
      let items = this.$store.getters["transaction/getItems"];
      return items;
    },
    kill_result() {
      return this.$store.getters["transaction/getKillResult"];
    },
    transactionsChartWidth: {
      get() {
        return parseInt(this.CompTransactionsWidth) / 2.9;
      },
    },
    transactionsChartHeight: {
      get() {
        return parseInt(this.CompTransactionsHeight) / 2;
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
    next_page_index() {
      return this.$store.getters["transaction/getNextPage"];
    },
    record_length() {
      return this.$store.getters["transaction/getLengthRecord"];
    },
    start_record() {
      return this.$store.getters["transaction/getStartRecord"];
    },
    kill_record() {
      return this.$store.getters["transaction/getKillRecord"];
    },
    start_filter_date: {
      get() {
        return this.$store.getters["dashboard/getStartFilterDate"];
      },
    },
    end_filter_date: {
      get() {
        return this.$store.getters["dashboard/getEndFilterDate"];
      },
    },
    is_place_order() {
      return this.$store.getters["transaction/getIsPlaceOrder"];
    },
    is_transaction_update() {
      return this.$store.getters["dashboard/getIsTransactionUpdate"];
    },
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
    transaction_details() {
      let transaction_detail = this.$store.getters[
        "transaction/getTransactionDetails"
      ];
      return transaction_detail;
    },
    trade_comments() {
      let trade_comment = this.$store.getters["transaction/getTradeComments"];
      return trade_comment;
    },
    record_type: {
      get() {
        return this.$store.getters["transaction/getRecordType"];
      },
    },
    total_record() {
      let total_record = new Intl.NumberFormat("en-US").format(
        this.$store.getters["transaction/getTotalRecord"]
      );
      return total_record;
    },
    is_transaction_scanner() {
      return this.$store.getters["transaction/getIsTransactionScanner"];
    },
  },
  watch: {
    async filter_portfolio() {
      // await this.updateItems();
      await this.fetchTransactions(1);
      await this.fetchKillCounts();
    },
    // async start_filter_date(new_date, old_date) {
    //   if (new_date != old_date) {
    //     // await this.updateItems();
    //     await this.fetchTransactions(1);
    //   }
    // },
    // async end_filter_date(new_date, old_date) {
    //   if (new_date != old_date) {
    //     // await this.updateItems();
    //     await this.fetchTransactions(1);
    //   }
    // },
    async kill_record(new_count, old_count) {
      if (new_count > 0) {
        const btn = document.getElementById("btnKillAll");
        if (old_count == 0) {
          if (btn) {
            btn.classList.remove("disabled");
            btn.removeAttribute("disabled");
          }
        }
      } else if (new_count == 0 && old_count > 0) {
        const btn = document.getElementById("btnKillAll");
        if (btn) {
          btn.classList.add("disabled");
          btn.setAttribute("disabled", true);
        }
      }
    },
    async is_transaction_update(new_val, old_val) {
      if (new_val == "1" && old_val != "1") {
        await this.fetchTransactions(1);
        await this.$store.dispatch("dashboard/updateTransactionList", "0");
      }
    },
    async is_transaction_scanner(is_transaction_scanner) {
      var transactionClear = document.getElementById("transaction-clear-btn");
      if (is_transaction_scanner == 1) {
        transactionClear.classList.remove("d-none");
      } else {
        transactionClear.classList.add("d-none");
      }
    },
  },
  methods: {
    checkOpen(open) {
      console.log("event: open", open);
    },
    trOrderStatus(item) {
      return item.is_kill == "1" ? "tr-order-status" : "";
    },
    async dateRangeBtn() {
      this.dateRangeDialog = true;
    },
    async filterClear() {},
    async killAll() {
      this.$swal({
        title: "Kill All",
        showCancelButton: true,
        text: "Are you sure ?",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          await this.killAllTransactions();
          this.loading = true;
          if (this.kill_result.status == "1") {
            // await this.updateItems();
            await this.fetchTransactions(1);
            await this.fetchKillCounts();
            await this.successAlert(this.kill_result.message);
          } else {
            await this.erroeAlert(this.kill_result.message);
          }
          this.loading = false;
        }
      });
    },
    async showModal(row_item) {
      this.transactionsDetailsLoading = true;
      if (row_item.id > 0) {
        //await this.transactionsDetails(row_item);
        this.item_details = row_item;
        // if (this.is_maximize == 1) {
        //   this.ViewDetailsDrawer = true;
        // } else {
        //   this.dialog = true;
        // }
        const returnData = await this.$store.dispatch("transaction/details", {
          record_type: row_item.record_type,
          id: row_item.id,
        });
        this.$emit("transactionsclickView", returnData);
      } else {
        await this.successAlert("Order is in progress");
      }
      this.transactionsDetailsLoading = false;
    },
    async transactionsDetails(row_item) {
      await this.$store.dispatch("transaction/details", {
        record_type: row_item.record_type,
        id: row_item.id,
      });
    },
    async successAlert(msg) {
      this.$swal({
        title: "",
        showCancelButton: false,
        text: msg,
      });
    },
    async erroeAlert(msg) {
      this.$swal({
        title: "Oops...",
        text: msg,
      });
    },

    async killTransactions(item) {
      this.loading = true;
      await this.$store.dispatch("transaction/tradeKill", {
        record_type: item.record_type,
        id: item.id,
      });
      this.loading = false;
    },
    async killAllTransactions() {
      this.loading = true;
      await this.$store.dispatch("transaction/tradeKill", {
        record_type: "All",
        id: 0,
      });
      this.loading = false;
    },
    async killAlert(item) {
      this.$swal({
        title: "Kill",
        showCancelButton: true,
        text: "Are you sure ?",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          this.dialog = false;
          await this.killTransactions(item);
          // await this.updateItems();
          await this.fetchTransactions(1);
          if (this.kill_result.status == "1") {
            await this.successAlert(this.kill_result.message);
          } else {
            await this.erroeAlert(this.kill_result.message);
          }
          this.loading = false;
        }
      });
    },
    async executeTrade(item){
      this.$swal({
        title: "Execute",
        showCancelButton: true,
        text: "Are you sure ?",
        reverseButtons: true,
      }).then(async (result) => {
      
      this.btn_load_execute = true;
      this.btn_play_execute = false;

      if (result.value) {
          let payload = {id: item.id, type: item.record_type};
          await axios
          .post("transaction-execute", payload)
          .then(async (response) => {
            this.loading = false;
            this.btn_load_execute = false;
            this.btn_play_execute = true;
            if (response.data.status == 1) {
                if (response.data.api_error == 1) {
                  this.$swal({
                    showDenyButton: true,
                    denyButtonText: `Ok`,
                    showConfirmButton: false,
                    title: "Error",
                    text: response.data.message,
                  });
                } else {

                  this.$swal({
                    title: "Order placed",
                  });

                  if(typeof response.data.trade_record !== "undefined") {
                    var transaction_record = [response.data.trade_record];
                    let items_new = await this.$store.getters[
                      "transaction/getItems"
                    ];
                    transaction_record.push(...items_new);
                    await this.$store.dispatch(
                      "transaction/updateItems",
                      transaction_record
                    );
                  }

                  await this.$store.dispatch("transaction/updatePlaceOrder", 1);
                }
            } else {
              this.$swal({
                showDenyButton: true,
                denyButtonText: `Ok`,
                showConfirmButton: false,
                title: "Error",
                text: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.$swal({
              showDenyButton: true,
              denyButtonText: `Ok`,
              showConfirmButton: false,
              title: "Error",
              text: "Something went wrong! Please try again later.",
            });
          });
          this.loading = false;
          }
      });
    },
    async showBookModal(item){
      this.dialog=false;
      this.bookingDialog=true;
      this.booking_details=item;
    },
    async submitBooking(){
      this.$swal({
        title: "Book",
        showCancelButton: true,
        text: "Are you sure ?",
        reverseButtons: true,
      }).then(async (result) => {
      
      this.btn_load_book = true;
      this.btn_play_book = false;

      if (result.value) {
          let item = this.booking_details;
          let book_price = this.book_price;
          let payload = {
                          id: item.id, type: item.record_type, is_book: 1, 
                          book_price: book_price, 
                          book_commission: this.book_commission,
                          book_execution_fees: this.book_execution_fees,
                          book_ib_execution_fees: this.book_ib_execution_fees
                        };
          await axios
          .post("transaction-execute", payload)
          .then(async (response) => {
            this.loading = false;
            this.btn_load_book = false;
            this.btn_play_book = true;
            if (response.data.status == 1) {
              this.bookingDialog = false;
              this.book_price = 0;
              this.book_commission = 0;
              this.book_execution_fees = 0;
              this.book_ib_execution_fees = 0;
              this.$swal({
                title: "Order Booked",
              });
              this.fetchTransactions(1);
              await this.$store.dispatch("transaction/updatePlaceOrder", 1);
            } else {
              this.$swal({
                showDenyButton: true,
                denyButtonText: `Ok`,
                showConfirmButton: false,
                title: "Error",
                text: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.$swal({
              showDenyButton: true,
              denyButtonText: `Ok`,
              showConfirmButton: false,
              title: "Error",
              text: "Something went wrong! Please try again later.",
            });
          });
          this.loading = false;
          }
      });
    },
    async clearScanner() {
      this.search_table.reference = null;
      this.search_table.trade_date = null;
      this.search_table.settlement_date = null;
      this.search_table.identifier_name = null;
      this.search_table.amount = null;
      this.search_table.price = null;
      this.search_table.portfolio = null;
      this.search_table.legal_name_the_entity = null;
      this.search_table.status = null;
      this.search_table.settlement_date_oprator = "equal";
      this.search_table.settlement_date_val = null;
      this.search_table.trade_date_oprator = "equal";
      this.search_table.trade_date_val = null;
      this.search_table.security_name_oprator = "equal";
      this.search_table.security_name_val = null;
      this.search_table.identifier_oprator = "equal";
      this.search_table.identifier_val = null;
      this.search_table.amount_oprator = "equal";
      this.search_table.amount_val = null;
      this.search_table.price_oprator = "equal";
      this.search_table.price_val = null;
      this.search_table.portfolio_oprator = "equal";
      this.search_table.portfolio_val = null;
      this.search_table.counterparty_oprator = "equal";
      this.search_table.counterparty_val = null;
      this.search_table.status_oprator = "equal";
      this.search_table.status_val = null;
      this.ScannerDrawer = false;
      await this.fetchTransactions(1);
    },
    DetailsDrawer() {
      if (!this.ViewDetailsDrawer) {
        this.showModal(this.items[0]);
      } else {
        this.ViewDetailsDrawer = false;
      }
    },
  },
  props: ["CompisTransactionsOpen","CompTransactionsHeight", "CompTransactionsWidth"],
  async mounted() {
    if(this.CompisTransactionsOpen){
      // console.log("call Transactions list component mounted order");
      await this.fetchTransactions(0);
      let currenctDom = this;
      setTimeout(() => {
        var x = document.getElementsByClassName("lm_header");
        for (let i = 0; i < x.length; i++) {
          let isFind = x[i].querySelectorAll("li[title='CashFlow']").length;
          if (isFind > 0 && i == 3) {
            let kill_count = currenctDom.kill_record;
            var btn = document.createElement("BUTTON");
            btn.innerHTML =
              '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-times fa-w-11"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path></svg> Kill All';
            btn.title = "Kill all pending orders";
            btn.id = "btnKillAll";
            if (kill_count > 0) {
              btn.classList = "btn btn-danger btn-kill-all";
              btn.disabled = false;
            } else {
              btn.classList = "btn btn-danger btn-kill-all disabled";
              btn.disabled = true;
            }
            x[i].appendChild(btn);
            btn.onclick = async function() {
              await currenctDom.killAll();
            };
            // var dateRangeBox = document.createElement("div");
            // dateRangeBox.className = "date-range-box no-kill-btn";
            // dateRangeBox.innerHTML =
            //   '<span><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-calendar-alt fa-w-14 fa-3x"><path class="CalendarSvgColor" d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg> </span><div id="date-range-text">' +
            //   currenctDom.formatDate(currenctDom.start_filter_date) +
            //   " - " +
            //   currenctDom.formatDate(currenctDom.end_filter_date) +
            //   "</div>";
            // var isDateRange = document.getElementsByClassName("date-range-box");
            // if (!(isDateRange.length > 0)) {
            //   x[i].appendChild(dateRangeBox);
            // }
            // dateRangeBox.onclick = async function() {
            //   await currenctDom.dateRangeBtn();
            // };
            var filterClearBtn = document.createElement("BUTTON");
            filterClearBtn.innerHTML =
              ' <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><path d="M 1 0 C 0.448 0 0 0.448 0 1 L 0 4 C 0 4.255 0.10625 4.5015 0.28125 4.6875 L 14.6875 20 L 29.3125 20 L 43.71875 4.6875 C 43.75375 4.6505 43.7845 4.6035 43.8125 4.5625 C 43.8555 4.4995 43.9095 4.447 43.9375 4.375 C 43.9665 4.301 43.95775 4.203 43.96875 4.125 C 43.97575 4.077 44 4.049 44 4 L 44 1 C 44 0.448 43.553 0 43 0 L 1 0 z M 16 22 L 16 39 C 16 39.352 16.199 39.66375 16.5 39.84375 L 26.5 45.84375 C 26.659 45.93875 26.822 46 27 46 C 27.17 46 27.347 45.962 27.5 45.875 C 27.814 45.697 28 45.36 28 45 L 28 22 L 16 22 z M 32.90625 31.96875 A 1.0001 1.0001 0 0 0 32.78125 32 A 1.0001 1.0001 0 0 0 32.28125 33.71875 L 39.5625 41 L 32.28125 48.28125 A 1.016466 1.016466 0 1 0 33.71875 49.71875 L 41 42.4375 L 48.28125 49.71875 A 1.016466 1.016466 0 1 0 49.71875 48.28125 L 42.4375 41 L 49.71875 33.71875 A 1.0001 1.0001 0 0 0 48.875 32 A 1.0001 1.0001 0 0 0 48.28125 32.28125 L 41 39.5625 L 33.71875 32.28125 A 1.0001 1.0001 0 0 0 32.90625 31.96875 z"/></svg>';
            filterClearBtn.classList =
              "filter-button transactions-filter-button d-none";
            filterClearBtn.id = "transaction-clear-btn";
            x[i].appendChild(filterClearBtn);
            filterClearBtn.onclick = async function() {
              await currenctDom.clearScanner();
            };
          }
        }
      }, 1000);

      // Detect when scrolled to bottom.
      const listElm = document.querySelector("#virtual-scroll-table");
      listElm.addEventListener("scroll", function() {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (currenctDom.next_page_index != 0) currenctDom.fetchTransactions(0);
        } else {
          this.loading = false;
        }
      });
      this.ranges = this.getFilterDateRange();
      // let ytd_date = new Date();
      // ytd_date = new Date(ytd_date.getFullYear(), 0, 1);

      let start_date = new Date(this.start_filter_date);
      start_date.setHours(0, 0, 0, 0);
      let end_date = new Date(this.end_filter_date);
      end_date.setHours(0, 0, 0, 0);
      this.dateRange = {
        startDate: start_date,
        endDate: end_date,
      };
    }
    
    // alert(this.start_filter_date);
    // alert(this.end_filter_date);
    // console.log("start_date", start_date);
    // console.log("end_date", end_date);
    // console.log("ytd_date", ytd_date);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";

.transactions-wrap {
  padding: 0;
  #virtual-scroll-table {
    overflow: auto;
  }
  .basic-details-ul {
    border-bottom: 1px solid var(--primary);
    list-style: none;
    margin: 0 0 8px;
    padding: 0;
    display: block;
    width: 100%;
    &:last-child {
      border-bottom: none;
    }
    li {
      margin: 0;
      width: 100%;
      width: 100%;
      padding: 0;
      background: transparent;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 27px;
      p {
        padding: 1.5px 8px !important;
        height: 27px;
        line-height: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media only screen and (max-width: 1599px) {
          padding: 1.5px 4px !important;
        }
      }
      .title {
        font-family: var(--font-family) !important;
        color: var(--dark);
        font-size: 13px !important;
        font-weight: 400;
        margin-bottom: 0;
        text-align: left;
        min-width: 45%;
        max-width: 45%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
        }
        @media only screen and (max-width: 1399px) {
          font-size: 12px !important;
        }
      }
      .value {
        font-family: var(--font-family);
        color: var(--dark);
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0;
        text-align: left;
        white-space: nowrap;
        min-width: 55%;
        max-width: 55%;
        text-overflow: ellipsis;
        overflow: hidden;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
        }
        @media only screen and (max-width: 1399px) {
          font-size: 12px !important;
        }
        &.bond {
          display: none;
        }
      }
      &:nth-of-type(even) {
        background: rgba(var(--even-hover-rgba));
      }
    }
  }
}
.v-picker__title.primary {
  // display: none;
}
.v-dialog {
  border-radius: 0 !important;
  background: var(--modal-bg);
  .custome-modal {
    &.v-card {
      border-radius: 0;
      background: transparent;
      box-shadow: none !important;
      .v-card__title {
        padding: 10px 15px;
        border-bottom: 1px solid var(--modal-title);
        .box-title {
          width: 100%;
          h4 {
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 18px;
            color: var(--modal-title);
            margin-bottom: 0;
          }
        }
        .table-button-groups {
          .btn {
            height: 30px;
            font-size: 13px !important;
          }
        }
      }
      .v-card__text {
        padding: 0 0 10px;
        ul {
          list-style: none;
          padding: 0 !important;
          margin: 0 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          li {
            width: 100%;
            background-color: transparent !important;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: 5px 8px;
            span {
              &.text-title {
                color: var(--modal-list-text);
                font-size: 13px;
                font-weight: 500;
                width: 40%;
                text-align: left;
              }
              &.text-value {
                color: var(--modal-list-text2);
                text-align: left;
                font-size: 13px;
                font-weight: 500;
              }
            }
            &:nth-of-type(even) {
              background-color: rgba(var(--modal-even-hover-rgba)) !important;
            }
          }
        }
      }
      .v-card__actions {
        border-top: 1px solid var(--modal-title);
        .close-btn {
          color: var(--submit-portfolio-text);
          background-color: var(--submit-portfolio-bg);
          border: 1px solid var(--submit-portfolio-bg);
          line-height: 1;
          display: block;
          margin-bottom: 0;
          font-weight: 400;
          text-align: center;
          cursor: pointer;
          white-space: nowrap;
          padding: 5px 12px;
          font-size: 13px;
          border-radius: 0;
          width: 80px;
          margin: 0;
          height: 30px;
          text-transform: capitalize;
          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
            color: var(--submit-portfolio-bg);
            background-color: var(--submit-portfolio-text);
            border-color: var(--submit-portfolio-bg) !important;
          }
        }
      }
    }
    .calender-custome {
      .daterangepicker {
        position: relative !important;
        margin: 0;
        display: block !important;
      }
    }
  }
}

#app {
  .table-button-groups{
    .btn-primary.disabled, .btn-primary:disabled{
        background-color:var(--primary) !important;
        border-color:var(--primary) !important;
    }
  }  
  .transactions-wrap {
    .custome-table {
      table {
        > thead {
          > tr {
            > th {
              @media only screen and (max-width: 1799px) {
                &.price-head {
                  width: 10% !important;
                  min-width: 10% !important;
                }
                &.status-head {
                  width: 20% !important;
                  min-width: 20% !important;
                }
              }
            }
            > td {
              @media only screen and (max-width: 1799px) {
                &:nth-child(5) {
                  width: 10%;
                }
                &:last-child {
                  width: 20%;
                }
              }
            }
          }
        }
        > tbody {
          tr {
            td {
              &:first-child {
                padding-left: 10px !important;
              }
              .security_w {
                width: 140px !important;
                @media only screen and (min-width: 1599px) {
                  width: 160px !important;
                }
              }
            }
            &.sticky-header {
              td {
                &:first-child {
                  padding-left: 8px !important;
                  @media only screen and (max-width: 1599px) {
                    padding-left: 4px !important;
                  }
                }
              }
            }
          }
        }
        tr {
          td {
            &.text-center {
              .teble-ellipsis-wrap {
                margin: 0 auto !important;
              }
            }
          }
        }
        > thead,
        > tbody {
          > tr {
            > th,
            td {
              .teble-ellipsis-wrap {
                width: 115px !important;
                @media only screen and (max-width: 1699px) {
                  width: 90px !important;
                }
                @media only screen and (max-width: 1399px) {
                  width: 90px !important;
                }
                @media only screen and (max-width: 1299px) {
                  width: 80px !important;
                }
                &.security_w {
                  width: 230px !important;
                  @media only screen and (max-width: 1699px) {
                    width: 170px !important;
                  }
                  @media only screen and (max-width: 1399px) {
                    width: 150px !important;
                  }
                  @media only screen and (max-width: 1299px) {
                    width: 130px !important;
                  }
                }
                &.price-head {
                  width: 75px !important;
                  @media only screen and (max-width: 1699px) {
                    width: 70px !important;
                  }
                  @media only screen and (max-width: 1399px) {
                    width: 70px !important;
                  }
                  @media only screen and (max-width: 1299px) {
                    width: 60px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .booked-form-wrap {
  padding: 10px 15px;;
 .booked-form {
    .form-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0.5rem;
      .v-input {
        margin: 0;
        .v-input__slot {
          &:before,
          &:after {
            display: none;
          }
          input {
            color: var(--form-control-text);
            padding: 12px 0 0;
            @media only screen and (min-width: 1799px) {
              padding: 20px 0 0;
            }
          }
        }
      }
      .v-input--is-focused {
        border: 1px solid var(--input-focus) !important;
        outline: 0 !important;
        box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
      }
      label {
        color: var(--grey);
        font-size: 13px;
        font-weight: 500;
        line-height: 1;
        margin: 0 0 0;
        text-transform: capitalize;
        top: 8px !important;
        height: auto;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
          top: 12px !important;
        }
        &.v-label--active {
          top: 18px !important;
          font-size: 11px;
          transform: translateY(-18px) scale(1);
          @media only screen and (min-width: 1799px) {
            font-size: 12px !important;
            top: 20px !important;
          }
        }
        &.primary--text {
          color: var(--grey) !important;
        }
      }
      div,
      select,
      input {
        width: 100%;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .form-control,
      select {
        color: var(--form-control-text);
        font-size: 13px;
        font-weight: 500;
        height: 42px;
        background-color: var(--form-control-bg);
        border: 1px solid transparent;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        box-shadow: none;
        -webkit-box-shadow: none;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
          height: 50px;
        }
        &:focus {
          border: 1px solid var(--input-focus) !important;
          outline: 0 !important;
          box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
        }
      }
    }   
    button {
      width: 48%;
      min-height: 30px;
      font-size: 14px;
      font-weight: 500;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      box-shadow: none;
      -webkit-box-shadow: none;
      text-transform: capitalize;
      @media only screen and (min-width: 1799px) {
        font-size: 15px !important;
        min-height: 38px;
      }
      &.cancel-rfq-btn {
        color: var(--submit-portfolio-text);
        background-color: var(--grey);
        border: 1px solid var(--grey);
        width: 48%;
        min-height: 30px;
        font-size: 14px;
        font-weight: 500;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        box-shadow: none;
        -webkit-box-shadow: none;
        text-transform: capitalize;
        margin-bottom: 0;
        @media only screen and (min-width: 1799px) {
          font-size: 15px !important;
          min-height: 38px;
        }
        &:hover,
        &:focus {
          color: var(--grey);
          background-color: var(--submit-portfolio-text);
          border-color: var(--grey) !important;
        }
      }
    }    
  }
  .table-button-groups .confirm-btn {
    margin: 0 !important;
  }
}
  .filter-sidebar {
    z-index: 999;
    padding: 0;
    text-align: left;
    background: var(--lm-content-rgb) !important;
    .v-navigation-drawer__content {
      padding: 10px !important;
      .paddingtop-10 {
        padding-top: 10px;
      }
    }
    .sidebar-header {
      border-bottom: 1px solid var(--modal-title);
      margin-bottom: 8px;
      padding-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 28px;
        color: var(--sidebar-title);
        width: auto;
        margin-bottom: 0;
        @media only screen and (max-width: 1599px) {
          font-size: 22px;
        }
        @media only screen and (max-width: 1399px) {
          font-size: 20px;
        }
      }
      .cancle-btn {
        margin-bottom: 0;
        margin-right: 0;
      }
    }

    .form-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0.5rem;
      .v-input__control {
        justify-content: center;
        .v-input__append-inner {
          .v-icon {
            color: var(--select-icon) !important;
            caret-color: var(--select-icon) !important;
            &:focus {
              color: var(--select-icon) !important;
              caret-color: var(--select-icon) !important;
            }
          }
        }
      }
      .v-input {
        margin: 0;
        .v-input__slot {
          &:before,
          &:after {
            display: none;
          }
          input {
            color: var(--form-control-text);
            padding: 12px 0 0;
            min-height: 44px !important;
            @media only screen and (min-width: 1799px) {
              padding: 20px 0 0;
            }
          }
        }
      }
      .v-input--is-focused {
        border: 1px solid var(--input-focus) !important;
        outline: 0 !important;
        box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
      }
      label {
        color: var(--grey);
        font-size: 13px;
        font-weight: 500;
        line-height: 15px;
        text-transform: capitalize;
        top: 14px !important;
        height: auto;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
          top: 14px !important;
        }
        &.v-label--active {
          top: 18px !important;
          font-size: 11px;
          transform: translateY(-18px) scale(1);
          @media only screen and (min-width: 1799px) {
            font-size: 12px !important;
            top: 18px !important;
          }
        }
        &.primary--text {
          color: var(--grey) !important;
        }
      }
      div,
      select,
      input {
        width: 100%;
      }
      .multi-select {
        display: block;
        height: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        .v-input__control {
          min-height: 44px !important;
          .v-input__slot {
            margin-bottom: 0 !important;
            min-height: auto !important;
            .v-select__slot {
              min-height: auto !important;
              color: var(--form-control-text);
              padding: 12px 0 0;
              @media only screen and (min-width: 1799px) {
                padding: 20px 0 0;
              }
              label {
                top: 4px !important;
                @media only screen and (min-width: 1799px) {
                  top: 8px !important;
                }
                &.v-label--active {
                  top: 20px !important;
                  transform: translateY(-18px) scale(1);
                  @media only screen and (min-width: 1799px) {
                    top: 22px !important;
                  }
                }
              }
              .v-select__selections {
                min-height: auto !important;
                padding-left: 8px;
                padding-right: 16px;
                input {
                  padding-top: 0 !important;
                  min-height: auto !important;
                }
                .v-input__append-inner {
                  min-height: auto !important;
                }
              }
            }
          }
          .v-text-field__details {
            display: none;
          }
        }
        .v-chip--select.v-chip {
          padding: 0;
          border-radius: 0;
          background: transparent !important;
          min-height: 24px;
          margin-top: 4px;
          margin-bottom: 4px;
          height: auto !important;
          &::before {
            content: "";
            display: none;
          }
          .v-chip__content {
            min-width: auto !important;
            background: var(--update-btn-bg);
            color: #fff;
            border-radius: 0;
            font-size: 12px;
            font-weight: 400;
            min-height: 20px;
            padding: 0 6px;
            text-align: left;
            margin: 0;
            white-space: normal;
            &::before {
              content: "";
              display: none;
            }
            .v-icon {
              &:before {
                color: #fff;
              }
            }
          }
        }

        .v-input__append-inner,
        .v-input__prepend-inner {
          margin-top: 0 !important;
          position: absolute;
          top: 16%;
          right: 0;
        }
        input,
        select,
        div {
          width: auto !important;
        }
        .v-input__slot {
          width: 100% !important;
        }
        .v-select__slot {
          display: flex;
          justify-content: space-between;
          width: 100% !important;
        }
        label {
          padding-left: 12px;
        }
      }
      .single-select {
        display: block;
        height: 100%;
        padding: 0.375rem 0.75rem !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        .v-input__control {
          min-height: 44px !important;
          .v-input__slot {
            margin-bottom: 0 !important;
            min-height: auto !important;
            background: transparent;
            .v-select__slot {
              min-height: auto !important;
              color: var(--form-control-text);
              .v-select__selections {
                min-height: auto !important;
                padding-left: 8px;
                input {
                  padding: 12px 0 0;
                  min-height: 44px !important;
                  @media only screen and (min-width: 1799px) {
                    padding: 20px 0 0;
                  }
                }
                .v-input__append-inner,
                .v-input__prepend-inner {
                  min-height: auto !important;
                }
              }
            }
          }
          .v-chip.v-size--default {
            min-width: auto !important;
            background: var(--update-btn-bg);
            color: #fff;
          }
          .v-input__append-inner,
          .v-input__prepend-inner {
            margin-top: 7px !important;
          }
        }
        .v-text-field__details {
          display: none;
        }
        input {
          margin-top: 0 !important;
        }
        input,
        select,
        div {
          width: auto !important;
        }
        .v-input__slot {
          width: 100% !important;
        }
        .v-select__slot {
          display: flex;
          justify-content: space-between;
          width: 100% !important;
        }
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .form-control,
      select {
        color: var(--form-control-text);
        font-size: 13px;
        font-weight: 500;
        min-height: 50px !important;
        background-color: var(--form-control-bg);
        border: 1px solid transparent;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        box-shadow: none;
        -webkit-box-shadow: none;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
          min-height: 58px !important;
        }
        &:focus {
          border: 1px solid var(--input-focus) !important;
          outline: 0 !important;
          box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
        }
      }
    }
    .btn-group {
      display: flex;
      justify-content: space-between;
      align-content: center;
      margin-top: 0;
      .submit_portfolio,
      .cancle-btn {
        width: 100px !important;
        margin: 0;
        text-transform: capitalize;
        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }
        &::before {
          display: none;
        }
      }
    }
  }
  .transactions-wrap {
    table {
      > thead,
      > tbody {
        > tr {
          > th,
          td {
            &:nth-child(3) {
              display: none;
            }
            &:nth-child(5) {
              display: none;
            }
            &:nth-child(8) {
              display: none;
            }
            &:nth-child(9) {
              display: none;
            }
          }
        }
      }
    }
  }
  .lm_maximised {
    .transactions-wrap {
      .custome-table {
        table {
          > thead,
          > tbody {
            > tr {
              > th,
              td {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                  width: 8.28% !important;
                  min-width: 8.28% !important;
                  @media only screen and (max-width: 1699px) {
                    width: 9.28% !important;
                    min-width: 9.28% !important;
                  }
                }
                &:nth-child(4) {
                  @media only screen and (max-width: 1699px) {
                    width: 17% !important;
                    min-width: 17% !important;
                  }
                }
                &:nth-child(3) {
                  display: table-cell;
                }
                &:nth-child(5) {
                  display: table-cell;
                }
                &:nth-child(8) {
                  display: table-cell;
                }
                &:nth-child(9) {
                  display: table-cell;
                  width: 12.04% !important;
                  min-width: 12.04% !important;
                }
                &:last-child {
                  width: 10% !important;
                  min-width: 10% !important;
                }
                .teble-ellipsis-wrap {
                  width: 115px !important;
                  @media only screen and (max-width: 1699px) {
                    width: 115px !important;
                  }
                  @media only screen and (max-width: 1399px) {
                    width: 105px !important;
                  }
                  @media only screen and (max-width: 1299px) {
                    width: 100px !important;
                  }
                  &.security_w {
                    width: 320px !important;
                    @media only screen and (max-width: 1699px) {
                      width: 200px !important;
                    }
                    @media only screen and (max-width: 1399px) {
                      width: 200px !important;
                    }
                    @media only screen and (max-width: 1299px) {
                      width: 180px !important;
                    }
                  }
                  &.price-head {
                    width: 85px !important;
                    @media only screen and (max-width: 1699px) {
                      width: 85px !important;
                    }
                    @media only screen and (max-width: 1399px) {
                      width: 75px !important;
                    }
                  }
                  &.counterparty_w {
                    width: 280px !important;
                    @media only screen and (max-width: 1699px) {
                      width: 200px !important;
                    }
                    @media only screen and (max-width: 1399px) {
                      width: 180px !important;
                    }
                    @media only screen and (max-width: 1299px) {
                      width: 180px !important;
                    }
                  }
                  &.portfolio_w {
                    width: 220px !important;
                    @media only screen and (max-width: 1699px) {
                      width: 160px !important;
                    }
                    @media only screen and (max-width: 1399px) {
                      width: 140px !important;
                    }
                    @media only screen and (max-width: 1299px) {
                      width: 140px !important;
                    }
                  }
                  &.date_w {
                    width: 100px !important;
                    @media only screen and (max-width: 1699px) {
                      width: 95px !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.view-details-table {
          table {
            > thead,
            > tbody {
              > tr {
                > th,
                td {
                  cursor: default;
                  &:nth-child(1) {
                    width: 45% !important;
                    min-width: 45% !important;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                  &:nth-child(2) {
                    width: 55% !important;
                    min-width: 55% !important;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                }
                &:hover,
                &:focus {
                  background-color: transparent;
                  cursor: pointer;
                  &:nth-of-type(even) {
                    background-color: rgba(var(--even-hover-rgba));
                  }
                  > td {
                    color: var(--dark);
                    > span {
                      > a {
                        color: var(--dark);
                        &:hover {
                          color: var(--dark);
                        }
                      }
                    }
                    .v-icon {
                      &:before {
                        color: var(--dark);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
button.btn.btn-danger.btn-kill-all.disabled {
  cursor: not-allowed;
}
</style>
