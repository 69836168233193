export default {
	async setItemss(state, payload) {
		for (const key in payload) {
			if (Object.hasOwnProperty.call(payload, key)) {
				const menu = payload[key];
				state.items[menu.market_id] = menu.items
			}
		}
	},
	setItems(state, payload) {
		state.items = payload;
	},
	setSelectedItem(state, payload) {
		state.selectedItem = payload
	},
	async setMenus(state, payload) {
		let menus = []
		for (const key in payload) {
			if (Object.hasOwnProperty.call(payload, key)) {
				const menu = payload[key];
				menus.push({
					title: menu.title,
					market_id: menu.market_id
				})
			}
		}
		state.menus = menus
	},
	setRemoveResult(state, payload) {
		state.remove_result = payload;
	},
	setCashSymbols(state, payload) {
        state.cash_symbols = payload
    },
	setStockSymbols(state, payload) {
        state.stock_symbols = payload
    },
}