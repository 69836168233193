import axios from '../../axios'

export default {
	fetchItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('transactions-chart', payload)
				.then((response) => {
					commit('setBondsTrades', response.data.data.bonds_data)
					commit('setEquitiesTrades', response.data.data.equities_data)
					commit('setCashTrades', response.data.data.cash_data)
					commit('setFundsTrades', response.data.data.funds_data)
					commit('setAllTrades', response.data.data.all_trades_data)
					commit('setMaxValue', response.data.data.max_value)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchTimeline({
		commit
	}, payload) {
		commit('setTimeline', payload)
	},
	updateMovingAvg({
		commit
	}, payload) {
		commit('setMovingAvg', payload)
	},
	updatePeriodicity({
		commit
	}, payload) {
		commit('setPeriodicity', payload)
	},
	updateCashStatus({
		commit
	}, payload) {
		commit('setCashStatus', payload)
	},
	updateEquitiesStatus({
		commit
	}, payload) {
		commit('setEquitiesStatus', payload)
	},
	updateBondsStatus({
		commit
	}, payload) {
		commit('setBondsStatus', payload)
	},
	updateFundsStatus({
		commit
	}, payload) {
		commit('setFundsStatus', payload)
	},
}