export default {
	setItems(state, payload) {
		state.items.push(...payload)
	},
	setNextPageIndex(state, payload) {
        state.next_page_index = payload
    },
	setStartRecord(state, payload) {
        state.start_record = payload
    },
	setupdateItems(state, payload){
		state.items = payload;
	},
	setTotalRecord(state, payload) {
        state.total_record = payload
	},
	setIsCashFilter(state, payload) {
        state.is_cash_scanner = payload
    },
	setFxspotCurrencyList(state, payload) {
        state.fxspot_currency_list = payload
    },
	setCashSymbols(state, payload) {
        state.cash_symbols = payload
    },
}