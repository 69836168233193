export default {
	setItems(state, payload) {
		//state.items = payload;
		state.items.push(...payload)
	},
	setKillResult(state, payload) {
		state.kill_result = payload;
	},
	setNextPageIndex(state, payload) {
        state.next_page_index = payload
    },
	setStartRecord(state, payload) {
        state.start_record = payload
    },
	setupdateItems(state, payload){
		state.items = payload;
	},
	setKillCount(state, payload) {
		state.kill_count = payload;
	},
	setIsPlaceOrder(state, payload){
		state.is_place_order = payload;
	},
	setTransactionDetails(state, payload){
		state.transaction_details = payload;
	},
	setTradeComments(state, payload){
		state.trade_comments = payload;
	},
	setRecordType(state, payload){
		state.record_type = payload;
	},
	setTotalRecord(state, payload) {
        state.total_record = payload
	},
	setIsTransactionScanner(state, payload) {
        state.is_transaction_scanner = payload
    },
}