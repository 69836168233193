<template>
  <header>
    <NavBar />
  </header>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "Header",
  components: {
    NavBar
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
header {
  background: var(--menu-bg);
}
</style>
