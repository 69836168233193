export default {
	setItems(state, payload) {
		// state.items = payload
		state.items.push(...payload)
	},
	setStatementUrl(state, payload) {
		state.statement_url = payload
	},
	setStatementName(state, payload) {
		state.statement_name = payload
	},
	setItemDetails(state, payload) {
		state.item_details = payload
	},
	setMMDetails(state, payload) {
		state.mm_details = payload
	},
	setInterestDetails(state, payload) {
		state.interest_details = payload
	},
	setTradingDetails(state, payload) {
		state.trading_details = payload
	},
	setNextPageIndex(state, payload) {
        state.next_page_index = payload
    },
	setStartRecord(state, payload) {
        state.start_record = payload
    },
	setupdateItems(state, payload){
		state.items = payload;
	},
}