<template>
  <div class="holding-chart-wrap">
    <div id="holdingchart" class="chart-loaded">
      <div id="holding-chart">
        <b-row class="m-0 p-0 flex-nowrap">
          <b-col class="p-0">
            <div id="holdingchart" class="p-0" v-if="holding_chart_type=='Assets'">
              <span class="chart-title">By <br />Currency</span>
              <apexchart
                type="donut"
                :height="holdingHeight"
                :width="holdingWidth"
                ref="currencyholdingchart"
                :options="currencyChartOptions"
                :series="currencyseries"
                class="pt-0"
              ></apexchart>
            </div>

            <div
              style="transition-duration: 2s"
              id="dhchart"
              v-if="is_daily_history"
            >
              <div class="toolbar position-right right-5px" v-if="holding_chart_type!='Assets'">
                <b-dropdown
                  id="time-drp"
                  right
                  :text="this.time_drp_placeholder"
                  variant="primary"
                  class="custome-dropdown margin-r-10"
                >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="updateDailyHoldingData('Month')"
                    v-if="this.time_drp_placeholder != 'Month'"
                    >Month</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="updateDailyHoldingData('Quarter')"
                    v-if="this.time_drp_placeholder != 'Quarter'"
                    >Quarter</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="updateDailyHoldingData('Year')"
                    v-if="this.time_drp_placeholder != 'Year'"
                    >Year</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="updateDailyHoldingData('YTD')"
                    v-if="this.time_drp_placeholder != 'YTD'"
                    >YTD</b-dropdown-item
                  >
                </b-dropdown>
                <b-dropdown
                  id="market-type-drp"
                  right
                  :text="this.market_drp_placeholder"
                  variant="primary"
                  class="custome-dropdown"
                >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="updateHoldingTypeData('All')"
                    v-if="this.market_drp_placeholder != 'All'"
                    >All</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="updateHoldingTypeData('Currencies')"
                    v-if="this.market_drp_placeholder != 'Currencies'"
                    >Currencies</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="updateHoldingTypeData('Equities')"
                    v-if="this.market_drp_placeholder != 'Equities'"
                    >Equities</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="updateHoldingTypeData('Funds')"
                    v-if="this.market_drp_placeholder != 'Funds'"
                    >Funds</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="updateHoldingTypeData('Bonds')"
                    v-if="this.market_drp_placeholder != 'Bonds'"
                    >Bonds</b-dropdown-item
                  >
                </b-dropdown>
                <b-dropdown
                  id="holding-first_drp"
                  right
                  text="Historical"
                  variant="primary"
                  class="custome-dropdown margin-l-10"
                  v-if="!is_history"
                >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="changeDisplayChart('Assets')"
                    >Breakdown</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <div id="daily-holding-chart">
                <apexchart
                  type="area"
                  :height="CompHoldingChartHeight"
                  :width="holdingWidth"
                  ref="dhchart"
                  :options="historychartOptions"
                  :series="dhseries"
                ></apexchart>
              </div>
            </div>
          </b-col>
          <b-col class="p-0">
            <div id="holdingchart" class="p-0" v-if="holding_chart_type=='Assets'">
              <div class="toolbar position-right right-5">
                <b-dropdown
                  id="holding-drp"
                  right
                  :text="this.chart_placeholder"
                  variant="primary"
                  class="custome-dropdown margin-r-10"
                >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="changeDisplayChart('Historical')"
                    >Historical</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <span class="chart-title"
                >By <br />
                Asset Class</span
              >
              <apexchart
                type="donut"
                :height="holdingHeight"
                :width="holdingWidth"
                ref="holdingchart"
                :options="chartOptions"
                :series="holdingseries"
                class="pt-0"
              ></apexchart>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import themeconfig from '../themeconfig.js';
export default {
  name: "HoldingChart",
  components: { apexchart: VueApexCharts },
  props: ["CompisHoldingChartOpen","CompHoldingChartHeight", "CompHoldingChartWidth"],
  data() {
    return {
      holding_chart_type:'Assets',
      loading: false,
      holdingseries: [],
      currencyseries: [],
      chartOptions: {
        type: Object,
      },
      currencyChartOptions: {
        type: Object,
      },
      chart_placeholder: "Breakdown",
      dhseries: [
        {
          name: "Market Value",
          type: "area",
          data: [],
        },
      ],
      historychartOptions: {
        chart: {
          id: "daily-holding-chart",
          type: "area",
          foreColor:'',
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        colors:[],
        // colors: ["#ffffff"],
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat",
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return new Intl.NumberFormat("en-US").format(val.toFixed(0));
            },
          },
        },
        grid: {
          // borderColor: '#333333',
          borderColor:""
            
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "dd MMM yyyy",
          },
          y: {
            formatter: function(value) {
              return new Intl.NumberFormat("en-US").format(value.toFixed(0));
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.3,
            stops: [0, 100],
          },
        },
        legend: {
          show: false,
        },
      },
      time_drp_placeholder: "Year",
      market_drp_placeholder: "All",
    };
  },
  computed: {
    timeline: {
      get() {
        return this.$store.getters["daily_holding_chart/getTimeline"];
      },
    },
    holding_type: {
      get() {
        return this.$store.getters["daily_holding_chart/getHoldingType"];
      },
    },
    currency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
    items() {
      return this.$store.getters["holding/getAssets"];
    },
    currency_chart_data() {
      return this.$store.getters["holding/getCurrencyChartData"];
    },
    holding() {
      return this.$store.getters["holding/getHoldings"];
    },
    is_history: {
      get() {
        return this.$store.getters["daily_holding_chart/getIsHistory"];
      },
    },
    is_daily_history: {
      get() {
        return this.$store.getters["holding_chart/getIsDailyHistory"];
      },
    },
    holdingWidth: {
      get() {
        if (!this.is_history) {
          return parseInt(this.CompHoldingChartWidth) / 1 - 10;
        } else {
          return parseInt(this.CompHoldingChartWidth) / 2 - 10;
        }
      },
    },
    holdingHeight: {
      get() {
        return parseInt(this.CompHoldingChartHeight) + 20;
      },
    },
    history_items: {
      get() {
        let AllItems = this.$store.getters["daily_holding_chart/getItems"];
        let history_items = new Array();
        for (var i in AllItems) {
          let one = new Array();
          one.push(new Date(AllItems[i].created).getTime());
          one.push(AllItems[i].total_market.toFixed(2));
          history_items.push(one);
        }
        return history_items;
      },
    },
    is_current_theme: {
      get() {
        return this.$store.getters["dashboard/getCurrentTheme"];
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
  },
  watch: {
    CompHoldingChartHeight(new_val, old_val) {
      if (new_val != old_val) {
        if (this.is_history) {
          this.fetchAssetsChart();
        }
        if (this.is_daily_history) {
          this.fetchDailyHoldingChart();
        }
      }
    },
    CompHoldingChartWidth(new_val, old_val) {
      if (new_val != old_val) {
        if (this.is_history) {
          this.fetchAssetsChart();
        }
        if (this.is_daily_history) {
          this.fetchDailyHoldingChart();
        }
      }
    },
    holdingWidth(new_val, old_val) {
      if (new_val != old_val) {
        if (this.is_history) {
          this.fetchAssetsChart();
        }
        if (this.is_daily_history) {
          this.fetchDailyHoldingChart();
        }
      }
    },
    currency() {
      if (this.is_history) {
        this.fetchAssetsChart();
      }
      if (this.is_daily_history) {
        this.fetchDailyHoldingChart();
      }
    },
    is_history(val) {
      if (val) {
        this.fetchAssetsChart();
      }
    },
    is_daily_history(val) {
      if (val) {
        this.fetchDailyHoldingChart();
      }
    },
    async is_current_theme() {
      if (this.is_history) {
        await this.fetchAssetsChart();
      }
      if (this.is_daily_history) {
        await this.updateDailyHoldingChartOption();
        await this.updateDailyHoldingSeries();
      }
    },
    async holding() {
      if (this.is_history) {
        this.fetchAssetsChart();
      }
      if (this.is_daily_history) {
        this.fetchDailyHoldingChart();
      }
    },
    async holding_type() {
      this.fetchDailyHoldingChart();
    },
    async filter_portfolio() {
      if (this.is_history) {
        this.fetchAssetsChart();
      }
      if (this.is_daily_history) {
        this.fetchDailyHoldingChart();
      }
    }
  },
  methods: {
    async setThemeoption(){
      const curretn_theme = themeconfig.getTheme();

       localStorage.getItem("isDarkActive") == 1 ? this.historychartOptions.chart.foreColor = "#a8a8a8" : this.historychartOptions.chart.foreColor = curretn_theme.chart_for_color;
       localStorage.getItem("isDarkActive") == 1 ? this.historychartOptions.colors = ["#ffffff"] : this.historychartOptions.colors = [curretn_theme.chart_primary_color];
       localStorage.getItem("isDarkActive") == 1 ? this.historychartOptions.grid.borderColor ="#333333" : this.historychartOptions.grid.borderColor = curretn_theme.chart_grid_border_color;
    },
    async updateDailyHoldingData(timeline) {
      this.time_drp_placeholder = timeline;
      let time = this.getTimeSelected(timeline);
      this.selection = time;
      await this.$store.dispatch("daily_holding_chart/fetchTimeline", time);
      await this.fetchDailyHoldingChart();
    },
    async updateHoldingTypeData(selectedMarket) {
      this.market_drp_placeholder = selectedMarket;
      await this.$store.dispatch(
        "daily_holding_chart/fetchTimeline",
        this.timeline
      );
      let market_type = this.getMarketTypeSelected(selectedMarket);
      await this.$store.dispatch(
        "daily_holding_chart/fetchHoldingType",
        market_type
      );
      await this.updateDailyHoldingSeries();
    },
    async fetchAssetsChart() {
      this.loading = true;
      this.holdingseries = this.items.amount;
      this.chartOptions = {
        chart: {
          type: "donut",
        },
        labels: this.items.label,
        plotOptions: {
          pie: {
            donut: {
              size: "41%",
              labels: {
                show: false,
              },
            },
          },
        },
        colors: [
          // localStorage.getItem("isDarkActive") == 1 ? "#e22963" : "#e22963",
          // localStorage.getItem("isDarkActive") == 1 ? "#218e89" : "#218e89",
          // localStorage.getItem("isDarkActive") == 1 ? "#ff7101" : "#ff7101",
          // localStorage.getItem("isDarkActive") == 1 ? "#3b0550" : "#3b0550",
          // localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : "#bfbfbf",
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#001a33",
          localStorage.getItem("isDarkActive") == 1 ? "#ca2330" : "#ca2330",
          localStorage.getItem("isDarkActive") == 1 ? "#005842" : "#005842",
          localStorage.getItem("isDarkActive") == 1 ? "#fbc117" : "#fbc117",
          // localStorage.getItem("isDarkActive") == 1 ? "#3c3c3c" : "#3c3c3c",
        ],
        legend: {
          show: false,
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: false,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
          },
          y: {
            formatter: function(value) {
              return new Intl.NumberFormat("en-US").format(value.toFixed(0));
            },
          },
        },
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat",
        },
        animations: {
          enabled: true,
          easing: "easein",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        // title: {
        //   text: "By Asset Class",
        //   align: "center",
        //   margin: 0,
        //   offsetX: 0,
        //   offsetY: 0,
        //   floating: false,
        //   style: {
        //     fontSize: "14px",
        //     fontWeight: "bold",
        //     fontFamily: "Montserrat",
        //     color: "#263238",
        //   },
        // },
      };
      this.currencyseries = this.currency_chart_data.amount;
      const curretn_theme = themeconfig.getTheme();
      this.currencyChartOptions = {
        chart: {
          type: "donut",
        },
        labels: this.currency_chart_data.label,
        plotOptions: {
          pie: {
            donut: {
              size: "41%",
              labels: {
                show: false,
              },
            },
          },
        },
        colors: [
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : curretn_theme.chart_primary_color,
          localStorage.getItem("isDarkActive") == 1 ? "#404040" : "#333333",
          localStorage.getItem("isDarkActive") == 1 ? "#7f7f7f" : "#555555",
          localStorage.getItem("isDarkActive") == 1 ? "#bfbfbf" : "#7f7f7f",
        ],
        legend: {
          show: false,
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: false,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
          },
          y: {
            formatter: function(value) {
              return new Intl.NumberFormat("en-US").format(value.toFixed(0));
            },
          },
        },
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat",
        },
        animations: {
          enabled: true,
          easing: "easeout",
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        // title: {
        //   text: "By Currency",
        //   align: "center",
        //   margin: 0,
        //   offsetX: 0,
        //   offsetY: 0,
        //   floating: false,
        //   style: {
        //     fontSize: "14px",
        //     fontWeight: "bold",
        //     fontFamily: "Montserrat",
        //     color: "#263238",
        //   },
        // },
      };
      this.loading = false;
      document.getElementById("holdingchart").className = "chart-loaded";
    },
    async fetchDailyHoldingChart() {
      this.loading = true;
      await this.$store.dispatch("daily_holding_chart/fetchItems", {
        filter_portfolio_ids: this.filter_portfolio,
        timeline: this.timeline,
        currency_filter: this.currency,
        market_type: this.holding_type,
      });
      await this.updateDailyHoldingSeries();
      this.loading = false;
    },
    async updateDailyHoldingSeries() {
      this.$refs.dhchart.updateSeries([
        {
          name: "",
          data: this.history_items,
        },
      ]);
    },
    async updateDailyHoldingChartOption() {
      const curretn_theme = themeconfig.getTheme();
      this.historychartOptions = {
        chart: {
          id: "daily-holding-chart",
          type: "area",
          foreColor:
            localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : curretn_theme.chart_for_color,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        colors:
          localStorage.getItem("isDarkActive") == 1 ? ["#ffffff"] : [curretn_theme.chart_primary_color],
        // colors: ["#ffffff"],
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat",
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return new Intl.NumberFormat("en-US").format(val.toFixed(0));
            },
          },
        },
        grid: {
          // borderColor: '#333333',
          borderColor:
            localStorage.getItem("isDarkActive") == 1 ? "#333333" : curretn_theme.chart_grid_border_color,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "dd MMM yyyy",
          },
          y: {
            formatter: function(value) {
              return new Intl.NumberFormat("en-US").format(value.toFixed(0));
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.3,
            stops: [0, 100],
          },
        },
        legend: {
          show: false,
        },
      };
    },
    async changeDisplayChart(chart_type) {
      this.holding_chart_type =chart_type;
      if (chart_type == "Historical") {
        let is_param_dhistory = true;
        await this.$store.dispatch(
          "holding_chart/fetchIsDailyHistory",
          is_param_dhistory
        );
        let is_param_history = false;
        await this.$store.dispatch(
          "daily_holding_chart/fetchIsHistory",
          is_param_history
        );
      } else {
        let is_param_history = true;
        await this.$store.dispatch(
          "daily_holding_chart/fetchIsHistory",
          is_param_history
        );
        let is_param_dhistory = true;
        await this.$store.dispatch(
          "holding_chart/fetchIsDailyHistory",
          is_param_dhistory
        );
      }
    },
  },
  async mounted() {
    if(this.CompisHoldingChartOpen){
      
      // console.log("call HoldingChart component mounted order");
      await this.setThemeoption();
      await this.fetchAssetsChart();
      await this.fetchDailyHoldingChart();
      this.changeDisplayChart(this.holding_chart_type);
      // setTimeout(() => {
      //   console.log("call dispatchEvent resize===",this.holding_chart_type);
        
      //   // window.dispatchEvent(new Event('resize'))
      // }, 500);
      
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.holding-chart-wrap {
  padding: 5px 0;
  height: 100%;
  width: 100%;
  #holdingchart {
    position: relative;
    padding: 0 5px;
    display: flex;
    height: 100%;
    width: 100%;
    #holding-chart {
      display: flex;
      height: 100%;
      width: 100%;
      .toolbar {
        top: 0;
      }
      .apexcharts-tooltip {
        .apexcharts-tooltip-series-group {
          padding-top: 6px;
        }
      }
    }
  }
}
.assets-table {
  // position: absolute;
  // top: 10px;
  // left: 10px;
  padding: 0;
  margin: 0;
  .v-data-table {
    background: transparent !important;
  }
  table {
    border: 1px solid var(--assets-table-color);
  }
}
.chart-title {
  font-size: 13px;
  margin-bottom: 0;
  color: var(--primary);
  font-weight: 400;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  text-align: center;
}
body {
  #app {
    #holdingchart {
      position: relative;
      div {
        .apexcharts-canvas {
          padding-top: 10px !important;
        }
      }
    }
  }
}
</style>
