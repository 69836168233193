import axios from 'axios'

export default {
    fetchItems({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/holding', payload)
                .then(response => {
                    commit('setItems', response.data.data)
                    commit('setAssets', response.data.asset_chart_data)
                    commit('setCurrencyChart', response.data.currency_chart_data)
                    commit('setTotalHolding', response.data.holding_data)
                    let tempCash = [];
                    let tempStock = [];
					for (let obj of response.data.data) {
                        if(obj.iex_symbol != undefined && obj.iex_symbol != null){
                            if(obj.market_id == 6 || obj.market_id == 'cash'){
                                tempCash.push(obj.iex_symbol);
                            }
                            else if(obj.market_id == 1){
                                tempStock.push(obj.iex_symbol);
                            }
						}
					}
					tempCash = [...new Set(tempCash)];
					tempStock = [...new Set(tempStock)];
					
					commit('setCashSymbols', tempCash)
					commit('setStockSymbols', tempStock)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateFormQuantity({
        commit
    }, payload){
        commit('setQuantity', payload)
    },
    updateCurrency({commit}, payload){
        commit('setCurrency', payload)
    },
    updateItems({
		commit
	}, payload){
		commit('setItems', payload)
	},
}