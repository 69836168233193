export default {
	setItems(state, data) {
		// let items = new Array();
		// for (var i in data) {
		// 	let one = new Array();
		// 	one.push(data[i].created * 1000);
		// 	one.push(data[i].total_market.toFixed(2));
		// 	items.push(one);
		// }
		state.items = data
	},
	setTimeline(state, timeline) {
		state.timeline = timeline
	},
	setHoldingType(state, holding_type){
		state.holding_type = holding_type
	},
	setIsHistory(state, is_history){
		state.is_history=is_history
	}
}