import axios from '../../axios'

export default {
	fetchItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('chart-data', payload)
				.then((response) => {
					commit('setAllItems', response.data.data.chart_data ?? [])
					commit('setName', response.data.security_name ?? [])
					commit('setMarketId', response.data.market_id ?? [])
					commit('setItems', response.data ?? [])
					resolve(response.data.data ?? [])
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchSecurityId({
		commit
	}, payload) {
		commit('setSecurityId', payload)
	},
	fetchTimeline({
		commit
	}, payload) {
		commit('setTimeline', payload)
	},
	fetchCandleTimeline({
		commit
	}, payload) {
		commit('setCandleTimeline', payload)
	},
	updateIsLoading({
		commit
	}, payload) {
		commit('setIsLoading', payload)
	},
	updateDefaultChart({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('set-default-chart-security', payload)
				.then((response) => {
					commit('setDefaultSecurities', response.data)
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	getDefaultSecurity({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.get('get-default-chart-security', payload)
				.then((response) => {
					//console.log('security_id',response.data.data.security_id);
					commit('setDefaultSecurity', response.data.data.security_id)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	getAutoCreateSecurity({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('auto-security-import', payload)
				.then((response) => {
					commit('setDefaultSecurity', response.data.data.id)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchBid({
		commit
	}, payload) {
		commit('setBid', payload)
	},
	fetchAppUri({
		commit
	}, payload) {
		commit('setAppUri', payload)
	},
	fetchAsk({
		commit
	}, payload) {
		commit('setAsk', payload)
	},
	fetchSetPrice({
		commit
	}, payload) {
		commit('setBid', payload.bid_price);
		commit('setAsk', payload.ask_price);
		commit('setLastPrice', payload.last_price);
		commit('setRate', payload.rate);		
	},
	fetchRate({
		commit
	}, payload) {
		commit('setBid', 0);
		commit('setAsk', 0);
		commit('setLastPrice', 0);
		commit('setRate', 0);

		return new Promise((resolve, reject) => {
			axios.post('get-quote', payload)
				.then((response) => {
					let market_id = response.data.data.market_id;
					let bid = response.data.data.bid_price;
					let ask = response.data.data.ask_price;
					let rate = response.data.data.rate;
					if(ask > 0 && bid > 0){
						if(market_id == 6){
							rate = ((ask - bid) * 10000).toFixed(2);
						}
						else{
							rate = (ask - bid).toFixed(2);
						}
					}
					commit('setMarketId', market_id)
					commit('setAddedWatchlist', response.data.data.added_watchlist)
					commit('setBid', bid)
					commit('setAsk', ask)
					commit('setRate', rate)
					commit('setAppUri', response.data.data.api_uri)
					commit('setLastPrice', response.data.data.last_price)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchWs({
		commit
	}, payload) {
		commit('setBid', payload.bid_price)
		commit('setAsk', payload.ask_price)
		commit('setRate', payload.rate)
	},
	fetchCounterparties({
		commit
	}, payload) {
		commit('setCounterparty', payload)
	},
	fetchAddedWatchlist({
		commit
	}, payload) {
		commit('setAddedWatchlist', payload)
	},
	fetchPriceType({
		commit
	}, payload) {
		commit('setPriceType', payload)
	},
	updateMarketId({
		commit
	}, payload) {
		commit('setMarketId', payload.market_id)
	},
	updateConId({
		commit
	}, payload) {
		commit('setConId', payload.con_id)
	},
}