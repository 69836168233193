export default {
	setItems(state, payload) {
		state.items.push(...payload)
	},
	setNextPageIndex(state, payload) {
        state.next_page_index = payload
    },
	setStartRecord(state, payload) {
        state.start_record = payload
    },
	setupdateItems(state, payload){
		state.items = payload;
	},
	resetPageIndex(state, payload){
		state.next_page_index = payload.next_page_index;
		state.start_record = payload.start_record;
	},
	setTotalRecord(state, payload) {
        state.total_record = payload
	},
	setIsEquitiesFilter(state, payload) {
        state.is_equities_scanner = payload
    },
	setStockSymbols(state, payload) {
        state.stock_symbols = payload
    },
}