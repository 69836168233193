export default {
	setItems(state, payload) {
		//state.items = payload
		state.items.push(...payload)
	},
	setItemDetails(state, payload) {
		state.item_details = payload
	},
	setItemSecondaryDetails(state, payload) {
		state.item_secondary_details = payload
	},
	setNextPageIndex(state, payload) {
        state.next_page_index = payload
    },
	setStartRecord(state, payload) {
        state.start_record = payload
    },
	setupdateItems(state, payload){
		state.items = payload;
	},
}