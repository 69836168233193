<template>
  <div>
    <!-- user side -->
    <ChatUser v-if="profiles.id != 2" :CompChatHeight="CompChatHeight" />
    
    <!-- Admin side -->
    <ChatAdmin v-if="profiles.id == 2" :CompChatHeight="CompChatHeight" :CompChat2Height="CompChat2Height" />
    
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </div>
</template>

<script>
import ChatUser from "@/components/ChatUser.vue";
import ChatAdmin from "@/components/ChatAdmin.vue";
export default {
  name: "Chat",
  components:{
    ChatUser,
    ChatAdmin
  },
  data() {
    return {
      loading: true,
    };
  },
  props: ["CompisChatOpen","CompChatHeight", "CompChat2Height"],
  computed: {
    profiles() {
      let profile = this.$store.getters["dashboard/getProfiles"];
      return profile;
    },
  },
  watch:{
    profiles(val) {
      if(val && val.id){
        this.loading = false;
      }
    },
  },
  methods: { },
  created() { },
  updated: () => { },
  mounted() {
    if(this.CompisChatOpen){
      // console.log("call Chat component mounted order");
      if(this.profiles.id){
        this.loading = false;
      }
    }
    
  },
  destroyed() {
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.message-box {
  position: relative;
  .admin-chat-user {
    padding-top: 46px;
  }
  .chat-header-user {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
.message-group {
  position: relative;
  display: block;
  overflow: auto;
  padding: 5px; 
  .message-receive {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 8px;
    padding-bottom: 8px;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-top: 2px;
    }
    .message-text {
      background: rgba(var(--message-receive-bgcolor));
      margin-left: 0px;
      position: relative;
      // &:after {
      //   position: absolute;
      //   left: -10px;
      //   top: 10px;
      //   right: inherit;
      //   display: inline-block !important;
      //   border-top: 5px solid transparent;
      //   border-right: 10px solid #f2f2f2;
      //   border-bottom: 5px solid transparent;
      //   content: "";
      // }
    }
  }
  .message-send {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 8px;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-top: 2px;
    }
    .message-text {
      background: rgba(var(--message-send-bgcolor));
      margin-right: 0px;
      position: relative;
      // &:after {
      //   position: absolute;
      //   right: -10px;
      //   top: 10px;
      //   left: inherit;
      //   display: inline-block !important;
      //   border-top: 5px solid transparent;
      //   border-left: 10px solid #e1e3e5;
      //   border-bottom: 5px solid transparent;
      //   content: "";
      // }
    }
  }
  .message-text {
    padding: 8px;
    max-width: 85%;
    border-radius: 4px;

    p {
      color: var(--dark);
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 0;
      text-align: left;
      strong {
        font-weight: 600 !important;
      }
    }
  }
}
.message-form {
  position: relative;
  input {
    &.form-control {
      background-color: var(--search-bar-bg) !important;
      border: 2px solid var(--search-bar-border) !important;
      transition: box-shadow 300ms ease;
      box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
      font-size: 14px;
      border: none;
      min-height: 30px;
      height: auto !important;
      background-color: transparent;
      padding: 4px 40px 4px 10px;
      margin-bottom: 0;
      border-radius: 0;
      color: var(--primary);
      font-weight: 400;

      &:focus {
        outline: none;
        box-shadow: none;
        border-color: var(--input-focus2) !important;
        outline: 0 !important;
        box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
      }
    }
  }
  &.user-list-search {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    display: none;
    input {
      &.form-control {
        padding: 4px 10px 4px 36px !important;
      }
    }
  }
  .send-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    img {
      width: 20px;
      height: 19px;
    }
  }
  .search-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
}
.typing {
  text-align: left;
  margin-left: 10px;
  @keyframes bounce {
    50% {
      transform: translate(0, 5px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  .ellipsis {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgba(var(--message-send-bgcolor));
    padding: 5px;
    margin-right: 5px;
    text-align: left;
  }
  .dot_1 {
    /* animation: name duration timing-function delay iteration-count */
    animation: bounce 0.8s linear 0.1s infinite;
  }
  .dot_2 {
    animation: bounce 0.8s linear 0.2s infinite;
  }
  .dot_3 {
    animation: bounce 0.8s linear 0.3s infinite;
  }
}
.chat-user-list {
  position: relative;
  .common-list {
    list-style: none;
    margin: 0 0 0;
    padding: 0;
    display: flex;
    justify-items: flex-start;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
    height: 250px;
    li {
      margin: 0;
      padding: 0;
      width: 100%;
      a {
        width: 100%;
        padding: 0.25rem 0.5rem;
        background: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          color: var(--dark);
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 0;
          line-height: 18px;
          text-align: left;
          @media only screen and (min-width: 1799px) {
            font-size: 14px !important;
          }
          @media only screen and (max-width: 1399px) {
            font-size: 12px !important;
          }
        }
        p {
          color: var(--dark);
          font-size: 13px;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 0;
          text-align: left;
          white-space: nowrap;
          max-width: 60%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          @media only screen and (min-width: 1799px) {
            font-size: 14px !important;
          }
          @media only screen and (max-width: 1399px) {
            font-size: 12px !important;
          }
        }
        .date {
          color: var(--grey);
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0;
          @media only screen and (min-width: 1799px) {
            font-size: 13px !important;
          }
          @media only screen and (max-width: 1399px) {
            font-size: 11px !important;
          }
        }
        &:hover {
          color: var(--white);
          background: var(--list-hover-bg);
          h3 {
            color: var(--white);
          }
          p {
            color: var(--white);
          }
          .date {
            color: var(--list-date-hover-bg);
          }
          .chat-img-dark{ display: block; }
          .chat-img-light{ display: none; }
        }
      }
      &.unread {
        a {
          color: var(--white);
          background: var(--list-hover-bg);
          h3, p {
            color: var(--white);
          }
          .date {
            color: var(--list-date-hover-bg);
          }
          .chat-img-dark{ display: block; }
          .chat-img-light{ display: none; }
        }
        &:nth-of-type(even) {
          a {
            color: var(--white);
            background: var(--list-hover-bg);
            h3 {
              color: var(--white);
            }
            .date {
              color: var(--list-date-hover-bg);
            }
          }
        }
      }
      &:nth-of-type(even) {
        a {
          background: rgba(var(--even-hover-rgba));
          &:hover {
            color: var(--white);
            background: var(--list-hover-bg);
          }
        }
      }
    }
  }
  .chat-user-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 85%;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
}
.chat-user-details {
  background: var(--chat-user-details-bg);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 5px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 27px;
    color: var(--white) !important;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 15px;
    text-align: left;
    margin-right: 20px;
    &:hover,
    &:focus {
      color: var(--white) !important;
    }
  }
  .chat-user-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    h3 {
      color: var(--white);
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 18px;
      text-align: left;
      @media only screen and (min-width: 1799px) {
        font-size: 14px !important;
      }
      @media only screen and (max-width: 1399px) {
        font-size: 12px !important;
      }
    }
  }
  .chat-menu-icon {
    color: var(--white);
    margin: 0 5px 0 auto;
  }
}
.lm_maximised {
  .chat-height {
    border-top: none !important;
  }
  .admin-chat {
    .chat-user-box {
      display: flex !important;
    }
    .admin-chat-list {
      display: block !important;
      max-width: 25% !important;
      padding-right: 0 !important;
      border-right: 1px solid var(--primary);
      .message-form {
        &.user-list-search {
          display: block;
        }
      }
      // .chat-user-list {
      //   .common-list {
      //     padding-top: 33px;
      //   }
      // }
    }
    .admin-chat-message {
      display: block !important;
      &.maximize-w-75 {
        padding-left: 0 !important;
        max-width: 75% !important;
      }
      .chat-user-details {
        padding: 10px;
        a {
          display: none;
        }
      }
      .admin-chat-user {
        padding-top: 50px;
      }
    }
  }
  .message-group {
    width: 100%;
    margin: 0 auto;
    padding: 0 9%;  
  }
  .message-form {
    margin: 0 9%;
  }
}
.no-chat-history {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 44%;
  }
  h2 {
    color: var(--dark);
    font-size: 28x;
    font-weight: 600;
    margin: 20px auto;
    line-height: 38px;
    text-align: center;
    width: 80%;
    @media only screen and (min-width: 1799px) {
      font-size: 32px !important;
    }
    @media only screen and (max-width: 1399px) {
      font-size: 24px !important;
    }
  }
  p {
    color: var(--dark);
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 18px;
    text-align: center;
    @media only screen and (min-width: 1799px) {
      font-size: 14px !important;
    }
    @media only screen and (max-width: 1399px) {
      font-size: 12px !important;
    }
  }
}
.message-log {
  color: var(--message-log);
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 3px;
  line-height: 15px;
}
</style>
