export default {
	bonds_trades: [],
	equities_trades: [],
	funds_trades: [],
	cash_trades: [],
	all_trades: [],
	timeline: "five_year",
	moving_avg: "90",
	periodicity: "monthly",
	max_value: 0,
	cash_status:1,
	equities_status:1,
	bonds_status:1,
	funds_status:1,
}