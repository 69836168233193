<template>
  <div class="dashboard">
    <Header />
    <golden-layout class="layout-custome" :borderWidth="4" :headerHeight="30" :minItemWidth="300" :selectionEnabled="true">
      <!-- START:: row -->
      <gl-row>
        <!-- START:: First column  -->
        <gl-col class="holdings-watchlist-section">
          <!-- START:: Currencies block tabs  -->
          <gl-stack v-model="openedOrderTab">
            <gl-component @resize="onResizeOrderTab('TickerDetails')" @show="onShowOrderTab('TickerDetails')" tab-id="TickerDetails" :closable="false" :reorderEnabled="true" title="Order" class="order-entry-height none-border overflow-hidden" >
              <TickerDetails CompisTickerDetailsOpen='isTickerDetailsOpen' :OrderEntryHeight="sendOrderEntryHeight" v-if="isTickerDetailsOpen"/>
              <transition name="fade">
                <div class="loading full-page" v-if="!isTickerDetailsOpen">
                  <div><span></span><span></span><span></span><span></span></div>
                </div>
              </transition>
            </gl-component>
            <gl-component @resize="onResizeOrderTab('Currencies')" @show="onShowOrderTab('Currencies')" tab-id="Currencies" :closable="false" :reorderEnabled="true" title="Currencies" class="cash-height cash-boder overflow-hidden">
              <Cash CompisCashOpen='isCashOpen' :CompCashHeight="sendCashHeight" v-if="isCashOpen"/>
            </gl-component>
            <gl-component @resize="onResizeOrderTab('Equities')" @show="onShowOrderTab('Equities')" tab-id="Equities" :closable="false" :reorderEnabled="true" title="Equities" class="equities-height equities-boder overflow-hidden">
              <Equities CompisEquitiesOpen='isEquitiesOpen' :CompEquitiesHeight="sendEquitiesHeight" v-if="isEquitiesOpen"/>
            </gl-component>
            <gl-component @resize="onResizeOrderTab('Bonds')" @show="onShowOrderTab('Bonds')" tab-id="Bonds" :closable="false" :reorderEnabled="true" title="Bonds" class="bonds-height bonds-boder overflow-hidden">
              <Bonds CompisBondsOpen='isBondsOpen' :CompBondsHeight="sendBondsHeight" v-if="isBondsOpen"/>
            </gl-component>
            <gl-component @resize="onResizeOrderTab('Funds')" @show="onShowOrderTab('Funds')" tab-id="Funds" :closable="false" :reorderEnabled="true" title="Funds" class="funds-height funds-boder overflow-hidden">
              <Funds CompisFundsOpen='isFundsOpen' :CompFundsHeight="sendFundsHeight" v-if="isFundsOpen"/>
            </gl-component>
          </gl-stack>
          <!--END:: Currencies block tabs  -->
          <!-- START:: Watchlist & Holding block tabs  -->
          <gl-stack class="holding-component" id="holding-list-tab" v-model="openedHoldingTab">
            <gl-component @resize="onResizeWatchlistHoldingTab('Watchlist')" @show="onShowWatchlistHoldingTab('Watchlist')" tab-id="Watchlist" :closable="false" :reorderEnabled="true" title="Watchlist" class="watchlist-height overflow-hidden">
              <Watchlist CompisWatchlistOpen='isWatchlistOpen' :CompWatchlistHeight="sendWatchlistHeight" v-if="isWatchlistOpen"/>
              <transition name="fade">
                <div class="loading full-page" v-if="!isWatchlistOpen">
                  <div><span></span><span></span><span></span><span></span></div>
                </div>
              </transition>
            </gl-component>
            <gl-component @resize="onResizeWatchlistHoldingTab('Holdings')" @show="onShowWatchlistHoldingTab('Holdings')" tab-id="Holdings" :closable="false" :reorderEnabled="true" title="Holdings" class="holdings-height holdings-width overflow-hidden" >
              <Holding CompisHoldingOpen='isHoldingOpen' :HoldingTableHeight="sendHoldingHeight" :HoldingTableWidth="sendHoldingWidth" v-if="isHoldingOpen"/>
              <transition name="fade">
                <div class="loading full-page" v-if="!isHoldingOpen">
                  <div><span></span><span></span><span></span><span></span></div>
                </div>
              </transition>
            </gl-component>
          </gl-stack>
          <!-- END:: Watchlist & Holding block tabs  -->
        </gl-col>
        <!-- END:: First column  -->
        <!-- START:: Second column  -->
        <gl-col :width="48">
          <!-- START:: Chart block tabs  -->
          <gl-stack class="123" v-model="openedChartTab">
            <gl-component @resize="onResizeChartTab('TradingChart')" @show="onShowChartTab('TradingChart')" tab-id="TradingChart" class="chart-height trade-chart-dimension chart-width overflow-hidden" :closable="false" :reorderEnabled="true" :title="security_name != '' ? security_name : ''">
              <TradingChart v-if="isTradingChartOpen" CompisTradingChartOpen='isTradingChartOpen' :TraddingChartHeight="traddingHeight" :TraddingChartWidth="traddingWidth" ChartOrigin="Dashboard"/>
            </gl-component>
            <gl-component @resize="onResizeChartTab('HoldingChart')" @show="onShowChartTab('HoldingChart')" tab-id="HoldingChart" class="dh-chart-height dh-chart-width overflow-hidden" :closable="false" :reorderEnabled="true" title="Holdings" :setActive="true">
              <HoldingChart v-if="isHoldingChartOpen" CompisHoldingChartOpen='isHoldingChartOpen' :CompHoldingChartHeight="sendDailyHoldingChartHeight" :CompHoldingChartWidth="sendDailyHoldingChartWidth"/>
            </gl-component>
            <gl-component @resize="onResizeChartTab('TransactionChart')" @show="onShowChartTab('TransactionChart')" tab-id="TransactionChart" class="transaction-chart-height transaction-chart-width overflow-hidden" :closable="false" :reorderEnabled="true" title="Transactions">
              <TransectionChart v-if="isTransectionChartOpen" CompisTransectionChartOpen='isTransectionChartOpen' :CompisLoadData="openedChartTab" :CompTransactionChartHeight="sendTransectionChartHeight" :CompTransactionChartWidth="sendTransectionChartWidth"/>
            </gl-component>
            <gl-component @resize="onResizeChartTab('PerformanceChart')" @show="onShowChartTab('PerformanceChart')" tab-id="PerformanceChart" class="p-chart-height p-chart-width overflow-hidden" :closable="false" :reorderEnabled="true" title="Performance" >
              <PerformanceChart v-if="isPerformanceChartOpen" CompisPerformanceChartOpen='isPerformanceChartOpen' :CompPerformanceChartHeight="sendPerformanceChartHeight" :CompPerformanceChartWidth="sendPerformanceChartWidth"/>
            </gl-component>
            <gl-component @resize="onResizeChartTab('CashFlowChart')" @show="onShowChartTab('CashFlowChart')" tab-id="CashFlowChart" class="cashflows-chart-height cashflows-chart-width overflow-hidden" :closable="false" :reorderEnabled="true" title="CashFlow">
              <CashFlowsChart v-if="isCashFlowsChartOpen" CompisCashFlowsChartOpen='isCashFlowsChartOpen' :CompCashflowsHeight="sendCashflowsHeight" :CompCashflowsWidth="sendCashflowsWidth"/>
            </gl-component>
          </gl-stack>
          <!-- END:: Chart block tabs  -->
          <!-- START:: transection,perfomance, cashflow, statement block tabs  -->
          <gl-stack v-model="openedTableListTab">
            <gl-component @resize="onResizeTableListTab('Transactions')" @show="onShowTableListTab('Transactions')" tab-id="Transactions" class="component-transactions-height component-transactions-width overflow-hidden" :closable="false" :reorderEnabled="true" title="Transactions">
              <Transactions v-if="isTransactionsOpen" CompisTransactionsOpen='isTransactionsOpen' :CompTransactionsHeight="sendTransactionsHeight" :CompTransactionsWidth="sendTransactionsWidth" @transactionsclickView="transactionsclickViewMethod" />
            </gl-component>
            <gl-component @resize="onResizeTableListTab('Performance')" @show="onShowTableListTab('Performance')" tab-id="Performance" class="component-performance-height component-performance-width overflow-hidden" :closable="false" :reorderEnabled="true" title="Performance">
              <Performance v-if="isPerformanceOpen" CompisPerformanceOpen='isPerformanceOpen' :CompPerformanceHeight="sendPerformanceHeight" :CompPerformanceWidth="sendPerformanceWidth" @performanceUnRealizeView="performanceUnRealizeViewMethod" @performanceRealizeView = "performanceRealizeViewMethod"/>
            </gl-component>
            <gl-component @resize="onResizeTableListTab('CashFlow')" @show="onShowTableListTab('CashFlow')" tab-id="CashFlow" class="component-cashflow-height component-cashflow-width overflow-hidden" :closable="false" :reorderEnabled="true" title="CashFlow">
              <Cashflow v-if="isCashflowOpen" CompisCashflowOpen='isCashflowOpen' :CompCashflowHeight="sendCashflowHeight" :CompCashflowWidth="sendCashflowWidth" @cashclickView="cashFlowclickViewMethod"/>
            </gl-component>
            <gl-component @resize="onResizeTableListTab('Statement')" @show="onShowTableListTab('Statement')" tab-id="Statements" class="component-statement-height component-statement-width overflow-hidden" :closable="false" :reorderEnabled="true" title="Statements">
              <Statements v-if="isStatementsOpen" CompisStatementsOpen='isStatementsOpen' :CompStatementHeight="sendStatementHeight" :CompStatementWidth="sendStatementWidth"/>
            </gl-component>
          </gl-stack>
          <!-- END:: transection,perfomance, cashflow, statement block tabs  -->
        </gl-col>
        <!-- END:: Second column  -->
        <!-- START:: Third column  -->
        <gl-col>
          <!-- START:: Data, News, Research block tabs  -->
          <gl-stack v-model="openedResearchTab">
            <gl-component @resize="onResizeDataResearchTab('SecurityDetails')" @show="onShowDataResearchTab('SecurityDetails')" tab-id="SecurityDetails" :closable="false" :reorderEnabled="true" title="Data" class="security-details-height none-border overflow-hidden" >
              <SecurityDetails v-if="isSecurityDetailsOpen" CompisSecurityDetailsOpen='isSecurityDetailsOpen' :CompSecurityDetailsHeight="sendSecurityDetailsHeight"/>
            </gl-component>
            <gl-component @resize="onResizeDataResearchTab('News')" @show="onShowDataResearchTab('News')" tab-id="News" :closable="false" :reorderEnabled="true" title="News" class="news-height none-border overflow-hidden">
              <News v-if="isNewsOpen" CompisNewsOpen='isNewsOpen' :CompNewsHeight="sendNewsHeight" :CompNewsWidth="sendNewsWidth"/>
            </gl-component>
            <gl-component @resize="onResizeDataResearchTab('Research')" @show="onShowDataResearchTab('Research')" tab-id="Research" :closable="false" :reorderEnabled="true" title="Research" class="research-height none-border overflow-hidden" >
              <Research v-if="isResearchOpen" CompisResearchOpen='isResearchOpen' :CompResearchHeight="sendResearchHeight" :CompResearchHeightMaxi="sendResearchHeightMaxi"/>
            </gl-component>
          </gl-stack>
          <!-- END:: Data, News, Research block tabs  -->
          <!-- START:: Notification and Chat block tabs  -->
          <gl-stack v-model="openedNotificationTab">
            <gl-component @resize="onResizeNotificationTab('Notification')" @show="onShowNotificationTab('Notification')" tab-id="Notification" :closable="false" :reorderEnabled="true" class="noti-height overflow-hidden" :title="noti_title">
              <Notification v-if="isNotificationOpen" CompisNotificationOpen='isNotificationOpen' :CompNotiHeight="sendNotHeight" />
            </gl-component>
            <gl-component @resize="onResizeNotificationTab('Chat')" @show="onShowNotificationTab('Chat')" title="Chat" tab-id="Chat" :closable="false" :reorderEnabled="true" class="chat-height overflow-hidden" >
              <Chat v-if="isChatOpen" CompisChatOpen='isChatOpen' :CompChatHeight="sendChatHeight" :CompChat2Height="sendChat2Height"/>
            </gl-component>
          </gl-stack>
          <!-- END:: Notification and Chat block tabs  -->
        </gl-col>
         <!-- END:: Third column  -->
      </gl-row>
      <!-- END:: row -->
      <!-- START:: Add Side bar window components -->
      <PerformanceRealizeDetail :PerformanceRealizeDetailProp="PerformanceRealizeData" v-model="isperformanceRealizeDetail" />
      <PerformanceUnRealizeDetail :PerformanceUnRealizeDetailProp="PerformanceUnRealizeData" v-model="isperformanceUnRealizeDetail"/>
      <CashflowDetail :CashflowDetailProp="CashflowDetailData" :isSecondaryOpenProp = "isSecondaryOpen" v-model="isCashflowDetail" @cashflowDetailclickView="cashflowDetailclickViewMethod"/>
      <CashflowSecondaryDetail :CashflowSecondaryDetailProp="CashflowSecondaryDetailData" @cashclickViewReturn="cashclickViewReturnRetrunMethod" v-model="isCashflowSecondaryDetail"/>
      <TransactionDetail :TransactionDetailProp="TransactionDetailData" v-model="isTransactionDetail"/>
      <!-- END:: Add Side bar window components -->
    </golden-layout>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Watchlist from "@/components/Watchlist.vue";
import TickerDetails from "@/components/TickerDetails.vue";
import Research from "@/components/Research.vue";
import News from "@/components/News.vue";
import Holding from "@/components/Holding.vue";
import Transactions from "@/components/Transactions.vue";
import Performance from "@/components/Performance.vue";
import Cashflow from "@/components/Cashflow.vue";
import CashflowDetail from "@/components/CashflowDetail.vue";
import CashflowSecondaryDetail from "@/components/CashflowSecondaryDetail.vue";
import PerformanceUnRealizeDetail from "@/components/PerformanceUnRealizeDetail.vue";
import PerformanceRealizeDetail from "@/components/PerformanceRealizeDetail.vue";
import TransactionDetail from "@/components/TransactionDetail.vue"
import Notification from "@/components/Notification.vue";
import TradingChart from "@/components/TradingChart.vue";
import PerformanceChart from "@/components/PerformanceChart.vue";
import HoldingChart from "@/components/HoldingChart.vue";
import CashFlowsChart from "@/components/CashFlowsChart.vue";
import TransectionChart from "@/components/TransectionChart.vue";
import Chat from "@/components/Chat.vue";
import Bonds from "@/components/Bonds.vue";
import Cash from "@/components/Cash.vue";
import Equities from "@/components/Equities.vue";
import Funds from "@/components/Funds.vue";
import SecurityDetails from "@/components/SecurityDetails.vue";
import Statements from "@/components/Statements.vue";
import axios from "@/axios";
import router from "@/router";
export default {
  name: "Dashboard",
  data() {
    return {
      isMountedCompleted:false,
      isTickerDetailsOpen:false,
      isCashOpen:false,
      isEquitiesOpen:false,
      isBondsOpen:false,
      isFundsOpen:false,
      isWatchlistOpen:false,
      isHoldingOpen:true,
      isTradingChartOpen:true,
      isHoldingChartOpen:false,
      isTransectionChartOpen:false,
      isPerformanceChartOpen:false,
      isCashFlowsChartOpen:false,
      isTransactionsOpen:true,
      isPerformanceOpen:false,
      isCashflowOpen:false,
      isStatementsOpen:false,
      isSecurityDetailsOpen:false,
      isNewsOpen:false,
      isResearchOpen:true,
      isNotificationOpen:true,
      isChatOpen:false,

      isSecondaryOpen:false,
      isperformanceRealizeDetail : true,
      PerformanceRealizeData : [],
      isperformanceUnRealizeDetail:false,
      PerformanceUnRealizeData:[],
      CashflowDetailData:[],
      CashflowSecondaryDetailData:[],
      isCashflowSecondaryDetail:false,
      isCashflowDetail:false,
      TransactionDetailData:[],
      isTransactionDetail:false,
      openedOrderTab: "",
      openedChartTab: "TradingChart",
      openedTableListTab: "Transactions",
      openedHoldingTab: "Watchlist",
      openedResearchTab: "Research",
      openedNotificationTab: "Notification",
      componentTransactionsHeight: 0,
      componentTransactionsWidth: 0,
      componentPerformanceHeight: 0,
      componentPerformanceWidth: 0,
      componentCashflowHeight: 0,
      componentCashflowWidth: 0,
      sendTransactionsHeight: 0,
      sendTransactionsWidth: 0,
      sendPerformanceHeight: 0,
      sendPerformanceWidth: 0,
      sendStatementHeight: 0,
      sendStatementWidth: 0,
      componentStatementHeight: 0,
      componentStatementWidth: 0,
      sendCashflowHeight: 0,
      sendCashflowWidth: 0,
      componentOrderEntryHeight: 0,
      sendOrderEntryHeight: 0,
      componentBondsHeight: 0,
      sendBondsHeight: 0,
      componentCashHeight: 0,
      sendCashHeight: 0,
      componentEquitiesHeight: 0,
      sendEquitiesHeight: 0,
      componentFundsHeight: 0,
      sendFundsHeight: 0,
      chartWidth: 0,
      chartHeight: 0,
      sendHeight: 0,
      traddingHeight: 0,
      traddingWidth: 0,
      sendHoldingHeight: 0,
      sendHoldingWidth: 0,
      holdingComponentHeight: 0,
      holdingComponentWidth: 0,
      sendNotHeight: 0,
      sendChatHeight: 0,
      sendChat2Height: 0,
      notificationHeight: 0,
      chatHeight: 0,
      sendResearchHeight: 0,
      sendResearchHeightMaxi: 0,
      researchHeight: 0,
      WatchlistHeight: 0,
      sendWatchlistHeight: 0,
      newsHeight: 0,
      securityDetailsHeight: 0,
      sendNewsHeight: 0,
      sendSecurityDetailsHeight: 0,
      sendNewsWidth: 0,
      portfolioList: [],
      noti_count: 0,
      noti_title: "Notifications",
      performanceChartWidth: 0,
      performanceChartHeight: 0,
      sendPerformanceChartHeight: 0,
      sendPerformanceChartWidth: 0,
      dailyHoldingChartWidth: 0,
      dailyHoldingChartHeight: 0,
      sendDailyHoldingChartHeight: 0,
      sendDailyHoldingChartWidth: 0,
      isVisibleChart: 0,
      regenerateTradeChart: 0,
      cashflowsChartWidth: 0,
      cashflowsChartHeight: 0,
      sendCashflowsHeight: 0,
      sendCashflowsWidth: 0,
      transactionChartWidth: 0,
      transactionChartHeight: 0,
      sendTransectionChartHeight: 0,
      sendTransectionChartWidth: 0,
      isHoldingChart: false,
      isDuplicateChart: true,
      hasmaximiseClass: 0,
      tl_active_tab: "", // Top left section active tab
      bl_active_tab: "", // Bottom left section active tab
      tl_security_list: [], // Top left section security list
      bl_security_list: [], // Bottom left section security list
      main_security_list: [],
      req_reverse: [
        16,
        314,
        315,
        2799,
        2800,
        2804,
        2806,
        2808,
        2822,
        2824,
        8807,
        8820,
        58772,
        58773,
        58774,
        58775,
        58776,
      ],
      cashSSE: null,
      stockSSE: null,
      cashSymbolList:[],
      stockSymbolList:[],
      stockSymbolListMax:[],
      maximizeTabName: null,
      isIexStreamStatus: false,
      forexendpoint: 'forex1Second',
      stockendpoint: 'stocksUSNoUTP1Second',
      forexEndpointName: 'FOREX_QUOTE_SSE',
      stockEndpointName: 'STOCKS_US_NO_UTP_SSE',
      iex_token: "pk_058040fbe46c4e11b4af4740e274d694",
    };
  },
  sockets: {
    connect: function() {
      this.joinUserSocket();
    },
  },
  computed: {
    filter_portfolios() {
      var selected = new Array();
      var portfolios = document.getElementById("portfolio-selection");
      var chks = portfolios.getElementsByTagName("INPUT");
      for (var i = 0; i < chks.length; i++) {
        selected.push(chks[i].value);
      }
      return selected;
    },
    items() {
      return this.$store.getters["notification/getItems"];
    },
    isVisibleNews: {
      get() {
        return this.$store.getters["dashboard/getIsVisibleNews"];
      },
    },
    isVisibleOrderEntry: {
      get() {
        return this.$store.getters["dashboard/getIsVisibleOrderEntry"];
      },
    },
    security_id: {
      get() {
        return this.$store.getters["chart/getSecurityId"];
      },
    },
    security_name() {
      return this.$store.getters["chart/getName"];
    },
    is_history: {
      get() {
        return this.$store.getters["daily_holding_chart/getIsHistory"];
      },
    },
    active_tab: {
      get() {
        return this.$store.getters["dashboard/getActiveTab"];
      },
    },
    bl_active_tab1: {
      get() {
        return this.$store.getters["dashboard/getBLActiveTab"];
      },
    },
    cash_items() {
      let items = this.$store.getters["cash/getItems"];
      return items;
    },
    stockItems() {
      let items = this.$store.getters["equities/getItems"];
      return items;
    },
    con_id: {
      get() {
        return this.$store.getters["chart/getConId"];
      },
    },
    holding_amount: {
      get() {
        var holding_data = this.$store.getters["holding/getHoldings"];
        var total_amount = holding_data.total_amount;
        return total_amount;
      },
    },
    holding_data: {
      get() {
        return this.$store.getters["holding/getHoldings"];
      },
    },
    market_id() {
      return this.$store.getters["chart/getMarketId"];
    },
    watchlistItems() {
      let items = this.$store.getters["watchList/getItems"];
      return items;
    },
    holdingItems() {
      let items = this.$store.getters["holding/getItems"];
      return items;
    },
    filterCurrency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
    filterCurrencyRate() {
      return this.$store.getters["dashboard/getFilterCurrencyRate"];
    },
    filterCurrencyUSDRate() {
      return this.$store.getters["dashboard/getFilterCurrencyUSDRate"];
    },
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
    default_security_set() {
      return this.$store.getters["dashboard/getDefaultSecuritySet"];
    },
  },

  async mounted() {

    if(process.env.VUE_APP_MODE === 'production'){
      // this.isIexStreamStatus = true;
    }

    //this.checkAccess();
    await this.getProfiles();
    await this.getSettings();
    await this.getDefaultSecurity();
    await this.sockets.subscribe(
      "new-notification-channel:App\\Events\\NotificationEvent",
      (data) => {
        for (var i in data) {
          let messages = data[i];
          for (var j in messages) {
            if (j == "-1" && messages[j].message == "transactions") {
              this.$store.dispatch("dashboard/updateTransactionList", "1");
            } else if (this.filter_portfolios.includes(j)) {
              let newMessage = messages[j].message;
              this.noti_count = 1;
              this.noti_title =
                "Notifications <span class='badge-counter'>" +
                this.noti_count +
                "</span>";
              this.loading = true;
              this.$store.dispatch("notification/fetchItems", {
                page: 1,
              });
              this.notificationAlert(newMessage);
              this.loading = false;
            }
          }
        }
      }
    );

    const channel = localStorage.getItem("stream-channel");
    this.sockets.subscribe(channel, (data) => {
      if (this.bl_active_tab == "Watchlist") {
        let currentIndex = this.watchlistItems.findIndex(
          (security) => security.id == data.security_id
        );
        if (currentIndex > -1) {
          let security = this.watchlistItems[currentIndex];

          if (security) {
            security.last_price = data.price;
            //security.bid_size = data.bidSize;
            security.bid_price = data.bid;
            //security.ask_size = data.askSize;
            security.ask_price = data.ask;
            let spread = data.ask - data.bid;
            if (security.market_id == 6) {
              spread = spread * 10000;
            }
            security.spread = spread.toFixed(2);

            if (data.change != undefined) {
              security.change = data.change;
            }
            if (data.changePercent != undefined) {
              security.change_percent = data.changePercent;
            }
            if (data.high != undefined && data.high > 0) {
              security.high_price = data.high;
            }
            if (data.low != undefined && data.low > 0) {
              security.low_price = data.low;
            }

            this.$store.dispatch("watchList/updateItems", this.watchlistItems);
          }
        }
      }
      else if (this.bl_active_tab == "Holdings") {
        this.calculateHoldings(data,false);
      }
    });

    this.sockets.subscribe('ibstreamstatus', (data) => {
      this.isIexStreamStatus = !data;
    });
    setInterval(() => {
      if (!this.$socket.connected) {
        this.$socket.connect();
      }
    }, 1500);
    this.isMountedCompleted = true; 
    if(this.isMountedCompleted){
      this.onShowOrderTab('TickerDetails');
    }
  },
  watch: {
    WatchlistHeight(val) {
      if (val > 0) {
        this.sendWatchlistHeight = val;
      }
    },
    componentTransactionsHeight(val) {
      if (val > 0) {
        this.sendTransactionsHeight = val;
      }
    },
    componentTransactionsWidth(val) {
      if (val > 0) {
        this.sendTransactionsWidth = val;
      }
    },
    componentPerformanceHeight(val) {
      if (val > 0) {
        this.sendPerformanceHeight = val;
      }
    },
    componentPerformanceWidth(val) {
      if (val > 0) {
        this.sendPerformanceWidth = val;
      }
    },
    componentStatementHeight(val) {
      if (val > 0) {
        this.sendStatementHeight = val;
      }
    },
    componentStatementWidth(val) {
      if (val > 0) {
        this.sendPerformanc1eWidth = val;
      }
    },
    componentCashflowHeight(val) {
      if (val > 0) {
        this.sendCashflowHeight = val;
      }
    },
    componentCashflowWidth(val) {
      if (val > 0) {
        this.sendCashflowWidth = val;
      }
    },
    componentOrderEntryHeight(val) {
      this.sendOrderEntryHeight = val - 34;
    },
    componentBondsHeight(val) {
      this.sendBondsHeight = val;
    },
    componentCashHeight(val) {
      this.sendCashHeight = val;
    },
    componentEquitiesHeight(val) {
      this.sendEquitiesHeight = val;
    },
    componentFundsHeight(val) {
      this.sendFundsHeight = val;
    },
    chartWidth(val) {
      this.traddingWidth = val;
    },
    chartHeight(val) {
      this.traddingHeight = val - 20;
    },
    holdingComponentHeight(val) {
      this.sendHoldingHeight = val - 1;
    },
    holdingComponentWidth(val) {
      this.sendHoldingWidth = val - 1;
    },
    notificationHeight(val) {
      this.sendNotHeight = val;
    },
    chatHeight(val) {
      this.sendChatHeight = val - 34;
      this.sendChat2Height = val;
    },
    researchHeight(val) {
      this.sendResearchHeight = val - 34;
      this.sendResearchHeightMaxi = val;
    },
    newsHeight(val) {
      this.sendNewsHeight = val - 34;
    },
    securityDetailsHeight(val) {
      this.sendSecurityDetailsHeight = val;
    },
    security_id() {
      this.openedChartTab = "TradingChart";
      this.openedResearchTab = "SecurityDetails";
    },
    isVisibleOrderEntry(new_val, old_val) {
      if (new_val == 1 && old_val == 0) {
        this.openedOrderTab = "TickerDetails";
      }
      if (new_val == 1 && old_val == 1) {
        this.openedOrderTab = "TickerDetails";
      }
    },
    performanceChartWidth(val) {
      this.sendPerformanceChartWidth = val;
    },
    performanceChartHeight(val) {
      this.sendPerformanceChartHeight = val - 20;
    },
    dailyHoldingChartWidth(val) {
      this.sendDailyHoldingChartWidth = val;
    },
    dailyHoldingChartHeight(val) {
      this.sendDailyHoldingChartHeight = val - 20;
    },
    cashflowsChartWidth(val) {
      this.sendCashflowsWidth = val;
    },
    cashflowsChartHeight(val) {
      this.sendCashflowsHeight = val - 20;
    },
    transactionChartWidth(val) {
      this.sendTransectionChartWidth = val;
    },
    transactionChartHeight(val) {
      this.sendTransectionChartHeight = val - 20;
    },
    active_tab() {
      this.openedOrderTab = this.active_tab;
    },
    async hasmaximiseClass(val) {
      if (val > 0) {
        if (this.active_tab == "TickerDetails") {
          if (this.market_id == 1) {
            this.openedOrderTab = "Equities";
          } else if (this.market_id == 6) {
            this.openedOrderTab = "Currencies";
          } else if (this.market_id == 5) {
            this.openedOrderTab = "Bonds";
          } else if (this.market_id == 8) {
            this.openedOrderTab = "Funds";
          }
          let set_tab_name="";
          await this.$store.dispatch("dashboard/updateBLActiveTab", set_tab_name);
        }else{
          let set_tab_name="";
          await this.$store.dispatch("dashboard/updateActiveTab", set_tab_name);
        }
        await this.$store.dispatch("dashboard/updateIsMaximizeList", 1);
      } else {
        await this.$store.dispatch("dashboard/updateIsMaximizeList", 0);
      }
    },
    holding_amount(val) {
      if (val > 0) {
        this.openedHoldingTab = "Holdings";
      }
    },
    tl_security_list(val) {
      this.main_security_list = [...val, ...this.bl_security_list];
    },
    bl_security_list(val) {
      this.main_security_list = [...val, ...this.tl_security_list];
    },
    default_security_set(val) {
      let user_id = localStorage.getItem("login-id");
      val.user_id = user_id;
      this.main_security_list = [...[val], ...this.main_security_list];
    },
    main_security_list(val) {
      if (val.length > 0) {
        let subsecinterval = setTimeout(async () => {
          if (this.$socket.connected) {
            this.joinUserSocket();
            clearInterval(subsecinterval);
          }
        }, 1000);
      }
    },
    isIexStreamStatus(val){
      if(val === true){
        // activate iex stream
        this.connectSSEForex(this.cashSymbolList,this.handleSSEForex);
        this.connectSSEStock(this.stockSymbolList,this.handleSSEStock);
      }
      else{
        // deactivate iex stream
        this.disConnectSSEForex();
        this.disConnectSSEStock();
      }
    },
    cashSymbolList(val){
      if(this.isIexStreamStatus && val != undefined && val != null && val != ''){
        this.connectSSEForex(val,this.handleSSEForex);
      }
      else{
        this.disConnectSSEForex();
      }
    },
    stockSymbolList(val){
      if(this.isIexStreamStatus && val != undefined && val != null && val != ''){
        this.connectSSEStock(val,this.handleSSEStock);
      }
      else{
        this.disConnectSSEStock();
      }
    },
    // isTickerDetailsOpen(val){
    //   console.log("order isTickerDetailsOpen =>",val);
    // },
    // isCashOpen(val){
    //   console.log("order isCashOpen =>",val);
    // }
  },
  components: {
    Header, Watchlist, TickerDetails, Research, News, Holding, Transactions, Performance, PerformanceUnRealizeDetail, PerformanceRealizeDetail,
    Cashflow, CashflowDetail, CashflowSecondaryDetail,TransactionDetail, TradingChart, Notification, PerformanceChart, HoldingChart, CashFlowsChart,
    TransectionChart, Chat, Bonds, Cash, Equities, Funds, SecurityDetails, Statements,
  },
  methods: {
    onResizeOrderTab(tab_name){
      // console.log("onResizeOrderTab =>",tab_name);
      this.onResize(tab_name);
    },
    onShowOrderTab(tab_name){
      // console.log("onShowOrderTab =>",tab_name);
      if(this.isMountedCompleted){
        // console.log("order isMountedCompleted =>",this.isMountedCompleted);
        
        this.isTickerDetailsOpen = (tab_name == 'TickerDetails') ?true:false;
        this.isCashOpen = (tab_name == 'Currencies') ?true:false;
        this.isEquitiesOpen = (tab_name == 'Equities') ?true:false;
        this.isBondsOpen = (tab_name == 'Bonds') ?true:false;
        this.isFundsOpen = (tab_name == 'Funds') ?true:false;
        this.onResize2(tab_name);
      }
    },
    onResizeWatchlistHoldingTab(tab_name){
      // console.log("onResizeWatchlistHoldingTab =>",tab_name);
      this.onResize(tab_name);
    },
    onShowWatchlistHoldingTab(tab_name){
      // console.log("onShowWatchlistHoldingTab =>",tab_name);
      if(this.isMountedCompleted){
        // console.log("order isMountedCompleted =>",this.isMountedCompleted);
        
        this.isWatchlistOpen = (tab_name == 'Watchlist') ?true:false;
        this.isHoldingOpen = (tab_name == 'Holdings') ?true:false;
        this.onResize2(tab_name);
      }
    },
    onResizeChartTab(tab_name){
      // console.log("onResizeChartTab =>",tab_name);
      this.onResize(tab_name);
    },
    onShowChartTab(tab_name){
      // console.log("onShowChartTab =>",tab_name);
      if(this.isMountedCompleted){
        // console.log("order isMountedCompleted =>",this.isMountedCompleted);

        this.isTradingChartOpen = (tab_name == 'TradingChart') ?true:false;
        this.isHoldingChartOpen = (tab_name == 'HoldingChart') ?true:false;
        this.isTransectionChartOpen = (tab_name == 'TransactionChart') ?true:false;
        this.isPerformanceChartOpen = (tab_name == 'PerformanceChart') ?true:false;
        this.isCashFlowsChartOpen = (tab_name == 'CashFlowChart') ?true:false;
        if (tab_name=="TransactionChart"){
          this.openedTableListTab = 'Transactions';
        }
        if (tab_name=="PerformanceChart"){
          this.openedTableListTab = 'Performance';
        }
        if (tab_name=="CashFlowChart"){
          this.openedTableListTab = 'CashFlow';
        } 
        this.onResize2(tab_name);
      }
    },
    onResizeTableListTab(tab_name){
      // console.log("onResizeTableListTab =>",tab_name);
      this.onResize(tab_name);
    },
    onShowTableListTab(tab_name){
      // console.log("onShowTableListTab =>",tab_name);
      if(this.isMountedCompleted){
        // console.log("order isMountedCompleted =>",this.isMountedCompleted);
        this.isTransactionsOpen = (tab_name == 'Transactions') ?true:false;
        this.isPerformanceOpen = (tab_name == 'Performance') ?true:false;
        this.isCashflowOpen = (tab_name == 'CashFlow') ?true:false;
        this.isStatementsOpen = (tab_name == 'Statement') ?true:false;
        if (tab_name=="Transactions"){
          this.openedChartTab = 'TransactionChart';
        }
        if (tab_name=="Performance"){
          this.openedChartTab = 'PerformanceChart';
        }
        if (tab_name=="CashFlow"){
          this.openedChartTab = 'CashFlowChart';
        }
        this.displayHideKillButton(tab_name);
      }
    },
    onResizeDataResearchTab(tab_name){
      // console.log("onResizeDataResearchTab =>",tab_name);
      this.onResize(tab_name);
    },
    onShowDataResearchTab(tab_name){
      //  console.log("onShowDataResearchTab =>",tab_name);
      if(this.isMountedCompleted){
        // console.log("order isMountedCompleted =>",this.isMountedCompleted);
        this.isSecurityDetailsOpen = (tab_name == 'SecurityDetails') ?true:false;
        this.isNewsOpen = (tab_name == 'News') ?true:false;
        this.isResearchOpen = (tab_name == 'Research') ?true:false;
        this.onResize2(tab_name);
      }
    },
    onResizeNotificationTab(tab_name){
      // console.log("onResizeNotificationTab =>",tab_name);
      this.onResize(tab_name);
    },
    onShowNotificationTab(tab_name){
      // console.log("onShowNotificationTab =>",tab_name);
      if(this.isMountedCompleted){
        // console.log("order isMountedCompleted =>",this.isMountedCompleted);
        this.isNotificationOpen = (tab_name == 'Notification') ?true:false;
        this.isChatOpen = (tab_name == 'Chat') ?true:false;
        this.onResize2(tab_name);
      }
    },
    performanceRealizeViewMethod(params){
      this.isperformanceRealizeDetail = true;
      this.PerformanceRealizeData = params;
    },
    performanceUnRealizeViewMethod(params){
      this.isperformanceUnRealizeDetail = true;
      this.PerformanceUnRealizeData = params;
    },
    cashclickViewReturnRetrunMethod(){
      this.isCashflowDetail = true;
      this.isSecondaryOpen = false
    },
    cashFlowclickViewMethod(params){
      this.isCashflowDetail = true;
      this.CashflowDetailData = params;
    },
    cashflowDetailclickViewMethod(params){
      this.isCashflowSecondaryDetail = true;
      this.isSecondaryOpen = true;
      this.CashflowSecondaryDetailData = params;
    },
    transactionsclickViewMethod(params){
      this.isTransactionDetail = true;
      this.TransactionDetailData = params;
    },
    checkAccess() {
      let interval = setInterval(() => {
        axios
          .get("/chk-access")
          .then((response) => {
            console.log("Check Access", response);
          })
          .catch((error) => {
            if (
              error.response.status == 401 &&
              error.data.message != undefined &&
              error.data.message == "Access token expired"
            ) {
              clearInterval(interval);
              localStorage.removeItem("user-token");
              router.push("/login");
            }
          });
      }, 10000);
    },
    displayHideKillButton(current_tab) {
      this.showRelativetab(current_tab);
    },
    showRelativetab(current_tab) {
      let btn = document.querySelector(".date-range-box");
      if (current_tab == "Performance") {
        if (btn) {
          btn.style.visibility = "visible";
        }
      } else {
        if (btn) {
          btn.style.visibility = "hidden";
        }
      }
    },
    async notificationAlert(message) {
      this.$swal({
        title: "",
        showCancelButton: false,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        text: message,
      });
      setTimeout(() => {
        this.noti_count = 0;
        this.noti_title = "Notifications ";
      }, 4000);
    },
    currencyList() {
      
    },
    async onResize2(tab_name) {
      // console.log("resize2 tab_name",tab_name);
      // if( tab_name== 'TradingChart'){
      //   setTimeout(async () => {
      //     this.openedChartTab = 'TradingChart';
      //     // this.isTradingChartOpen = true;
      //   }, 1000);
      // }
      let equities_clear_btn = document.querySelector("#equities-clear-btn");
      if(equities_clear_btn){
        document.getElementById("equities-clear-btn").style.visibility = "hidden";
      }
      let bonds_clear_btn = document.querySelector("#bonds-clear-btn");
      if(bonds_clear_btn){
        document.getElementById("bonds-clear-btn").style.visibility = "hidden";
      }
      let funds_clear_btn = document.querySelector("#funds-clear-btn");
      if(funds_clear_btn){
        document.getElementById("funds-clear-btn").style.visibility = "hidden";
      }
      let cash_clear_btn = document.querySelector("#cash-clear-btn");
      if(cash_clear_btn){
        document.getElementById("cash-clear-btn").style.visibility = "hidden";
      }
      let grid_btn = document.querySelector("#grid-change-btn");
      if (tab_name == "Currencies" && grid_btn && cash_clear_btn) {
        document.getElementById("grid-change-btn").style.visibility = "visible";
        document.getElementById("cash-clear-btn").style.visibility = "visible";
      }else{
        if(grid_btn){
          document.getElementById("grid-change-btn").style.visibility = "hidden";
        }
      }
      if (tab_name == "Equities" && equities_clear_btn) {
        document.getElementById("equities-clear-btn").style.visibility =
          "visible";
      }
      if (tab_name == "Bonds" && bonds_clear_btn) {
        document.getElementById("bonds-clear-btn").style.visibility = "visible";
      }
      if (tab_name == "Funds" && funds_clear_btn) {
        document.getElementById("funds-clear-btn").style.visibility = "visible";
      }

      let tabs = ['TickerDetails','Currencies','Equities','Bonds','Funds'];
      if (tabs.includes(tab_name)) {
        await this.$store.dispatch("dashboard/updateActiveTab", tab_name);
        await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 0);
        if (this.isDuplicateChart && tab_name != "Funds") {
          this.isDuplicateChart = false;
          await this.generateTabWiseSecurtyLIst(tab_name);
        }
        this.tl_active_tab = tab_name;
      }
      else if (tab_name == "Watchlist" || tab_name == "Holdings") {
        await this.$store.dispatch("dashboard/updateBLActiveTab", tab_name);
        this.bl_active_tab = tab_name;
        await this.generateTabWiseSecurtyLIst(tab_name);
      }

      tabs = ['TickerDetails','Currencies','Equities','Bonds','Funds','Holdings','Watchlist'];
      if(tabs.includes(tab_name)){
        // prepare tab wise data list
        this.generateTabWiseSymbols(tab_name,0);
      }
      tabs = ['Research','SecurityDetails','News'];
      if(tabs.includes(tab_name)){
        this.openedResearchTab = tab_name;
      }
      
      setTimeout(async () => {
        let dailyholdingLetHeight = document.querySelector(".dh-chart-height");
        if (dailyholdingLetHeight) {
          this.dailyHoldingChartHeight = dailyholdingLetHeight.clientHeight;
        }
        let performanceLetHeight = document.querySelector(".component-performance-height");
        if (performanceLetHeight) {
          this.componentPerformanceHeight = performanceLetHeight.clientHeight;
        }
        let performanceLetWidth = document.querySelector(".component-performance-width");
        if (performanceLetWidth) {
          this.componentPerformanceWidth = performanceLetWidth.clientWidth;
        }
        let statementLetHeight = document.querySelector(".component-statement-height");
        if (statementLetHeight) {
          this.componentStatementHeight = statementLetHeight.clientHeight;
        }
        let statementLetWidth = document.querySelector(".component-statement-width");
        if (statementLetWidth) {
          this.componentStatementWidth = statementLetWidth.clientWidth;
        }
        let transactionsLetHeight = document.querySelector(".component-transactions-height");
        if (transactionsLetHeight) {
          this.componentTransactionsHeight = transactionsLetHeight.clientHeight;
        }
        let transactionsLetWidth = document.querySelector(".component-transactions-width");
        if (transactionsLetWidth) {
          this.componentTransactionsWidth = transactionsLetWidth.clientWidth;
        }
        let cashflowLetHeight = document.querySelector(".component-cashflow-height");
        if (cashflowLetHeight) {
          this.componentCashflowHeight = cashflowLetHeight.clientHeight;
        }
        let cashflowLetWidth = document.querySelector(".component-cashflow-width");
        if (cashflowLetWidth) {
          this.componentCashflowWidth = cashflowLetWidth.clientWidth;
        }
        let watchlistLetHeight = document.querySelector(".watchlist-height");
        if (watchlistLetHeight) {
          this.WatchlistHeight = watchlistLetHeight.clientHeight;
        }
        let orderEntryLetHeight = document.querySelector(".order-entry-height");
        if (orderEntryLetHeight) {
          this.componentOrderEntryHeight = orderEntryLetHeight.clientHeight;
        }
        let chartLetHeight = document.querySelector(".trade-chart-dimension");
        if (chartLetHeight) {
          this.chartHeight = chartLetHeight.clientHeight;
        }
        let chartLetWidth = document.querySelector(".trade-chart-dimension");
        if (chartLetWidth) {
          this.chartWidth = chartLetWidth.clientWidth;
        }
        let notiLetHeight = document.querySelector(".noti-height");
        if (notiLetHeight) {
          this.notificationHeight = notiLetHeight.clientHeight;
        }
        let chatLetHeight = document.querySelector(".chat-height");
        if (chatLetHeight) {
          this.chatHeight = chatLetHeight.clientHeight;
        }
        let holdingLetHeight = document.querySelector(".holdings-height");
        if (holdingLetHeight) {
          this.holdingComponentHeight = holdingLetHeight.clientHeight;
        }
        let holdingLetWidth = document.querySelector(".holdings-width");
        if (holdingLetWidth) {
          this.holdingComponentWidth = holdingLetWidth.clientWidth;
        }
        let researchLetHeight = document.querySelector(".research-height");
        if (researchLetHeight) {
          this.researchHeight = researchLetHeight.clientHeight;
        }
        let newsLetHeight = document.querySelector(".news-height");
        if (newsLetHeight) {
          this.newsHeight = newsLetHeight.clientHeight;
        }
        let SecurityDetailsLetHeight = document.querySelector(".security-details-height");
        if (SecurityDetailsLetHeight) {
          this.securityDetailsHeight = SecurityDetailsLetHeight.clientHeight;
        }
        let performancechartLetHeight = document.querySelector(".p-chart-height");
        if (performancechartLetHeight) {
          this.performanceChartHeight = performancechartLetHeight.clientHeight;
        }
        let performancechartLetWidth = document.querySelector(".p-chart-width");
        if (performancechartLetWidth) {
          this.performanceChartWidth = performancechartLetWidth.clientWidth;
        }
        let dailyholdingchartLetHeight = document.querySelector(".dh-chart-height");
        if (dailyholdingchartLetHeight) {
          this.dailyHoldingChartHeight = dailyholdingchartLetHeight.clientHeight;
        }
        let dailyholdingchartLetWidth = document.querySelector(".dh-chart-width");
        if (dailyholdingchartLetWidth) {
          this.dailyHoldingChartWidth = dailyholdingchartLetWidth.clientWidth;
        }
        let cashflowschartLetHeight = document.querySelector(".cashflows-chart-height");
        if (cashflowschartLetHeight) {
          this.cashflowsChartHeight = cashflowschartLetHeight.clientHeight;
        }
        let cashflowschartLetWidth = document.querySelector(".cashflows-chart-width");
        if (cashflowschartLetWidth) {
          this.cashflowsChartWidth = cashflowschartLetWidth.clientWidth;
        }
        let transactionchartLetWidth = document.querySelector(".transaction-chart-width");
        if (transactionchartLetWidth) {
          this.transactionChartWidth = transactionchartLetWidth.clientWidth;
        }
        let transactionchartLetHeight = document.querySelector(".transaction-chart-height");
        if (transactionchartLetHeight) {
          this.transactionChartHeight = transactionchartLetHeight.clientHeight;
        }
        let bondsLetHeight = document.querySelector(".bonds-height");
        if (bondsLetHeight) {
          this.componentBondsHeight = bondsLetHeight.clientHeight;
        }
        let cashLetHeight = document.querySelector(".cash-height");
        if (cashLetHeight) {
          this.componentCashHeight = cashLetHeight.clientHeight;
        }
        let equitiesLetHeight = document.querySelector(".equities-height");
        if (equitiesLetHeight) {
          this.componentEquitiesHeight = equitiesLetHeight.clientHeight;
        }
        let fundsLetHeight = document.querySelector(".funds-height");
        if (fundsLetHeight) {
          this.componentFundsHeight = fundsLetHeight.clientHeight;
        }
        let notLetHeight = document.querySelector(".noti-height");
        if (notLetHeight) {
          this.notificationHeight = notLetHeight.clientHeight;
        }
      }, 5);
    },
    async onResize(tab_name) {
      let dailyholdingLetHeight = document.querySelector(".dh-chart-height");
      if (dailyholdingLetHeight) {
        this.dailyHoldingChartHeight = dailyholdingLetHeight.clientHeight;
      }
      let performanceLetHeight = document.querySelector(".component-performance-height");
      if (performanceLetHeight) {
        this.componentPerformanceHeight = performanceLetHeight.clientHeight;
      }
      let performanceLetWidth = document.querySelector(".component-performance-width");
      if (performanceLetWidth) {
        this.componentPerformanceWidth = performanceLetWidth.clientWidth;
      }
      let statementLetHeight = document.querySelector(".component-statement-height");
      if (statementLetHeight) {
        this.componentStatementHeight = statementLetHeight.clientHeight;
      }
      let statementLetWidth = document.querySelector(".component-statement-width");
      if (statementLetWidth) {
        this.componentStatementWidth = statementLetWidth.clientWidth;
      }
      let transactionsLetHeight = document.querySelector(".component-transactions-height");
      if (transactionsLetHeight) {
        this.componentTransactionsHeight = transactionsLetHeight.clientHeight;
      }
      let transactionsLetWidth = document.querySelector(".component-transactions-width");
      if (transactionsLetWidth) {
        this.componentTransactionsWidth = transactionsLetWidth.clientWidth;
      }

      let cashflowLetHeight = document.querySelector(".component-cashflow-height");
      if (cashflowLetHeight) {
        this.componentCashflowHeight = cashflowLetHeight.clientHeight;
      }
      let cashflowLetWidth = document.querySelector(".component-cashflow-width");
      if (cashflowLetWidth) {
        this.componentCashflowWidth = cashflowLetWidth.clientWidth;
      }
      let watchlistLetHeight = document.querySelector(".watchlist-height");
      if (watchlistLetHeight) {
        this.WatchlistHeight = watchlistLetHeight.clientHeight;
      }
      let orderEntryLetHeight = document.querySelector(".order-entry-height");
      if (orderEntryLetHeight) {
        this.componentOrderEntryHeight = orderEntryLetHeight.clientHeight;
      }
      let chartLetHeight = document.querySelector(".trade-chart-dimension");
      if (chartLetHeight) {
        this.chartHeight = chartLetHeight.clientHeight;
      }
      let chartLetWidth = document.querySelector(".trade-chart-dimension");
      if (chartLetWidth) {
        this.chartWidth = chartLetWidth.clientWidth;
      }
      let notiLetHeight = document.querySelector(".noti-height");
      if (notiLetHeight) {
        this.notificationHeight = notiLetHeight.clientHeight;
      }
      let chatLetHeight = document.querySelector(".chat-height");
      if (chatLetHeight) {
        this.chatHeight = chatLetHeight.clientHeight;
      }
      let holdingLetHeight = document.querySelector(".holdings-height");
      if (holdingLetHeight) {
        this.holdingComponentHeight = holdingLetHeight.clientHeight;
      }
      let holdingLetWidth = document.querySelector(".holdings-width");
      if (holdingLetWidth) {
        this.holdingComponentWidth = holdingLetWidth.clientWidth;
      }
      let researchLetHeight = document.querySelector(".research-height");
      if (researchLetHeight) {
        this.researchHeight = researchLetHeight.clientHeight;
      }
      let newsLetHeight = document.querySelector(".news-height");
      if (newsLetHeight) {
        this.newsHeight = newsLetHeight.clientHeight;
      }
      let SecurityDetailsLetHeight = document.querySelector(".security-details-height");
      if (SecurityDetailsLetHeight) {
        this.securityDetailsHeight = SecurityDetailsLetHeight.clientHeight;
      }
      let performancechartLetHeight = document.querySelector(".p-chart-height");
      if (performancechartLetHeight) {
        this.performanceChartHeight = performancechartLetHeight.clientHeight;
      }
      let performancechartLetWidth = document.querySelector(".p-chart-width");
      if (performancechartLetWidth) {
        this.performanceChartWidth = performancechartLetWidth.clientWidth;
      }
      let dailyholdingchartLetHeight = document.querySelector(".dh-chart-height");
      if (dailyholdingchartLetHeight) {
        this.dailyHoldingChartHeight = dailyholdingchartLetHeight.clientHeight;
      }
      let dailyholdingchartLetWidth = document.querySelector(".dh-chart-width");
      if (dailyholdingchartLetWidth) {
        this.dailyHoldingChartWidth = dailyholdingchartLetWidth.clientWidth;
      }
      let cashflowschartLetHeight = document.querySelector(".cashflows-chart-height");
      if (cashflowschartLetHeight) {
        this.cashflowsChartHeight = cashflowschartLetHeight.clientHeight;
      }
      let cashflowschartLetWidth = document.querySelector(".cashflows-chart-width");
      if (cashflowschartLetWidth) {
        this.cashflowsChartWidth = cashflowschartLetWidth.clientWidth;
      }
      let transactionchartLetWidth = document.querySelector(".transaction-chart-width");
      if (transactionchartLetWidth) {
        this.transactionChartWidth = transactionchartLetWidth.clientWidth;
      }
      let transactionchartLetHeight = document.querySelector(".transaction-chart-height");
      if (transactionchartLetHeight) {
        this.transactionChartHeight = transactionchartLetHeight.clientHeight;
      }
      let bondsLetHeight = document.querySelector(".bonds-height");
      if (bondsLetHeight) {
        this.componentBondsHeight = bondsLetHeight.clientHeight;
      }
      let cashLetHeight = document.querySelector(".cash-height");
      if (cashLetHeight) {
        this.componentCashHeight = cashLetHeight.clientHeight;
      }
      let equitiesLetHeight = document.querySelector(".equities-height");
      if (equitiesLetHeight) {
        this.componentEquitiesHeight = equitiesLetHeight.clientHeight;
      }
      let fundsLetHeight = document.querySelector(".funds-height");
      if (fundsLetHeight) {
        this.componentFundsHeight = fundsLetHeight.clientHeight;
      }
      let notLetHeight = document.querySelector(".noti-height");
      if (notLetHeight) {
        this.notificationHeight = notLetHeight.clientHeight;
      }
      var isMax = document.getElementsByClassName("lm_maximised");
      let tabs = ['TickerDetails','Equities','Currencies','Bonds','Funds'];
      if (isMax.length > 0 && tabs.includes(tab_name)) {
        this.hasmaximiseClass = isMax.length;
      }else{
          let set_tab_name="";
          await this.$store.dispatch("dashboard/updateBLActiveTab", set_tab_name);
      }
      this.generateTabWiseSymbols(tab_name,isMax.length);
      this.$store.dispatch("dashboard/updateIsMaximizeList",isMax.length);
    },
    async updateisVisibleNews(is_visible_news) {
      this.isVisibleNews = is_visible_news;
      await this.$store.dispatch("dashboard/fetchIsVisibleNews",is_visible_news);
    },
    async updateisVisibleOrderEntry(is_visible_order) {
      await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry",is_visible_order);
    },
    async getSettings() {
      await this.$store.dispatch("dashboard/fetchSetting");
    },
    async getProfiles() {
      await this.$store.dispatch("dashboard/fetchProfile");
    },
    async generateTabWiseSecurtyLIst(tab_name) {
      // console.log("Switch Tab: "+tab_name);

      let user_id = localStorage.getItem("login-id");
      let securityList = [];
      if (tab_name == "TickerDetails" || tab_name == "Currencies" || tab_name == "Equities" || tab_name == "Bonds") {
        securityList = [];
        let default_security_set = this.default_security_set;
        default_security_set.user_id = user_id;
        if (tab_name == "TickerDetails") {
          securityList = [default_security_set];
        }
        if (tab_name == "Currencies") {
          let cash_list = this.$store.getters["cash/getItems"];
          securityList.push(default_security_set);
          for (let obj of cash_list) {
            if (obj.market_id == "6") {
              securityList.push({security_id: obj.id, conid: obj.ib_conid, user_id});
            }
          }
        }
        if (tab_name == "Equities") {
          let equities_list = this.$store.getters["equities/getItems"];
          securityList.push(default_security_set);
          for (var j in equities_list) {
            if (equities_list[j].market_id == "1") {
              securityList.push({security_id: equities_list[j].id, conid: equities_list[j].ib_conid, user_id});
            }
          }
        }
        if (tab_name == "Bonds") {
          securityList.push(default_security_set);
          let bonds_list = this.$store.getters["bonds/getItems"];
          for (var k in bonds_list) {
            if (bonds_list[k].market_id == "5") {
              securityList.push({security_id: bonds_list[k].id, conid: bonds_list[k].ib_conid, user_id});
            }
          }
        }
        this.tl_security_list = securityList;
      }
      if (tab_name == "Watchlist" || tab_name == "Holdings") {
        securityList = [];
        if (tab_name == "Watchlist") {
          let watchlist = this.$store.getters["watchList/getItems"];
          for (let k in watchlist) {
            if (watchlist[k].id != undefined && watchlist[k].ib_conid != undefined && watchlist[k].id > 0 && watchlist[k].ib_conid > 0) {
              securityList.push({security_id: watchlist[k].id, conid: watchlist[k].ib_conid, user_id});
            }
          }
        }
        if (tab_name == "Holdings") {
          let holdings = this.$store.getters["holding/getItems"];
          for (let holding of holdings) {
            if (holding.security_id != undefined && holding.ib_conid != undefined && holding.security_id > 0 && holding.ib_conid > 0) {
              securityList.push({security_id: holding.security_id, conid: holding.ib_conid, user_id});
            }
          }
        }
        this.bl_security_list = securityList;
      }
      this.isDuplicateChart = true;
    },
    async generateTabWiseSymbols(tab_name,is_maximize) {
      if(is_maximize != undefined && is_maximize > 0){
        let tabs = ['TickerDetails','Currencies','Equities','Bonds','Funds','Holdings','Watchlist'];

        if(tabs.includes(tab_name)){
          let cashSymbol1 = [];
          let stockSymbol1 = [];
          if(tab_name == "TickerDetails"){
            if(this.default_security_set.iex_symbol != undefined && this.default_security_set.iex_symbol != null){
              if(this.default_security_set.market_id == 6 || this.default_security_set.market_id == 'cash'){
                cashSymbol1 = [this.default_security_set.iex_symbol];
              }
              else if(this.default_security_set.market_id == 1){
                stockSymbol1 = [this.default_security_set.iex_symbol];                
              }
            }
          }
          else if(tab_name == "Currencies"){
            cashSymbol1 = this.$store.getters["cash/getCashSymbols"];
          }
          else if(tab_name == "Equities"){
            stockSymbol1 = this.$store.getters["equities/getStockSymbols"];
          }
          if(tab_name == "Watchlist"){
            cashSymbol1 = this.$store.getters["watchlist/getCashSymbols"];
            stockSymbol1 = this.$store.getters["watchlist/getStockSymbols"];
          }
          else if(tab_name == "Holdings"){
            cashSymbol1 = this.$store.getters["holding/getCashSymbols"];
            stockSymbol1 = this.$store.getters["holding/getStockSymbols"];
          }
          if(cashSymbol1 == undefined || cashSymbol1 == null || cashSymbol1 == ''){
            cashSymbol1 = [];
            stockSymbol1 = [];
          }
          this.cashSymbolList = [...new Set(cashSymbol1)];
          this.stockSymbolList = [...new Set(stockSymbol1)];
        }
        else{
          this.cashSymbolList = [];
          this.stockSymbolList = [];
        }
      }
      else{
        let cashSymbol1 = [];
        let cashSymbol2 = [];
        let stockSymbol1 = [];
        let stockSymbol2 = [];

        if(this.tl_active_tab == "TickerDetails"){
          if(this.default_security_set.iex_symbol != undefined && this.default_security_set.iex_symbol != null){
            if(this.default_security_set.market_id == 6 || this.default_security_set.market_id == 'cash'){
              cashSymbol1 = [this.default_security_set.iex_symbol];
            }
            else if(this.default_security_set.market_id == 1){
              stockSymbol1 = [this.default_security_set.iex_symbol];
            }
          }
        }
        else if(this.tl_active_tab == "Currencies"){
          cashSymbol1 = this.$store.getters["cash/getCashSymbols"];
        }
        else if(this.tl_active_tab == "Equities"){
          stockSymbol1 = this.$store.getters["equities/getStockSymbols"];
        }
        if(this.bl_active_tab == "Watchlist"){
          cashSymbol2 = this.$store.getters["watchlist/getCashSymbols"];
          stockSymbol2 = this.$store.getters["watchlist/getStockSymbols"];
        }
        else if(this.bl_active_tab == "Holdings"){
          cashSymbol2 = this.$store.getters["holding/getCashSymbols"];
          stockSymbol2 = this.$store.getters["holding/getStockSymbols"];
        }
        if(cashSymbol1 == undefined || cashSymbol1 == null || cashSymbol1 == ''){
          cashSymbol1 = [];
        }
        if(cashSymbol2 == undefined || cashSymbol2 == null || cashSymbol2 == ''){
          cashSymbol2 = [];
        }
        if(stockSymbol1 == undefined || stockSymbol1 == null || stockSymbol1 == ''){
          stockSymbol1 = [];
        }
        if(stockSymbol2 == undefined || stockSymbol2 == null || stockSymbol2 == ''){
          stockSymbol2 = [];
        }
        let temp = [...cashSymbol1, ...cashSymbol2];
        this.cashSymbolList = [...new Set(temp)];
        temp = [...stockSymbol1, ...stockSymbol2];
        this.stockSymbolList = [...new Set(temp)];
      }
    },
    joinUserSocket() {
      let user_id = localStorage.getItem("login-id");
      let user_name = localStorage.getItem("login-name");
      if (user_id && user_name) {
        this.$socket.emit("user_join", { user_id, user_name });
        this.$socket.emit("joinUser", { user_id, user_name });
      }
    },
    subscribeSecuritySet(securitySet) {
      this.$socket.emit("security_subscription", securitySet);
    },
    async connectSSEForex(symbols, callback) {
      let user_id = localStorage.getItem("login-id");
      if (user_id) {
        if(symbols !== undefined && symbols !== null && symbols !== ''){
          symbols = symbols.filter(Boolean);
          if(symbols.length > 0){
            if(symbols.length > 50){
              symbols = symbols.slice(0,50);
            }
            let symbols_str = symbols.join(',');
            
            let token = this.iex_token;
            //symbols = "EURUSD";
            let endpoint = this.forexendpoint;
            let url = `https://cloud-sse.iexapis.com/stable/${endpoint}?token=${token}&symbols=${symbols_str}`;
            
            await this.disConnectSSEForex();
            this.cashSSE = new EventSource(url);
            this.cashSSE.onopen = () => {
              this.$socket.emit("iexconnection", {user_id, endpoint: this.forexEndpointName, url, is_stream: true});
            }
            this.cashSSE.onmessage = (event) => {
              let quote = JSON.parse(event.data)[0];
              if(quote !== undefined && quote !== null && quote !== ""){
                if(typeof callback === "function"){
                  callback(quote);
                }
              }
            }
            this.cashSSE.onerror = () => {
              setTimeout(() => {
                this.connectSSEForex(symbols, callback);
              }, 1000);
            };
          }
        }
      }
    },
    async connectSSEStock(symbols, callback) {
      let user_id = localStorage.getItem("login-id");
      if (user_id) {
        if(symbols !== undefined && symbols !== null && symbols !== ''){
          symbols = symbols.filter(Boolean);
          if(symbols.length > 0){
            if(symbols.length > 50){
              symbols = symbols.slice(0,50);
            }
            let symbols_str = symbols.join(',');
            let token = this.iex_token;
            //symbols = "AAPL";
            let endpoint = this.stockendpoint;
            let url = `https://cloud-sse.iexapis.com/stable/${endpoint}?token=${token}&symbols=${symbols_str}`;
            await this.disConnectSSEStock();
            this.stockSSE = new EventSource(url);
            this.stockSSE.onopen = () => {
              this.$socket.emit("iexconnection", {user_id, endpoint: this.stockEndpointName, url, is_stream: true});
            }
            this.stockSSE.onmessage = (event) => {
              let quote = JSON.parse(event.data)[0];
              if(quote !== undefined && quote !== null && quote !== ""){
                if(typeof callback === "function"){
                  callback(quote);
                }
              }
            }
            this.stockSSE.onerror = () => {
              setTimeout(() => {
                this.connectSSEStock(symbols, callback);
              }, 1000);
            };
            
          }
        }
      }
    },
    handleSSEForex(quote){
      let user_id = localStorage.getItem("login-id");
      if(user_id){
        let endpoint = this.forexEndpointName;
        this.$socket.emit("iexdata", {user_id, endpoint});
      }
      quote.price = quote.rate;
      let currency = this.cash_items.find((security) => {
        return security.iex_symbol == quote.symbol;
      });
      let watchlist = this.watchlistItems.find((security) => {
        return security.iex_symbol == quote.symbol && security.market_id == 6;
      });
      let d = new Date(quote.timestamp);
      let data = this.calculateBidAsk(quote);
      if (currency) {
        currency.bid_price = data.bid.toFixed(5);
        currency.ask_price = data.ask.toFixed(5);
        currency.last_price = quote.rate.toFixed(5);
        currency.timestamp = quote.timestamp;
        currency.datetime = d;
        currency.spread = ((currency.ask_price - currency.bid_price) * 10000).toFixed(2);
      }
      if (watchlist) {
        watchlist.bid_price = data.bid.toFixed(5);
        watchlist.ask_price = data.ask.toFixed(5);
        watchlist.last_price = quote.rate.toFixed(5);
        watchlist.timestamp = quote.timestamp;
        watchlist.datetime = d;
        watchlist.spread = ((watchlist.ask_price - watchlist.bid_price) * 10000).toFixed(2);
      }
      if(this.default_security_set && this.default_security_set.iex_symbol == quote.symbol){
        let payload = {
          bid_price: data.bid.toFixed(5),
          ask_price: data.ask.toFixed(5),
          rate: "0.00",
        };

        if (this.default_security_set.market_id == 6 || this.default_security_set.market_id == 'cash') {
          payload.rate = ((payload.ask_price - payload.bid_price) * 10000).toFixed(2);
        } else {
          payload.rate = (payload.ask_price - payload.bid_price).toFixed(2);
        }
        this.$store.dispatch("chart/fetchWs", payload);
      }
      this.calculateHoldings(quote,true);
    },
    handleSSEStock(quote){
      let user_id = localStorage.getItem("login-id");
      if(user_id){
        let endpoint = this.stockEndpointName;
        this.$socket.emit("iexdata", {user_id, endpoint});
      }
      let d = new Date(quote.latestUpdate);
      let bid_price = quote.iexBidPrice;
      let ask_price = quote.iexAskPrice;
      
      quote.price = quote.iexRealtimePrice;
      if(quote.iexBidPrice == 0 || quote.iexBidPrice == null || quote.iexBidPrice == undefined){
        bid_price = quote.price;
      }
      if(quote.iexAskPrice == 0 || quote.iexAskPrice == null || quote.iexAskPrice == undefined){
        ask_price = quote.price;
      }
      if(bid_price > 0 && ask_price > 0){
        let stockItems = this.stockItems.find((security) => {
          return security.iex_symbol == quote.symbol;
        });
        let watchlist = this.watchlistItems.find((security) => {
          return security.iex_symbol == quote.symbol && security.market_id == 1;
        });
        if (stockItems) {
          stockItems.datetime = d;
          stockItems.timestamp = quote.latestUpdate;
          stockItems.bid_size = quote.iexBidSize;
          stockItems.ask_size = quote.iexAskSize;
          if (stockItems.country_id == 14) {
            stockItems.bid_size = quote.iexBidSize * 100;
            stockItems.ask_size = quote.iexAskSize * 100;
          }
          stockItems.last_price = quote.price;
          stockItems.bid_price = bid_price;
          stockItems.ask_price = ask_price;
          stockItems.spread = (stockItems.ask_price - stockItems.bid_price).toFixed(2);
        }
        if (watchlist) {
          watchlist.datetime = d;
          watchlist.timestamp = quote.timestamp;
          watchlist.last_price = quote.price;
          watchlist.bid_price = bid_price;
          watchlist.ask_price = ask_price;
          watchlist.spread = (watchlist.ask_price - watchlist.bid_price).toFixed(2);
        }
        if(this.default_security_set && this.default_security_set.iex_symbol == quote.symbol){
          let payload = {bid_price, ask_price, rate: "0.00"};
          if (this.default_security_set.market_id == 6 || this.default_security_set.market_id == 'cash') {
            payload.rate = ((payload.ask_price - payload.bid_price) * 10000).toFixed(2);
            payload.bid_price = payload.bid_price.toFixed(5);
            payload.ask_price = payload.ask_price.toFixed(5);
          } else {
            payload.rate = (payload.ask_price - payload.bid_price).toFixed(2);
            payload.bid_price = payload.bid_price.toFixed(2);
            payload.ask_price = payload.ask_price.toFixed(2);
          }
          this.$store.dispatch("chart/fetchWs", payload);
        }
      }
      this.calculateHoldings(quote,true);
    },
    disConnectSSEForex(){
      if(this.cashSSE != null){
        if(this.cashSSE.readyState != undefined && this.cashSSE.readyState != 2){
          this.cashSSE.close();
        }
      }
    },
    disConnectSSEStock(){
      if(this.stockSSE != null){
        if(this.stockSSE.readyState != undefined && this.stockSSE.readyState != 2){
          this.stockSSE.close();
        }
      }
    },
    calculateBidAsk(data){
      let price = +data.rate;
      let ask = 0;
      let bid = 0;
      if(price > 0){
        ask = price + (price * 0.001) / 100;
        bid = price - (price * 0.001) / 100;
      }
      return { price, ask, bid};
    },
    calculateHoldings(data,is_symbol){
      let security;
      let system_filter_rate = this.filterCurrencyRate;
      let system_filter_currency = this.filterCurrency;
      if(is_symbol === true){
        security = this.holdingItems.find((security) => {
          return security.iex_symbol == data.symbol && (security.market_id == 'cash' || security.market_id == 1);
        });
      }
      else{
        security = this.holdingItems.find((security) => {
          return security.security_id == data.security_id
        });
      }
      if (security && data.price > 0) {
        let security_price = data.price;
        security.last_price = security_price;
        if (security.calculation_fields != undefined) {
          if (security.calculation_fields.is_div_100 == 1) {
            security_price = parseFloat(security_price / 100);
            security.last_price = parseFloat(security_price.toFixed(2));
          }
          if (security.market_id == 5) {
            let qty = security.calculation_fields.total_qty;
            let factor = security.calculation_fields.factor;
            let accuredPrice = security.calculation_fields.accuredPrice;
            let market_value = qty * (security_price / 100) * factor;
                market_value = parseFloat(market_value) + parseFloat(accuredPrice);
                market_value = market_value * security.calculation_fields.security_currency_rate * system_filter_rate;
            if (market_value > 0) {
              security.original_value = market_value;
              security.value = this.convertPositive(market_value, 0);
              security.p_and_l = this.convertPositive(market_value, 0);
            }
          }
          else if (security.market_id == 1) {
            let market_value = security.original_amount * security_price * security.calculation_fields.security_currency_rate;
                market_value = market_value * system_filter_rate;
            if (market_value > 0) {
              security.original_value = market_value;
              security.value = this.convertPositive(market_value, 0);
              security.p_and_l = this.convertPositive(market_value, 0);
            }
          }
          this.$store.dispatch("holding/updateItems", this.holdingItems);
        }
        else {
          if (security.market_id == 6 || security.market_id == "cash") {
            let change_allowed = 1;
            let rate_usd = data.price;
            if (((system_filter_currency == "EUR" || system_filter_currency == "USD") && security.currency == system_filter_currency) ||
                (system_filter_currency == "GBP" && (security.currency == "EUR" || security.currency == "USD"))) {
                  let rate_usd2 = rate_usd;
                  if (security.currency == system_filter_currency) {
                    security.last_price = 1;
                    change_allowed = 0;
                  }
                  let security2;
                  if ((system_filter_currency == "EUR" || system_filter_currency == "USD") && security.currency == system_filter_currency) {
                    if(is_symbol === true){
                      security2 = this.holdingItems.find((security) => {
                        return (security.iex_symbol == data.symbol 
                          && security.market_id == 6 
                          && security.currency != system_filter_currency);
                      });
                    }
                    else{
                      security2 = this.holdingItems.find((security) => {
                        return (security.security_id == data.security_id 
                          && security.currency != system_filter_currency)
                      });
                    }
                  }
                  else if (system_filter_currency == "GBP" && (security.currency == "EUR" || security.currency == "USD")) {
                    if(is_symbol === true){
                      security2 = this.holdingItems.find((security) => {
                        return (security.iex_symbol == data.symbol 
                          && security.market_id == 6 
                          && security.currency != "EUR");
                      });
                    }
                    else{
                      security2 = this.holdingItems.find((security) => {
                        return (security.security_id == data.security_id 
                          && security.currency != "EUR")
                      });
                    }
                    rate_usd2 = 1;
                }
                if (security2) {
                  if (system_filter_currency != "GBP") {
                    change_allowed = 0;
                  } 
                  if (system_filter_currency == "EUR") {
                    rate_usd2 = 1 / rate_usd2;
                    system_filter_rate = 1;
                  }
                  let rate = rate_usd2 * system_filter_rate;
                  security2.last_price = rate.toFixed(5);
                  let market_value = security.original_amount * rate;
                  security2.value = this.convertPositive(market_value, 0);
                  security.p_and_l = this.convertPositive(market_value, 0);
                }
            }
            if (security.currency == system_filter_currency) {
              system_filter_rate = rate_usd = 1;
            }
            if (change_allowed == 1) {
              if (this.req_reverse.indexOf(security.security_id) > -1) {
                rate_usd = 1 / rate_usd;
              }
              let rate = rate_usd * system_filter_rate;
              security.last_price = rate.toFixed(5);
              let market_value = security.original_amount * rate;
              security.original_value = market_value;
              security.value = this.convertPositive(market_value, 0);
              security.p_and_l = this.convertPositive(market_value, 0);
            }
          } else {
            let market_value = security.original_amount * data.price;
            security.original_value = market_value;
            security.value = this.convertPositive(market_value, 0);
            security.p_and_l = this.convertPositive(market_value, 0);
          }
        }
      }
    },
    async getDefaultSecurity() {
      await this.$store.dispatch("chart/getDefaultSecurity");
      this.loadSecurity(this.security_id, []);
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
@import "@/assets/scss/components/dashboard.scss";
</style>
