<template>
  <div class="holding-wrap">
    <div :class="drawer == true ? 'holding-maximize' : ''">
      <v-data-table
        :headers="dessertHeaders"
        :items="items"
        item-key="id"
        class="custome-table"
        hide-default-footer
        disable-pagination
        :height="HoldingTableHeight"
        fixed-header
      >
        <template v-slot:body.prepend>
          <tr class="table-dropdown sticky-header">
            <td width="44%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.security_name"
                ></v-text-field>
              </div>
            </td>
            <td width="9%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.identifier_name"
                ></v-text-field>
              </div>
            </td>
            <td width="9%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.market_name"
                ></v-text-field>
              </div>
            </td>
            <td width="9%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.last_price"
                ></v-text-field>
              </div>
            </td>
            <td width="9%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.amount"
                ></v-text-field>
              </div>
            </td>
            <td width="20%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                  v-model="search_table.market_value"
                ></v-text-field>
              </div>
            </td>
            <!-- <td width="20%">
              <div class="form-group">
                <v-text-field
                  class="form-control"
                  type="text"
                  hide-details="auto"
                ></v-text-field>
              </div>
            </td> -->
          </tr>
        </template>
        <template v-slot:[`item.security_name`]="{ item }">
          <!-- security_w when add P&L div please this class -->
          <div class="watch-div">

            <span v-if="item.market_id == 1 
              && item.iex_symbol !== null 
              && item.iex_symbol !== undefined 
              && item.iex_symbol !== ''" 
              class="hover-alert" title="Price Alert">
              <font-awesome-icon
                @click="priceAlert(item.security_id)"
                icon="bell"
              />
            </span>
            <span
              class="teble-ellipsis-wrap security_w-new"
              @click="selectItem(item, 0, '0')"
            >
              <div
                class="cash-type"
                v-if="item.market_id == 'cash'"
                title="Currencies"
              ></div>
              <div
                class="equity-type"
                v-if="item.market_id == '1'"
                title="Equites"
              ></div>
              <div
                class="bond-type"
                v-if="item.market_id == '5'"
                title="Bonds"
              ></div>
              <div
                class="fund-type"
                v-if="item.market_id == '8'"
                title="Funds"
              ></div>
              {{ item.security_name }}
              <template v-if="item.market_id == 'cash'">
                <input
                  type="hidden"
                  :value="item.base_amount"
                  :id="item.security_name + '-holdings'"
                />
              </template>
            </span>
          </div>
        </template>
        <template v-slot:[`item.market_name`]="{ item }">
          <span
            class="teble-ellipsis-wrap class-wrap"
            @click="selectItem(item, 0, '0')"
          >
            {{ item.market_name }}
          </span>
        </template>

        <template v-slot:[`item.original_value`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ item.value }}
          </span>
        </template>
        <template v-slot:[`item.last_price`]="{ item }">
          <span class="teble-ellipsis-wrap">
            {{ formatPrice(item.last_price, item.market_id) }}
          </span>
        </template>
        <!-- <template v-slot:[`item.p_and_l`]>
          <span class="teble-ellipsis-wrap">0</span>
        </template> -->
        
        <template v-slot:[`item.original_amount`]="{ item }">
          <span
            class="td-values"
            :class="item.market_id == 2 ? ' ' : 'hover-td-values'"
          >
            <!-- market-wrap when add P&L div please this class -->
            <div
              class="teble-ellipsis-wrap e-w-70 market-wrap-new value-none m-auto text-center"
            >
              {{ item.amount }}
            </div>
            <div class="hover-wrap" v-if="item.market_id != 2">
              <template>
                <b-button
                  v-if="is_maximize == 1"
                  @click.stop="order_sidebar(item)"
                  variant="danger"
                  >Sell</b-button
                >
                <b-button
                v-if="is_maximize == 0"
                @click="selectItem(item, item.original_amount, '1')"
                variant="danger"
                >Sell</b-button
              >
              </template>
              <template>
              <b-button
                v-if="is_maximize == 1"
                @click.stop="order_sidebar(item)"
                variant="success"
                >Buy</b-button
              >
              <b-button
                v-if="is_maximize == 0"
                @click="selectItem(item, '', '1')"
                variant="success"
                >Buy</b-button
              >
              </template>
            </div>
          </span>
        </template>
      </v-data-table>
      <transition name="fade">
        <div class="loading full-page" v-show="holding_loading">
          <div><span></span><span></span><span></span><span></span></div>
        </div>
      </transition>
    </div>
    <template v-if="is_maximize > 0">
      <v-navigation-drawer
        class="order-form-sidebar"
        width="26%"
        v-model="drawer"
        temporary
        absolute
        hide-overlay
        right
      >
        <div class="height-50 bb-4 holdingsidebar-area-chart">
          <div id="chart" class="mx-auto">
            <div class="toolbar position-right right-5px">
              <b-dropdown
                id="time-drp"
                right
                :text="this.time_drp_placeholder"
                variant="primary"
                class="custome-dropdown maximize-dropdown margin-r-10"
              >
                <b-dropdown-item
                  href="javascript:void(0);"
                  @click="updateDailyHoldingData('Month')"
                  v-if="this.time_drp_placeholder != 'Month'"
                  >Month</b-dropdown-item
                >
                <b-dropdown-item
                  href="javascript:void(0);"
                  @click="updateDailyHoldingData('Quarter')"
                  v-if="this.time_drp_placeholder != 'Quarter'"
                  >Quarter</b-dropdown-item
                >
                <b-dropdown-item
                  href="javascript:void(0);"
                  @click="updateDailyHoldingData('Year')"
                  v-if="this.time_drp_placeholder != 'Year'"
                  >Year</b-dropdown-item
                >
                <b-dropdown-item
                  href="javascript:void(0);"
                  @click="updateDailyHoldingData('YTD')"
                  v-if="this.time_drp_placeholder != 'YTD'"
                  >YTD</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                id="market-type-drp"
                right
                :text="this.market_drp_placeholder"
                variant="primary"
                class="custome-dropdown maximize-dropdown"
              >
                <b-dropdown-item
                  href="javascript:void(0);"
                  @click="updateHoldingTypeData('All')"
                  v-if="this.market_drp_placeholder != 'All'"
                  >All</b-dropdown-item
                >
                <b-dropdown-item
                  href="javascript:void(0);"
                  @click="updateHoldingTypeData('Currencies')"
                  v-if="this.market_drp_placeholder != 'Currencies'"
                  >Currencies</b-dropdown-item
                >
                <b-dropdown-item
                  href="javascript:void(0);"
                  @click="updateHoldingTypeData('Equities')"
                  v-if="this.market_drp_placeholder != 'Equities'"
                  >Equities</b-dropdown-item
                >
                <b-dropdown-item
                  href="javascript:void(0);"
                  @click="updateHoldingTypeData('Funds')"
                  v-if="this.market_drp_placeholder != 'Funds'"
                  >Funds</b-dropdown-item
                >
                <b-dropdown-item
                  href="javascript:void(0);"
                  @click="updateHoldingTypeData('Bonds')"
                  v-if="this.market_drp_placeholder != 'Bonds'"
                  >Bonds</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <apexchart
              v-if="isupdatedhseries"
              type="area"
              ref="dhchart"
              :options="historychartOptions"
              :series="dhseries"
            ></apexchart>
          </div>
        </div>
        <div class="height-50 pie-chart">
          <div id="chart" class="mx-auto donut-chart">
            <div class="toolbar position-right right-0">
              <b-dropdown
                id="market-type-drp"
                right
                :text="this.pae_drp_placeholder"
                variant="primary"
                class="custome-dropdown maximize-dropdown"
              >
                <b-dropdown-item
                  href="javascript:void(0);"
                  @click="updatePieChart('By Currency')"
                  v-if="this.pae_drp_placeholder != 'By Currency'"
                  >By Currency</b-dropdown-item
                >
                <b-dropdown-item
                  href="javascript:void(0);"
                  @click="updatePieChart('By Asset Class')"
                  v-if="this.pae_drp_placeholder != 'By Asset Class'"
                  >By Asset Class</b-dropdown-item
                >
              </b-dropdown>
            </div>

            <span
              class="chart-title"
              v-if="this.pae_drp_placeholder == 'By Asset Class'"
              >By <br />Asset Class</span
            >
            <apexchart
              type="donut"
              ref="holdingchart"
              :options="chartOptions"
              :series="holdingseries"
              class="pt-0"
              v-if="this.pae_drp_placeholder == 'By Asset Class'"
            ></apexchart>

            <span
              class="chart-title"
              v-if="this.pae_drp_placeholder == 'By Currency'"
              >By <br />Currency</span
            >
            <apexchart
              type="donut"
              ref="currencyholdingchart"
              :options="currencyChartOptions"
              :series="currencyseries"
              class="pt-0"
              v-if="this.pae_drp_placeholder == 'By Currency'"
            ></apexchart>
          </div>
        </div>
        <transition name="fade">
          <div class="loading full-page" v-show="chart_loading">
            <div><span></span><span></span><span></span><span></span></div>
          </div>
        </transition>
      </v-navigation-drawer>
      <a class="filter-sticky-icon rightside" @click="drawer = true">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a>
    </template>
    <template v-if="is_maximize > 0">
      <v-navigation-drawer
        class="filter-sidebar"
        width="26%"
        v-model="HoldingScannerDrawer"
        absolute
        temporary
        left
      >
        <div class="height-50 bb-4 align-items-start">
          <TickerDetails formName="holding_order_form" />
        </div>
        <div class="height-50" v-if="HoldingScannerDrawer">
          <!-- <TradingChart ChartOrigin="Holdings"/> -->
          <SideBarTradingChart ChartOrigin="Holdings" CompisTradingChartOpen='isTradingChartOpen' :TraddingChartHeight="400" :TraddingChartWidth="350" v-if="isTradingChartOpen"/>
        </div>
      </v-navigation-drawer>
      <a class="filter-sticky-icon" @click="opensidebarDrawer()">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a>
    </template>
    <template>
      <PriceAlert v-model="paDialog" />
    </template>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import TickerDetails from "@/components/TickerDetails.vue";
import SideBarTradingChart from "@/components/SideBarTradingChart.vue";
import PriceAlert from "@/components/PriceAlert.vue";
import themeconfig from '../themeconfig.js'
// import HoldingChart from "@/components/HoldingChart.vue";
export default {
  name: "Holding",
  components: { TickerDetails: TickerDetails, SideBarTradingChart: SideBarTradingChart,apexchart: VueApexCharts, PriceAlert },
  data() {
    return {
      isupdatedhseries:false,
      traddingHeight:397,
      isTradingChartOpen:false,
      dessertHeaders: [
        {
          text: "Name",
          align: "start",
          width: "44%",
          class: "security-name",
          value: "security_name",
          filter: (value) => {
            if (!this.search_table.security_name) return true;
            if (value) {
              return (
                String(value.toLowerCase()).search(
                  this.search_table.security_name.toLowerCase()
                ) > -1
              );
            }
          },
        },
        {
          text: "Identifier",
          align: "start",
          width: "9%",
          class: "identifier-name",
          value: "identifier_name",
          filter: (value) => {
            if (!this.search_table.identifier_name) return true;
            if (value) {
              return (
                String(value.toLowerCase()).search(
                  this.search_table.identifier_name.toLowerCase()
                ) > -1
              );
            }
          },
        },
        {
          text: "Class",
          align: "start",
          width: "9%",
          class: "market-name",
          value: "market_name",
          filter: (value) => {
            if (!this.search_table.market_name) return true;
            if (value) {
              return (
                String(value.toLowerCase()).search(
                  this.search_table.market_name.toLowerCase()
                ) > -1
              );
            }
          },
        },
        {
          text: "Price",
          align: "center",
          value: "last_price",
          width: "9%",
          filter: (value) => {
            if (!this.search_table.last_price) return true;
            if (value) {
              return String(value).search(this.search_table.last_price) > -1;
            }
          },
        },
        {
          text: "Amount",
          width: "9%",
          align: "center",
          class: "original-amount",
          value: "original_amount",
          filter: (value) => {
            if (!this.search_table.amount) return true;
            if (value) {
              return String(value).search(this.search_table.amount) > -1;
            }
          },
        },
        {
          text: "Market Value",
          class: "market-value",
          width: "20%",
          align: "center",
          value: "original_value",
          filter: (value) => {
            if (!this.search_table.market_value) return true;
            if (value) {
              return String(value).search(this.search_table.market_value) > -1;
            }
          },
        },
        // {
        //   text: "P&L",
        //   class: "p-and-l",
        //   width: "20%",
        //   align: "center",
        //   value: "p_and_l",
        // },
      ],
      holding_loading: false,
      chart_loading: false,
      menu2: false,
      drawer: true,
      HoldingScannerDrawer: null,
      holdingseries: [],
      currencyseries: [],
      chartOptions: {
        type: Object,
      },
      dhseries: [
        {
          name: "Market Value",
          data: [],
        },
      ],
      historychartOptions: {
        chart: {
          id: "daily-holding-chart",
          type: "area",
          foreColor:'',
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        colors:[],
        // colors: ["#ffffff"],
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat",
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return new Intl.NumberFormat("en-US").format(val.toFixed(0));
            },
          },
        },
        grid: {
          // borderColor: '#333333',
          borderColor:'',
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.3,
            stops: [0, 100],
          },
        },
        legend: {
          show: false,
        },
      },
      time_drp_placeholder: "Year",
      market_drp_placeholder: "All",
      pae_drp_placeholder: "By Asset Class",
      search_table: {
        security_name: null,
      },
      currencyChartOptions: {
        type: Object,
      },
      paDialog: {
        show: false,
        securityId: null
      }
    };
  },
  computed: {
    bl_active_tab: {
      get() {
        // console.log('d_active',this.$store.getters["dashboard/getBLActiveTab"]);
        return this.$store.getters["dashboard/getBLActiveTab"];
      },
    },
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
    timeline: {
      get() {
        return this.$store.getters["daily_holding_chart/getTimeline"];
      },
    },
    holding_type: {
      get() {
        return this.$store.getters["daily_holding_chart/getHoldingType"];
      },
    },
    items() {
      return this.$store.getters["holding/getItems"];
    },
    assets_items() {
      return this.$store.getters["holding/getAssets"];
    },
    quantity() {
      return this.$store.getters["holding/getQuantity"];
    },
    currency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
    holding_data() {
      return this.$store.getters["holding/getHoldings"];
    },
    holdingChartWidth: {
      get() {
        return parseInt(this.HoldingTableWidth) / 2.9;
      },
    },
    holdingChartHeight: {
      get() {
        return parseInt(this.HoldingTableHeight) / 2.2;
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
    history_items: {
      get() {
        let AllItems = this.$store.getters["daily_holding_chart/getItems"];
        let history_items = new Array();
        for (var i in AllItems) {
          let one = new Array();
          one.push(new Date(AllItems[i].created).getTime());
          one.push(AllItems[i].total_market.toFixed(2));
          history_items.push(one);
        }
        return history_items;
      },
    },
    is_place_order() {
      return this.$store.getters["transaction/getIsPlaceOrder"];
    },
    is_transaction_update() {
      return this.$store.getters["dashboard/getIsTransactionUpdate"];
    },
    currency_chart_data() {
      return this.$store.getters["holding/getCurrencyChartData"];
    },
    filterCurrency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
  },
  methods: {
    opensidebarDrawer(){
      // console.log("call opensidebarDrawer :: component mounted");
      this.HoldingScannerDrawer = this.HoldingScannerDrawer ? false :true;
      this.isTradingChartOpen= true;
    },
    async setThemeoption(){
      const curretn_theme = themeconfig.getTheme();
       this.historychartOptions.chart.foreColor = localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : curretn_theme.chart_for_color;
       this.historychartOptions.colors = localStorage.getItem("isDarkActive") == 1 ? ["#ffffff"] : [curretn_theme.chart_primary_color];
       this.historychartOptions.grid.borderColor = localStorage.getItem("isDarkActive") == 1 ? "#333333" : curretn_theme.chart_grid_border_color;
    },
    async order_sidebar(row_item) {
      if (this.is_maximize == 1) {
        // this.HoldingScannerDrawer = !this.HoldingScannerDrawer;
        this.opensidebarDrawer();
      }
      await this.selectItem(row_item, "0",'1');
    },
    async fetchHolding() {
      this.holding_loading = true;
      await this.$store.dispatch("holding/fetchItems", {
        filter_portfolio_ids: this.filter_portfolio,
        currency_filter: this.currency,
      });
      var HoldTTLCount = document.getElementsByClassName("header-market-value");
      if (HoldTTLCount.length > 0) {
        var total_amount = this.holding_data.total_amount.toFixed(0);
        total_amount = this.convertPositive(total_amount);
        document.getElementById("holding_ttl_amount").innerHTML =
          this.currency + " " + total_amount;
      }
      this.holding_loading = false;
    },
    async fetchDailyHoldingChart() {
      this.chart_loading = true;
      await this.$store.dispatch("daily_holding_chart/fetchItems", {
        filter_portfolio_ids: this.filter_portfolio,
        timeline: this.timeline,
        currency_filter: this.currency,
        market_type: this.holding_type,
      });
      await this.updateDailyHoldingSeries();
      this.chart_loading = false;
    },
    async selectItem(security_items, quantity, is_order) {
      let securityId = security_items.security_id;
      let ib_conid = security_items.ib_conid;
      let iex_symbol = security_items.iex_symbol;
      let market_id = security_items.market_id;
      
      if (securityId > 0) {
        await this.$store.dispatch("chart/updateIsLoading", false);
        await this.$store.dispatch("chart/updateIsLoading", true);
        
        if (ib_conid != undefined && ib_conid > 0) {
          await this.$store.dispatch("dashboard/updateDefaultSecuritySet", {
            secuirty_id: securityId,
            conid: ib_conid,
            iex_symbol: iex_symbol,
            market_id: market_id,
          });
        }

        if (is_order == "1") {
          await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 1);
        } else {
          await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 0);
        }

        await this.$store.dispatch("chart/updateDefaultChart", {
          security_id: securityId,
        });

        await this.$store.dispatch("chart/updateConId", {
          con_id: ib_conid,
        });
        await this.updateisVisibleNews("0");
        await this.updateisVisibleNews("1");
        
        await this.updateFormQuantity(quantity);

        this.selectedItemId = securityId;
        await this.loadSecurity(this.selectedItemId, []);
      }
    },
    async currencyChange() {
      await this.fetchHolding();
    },
    async updateDailyHoldingSeries() {
      // this.$refs.dhchart.updateSeries([
      //   {
      //     name: "Market Value",
      //     data: this.history_items,
      //   },
      // ]);
      this.isupdatedhseries = true;
      this.dhseries = [
        {
          name: "Market Value",
          data: this.history_items,
        },
      ];
    },
    async updateHoldingTypeData(selectedMarket) {
      this.chart_loading = true;
      this.market_drp_placeholder = selectedMarket;
      await this.$store.dispatch(
        "daily_holding_chart/fetchTimeline",
        this.timeline
      );
      let market_type = this.getMarketTypeSelected(selectedMarket);
      await this.$store.dispatch(
        "daily_holding_chart/fetchHoldingType",
        market_type
      );
      await this.fetchDailyHoldingChart();
      this.chart_loading = false;
    },
    async updateDailyHoldingData(timeline) {
      this.chart_loading = true;
      this.time_drp_placeholder = timeline;
      let time = this.getTimeSelected(timeline);
      this.selection = time;
      await this.$store.dispatch("daily_holding_chart/fetchTimeline", time);
      await this.fetchDailyHoldingChart();
      this.chart_loading = false;
    },
    async updatePieChart(chart_type) {
      this.pae_drp_placeholder = chart_type;
      const curretn_theme = themeconfig.getTheme();
       this.currencyChartOptions.colors=[
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : curretn_theme.chart_primary_color,
          localStorage.getItem("isDarkActive") == 1 ? "#404040" : "#333333",
          localStorage.getItem("isDarkActive") == 1 ? "#7f7f7f" : "#555555",
          localStorage.getItem("isDarkActive") == 1 ? "#bfbfbf" : "#7f7f7f",
        ]
    },
    priceAlert(id){
      this.paDialog = {
        show: true,
        securityId: id
      };
    }
  },
  watch: {
    currency() {
      this.fetchHolding();
    },
    async filter_portfolio() {
      await this.fetchHolding();
      await this.fetchAssetsChart();
      await this.updateDailyHoldingSeries();
    },
    async holdingChartWidth() {
      await this.fetchAssetsChart();
      await this.updateDailyHoldingSeries();
    },
    async holdingChartHeight() {
      await this.fetchAssetsChart();
      await this.updateDailyHoldingSeries();
    },
    async updateDailyHoldingData(timeline) {
      this.time_drp_placeholder = timeline;
      let time = this.getTimeSelected(timeline);
      this.selection = time;
      await this.$store.dispatch("daily_holding_chart/fetchTimeline", time);
      await this.fetchDailyHoldingChart();
    },
    async is_place_order(new_val) {
      if (new_val == 1) {
        await this.fetchHolding();
        await this.$store.dispatch("transaction/updatePlaceOrder", 0);
      }
    },
    async is_transaction_update(new_val, old_val) {
      if (new_val == "1" && old_val != "1") {
        await this.fetchHolding();
      }
    },
    items: {
      handler: function() {
        //console.log('a thing changed');
      },
      deep: true,
    },
  },
  props: ["CompisHoldingOpen","HoldingTableHeight", "HoldingTableWidth"],
  async mounted() {
    if(this.CompisHoldingOpen){
      // console.log("call Holding component mounted order");
      await this.setThemeoption();
      await this.fetchHolding();
      // var currentDom = this;
      var total_amount = this.holding_data.total_amount.toFixed(0);
      total_amount = this.convertPositive(total_amount);
      setTimeout(() => {
        var x = document.getElementsByClassName("lm_header");
        for (let i = 0; i < x.length; i++) {
          let isFind = x[i].querySelectorAll("li[title='Watchlist']").length;
          if (isFind > 0) {
            var selectList = document.createElement("div");
            selectList.className = "header-market-value";
            selectList.innerHTML =
              "Total: <span id='holding_ttl_amount'>" +
              this.currency +
              " " +
              total_amount +
              "</span>";
            var isHoldCount = document.getElementsByClassName(
              "header-market-value"
            );
            if (!(isHoldCount.length > 0)) {
              x[i].appendChild(selectList);
            }
          }
        }
      }, 1000);

      this.$watch(
        "items",
        function() {
          let Holdings = this.items;

          let total_value = 0;
          for (let i in Holdings) {
            let value = 0;

            if (
              Holdings[i].original_value != undefined &&
              Holdings[i].original_value > 0
            ) {
              value = Holdings[i].original_value;
            }
            total_value = total_value + parseFloat(value);
          }

          if (total_value > 0) {
            total_value = this.convertPositive(total_value.toFixed(0));
            setTimeout(() => {
              document.getElementById("holding_ttl_amount").innerHTML =
              this.filterCurrency + " " + total_value;
            }, 1000);
            
          }
        },
        { deep: true }
      );
      setTimeout(() => {
        this.fetchAssetsChart();
        this.updateDailyHoldingSeries();
      }, 1000);
      
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.holding-wrap {
  .holdingsidebar-area-chart{
      .apexcharts-reset-icon{
        display: none;;
      }
    }
  .pie-chart{
    
    // .toolbar{
    //   top:52% !important;
    // }
    // .chart-title{
    //   top:55% !important;
    // }
  }
  
  padding: 0px;
  .custome-table {
    background: transparent !important;
    border-radius: 0;
    border: 0;
    table {
      > tbody {
        > tr {
          td {
            position: relative;
          }
          .hover-wrap {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            margin: 0 auto;
            left: 50%;
            right: inherit;
            display: none;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-right: 0;
            .btn {
              font-size: 14px;
              font-weight: 400;
              height: inherit;
              padding: 1px 6px;
              min-width: inherit;
              width: 48%;
              border-radius: 0;
              line-height: 14px;
              height: 22px;
              margin: 0;
              border: none;
              @media only screen and (max-width: 1799px) {
                font-size: 12px;
                height: 20px;
              }
              &:first-child {
                margin-left: 0;
              }
              &:focus {
                box-shadow: none;
                outline: none;
              }
            }
            &:focus {
              box-shadow: none;
              outline: none;
            }
          }
          &:hover,
          &:focus {
            background-color: transparent !important;
            &:nth-of-type(even) {
              background-color: rgba(var(--even-hover-rgba)) !important;
            }
            td {
              color: var(--dark) !important;
              .v-icon {
                color: var(--dark) !important;
                &:before {
                  color: var(--dark) !important;
                }
              }
            }
          }
          &:hover {
            .hover-td-values {
              .value-none {
                visibility: hidden !important;
                opacity: 0;
              }
              .hover-wrap {
                display: flex;
              }
            }
          }
        }
      }
    }
    .v-data-table__wrapper {
      background: transparent;
      border-radius: 0;
      border: 0;
      overflow-x: hidden !important;
    }
  }
  .toolbar {
    position: absolute;
    top: 0;
    left: 15px;
    z-index: 9;
    &.position-right {
      left: inherit;
      right: 35px;
    }
    &.right-0 {
      right: 10px;
    }
    &.right-5 {
      right: -5px;
    }

    .btn {
      color: var(--toolbar-btn-color);
      font-size: 12px;
      font-weight: 400;
      height: inherit;
      padding: 5px 8px;
      border-radius: 0;
      line-height: 12px;
      margin: 0 0 0 8px;
      border: none;
      background-color: var(--toolbar-btn-bg);
      border: 1px solid var(--toolbar-btn-color);
      cursor: pointer;
      &:hover,
      &:focus,
      &.active {
        background-color: var(--toolbar-btn-color);
        border: 1px solid var(--toolbar-btn-color);
        color: var(--toolbar-btn-text);
      }
      &:first-child {
        margin-left: 0;
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    .maximize-dropdown {
      button {
        border: var(--primary) solid 1px;
        color: var(--primary);
      }
    }
  }
  .hover-alert {
    padding: 0 5px;
    &:focus {
      outline: 0 !important;
      border: none;
      svg {
        outline: 0 !important;
        border: none;
      }
    }
    svg {
      &:focus {
        outline: 0 !important;
        border: none;
      }
    }
    &:hover {
      animation: swing 0.6s infinite ease 0s;
    }
  }
  .watch-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.custome-accordion {
  .v-expansion-panel--active {
    > .v-expansion-panel-header {
      min-height: 27px;
    }
  }
  &.v-expansion-panels {
    border-radius: 0 !important;
    box-shadow: none;
    .v-expansion-panel {
      background: rgba(var(--even-hover-rgba)) !important;
      &:nth-of-type(even) {
        background: transparent !important;
      }
      &:before {
        box-shadow: none;
      }
      &:not(:first-child):after {
        border-color: transparent !important;
      }
      &:focus {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }
  .v-expansion-panel-header {
    padding: 3px 10px;
    min-height: 27px;
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
    &:hover,
    &:focus {
      background: var(--primary);
      .holding-title {
        color: var(--white);
      }
      .holding-values {
        color: var(--white);
      }
      .v-icon {
        color: var(--white) !important;
      }
    }
    .holding-title {
      color: var(--dark);
      font-size: 13px;
      font-weight: 500;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 18px;
    }
    .holding-values {
      color: var(--dark);
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 10px;
    border-top: 1px solid var(--primary);
    border-bottom: 1px solid var(--primary);
  }
}
.ellipsis-wrap {
  white-space: nowrap;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.holding-list {
  list-style: none;
  padding: 0 !important;
  margin: 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  li {
    width: 50%;
    padding: 5px;
    margin: 0;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    &.w-100 {
      span {
        &.text-title {
          width: auto;
          padding-right: 10px;
          color: var(--dark);
        }
      }
    }
    span {
      &.text-title {
        color: var(--dark);
        font-size: 12px;
        font-weight: 500;
        width: 45%;
      }
      &.text-value {
        color: var(--primary);
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
  .text-d-none {
    display: none;
  }
}
#app {
  .holding-wrap {
    .toolbar {
      .maximize-dropdown {
        button {
          border: var(--primary) solid 1px;
          color: var(--primary);
        }
      }
    }
    .custome-table {
      table {
        > thead {
          > tr {
            @media only screen and (max-width: 1799px) {
              > td {
                padding: 1.5px 4px !important;
              }
              > th {
                padding: 1.5px 4px !important;
                &:last-child {
                  span {
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
        > tbody {
          tr {
            .hover-wrap {
              width: 80%;
            }
            td {
              &:first-child {
                padding-left: 10px !important;
              }
            }
            &.sticky-header {
              td {
                &:first-child {
                  padding-left: 8px !important;
                  @media only screen and (max-width: 1599px) {
                    padding-left: 4px !important;
                  }
                }
              }
            }
          }
        }
        .teble-ellipsis-wrap {
          @media only screen and (max-width: 1399px) {
            width: 60px !important;
          }
        }
      }
    }
  }
  .header-market-value {
    font-family: var(--font-family);
    color: var(--holding-value-text);
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    padding: 2px 6px;
    border: 1px solid var(--holding-value-text);
    margin: 0;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    @media only screen and (max-width: 1599px) {
      font-size: 12px;
      line-height: 16px;
      padding: 2px 3px;
    }
    span {
      font-family: var(--font-family);
      color: var(--holding-value-text);
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      padding: 0;
      margin: 0;
      @media only screen and (max-width: 1599px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .holding-wrap {
    table {
      > thead,
      > tbody {
        > tr {
          > th,
          td {
            &:nth-child(2),
            &:nth-child(6) {
              display: none;
            }
            .teble-ellipsis-wrap {
              width: 75px !important;
              @media only screen and (max-width: 1699px) {
                width: 70px !important;
              }
              @media only screen and (max-width: 1399px) {
                width: 70px !important;
              }
              @media only screen and (max-width: 1299px) {
                width: 65px !important;
              }
              &.class-wrap {
                width: 65px !important;
                @media only screen and (max-width: 1399px) {
                  width: 60px !important;
                }
              }
              &.market-wrap {
                width: 100px !important;
                @media only screen and (max-width: 1699px) {
                  width: 80px !important;
                }
                @media only screen and (max-width: 1399px) {
                  width: 70px !important;
                }
                @media only screen and (max-width: 1299px) {
                  width: 70px !important;
                }
              }
              &.market-wrap-new {
                width: 100px !important;
                @media only screen and (max-width: 1699px) {
                  width: 80px !important;
                }
                @media only screen and (max-width: 1399px) {
                  width: 70px !important;
                }
                @media only screen and (max-width: 1299px) {
                  width: 70px !important;
                }
              }
              &.security_w {
                width: 160px !important;
                @media only screen and (max-width: 1699px) {
                  width: 100px !important;
                }
                @media only screen and (max-width: 1399px) {
                  width: 80px !important;
                }
                @media only screen and (max-width: 1299px) {
                  width: 80px !important;
                }
              }
              &.security_w-new {
                width: 180px !important;
                @media only screen and (max-width: 1699px) {
                  width: 100px !important;
                }
                @media only screen and (max-width: 1299px) {
                  width: 80px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .lm_maximised {
    .holding-wrap {
      .holding-maximize {
        width: 74%;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-property: width;
        .teble-ellipsis-wrap {
          width: 90px !important;
          &.e-w-70 {
            width: 90px !important;
          }
          &.security_w {
            width: 240px !important;
            @media only screen and (min-width: 1699px) {
              width: 340px !important;
            }
            @media only screen and (max-width: 1399px) {
              width: 200px !important;
            }
          }
          &.security_w-new {
            width: 400px !important;
            @media only screen and (min-width: 1699px) {
              width: 600px !important;
            }
            @media only screen and (max-width: 1399px) {
              width: 360px !important;
            }
            @media only screen and (max-width: 1299px) {
              width: 300px !important;
            }
          }
        }
        table {
          > thead,
          > tbody {
            > tr {
              > th,
              td {
                &:last-child,
                &:nth-child(6) {
                  .teble-ellipsis-wrap {
                    width: 160px !important;
                    @media only screen and (min-width: 1699px) {
                      width: 260px !important;
                    }
                    @media only screen and (max-width: 1399px) {
                      width: 135px !important;
                    }
                    &.e-w-70 {
                      width: 160px !important;
                      @media only screen and (min-width: 1699px) {
                        width: 260px !important;
                      }
                      @media only screen and (max-width: 1399px) {
                        width: 135px !important;
                      }
                    }
                    &.market-wrap-new {
                      width: 200px !important;
                      @media only screen and (min-width: 1699px) {
                        width: 260px !important;
                      }
                      @media only screen and (max-width: 1399px) {
                        width: 180px !important;
                      }
                      &.e-w-70 {
                        width: 200px !important;
                        @media only screen and (min-width: 1699px) {
                          width: 260px !important;
                        }
                        @media only screen and (max-width: 1399px) {
                          width: 180px !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          tr {
            td {
              &.text-center {
                .teble-ellipsis-wrap {
                  margin: 0 auto !important;
                }
              }
            }
          }
        }
      }
      table {
        > thead,
        > tbody {
          > tr {
            > th,
            td {
              &:nth-child(2),
              &:nth-child(6) {
                display: table-cell;
              }
              &:nth-child(3) {
                width: 6% !important;
                min-width: 6% !important;
              }
              &:nth-child(1) {
                width: 26% !important;
                min-width: 26% !important;
              }
              &:last-child,
              &:nth-child(6) {
                .teble-ellipsis-wrap {
                  width: 260px !important;
                  @media only screen and (min-width: 1699px) {
                    width: 365px !important;
                  }
                  @media only screen and (max-width: 1399px) {
                    width: 245px !important;
                  }
                  &.e-w-70 {
                    width: 260px !important;
                    @media only screen and (min-width: 1699px) {
                      width: 365px !important;
                    }
                    @media only screen and (max-width: 1399px) {
                      width: 245px !important;
                    }
                  }
                  &.market-wrap-new {
                    width: 270px !important;
                    @media only screen and (min-width: 1699px) {
                      width: 365px !important;
                    }
                    @media only screen and (max-width: 1399px) {
                      width: 255px !important;
                    }
                    @media only screen and (max-width: 1299px) {
                      width: 240px !important;
                    }
                    &.e-w-70 {
                      width: 270px !important;
                      @media only screen and (min-width: 1699px) {
                        width: 365px !important;
                      }
                      @media only screen and (max-width: 1399px) {
                        width: 255px !important;
                      }
                      @media only screen and (max-width: 1299px) {
                        width: 240px !important;
                      }
                    }
                  }
                }
              }
              &:nth-child(5),
              &:nth-child(4) {
                .teble-ellipsis-wrap {
                  @media only screen and (max-width: 1399px) {
                    width: 100px !important;
                  }
                }
              }
            }
          }
        }
        tr {
          td {
            &.text-center {
              .teble-ellipsis-wrap {
                margin: 0 auto !important;
              }
            }
          }
        }
      }
      .teble-ellipsis-wrap {
        width: 150px !important;
        &.e-w-70 {
          width: 150px !important;
        }
        @media only screen and (max-width: 1599px) {
          width: 120px !important;
          &.e-w-70 {
            width: 120px !important;
          }
        }
        @media only screen and (max-width: 1399px) {
          width: 100px !important;
          &.e-w-70 {
            width: 100px !important;
          }
        }
        &.security_w {
          width: 350px !important;
          @media only screen and (min-width: 1699px) {
            width: 480px !important;
          }
          @media only screen and (max-width: 1399px) {
            width: 310px !important;
          }
        }
        &.security_w-new {
          width: 650px !important;
          @media only screen and (min-width: 1699px) {
            width: 850px !important;
          }
          @media only screen and (max-width: 1399px) {
            width: 610px !important;
          }
          @media only screen and (max-width: 1299px) {
            width: 560px !important;
          }
        }
      }
    }
  }
}
</style>
