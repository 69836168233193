<template>
  <div>
    <div class="message-box">
      <div
        id="chatMessages"
        class="message-group"
        :style="{ height: CompChatHeight + 'px' }"
      >
        <div
          v-for="(msg, index) in messages"
          :key="index"
          :class="{
            'message-send right': profiles.id == msg.user_id,
            'message-receive left': profiles.id != msg.user_id,
          }"
        >
          <!-- <img alt="Chat" src="@/assets/images/chat-user.png" v-if="profiles.id != msg.user_id" /> -->
          <span class="message-log" v-if="profiles.id != msg.user_id"
            >{{ msg.user_name }}, {{ displayDateTime(msg.created_at) }}</span
          >
          <span class="message-log" v-if="profiles.id == msg.user_id">{{
            displayDateTime(msg.created_at)
          }}</span>
          <div class="message-text">
            <p>{{ msg.message }}</p>
          </div>
          <!-- <img alt="Chat" src="@/assets/images/chat-user.png" v-if="profiles.id == msg.user_id" /> -->
        </div>

        <div class="typing" v-if="typing == true">
          <div class="bubble">
            <div class="ellipsis dot_1"></div>
            <div class="ellipsis dot_2"></div>
            <div class="ellipsis dot_3"></div>
          </div>
        </div>
      </div>
      <form class="message-form" @submit="sendMessage">
        <input
          type="text"
          class="form-control chat-input"
          placeholder="Type to Chat"
          v-model="message"
        />
        <button type="submit" class="send-btn">
          <img
            alt="Chat"
            class="chat-img-light"
            src="@/assets/images/send.png"
          />
          <img
            alt="Chat"
            class="chat-img-dark"
            src="@/assets/images/white-send.png"
          />
        </button>
      </form>
    </div>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Chat",
  data() {
    return {
      isConnected: false,
      loading: false,
      message: "",
      messages: [],
      typing: false,
      room: null,
      page:1,
      previousScrollHeight: 0,
    };
  },
  props: ["CompChatHeight"],
  sockets: {
    connect: function() {
      // console.log("User Socket connected");
      this.isConnected = true;
      //this.loading = false;
    },
    disconnect() {
      this.isConnected = false;
    },
    message(data) {
      // console.log(data);
      this.messages.push(data);
      // this.$store.dispatch("dashboard/setUserChatCount",10);
    },
    loadMessage(data) {
      //this.messages.push(data);
      this.loading = true;
      if(data.nextPage > this.page){
        this.page = data.nextPage;
        this.messages = [...data.messages,...this.messages];
      }
      //this.messages = [...this.messages, ...data];
      this.loading = false;
    },
    typing(data) {
      this.typing = data;
    },
  },
  computed: {
    profiles() {
      let profile = this.$store.getters["dashboard/getProfiles"];
      if (profile.id && profile.id != 2) {
        this.sendSocketMessage("joinUser", {
          user_id: profile.id,
          user_name: profile.name,
        });
        if(this.page == 1){
          this.sendSocketMessage("loadMessage", {
            room: "room" + profile.id,
            is_user: true,
            page: this.page
          });
        }
      }
      return profile;
    },
  },
  watch: {
    profiles(val) {
      if (val.id) {
        this.room = "room" + val.id;
        this.loading = false;
      }
    },
    message(value) {
      value
        ? this.sendSocketMessage("typing", { room: this.room, is_typing: true })
        : this.sendSocketMessage("typing", {
            room: this.room,
            is_typing: false,
          });
    },
  },
  methods: {
    loadPreviousMessages: (currentDom) => {
      currentDom.sendSocketMessage("loadMessage", {
        room: "room" + currentDom.profiles.id,
        is_user: true,
        page: currentDom.page
      });
    },
    displayTime: (date) => {
      return new Date(date).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    displayDateTime: (date) => {
      var cd = new Date(date);
      var td = new Date();

      var chat_locale_date =
        cd.getFullYear() + "-" + (cd.getMonth() + 1) + "-" + cd.getDate();
      var chat_local_time = cd.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      var today_locale_date =
        td.getFullYear() + "-" + (td.getMonth() + 1) + "-" + td.getDate();
      var past_date = new Date(chat_locale_date);
      var current_date = new Date(today_locale_date);
      var diffTime = Math.abs(current_date - past_date);
      var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays == 0) {
        return chat_local_time;
      } else {
        return cd.toLocaleDateString() + " " + chat_local_time;
      }
    },
    sendMessage: function(e) {
      e.preventDefault();

      if (this.message.length <= 0) {
        // console.log("empty message");
        return false;
      }

      let data = {
        room: "room" + this.profiles.id,
        user_id: this.profiles.id,
        user_name: this.profiles.name,
        message: this.message,
        is_user: true,
        created_at: new Date(),
      };

      this.messages.push(data);
      this.message = "";

      this.sendSocketMessage("chatMessage", data);
    },
    sendSocketMessage: function(event, data) {
      this.$socket.emit(event, data);
    },
  },
  created() {},
  updated() {
    if(this.page == 1 || this.page == 2){
      var container = document.getElementById("chatMessages");
      container.scrollTop = container.scrollHeight;
    }
  },
  mounted() {
    if (this.profiles.id) {
      this.loading = false;
    }

    let currentDom = this;
        
    let int = setInterval(() => {
      let container = document.getElementById("chatMessages");
      if(container){
        container.removeEventListener('scroll',() => {
          // remove listener code
        });
        container.addEventListener("scroll", async function() {
          if(container.scrollTop == 0){
            currentDom.loadPreviousMessages(currentDom);
            let setScroll = container.scrollHeight - currentDom.previousScrollHeight;
            currentDom.previousScrollHeight = container.scrollHeight;
            container.scrollTop = setScroll;
          }
        });
        clearInterval(int);
      }
    }, 100);
  },
  destroyed() {
    this.$socket.removeAllListeners();
  },
};
</script>
