export default {
	setBondsTrades(state, data) {
		let bonds_items = new Array();
		let bonds_trades = new Array();
		let bond_data=data.data;
		for (var i in bond_data) {
			let one = new Array();
			one.push(new Date(bond_data[i].created).getTime());
			one.push(bond_data[i].total_count);
			bonds_items.push(one);
		}
		bonds_trades.push({
            name: "", 
            data:  bonds_items
        });
		state.bonds_trades = bonds_trades;
	},
	setEquitiesTrades(state, data) {
		let equities_items = new Array();
		let equities_trades = new Array();
		let equities_data=data.data;
		for (var i in equities_data) {
			let one = new Array();
			one.push(new Date(equities_data[i].created).getTime());
			one.push(equities_data[i].total_count);
			equities_items.push(one);
		}
		equities_trades.push({
            name: "", 
            data:  equities_items
        });
		state.equities_trades = equities_trades;
	},
	setCashTrades(state, data) {
		let cash_items = new Array();
		let cash_trades = new Array();
		let cash_data=data.data;
		for (var i in cash_data) {
			let one = new Array();
			one.push(new Date(cash_data[i].created).getTime());
			one.push(cash_data[i].total_count);
			cash_items.push(one);
		}
		cash_trades.push({
            name: "", 
            data:  cash_items
        });
		state.cash_trades = cash_trades;
	},
	setFundsTrades(state, data) {
		let funds_items = new Array();
		let funds_trades = new Array();
		let funds_data=data.data;
		for (var i in funds_data) {
			let one = new Array();
			one.push(new Date(funds_data[i].created).getTime());
			one.push(funds_data[i].total_count);
			funds_items.push(one);
		}
		funds_trades.push({
            name: "", 
            data:  funds_items
        });
		state.funds_trades = funds_trades;
	},
	setAllTrades(state, data) {
		let all_items = new Array();
		let all_trades = new Array();
		let all_data=data.data;
		for (var i in all_data) {
			let one = new Array();
			one.push(new Date(all_data[i].created).getTime());
			one.push(all_data[i].total_count);
			all_items.push(one);
		}
		all_trades.push({
			name: "",
            data:  all_items
        });
		state.all_trades = all_trades;
	},
	setTimeline(state, timeline) {
		state.timeline = timeline
	},
	setMaxValue(state, max_value) {
		state.max_value = max_value
	},
	setMovingAvg(state, moving_avg) {
		state.moving_avg = moving_avg
	},
	setPeriodicity(state, periodicity) {
		state.periodicity = periodicity
	},
	setEquitiesStatus(state, equities_status) {
		state.equities_status = equities_status
	},
	setBondsStatus(state, bonds_status) {
		state.bonds_status = bonds_status
	},
	setFundsStatus(state, funds_status){
		state.funds_status = funds_status
	},
	setCashStatus(state, cash_status){
		state.cash_status = cash_status
	},
}