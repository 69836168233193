import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import SSOLogin from "../views/SSOLogin.vue";
import ResearchDetails from "../views/ResearchDetails.vue";

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  const userToken = localStorage.getItem('user-token')
  if (userToken) {
    next()
  } else {
    next({name: 'login'})
  }
} 
const isLoginAlready = (to, from, next) => {
  const userToken = localStorage.getItem('user-token')
  if (userToken) {
    next('/')
  } else {
    next()
  }
} 

const router = new VueRouter({
  mode: "history",
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: "/research-details",
      name: "ResearchDetails",
      beforeEnter: ifAuthenticated,
      component: ResearchDetails
    },
    {
      path: "/login",
      name: "login",
      beforeEnter: isLoginAlready,
      component: Login
    },
    {
      path: "/sso-login",
      name: "SSOlogin",
      component: SSOLogin
    },
    {
      path: "/",
      name: "Dashboard",
      beforeEnter: ifAuthenticated,
      component: Dashboard
    }
  ]
});
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
})

export default router;
