import axios from '../../axios'

export default {
	fetchItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('cash-flows-chart', payload)
				.then((response) => {
					commit('setCouponItems', response.data.data.coupon_data)
					commit('setRedemptionItems', response.data.data.redemption_data)
					commit('setTrailerItems', response.data.data.trailer_data)
					commit('setAllItems', response.data.data.all_cashflow_data)
					commit('setMaxValue', response.data.data.max_value)
					commit('setMinValue', response.data.data.min_value)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchTimeline({
		commit
	}, payload) {
		commit('setTimeline', payload)
	},
	updatePeriodicity({
		commit
	}, payload) {
		commit('setPeriodicity', payload)
	}
}