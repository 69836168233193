<template>
  <div class="performance-wrap">
    <div :class="drawer == true ? 'holding-maximize' : ''">
      <v-card class="custome-card">
        <v-data-table
          id="performance-scroll-table"
          :headers="headers"
          :items="items"
          :search="search"
          hide-default-footer
          class="custome-table"
          disable-pagination
          :item-key="'id'"
          fixed-header
          :sort-by.sync="sortBy"
          :sort-asc.sync="sortAsc"
          :style="{ height: CompPerformanceHeight + 'px' }"
        >
          <template v-slot:body.prepend>
            <tr class="table-dropdown sticky-header">
              <td width="23%">
                <div class="form-group">
                  <v-text-field
                    class="form-control"
                    type="text"
                    hide-details="auto"
                    v-model="search_table.security_name"
                  ></v-text-field>
                </div>
              </td>
              <td width="11%">
                <div class="form-group">
                  <v-text-field
                    class="form-control"
                    type="text"
                    hide-details="auto"
                    v-model="search_table.total_quantity"
                  ></v-text-field>
                </div>
              </td>
              <td width="11%">
                <div class="form-group">
                  <v-text-field
                    class="form-control"
                    type="text"
                    hide-details="auto"
                    v-model="search_table.avg_cost"
                  ></v-text-field>
                </div>
              </td>
              <td width="11%">
                <div class="form-group">
                  <v-text-field
                    class="form-control"
                    type="text"
                    hide-details="auto"
                    v-model="search_table.cost_value"
                  ></v-text-field>
                </div>
              </td>
              <td width="11%">
                <div class="form-group">
                  <v-text-field
                    class="form-control"
                    type="text"
                    hide-details="auto"
                    v-model="search_table.market_price"
                  ></v-text-field>
                </div>
              </td>
              <td width="11%">
                <div class="form-group">
                  <v-text-field
                    class="form-control"
                    type="text"
                    hide-details="auto"
                    v-model="search_table.market_value"
                  ></v-text-field>
                </div>
              </td>
              <td width="11%">
                <div class="form-group">
                  <v-text-field
                    class="form-control"
                    type="text"
                    hide-details="auto"
                    v-model="search_table.un_realized"
                  ></v-text-field>
                </div>
              </td>
              <td width="11%">
                <div class="form-group">
                  <v-text-field
                    class="form-control"
                    type="text"
                    hide-details="auto"
                    v-model="search_table.realized"
                  ></v-text-field>
                </div>
              </td>
            </tr>
          </template>
          <template v-slot:[`item.security_name`]="{ item }">
            <span class="teble-ellipsis-wrap security_w">
              {{ item.security_name }}
            </span>
          </template>
          <template v-slot:[`item.total_quantity`]="{ item }">
            <span class="teble-ellipsis-wrap">
              {{ item.total_quantity }}
            </span>
          </template>
          <template v-slot:[`item.avg_cost`]="{ item }">
            <span class="teble-ellipsis-wrap">
              {{ formatPrice(item.avg_cost, item.market_id) }}
            </span>
          </template>
          <template v-slot:[`item.cost_value`]="{ item }">
            <span class="teble-ellipsis-wrap">
              {{ item.cost_value }}
            </span>
          </template>
          <template v-slot:[`item.market_price`]="{ item }">
            <span class="teble-ellipsis-wrap">
              {{ formatPrice(item.market_price, item.market_id) }}
            </span>
          </template>
          <template v-slot:[`item.market_value`]="{ item }">
            <span class="teble-ellipsis-wrap">
              {{ item.market_value }}
            </span>
          </template>
          <template v-slot:[`item.un_realized`]="{ item }">
            <span class="teble-ellipsis-wrap" v-if="item.un_realized != 0">
              <a href="javascript:void(0)" @click="showUnRealizedModal(item)">{{
                item.un_realized
              }}</a>
            </span>
            <span class="teble-ellipsis-wrap" v-if="item.un_realized == 0">
              {{ item.un_realized }}
            </span>
          </template>
          <template v-slot:[`item.realized`]="{ item }">
            <span class="teble-ellipsis-wrap" v-if="item.realized != 0">
              <a href="javascript:void(0)" @click="showRealizedModal(item)">{{
                item.realized
              }}</a>
            </span>
            <span class="teble-ellipsis-wrap" v-if="item.realized == 0">
              {{ item.realized }}
            </span>
          </template>
        </v-data-table>
      </v-card>
      <transition name="fade">
        <div class="loading full-page" v-show="performanceLoading">
          <div><span></span><span></span><span></span><span></span></div>
        </div>
      </transition>
    </div>
    <v-dialog
      v-model="unrealizedModal"
      max-width="1050"
      content-class="performance-table"
    >
      <v-card class="custome-modal table-t-body">
        <v-card-title class="headline px-2 py-1">
          <div
            class="box-title d-flex justify-content-between align-items-center"
          >
            <h4>
              Performance of {{ pnl_security_name }} ({{
                dateFromat(this.start_filter_date)
              }}
              To {{ dateFromat(this.end_filter_date) }})
            </h4>
            <a href="javascript:void(0);" @click="unrealizedModal = false">
              <font-awesome-icon class="close-icon" icon="times" />
            </a>
          </div>
        </v-card-title>

        <v-card-text>
          <v-simple-table
            class="custome-table child-table popup-table"
            fixed-header
            :height="unrealized_items.length > 25 ? '75vh' : ''"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-start" width="10%">
                    Report Date
                  </th>
                  <th class="text-center" width="10%">
                    Amount
                  </th>
                  <th class="text-center" width="14%">
                    Market Price
                  </th>
                  <th class="text-center" width="14%">
                    Mark-To-Market
                  </th>
                  <th class="text-center" width="14%">
                    New Business
                  </th>
                  <th class="text-center" width="14%">
                    Accrued Interest
                  </th>
                  <th class="text-center" width="12%">
                    Realized
                  </th>
                  <th class="text-center" width="12%">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="unrealized_item in unrealized_items"
                  :key="unrealized_item.id"
                >
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.date
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.total_quantity
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      formatPrice(
                        unrealized_item.market_price,
                        unrealized_item.market_id
                      )
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.mtm
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.nb
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.cost_of_cary
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.realized
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.total
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <transition name="fade">
            <div class="loading full-page" v-show="popupLoading">
              <div><span></span><span></span><span></span><span></span></div>
            </div>
          </transition>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="realizedModal"
      max-width="1050"
      content-class="performance-table"
    >
      <v-card class="custome-modal table-t-body">
        <v-card-title class="headline">
          <div
            class="box-title d-flex justify-content-between align-items-center"
          >
            <h4>
              Performance of {{ pnl_security_name }} ({{
                dateFromat(this.start_filter_date)
              }}
              To {{ dateFromat(this.end_filter_date) }})
            </h4>
            <a href="javascript:void(0);" @click="realizedModal = false">
              <font-awesome-icon class="close-icon" icon="times" />
            </a>
          </div>
        </v-card-title>

        <v-card-text>
          <v-simple-table
            class="custome-table child-table popup-table"
            fixed-header
            :height="realized_items.length > 25 ? '75vh' : ''"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-start" width="12%">
                    Report Date
                  </th>
                  <th class="text-center" width="14%">
                    Amount
                  </th>
                  <th class="text-center" width="15%">
                    Buy Price
                  </th>
                  <th class="text-center" width="15%">
                    Sell Price
                  </th>
                  <th class="text-center" width="15%">
                    Trading P&L
                  </th>
                  <th class="text-center" width="15%">
                    Paid Interest
                  </th>
                  <th class="text-center" width="14%">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="realized_item in realized_items"
                  :key="realized_item.id"
                >
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      realized_item.date
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      realized_item.total_quantity
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      formatPrice(
                        realized_item.buy_price,
                        realized_item.market_id
                      )
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      formatPrice(
                        realized_item.sell_price,
                        realized_item.market_id
                      )
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      realized_item.nb
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      realized_item.paid_interest
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      realized_item.total
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <transition name="fade">
            <div class="loading full-page" v-show="popupLoading">
              <div><span></span><span></span><span></span><span></span></div>
            </div>
          </transition>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog content-class="w-auto" v-model="dateRangeDialog" max-width="800">
      <v-card class="custome-modal">
        <v-card-title class="headline">
          <div
            class="box-title d-flex justify-content-between align-items-center"
          >
            <h4>Filter</h4>
            <a href="javascript:void(0)" @click="dateRangeDialog = false">
              <font-awesome-icon class="close-icon" icon="times" />
            </a>
          </div>
        </v-card-title>

        <v-card-text>
          <template>
            <date-range-picker
              ref="picker"
              class="calender-custome"
              :opens="opens"
              @toggle="checkOpen"
              v-model="dateRange"
              :always-show-calendars="false"
              :alwaysShowCalendars="false"
              :autoApply="autoApply"
              :showDropdowns="showDropdowns"
              :linkedCalendars="linkedCalendars"
              :ranges="ranges"
              @update="updateDateFilter"
            >
              <template v-slot:input="picker" style="min-width: 350px;">
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
              </template>
            </date-range-picker>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

   
  </div>
</template>

<script>
// import VueApexCharts from "vue-apexcharts";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
// import PerformanceChart from "@/components/PerformanceChart.vue";
export default {
  name: "Performance",
  components: {
    // PerformanceChart: PerformanceChart,
    DateRangePicker: DateRangePicker,
  },
  data() {
    return {
      realizedModal: false,
      unrealizedModal: false,
      unrealizedSlide: false,
      realizedSlide: false,
      pnl_security_name: "",
      dateRangeDialog: false,
      showDropdowns: false,
      autoApply: true,
      alwaysShowCalendars: false,
      isExpandHide: 0,
      interestdialog: false,
      tredingdialog: false,
      expanded: [],
      singleExpand: true,
      performanceLoading: false,
      popupLoading: false,
      drawer: null,
      ScannerDrawer: null,
      menu2: false,
      opens: "inline",
      locale: { firstDay: 0, format: "YYYY-MM-DD" },
      linkedCalendars: false,
      dateRange: "",
      ranges: this.getFilterDateRange(),
      headers: [
        {
          text: "Name",
          align: "start",
          value: "security_name",
          width: "23%",
          filter: (value) => {
            if (!this.search_table.security_name) return true;
            if (value) {
              return (
                String(value.toLowerCase()).search(
                  this.search_table.security_name.toLowerCase()
                ) > -1
              );
            }
          },
        },
        {
          text: "Amount",
          align: "center",
          width: "11%",
          value: "total_quantity",
          filter: (value) => {
            if (!this.search_table.total_quantity) return true;
            return (
              String(this.removeNumberFormat(value)).search(
                this.removeNumberFormat(this.search_table.total_quantity)
              ) > -1
            );
          },
        },
        {
          text: "Cost Basis",
          align: "center",
          width: "11%",
          value: "avg_cost",
          filter: (value) => {
            if (!this.search_table.avg_cost) return true;
            return (
              String(this.removeNumberFormat(value)).search(
                this.removeNumberFormat(this.search_table.avg_cost)
              ) > -1
            );
          },
        },
        {
          text: "Cost Value",
          align: "center",
          width: "11%",
          value: "cost_value",
          filter: (value) => {
            if (!this.search_table.cost_value) return true;
            return (
              String(this.removeNumberFormat(value)).search(
                this.removeNumberFormat(this.search_table.cost_value)
              ) > -1
            );
          },
        },
        {
          text: "Market Price",
          align: "center",
          width: "11%",
          value: "market_price",
          filter: (value) => {
            if (!this.search_table.market_price) return true;
            return (
              String(this.removeNumberFormat(value)).search(
                this.removeNumberFormat(this.search_table.market_price)
              ) > -1
            );
          },
        },
        {
          text: "Market Value",
          align: "center",
          width: "11%",
          value: "market_value",
          filter: (value) => {
            if (!this.search_table.market_value) return true;
            return (
              String(this.removeNumberFormat(value)).search(
                this.removeNumberFormat(this.search_table.market_value)
              ) > -1
            );
          },
        },
        {
          text: "Unrealized",
          align: "center",
          width: "11%",
          value: "un_realized",
          filter: (value) => {
            if (!this.search_table.un_realized) return true;
            return (
              String(this.removeNumberFormat(value)).search(
                this.removeNumberFormat(this.search_table.un_realized)
              ) > -1
            );
          },
        },
        {
          text: "Realized",
          align: "center",
          width: "11%",
          value: "realized",
          filter: (value) => {
            if (!this.search_table.realized) return true;
            return (
              String(this.removeNumberFormat(value)).search(
                this.removeNumberFormat(this.search_table.realized)
              ) > -1
            );
          },
        },
      ],
      search: "",
      sortBy: "security_name",
      sortAsc: true,
      pseries: [
        {
          name: "Total P&L",
          data: [[1327359600000, 30.95]],
        },
      ],
      chartOptions: {
        chart: {
          id: "area-datetime",
          type: "area",
          foreColor:
            localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : "#333333",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
          //redrawOnParentResize: true,
          // redrawOnWindowResize: true,
        },
        annotations: {
          yaxis: [
            {
              y: 0,
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        colors:
          localStorage.getItem("isDarkActive") == 1 ? ["#ffffff"] : ["#001a34"],
        // colors: ["#ffffff"],
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat",
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        grid: {
          // borderColor: '#333333',
          borderColor:
            localStorage.getItem("isDarkActive") == 1 ? "#333333" : "#dbdbdb",
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.3,
            stops: [0, 100],
          },
        },
        legend: {
          show: false,
        },
      },
      search_table: {
        payment_date: null,
      },
    };
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
  computed: {
    items() {
      return this.$store.getters["performance/getItems"];
    },
    unrealized_items() {
      return this.$store.getters["performance/getUnrealizedItem"];
    },
    realized_items() {
      return this.$store.getters["performance/getRealizedItem"];
    },
    currency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
    performanceChartWidth: {
      get() {
        return parseInt(this.CompPerformanceWidth) / 2.9;
      },
    },
    performanceChartHeight: {
      get() {
        return parseInt(this.CompPerformanceHeight) / 2;
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
    all_portfolios() {
      return this.$store.getters["dashboard/getAllPortfolio"];
    },
    chart_items() {
      return this.$store.getters["performance_chart/getItems"];
    },
    start_filter_date: {
      get() {
        return this.$store.getters["dashboard/getStartFilterDate"];
      },
    },
    end_filter_date: {
      get() {
        return this.$store.getters["dashboard/getEndFilterDate"];
      },
    },
    next_page_index() {
      return this.$store.getters["performance/getNextPage"];
    },
    record_length() {
      return this.$store.getters["performance/getLengthRecord"];
    },
    start_record() {
      return this.$store.getters["performance/getStartRecord"];
    },
    unrealized_next_page_index() {
      return this.$store.getters["performance/getUnrealizedNextPage"];
    },
    unrealized_length_record() {
      return this.$store.getters["performance/getUnrealizedLengthRecord"];
    },
    unrealized_start_record() {
      return this.$store.getters["performance/getUnrealizedStartRecord"];
    },
    realized_next_page_index() {
      return this.$store.getters["performance/getRealizedNextPage"];
    },
    realized_length_record() {
      return this.$store.getters["performance/getRealizedLengthRecord"];
    },
    realized_start_record() {
      return this.$store.getters["performance/getRealizedStartRecord"];
    },
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
  },
  watch: {
    async currency() {
      await this.updateItems();
      await this.fetchPerformance(1);
    },
    async filter_portfolio() {
      await this.updateItems();
      await this.fetchPerformance(1);
      this.isExpandHide = 1;
    },
    async all_portfolios() {
      this.isExpandHide = 1;
    },
    async start_filter_date(new_date, old_date) {
      if (new_date != old_date) {
        await this.updateItems();
        await this.fetchPerformance(1);
      }
    },
    async end_filter_date(new_date, old_date) {
      if (new_date != old_date) {
        await this.updateItems();
        await this.fetchPerformance(1);
      }
    },
  },
  methods: {

    async showUnRealizedModal(item) {
      this.popupLoading = true;
      this.pnl_security_name = item.security_name;
      // if (this.is_maximize == 1) {
      //   this.unrealizedSlide = true;
      //   this.realizedSlide = false;
      //   this.drawer = true;
      // } else {
      //   this.unrealizedModal = true;
      // }
      const returnData = await this.$store.dispatch("performance/fetchItemUnRealized", {
        detail_id: item.id,
        record_type: "unrealized",
        filter_portfolio_ids: this.filter_portfolio,
        currency_filter: this.currency,
        start_date: this.start_filter_date,
        end_date: this.end_filter_date,
        draw: this.unrealized_next_page_index,
        start: this.unrealized_start_record,
        length: this.unrealized_length_record,
      });
      returnData['CompPerformanceHeight'] = this.CompPerformanceHeight;
      returnData['pnl_security_name'] = this.pnl_security_name;
      returnData['start_filter_date'] = this.start_filter_date;
      returnData['end_filter_date'] = this.end_filter_date;
      this.$emit("performanceUnRealizeView", returnData);
      this.popupLoading = false;
    },
    async showRealizedModal(item) {
      this.popupLoading = true;
      this.pnl_security_name = item.security_name;
      // if (this.is_maximize == 1) {
      //   this.realizedSlide = true;
      //   this.unrealizedSlide = false;
      //   this.drawer = true;
      // } else {
      //   this.realizedModal = true;
      // }
      const returnData = await this.$store.dispatch("performance/fetchItemRealized", {
        detail_id: item.id,
        filter_portfolio_ids: this.filter_portfolio,
        record_type: "realized",
        currency_filter: this.currency,
        start_date: this.start_filter_date,
        end_date: this.end_filter_date,
        draw: this.realized_next_page_index,
        start: this.realized_start_record,
        length: this.realized_length_record,
      });
      returnData['CompPerformanceHeight'] = this.CompPerformanceHeight;
      returnData['pnl_security_name'] = this.pnl_security_name;
      returnData['start_filter_date'] = this.start_filter_date;
      returnData['end_filter_date'] = this.end_filter_date;
      this.$emit("performanceRealizeView", returnData);
      this.popupLoading = false;
    },
    checkOpen(open) {
      console.log("event: open", open);
    },
    async dateRangeBtn() {
      this.dateRangeDialog = true;
    },
    async updateDateFilter() {
      let first_date = moment(this.dateRange.startDate).format("YYYY-MM-DD");
      let end_date = moment(this.dateRange.endDate).format("YYYY-MM-DD");
      localStorage.setItem("start_filter_date", first_date);
      localStorage.setItem("end_filter_date", end_date);
      await this.$store.dispatch("dashboard/updateStartFilterDate", first_date);
      await this.$store.dispatch("dashboard/updateEndFilterDate", end_date);
      document.getElementById("date-range-text").innerHTML =
        first_date + " - " + end_date;
      this.dateRangeDialog = false;
    },
    onClickPicker() {
      this.togglePicker(null, true);
    },
    togglePicker(value, event) {
      if (typeof value === "boolean") {
        this.open = value;
      } else {
        this.open = !this.open;
      }
      if (event === true) this.$emit("toggle", this.open, this.togglePicker);
    },
    async fetchPerformance(is_filter) {
      this.performanceLoading = true;
      if (is_filter == 1) {
        await this.resetPageIndex();
      }
      await this.$store.dispatch("performance/fetchItems", {
        is_filter: is_filter,
        filter_portfolio_ids: this.filter_portfolio,
        currency_filter: this.currency,
        start_date: this.start_filter_date,
        end_date: this.end_filter_date,
        draw: this.next_page_index,
        start: this.start_record,
        length: this.record_length,
        security_name: this.search_table.security_name,
      });
      this.performanceLoading = false;
    },
    async resetPageIndex() {
      var items_prm = [];
      await this.$store.dispatch("performance/updateItems", items_prm);
    },
    async updateItems() {
      var items_prm = [];
      await this.$store.dispatch("performance/updateItems", items_prm);
    },
    DetailsDrawer() {
      if (!this.drawer) {
        this.showUnRealizedModal(this.items[0]);
      } else {
        this.drawer = false;
      }
    },
  },
  props: ["CompisPerformanceOpen","CompPerformanceHeight", "CompPerformanceWidth"],
  async mounted() {
    if(this.CompisPerformanceOpen){
      // console.log("call Performance component mount");
      let today = new Date();
      let today_date =
        today.getFullYear() +
        "-" +
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + today.getDate()).slice(-2);
        var oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 1);
        let l_year = oneYearFromNow.getFullYear()+'-'+("0" + (oneYearFromNow.getMonth() + 1)).slice(-2)+'-'+oneYearFromNow.getDate();
      //   console.log("oneYearFromNow",l_year) 
      // let ytd_date = new Date(today.getFullYear(), 0, 1);
      // console.log("ytd_date",ytd_date)
      // let ytd_date = new Date(today.getFullYear(), 0, 1);
      // let ytd_dates =
      //   ytd_date.getFullYear() +
      //   "-" +
      //   ("0" + (ytd_date.getMonth() + 1)).slice(-2) +
      //   "-" +
      //   ("0" + ytd_date.getDate()).slice(-2);
      var last_visit_date = localStorage.getItem("last_visit_date ");
      if (last_visit_date != today_date) {
        localStorage.setItem("last_visit_date", today_date);
        localStorage.setItem("start_filter_date", l_year);
        localStorage.setItem("end_filter_date", today_date);
      }

      let currenctDom = this;

      setTimeout(() => {
        var x = document.getElementsByClassName("lm_header");
        for (let i = 0; i < x.length; i++) {
          let isPFind = x[i].querySelectorAll("li[title='Statements']").length;
          // console.log("isPFind>", isPFind);
          // console.log("isPFindii>>", i);
          if (isPFind > 0 && i == 3) {
            var dateRangeBox = document.createElement("div");
            dateRangeBox.className = "date-range-box";
            // dateRangeBox.style.visibility = "hidden";
            dateRangeBox.innerHTML =
              '<span><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-calendar-alt fa-w-14 fa-3x"><path class="CalendarSvgColor" d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg> </span><div id="date-range-text">' +
              currenctDom.formatDate(currenctDom.start_filter_date) +
              " - " +
              currenctDom.formatDate(currenctDom.end_filter_date) +
              "</div>";
            var isDateRange = document.getElementsByClassName("date-range-box");
            if (!(isDateRange.length > 0)) {
              x[i].appendChild(dateRangeBox);
            }
            dateRangeBox.onclick = async function() {
              await currenctDom.dateRangeBtn();
            };
          }
        }
      }, 3500);

      this.ranges = this.getFilterDateRange();
      let start_date = new Date(this.start_filter_date);
      start_date.setHours(0, 0, 0, 0);
      let end_date = new Date(this.end_filter_date);
      end_date.setHours(0, 0, 0, 0);
      this.dateRange = {
        startDate: start_date,
        endDate: end_date,
      };
      await currenctDom.fetchPerformance(0);
      const listElm = document.querySelector("#performance-scroll-table");
      listElm.addEventListener("scroll", function() {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (currenctDom.next_page_index != 0) currenctDom.fetchPerformance(0);
        } else {
          this.performanceLoading = false;
        }
      });
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.performance-wrap {
  padding: 0;

  .child-table td {
    padding: 1.5px 8px !important;
  }
  #performance-scroll-table {
    overflow: auto;
  }
}
#app {
  .performance-wrap {
    .custome-table {
      table {
        > tbody,
        > thead {
          > tr {
            td,
            > th {
              &.text-center {
                .teble-ellipsis-wrap {
                  margin: 0 auto !important;
                }
              }
              .teble-ellipsis-wrap {
                width: 120px !important;
                @media only screen and (max-width: 1699px) {
                  width: 90px !important;
                }
                @media only screen and (max-width: 1399px) {
                  width: 85px !important;
                }
                @media only screen and (max-width: 1299px) {
                  width: 70px !important;
                }
                &.security_w {
                  width: 200px !important;
                  @media only screen and (max-width: 1699px) {
                    width: 150px !important;
                  }
                  @media only screen and (max-width: 1399px) {
                    width: 145px !important;
                  }
                  @media only screen and (max-width: 1299px) {
                    width: 125px !important;
                  }
                }
              }
              &:nth-child(3) {
                display: none;
              }
              &:nth-child(4) {
                display: none;
              }
            }
          }
        }
      }
      &.popup-table {
        table {
          > tbody,
          > thead {
            > tr {
              td,
              > th {
                .teble-ellipsis-wrap {
                  width: 110px !important;
                }
                &:nth-child(1) {
                  .teble-ellipsis-wrap {
                    width: 75px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .v-dialog {
    &.performance-table {
      .custome-table {
        &.popup-table {
          table {
            > tbody,
            > thead {
              > tr {
                td,
                > th {
                  .teble-ellipsis-wrap {
                    width: 90% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .performance-table {
    overflow: hidden;
  }
  .custome-modal {
    .v-card__text {
      padding-bottom: 0 !important;
    }
  }
  .lm_maximised {
    .performance-wrap {
      .custome-table {
        table {
          > thead,
          > tbody {
            > tr {
              > th,
              td {
                .teble-ellipsis-wrap {
                  width: 180px !important;
                  @media only screen and (max-width: 1699px) {
                    width: 140px !important;
                  }
                  @media only screen and (max-width: 1399px) {
                    width: 125px !important;
                  }
                  @media only screen and (max-width: 1299px) {
                    width: 120px !important;
                  }
                  &.security_w {
                    width: 420px !important;
                    @media only screen and (max-width: 1699px) {
                      width: 310px !important;
                    }
                    @media only screen and (max-width: 1399px) {
                      width: 295px !important;
                    }
                    @media only screen and (max-width: 1299px) {
                      width: 275px !important;
                    }
                  }
                }
                &:nth-child(3) {
                  display: table-cell;
                }
                &:nth-child(4) {
                  display: table-cell;
                }
              }
            }
          }
        }
      }
      .holding-maximize {
        position: relative;
        width: 38%;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-property: width;
        .custome-table {
          table {
            > tbody,
            > thead {
              > tr {
                td,
                > th {
                  &:nth-child(2),
                  &:nth-child(3),
                  &:nth-child(4),
                  &:nth-child(5),
                  &:nth-child(6) {
                    display: none;
                  }
                  .teble-ellipsis-wrap {
                    width: 130px !important;
                    @media only screen and (max-width: 1699px) {
                      width: 125px !important;
                    }
                    @media only screen and (max-width: 1399px) {
                      width: 120px !important;
                    }
                    @media only screen and (max-width: 1299px) {
                      width: 110px !important;
                    }
                    &.security_w {
                      width: 360px !important;
                      @media only screen and (max-width: 1699px) {
                        width: 250px !important;
                      }
                      @media only screen and (max-width: 1399px) {
                        width: 240px !important;
                      }
                      @media only screen and (max-width: 1299px) {
                        width: 215px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .order-form-sidebar {
        display: block;
        .box-title {
          width: 100%;
          text-align: left;
          h4 {
            font-family: var(--font-family);
            font-weight: 500;
            font-size: 18px;
            color: var(--modal-title);
            margin-bottom: 0;
          }
        }
        .custome-table {
          &.popup-table {
            table {
              > tbody,
              > thead {
                > tr {
                  td,
                  > th {
                    // .teble-ellipsis-wrap {
                    //   width: 135px !important;
                    //   @media only screen and (max-width: 1699px) {
                    //     width: 100px !important;
                    //   }
                    //   @media only screen and (max-width: 1399px) {
                    //     width: 90px !important;
                    //   }
                    //   @media only screen and (max-width: 1299px) {
                    //     width: 80px !important;
                    //   }
                    // }
                    // &:nth-child(1) {
                    //   .teble-ellipsis-wrap {
                    //     width: 135px !important;
                    //     @media only screen and (max-width: 1699px) {
                    //       width: 100px !important;
                    //     }
                    //     @media only screen and (max-width: 1399px) {
                    //       width: 90px !important;
                    //     }
                    //     @media only screen and (max-width: 1299px) {
                    //       width: 80px !important;
                    //     }
                    //   }
                    // }
                    .teble-ellipsis-wrap {
                      width: 90% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
