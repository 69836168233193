import axios from '../../axios'

export default {
	fetchItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('holding-chart-data', payload)
				.then((response) => {
					commit('setItems', response.data.data.chart_data)
					resolve(response.data.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	fetchTimeline({
		commit
	}, payload) {
		commit('setTimeline', payload)
	},
	fetchIsDailyHistory({
		commit
	}, payload) {
		commit('setIsDailyHistory', payload)
	}
}