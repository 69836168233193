export default {
	getItem: state => (id) => {
		return state.items.find((item) => item.id === id)
	},
	getStatementUrl: state => {
		return state.statement_url
	},
	getStatementName: state => {
		return state.statement_name
	},
	getItems: state => {
		return state.items
	},
	getItemDetails: state => {
		return state.item_details
	},
	getMMDetails: state => {
		return state.mm_details
	},
	getInterestDetails: state =>{
		return state.interest_details
	},
	getTradingDetails: state =>{
		return state.trading_details
	},
	getNextPage: state => {
        return state.next_page_index
    },
	getLengthRecord: state => {
        return state.length_record
    },
	getStartRecord: state => {
		return state.start_record
	},
}