<template>
  <div class="statements-wrap">
    <v-card class="custome-card" id="statements_list">
      <b-row class="p-0 m-0">
        <b-col class="p-0 m-0">
          <v-data-table
            id="statements-scroll-table"
            :headers="headers"
            :items="items"
            :search="search"
            hide-default-footer
            class="custome-table statements-table"
            disable-pagination
            :item-key="'id'"
            fixed-header
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :style="{ height: CompStatementHeight + 'px' }"
          >
            <!-- :style="{ height: CompStatementsHeight + 'px' }" -->
            <template v-slot:body.prepend>
              <tr class="table-dropdown sticky-header">
                <td width="20%">
                  <div class="form-group">
                    <v-text-field
                      class="form-control"
                      type="text"
                      hide-details="auto"
                      v-model="search_table.month"
                    ></v-text-field>
                  </div>
                </td>
                <td width="80%">
                  <div class="form-group">
                    <v-text-field
                      class="form-control"
                      type="text"
                      hide-details="auto"
                      v-model="search_table.title"
                    ></v-text-field>
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                {{ item.month }}
            </template>
            <template v-slot:[`item.title`]="{ item }">
              <span class="teble-ellipsis-wrap hover-td-values fund-hover">
                {{ item.title }}
                <div class="hover-wrap fund-hover-wrap">
                  <template>
                    <a
                      href="javascript:void(0)"
                      @click="downloadStatement(item)"
                      class="table-custom-btn download-btn"
                      title="Download PDF"
                    >
                      {{ item.download }}
                    </a>
                  </template>
                </div>
              </span>
            </template>
          </v-data-table>
        </b-col>
      </b-row>
    </v-card>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
   
  </div>
</template>

<script>
// import VueApexCharts from "vue-apexcharts";
export default {
  name: "Statements",
  data() {
    return {
      dialog: false,
      ScannerDrawer: null,
      headers: [
        {
          text: "Month-Year",
          align: "left",
          value: "date",
          width: "20%",
          class: "maxi-name",
          filter: (value) => {
            if (!this.search_table.month) return true;
            return (
              String(this.dateMonthYearFromat(value).toLowerCase()).search(
                this.search_table.month.toLowerCase()
              ) > -1
            );
          },
        },
        {
          text: "Portfolio",
          align: "left",
          value: "title",
          width: "80%",
          class: "maxi-name",
          filter: (value) => {
            if (!this.search_table.title) return true;
            return (
              String(value.toLowerCase()).search(
                this.search_table.title.toLowerCase()
              ) > -1
            );
          },
        },
      ],
      search: "",
      loading: false,
      sortBy: ['date', 'title'],
      sortDesc: [true, false],
      menu2: false,
      item_details: [],
      search_table: {
        month: null,
        title: null,
      },
    };
  },
  computed: {
    items() {
      return this.$store.getters["statements/getItems"];
    },
    statement_url() {
      return this.$store.getters["statements/getStatementUrl"];
    },
    statement_name() {
      return this.$store.getters["statements/getStatementName"];
    },
    item_detials() {
      return this.$store.getters["statements/getItemDetails"];
    },
    mm_details() {
      return this.$store.getters["statements/getMMDetails"];
    },
    interest_details() {
      return this.$store.getters["statements/getInterestDetails"];
    },
    trading_details() {
      return this.$store.getters["statements/getTradingDetails"];
    },
    currency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
    performance1ChartWidth: {
      get() {
        return parseInt(this.CompPerformance1Width) / 2.9;
      },
    },
    performance1ChartHeight: {
      get() {
        return parseInt(this.CompPerformance1Height) / 2;
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
    all_portfolios() {
      return this.$store.getters["dashboard/getAllPortfolio"];
    },
    chart_items() {
      return this.$store.getters["performance_chart/getItems"];
    },
    start_filter_date: {
      get() {
        return this.$store.getters["dashboard/getStartFilterDate"];
      },
    },
    end_filter_date: {
      get() {
        return this.$store.getters["dashboard/getEndFilterDate"];
      },
    },
    next_page_index() {
      return this.$store.getters["statements/getNextPage"];
    },
    record_length() {
      return this.$store.getters["statements/getLengthRecord"];
    },
    start_record() {
      return this.$store.getters["statements/getStartRecord"];
    },
  },
  watch: {
    async filter_portfolio() {
      await this.fetchstatements(1);
    },
  },
  methods: {
    async fetchstatements(is_filter) {
      this.loading = true;
      if(is_filter==1){
        var items_prm = [];
        await this.$store.dispatch("statements/updateItems", items_prm);
      }
      this.next_page_index =(this.next_page_index != undefined || this.next_page_index != 0) ? this.next_page_index :1;
      this.start_record =(this.start_record != undefined) ? this.start_record :0;
      console.log("In fetchstatements ",this.next_page_index);
      await this.$store.dispatch("statements/fetchItems", {
        draw: (this.next_page_index == 0) ? 1 : this.next_page_index,
        start: this.start_record,
        length: this.record_length,
        filter_portfolio_ids: this.filter_portfolio,
        is_filter: is_filter,
      });
      this.loading = false;
    },
    async downloadStatement(item) {
      this.loading = true;

      await this.$store.dispatch("statements/downloadStatement", {
        portfolio_id: item.portfolio_id,
        start_date: item.start_date,
        end_date: item.end_date,
      });
      if (this.statement_url != "") {
        this.downloadURL(this.statement_url, this.statement_name);
        //window.open(this.statement_url, "_blank");
      }
      this.loading = false;
    },
  },
  props: ["CompisStatementsOpen","CompStatementHeight", "CompStatementWidth"],
  async mounted() {
    if(this.CompisStatementsOpen){
      console.log("call Statements list component mounted order");
      await this.fetchstatements(0);
      console.log("call Statements afterfetchstatements");
      let currenctDom = this;
      const listElm = document.querySelector("#statements-scroll-table");
      listElm.addEventListener("scroll", function() {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (currenctDom.next_page_index != 0) currenctDom.fetchstatements(0);
        } else {
          this.loading = false;
        }
      });
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.statements-wrap {
  padding: 0;
  .teble-ellipsis-wrap {
    width: 100% !important;
  }
  .child-table td {
    padding: 1.5px 8px !important;
  }
  #statements-scroll-table {
    overflow: auto;
  }
  .table-custom-btn {
    &.download-btn {
      margin-right: 10px;
      display: none;
    }
  }
}
#app {
  .statements-wrap {
    .custome-table {
      background: transparent !important;
      border-radius: 0;
      border: 0;
      table {
        > tbody {
          > tr {
            td {
              position: relative;
            }
            .hover-wrap {
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%);
              margin: 0 auto;
              left: 50%;
              right: inherit;
              display: none;
              justify-content: center;
              align-items: center;
              width: 80%;
              padding-right: 0;
              @media only screen and (max-width: 1599px) {
                width: 100%;
              }
              .btn {
                font-size: 14px;
                font-weight: 400;
                height: inherit;
                padding: 1px 6px;
                min-width: inherit;
                width: 85px;
                border-radius: 0;
                line-height: 14px;
                height: 22px;
                margin: 0;
                border: none;
                text-align: center;
                @media only screen and (max-width: 1799px) {
                  width: 75px;
                  font-size: 12px;
                  height: 20px;
                }
                @media only screen and (max-width: 1599px) {
                  width: 65px;
                }
                &:first-child {
                  margin-left: 0;
                }
                &:focus {
                  box-shadow: none;
                  outline: none;
                }
              }
              &:focus {
                box-shadow: none;
                outline: none;
              }
            }
            &:hover,
            &:focus {
              background-color: transparent !important;
              &:nth-of-type(even) {
                background-color: rgba(var(--even-hover-rgba)) !important;
              }
              td {
                color: var(--dark) !important;
                .v-icon {
                  color: var(--dark) !important;
                  &:before {
                    color: var(--dark) !important;
                  }
                }
              }
            }
            &:hover {
              .hover-td-values {
                .value-none {
                  visibility: hidden !important;
                  opacity: 0;
                }
                .hover-wrap {
                  display: flex;
                }
              }
            }
          }
        }
      }
      .v-data-table__wrapper {
        background: transparent;
        border-radius: 0;
        border: 0;
      }
      &.statements-table {
        table {
          > tbody {
            > tr {
              .fund-hover {
                margin: 0 auto 0 0 !important;
                &.fund-ellipsis {
                  width: 100px !important;
                  @media only screen and (max-width: 1799px) {
                    width: 80px !important;
                  }
                }
                .fund-hover-wrap {
                  top: 50%;
                  transform: translateY(-50%) !important;
                  margin: 0 0 !important;
                  left: inherit;
                  right: 0;
                  justify-content: flex-end;
                  .margin-lr {
                    margin: 0 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .teble-ellipsis-wrap {
      width: 400px !important;
      margin-left: 0 !important;
      &.e-w-70 {
        width: 400px !important;
        margin-left: 0 !important;
      }
    }
    .security_w {
      width: 400px !important;
    }
  }

  .custome-modal {
    .v-card__text {
      padding-bottom: 0 !important;
    }
  }
  .dlink {
    background-color: rgb(50, 205, 50);
    border-color: rgb(50, 205, 50);
    padding: 3px;
  }
  .dlink:hover {
    color: white;
  }
  // .date-range-box {
  //   right: 27px !important;
  // }
  .lm_maximised {
    .statements-wrap {
      .teble-ellipsis-wrap {
        width: 400px !important;
        margin-left: 0 !important;
        &.e-w-70 {
          width: 400px !important;
          margin-left: 0 !important;
        }
      }
      .security_w {
        width: 400px !important;
      }
    }
  }
}
</style>
