import axios from "../../axios"

export default {
    fetchItems ({ commit }, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/statements', payload)
				.then((response) => {					
					commit('setItems', response.data.data.data)
					commit('setNextPageIndex', response.data.data.next_page_index)
					commit('setStartRecord', response.data.data.startPerPage)
					resolve(response.data.data.data)
				})
				.catch(error => { reject(error) })
		})
	},
	downloadStatement ({ commit }, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/download-statement', payload)
				.then((response) => {	
					// console.log(response)				
					commit('setStatementUrl', response.data.data.statement_url)
					commit('setStatementName', response.data.data.statement_name)
					resolve(response.data.data.data)
				})
				.catch(error => { reject(error) })
		})
	},
	updateItems({
        commit
    },payload) {
        commit('setupdateItems', payload)
		commit('setNextPageIndex', 1)
		commit('setStartRecord', 0)
	},

}