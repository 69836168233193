<template>
  <div class="transactions-wrap">
   
    <template>
      <v-navigation-drawer
        class="filter-sidebar"
        width="26%"
        v-model="ViewDetailsDrawer"
        absolute
        temporary
        right
      >
        <div v-if="record_type == 'trade'">
          <h3>{{ transaction_details.code }}</h3>
          <v-simple-table
            class="custome-table child-table view-details-table"
            fixed-header
            :height="transaction_details.length > 25 ? '70vh' : ''"
          >
            <tbody>
              <tr>
                <td class="text-start">
                  {{ transaction_details.labelAccountName }}
                </td>
                <td class="text-start">
                  {{ transaction_details.labelValueAccountName }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Created By
                </td>
                <td class="text-start">
                  {{ transaction_details.created_by }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Created At</td>
                <td class="text-start">
                  {{ transaction_details.created_at }}
                </td>
              </tr>
              <tr v-if="transaction_details.updated_by">
                <td class="text-start">Updated By</td>
                <td class="text-start">
                  {{ transaction_details.updated_by }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Updated At</td>
                <td class="text-start">
                  {{ transaction_details.updated_at }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Trade Reference</td>
                <td class="text-start">
                  {{ transaction_details.code }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Timestamp</td>
                <td class="text-start">
                  {{ transaction_details.created_at }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Status</td>
                <td class="text-start">
                  {{ transaction_details.status }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Direction</td>
                <td class="text-start">
                  {{ transaction_details.directionValue }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  {{ transaction_details.labelCounterpartyName }}
                </td>
                <td class="text-start">
                  {{ transaction_details.labelValueCounterpartyName }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Trade Date
                </td>
                <td class="text-start">
                  {{ transaction_details.trade_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Settlement Date
                </td>
                <td class="text-start">
                  {{ transaction_details.settlement_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Security Description</td>
                <td class="text-start">
                  {{ transaction_details.security_name }}
                </td>
              </tr>
              <tr>
                <td class="text-start">ISIN</td>
                <td class="text-start">
                  {{ transaction_details.isin_code }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Quantity</td>
                <td class="text-start">
                  {{ transaction_details.quantity }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Factor</td>
                <td class="text-start">
                  {{ transaction_details.factor }}
                </td>
              </tr>
              <tr @v-if="transaction_details.is_price == '1'">
                <td class="text-start">Price</td>
                <td class="text-start">
                  {{ transaction_details.price }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Price Type</td>
                <td class="text-start">
                  {{ transaction_details.price_type }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Principal</td>
                <td class="text-start">
                  {{ transaction_details.principal }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Accrued ({{ transaction_details.accured_days }} Days)
                </td>
                <td class="text-start">
                  {{ transaction_details.accrued }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Market Value</td>
                <td class="text-start">
                  {{ transaction_details.market_value }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Net Consideration</td>
                <td class="text-start">
                  {{ transaction_details.net_consideration }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-if="record_type == 'transfer'">
          <h3>{{ transaction_details.code }}</h3>
          <v-simple-table
            class="custome-table child-table"
            fixed-header
            :height="transaction_details.length > 25 ? '70vh' : ''"
          >
            <tbody>
              <tr>
                <td class="text-start">Timestamp</td>
                <td class="text-start">
                  {{ transaction_details.created_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Status</td>
                <td class="text-start">
                  {{ transaction_details.status }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Created By
                </td>
                <td class="text-start">
                  {{ transaction_details.created_by }}
                </td>
              </tr>
              <tr v-if="transaction_details.updated_by">
                <td class="text-start">Updated By</td>
                <td class="text-start">
                  {{ transaction_details.updated_by }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Account
                </td>
                <td class="text-start">
                  {{ transaction_details.portfolio }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Trade Date
                </td>
                <td class="text-start">
                  {{ transaction_details.trade_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Settlement Date
                </td>
                <td class="text-start">
                  {{ transaction_details.settlement_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Security Description</td>
                <td class="text-start">
                  {{ transaction_details.security_name }}
                </td>
              </tr>
              <tr>
                <td class="text-start">ISIN</td>
                <td class="text-start">
                  {{ transaction_details.isin_code }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Direction</td>
                <td class="text-start">
                  {{ transaction_details.direction }}
                </td>
              </tr>

              <tr>
                <td class="text-start">Quantity</td>
                <td class="text-start">
                  {{ transaction_details.quantity }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Counterparty</td>
                <td class="text-start">
                  {{ transaction_details.counterparty }}
                </td>
              </tr>
              <tr v-if="transaction_details.trade_comment">
                <td class="text-start">Comment</td>
                <td class="text-start">
                  {{ transaction_details.trade_comment }}
                </td>
              </tr>
              <tr v-if="transaction_details.fees > '0'">
                <td class="text-start">Execution Fees</td>
                <td class="text-start">
                  {{ transaction_details.fees }}
                </td>
              </tr>
              <tr v-if="transaction_details.set_fees > '0'">
                <td class="text-start">Settlement Fees</td>
                <td class="text-start">
                  {{ transaction_details.set_fees }}
                </td>
              </tr>
              <tr v-if="transaction_details.amendment_fees > '0'">
                <td class="text-start">Amendment Fees</td>
                <td class="text-start">
                  {{ transaction_details.amendment_fees }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-if="record_type == 'cash'">
          <h3>{{ transaction_details.code }}</h3>
          <v-simple-table
            class="custome-table child-table view-details-table"
            fixed-header
            :height="transaction_details.length > 25 ? '70vh' : ''"
          >
            <tbody>
              <tr>
                <td class="text-start">Timestamp</td>
                <td class="text-start">
                  {{ transaction_details.created_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Status</td>
                <td class="text-start">
                  {{ transaction_details.status }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Created By
                </td>
                <td class="text-start">
                  {{ transaction_details.created_by }}
                </td>
              </tr>
              <tr v-if="transaction_details.updated_by">
                <td class="text-start">Updated By</td>
                <td class="text-start">
                  {{ transaction_details.updated_by }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Account
                </td>
                <td class="text-start">
                  {{ transaction_details.portfolio }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Request Date
                </td>
                <td class="text-start">
                  {{ transaction_details.request_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">
                  Settlement Date
                </td>
                <td class="text-start">
                  {{ transaction_details.settlement_date }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Transaction</td>
                <td class="text-start">
                  {{ transaction_details.transaction_type }}
                </td>
              </tr>
              <tr v-if="transaction_details.security_id > 0">
                <td class="text-start">Security</td>
                <td class="text-start">
                  {{ transaction_details.security_name }}
                </td>
              </tr>

              <tr v-if="transaction_details.security_id > 0">
                <td class="text-start">ISIN</td>
                <td class="text-start">
                  {{ transaction_details.isin_code }}
                </td>
              </tr>

              <tr v-if="transaction_details.sec_quantity > 0">
                <td class="text-start">Quantity</td>
                <td class="text-start">
                  {{ transaction_details.sec_quantity }}
                </td>
              </tr>
              <div v-if="transaction_details.transaction_type != 'FX Trade'">
                <tr v-if="transaction_details.currency">
                  <td class="text-start">Currency</td>
                  <td class="text-start">
                    {{ transaction_details.currency }}
                  </td>
                </tr>
                <tr v-if="transaction_details.ratio > 0">
                  <td class="text-start">Ratio</td>
                  <td class="text-start">
                    {{ transaction_details.ratio }}
                  </td>
                </tr>
                <tr v-if="transaction_details.tax_amount > 0">
                  <td class="text-start">Tax Amount</td>
                  <td class="text-start">
                    {{ transaction_details.tax_amount }}
                  </td>
                </tr>
              </div>

              <tr v-if="transaction_details.amount > '0'">
                <td class="text-start">Amount</td>
                <td class="text-start">
                  {{ transaction_details.amount }}
                </td>
              </tr>
              <tr>
                <td class="text-start">Counterparty</td>
                <td class="text-start">
                  {{ transaction_details.relationship_title }}
                </td>
              </tr>

              <tr
                v-if="
                  transaction_details.transaction_type == 'FX Trade' &&
                    transaction_details.sell_currency
                "
              >
                <td class="text-start">Sell Currency</td>
                <td class="text-start">
                  {{ transaction_details.sell_currency }}
                </td>
              </tr>
              <tr
                v-if="
                  transaction_details.transaction_type == 'FX Trade' &&
                    transaction_details.convert_amount > 0
                "
              >
                <td class="text-start">Sell Amount</td>
                <td class="text-start">
                  {{ transaction_details.convert_amount }}
                </td>
              </tr>
              <tr
                v-if="
                  transaction_details.transaction_type == 'FX Trade' &&
                    transaction_details.buy_currency
                "
              >
                <td class="text-start">Buy Currency</td>
                <td class="text-start">
                  {{ transaction_details.buy_currency }}
                </td>
              </tr>
              <tr
                v-if="
                  transaction_details.transaction_type == 'FX Trade' &&
                    transaction_details.new_currency_amount > 0
                "
              >
                <td class="text-start">Buy Amount</td>
                <td class="text-start">
                  {{ transaction_details.new_currency_amount }}
                </td>
              </tr>
              <tr
                v-if="
                  transaction_details.transaction_type == 'FX Trade' &&
                    transaction_details.price > 0
                "
              >
                <td class="text-start">Price</td>
                <td class="text-start">
                  {{ transaction_details.price }}
                </td>
              </tr>
              <tr v-if="transaction_details.fees > 0">
                <td class="text-start">Execution Fees</td>
                <td class="text-start">
                  {{ transaction_details.fees }}
                </td>
              </tr>
              <tr v-if="transaction_details.set_fees > 0">
                <td class="text-start">Settlement Fees</td>
                <td class="text-start">
                  {{ transaction_details.set_fees }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div v-if="trade_comments.length > 0">
          <h3 class="paddingtop-10">Comments</h3>
          <div class="basic-details">
            <ul
              class="basic-details-ul"
              v-for="trade_comment in trade_comments"
              :key="trade_comment.id"
            >
              <li>
                <p class="title">Date</p>
                <p class="value">{{ trade_comment.created_at }}</p>
              </li>
              <li>
                <p class="title">User</p>
                <p class="value">
                  {{ trade_comment.common_user.first_name }}
                  {{ trade_comment.common_user.last_name }}
                </p>
              </li>
              <li>
                <p class="title">Reference</p>
                <p class="value">{{ trade_comment.trade_code }}</p>
              </li>
              <li>
                <p class="title">Comment</p>
                <p class="value">{{ trade_comment.comment }}</p>
              </li>
            </ul>
          </div>
        </div>
        <transition name="fade">
          <div class="loading full-page" v-show="transactionsDetailsLoading">
            <div><span></span><span></span><span></span><span></span></div>
          </div>
        </transition>
      </v-navigation-drawer>
      <a class="filter-sticky-icon rightside" @click="DetailsDrawer()">
        <img alt="filter icon " src="@/assets/images/right-arrow.png" />
      </a>
    </template>
  </div>
</template>

<script>
export default {
  name: "transactions",
  components: { },
  data() {
    return {
      ViewDetailsDrawer:false,
      transaction_details:[],
      transactionsDetailsLoading:false
    };
  },
  computed: {
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
    record_type: {
      get() {
        return this.$store.getters["transaction/getRecordType"];
      },
    },
    trade_comments() {
      let trade_comment = this.$store.getters["transaction/getTradeComments"];
      return trade_comment;
    },
    // transaction_details() {
    //   let transaction_detail = this.$store.getters[
    //     "transaction/getTransactionDetails"
    //   ];
    //   return transaction_detail;
    // },
  },
  watch: {
    async TransactionDetailProp() {
      this.transaction_details = this.TransactionDetailProp.data.transaction_details;
      //console.log("call CashflowDetail...",this.TransactionDetailProp.data.transaction_details);
      this.ViewDetailsDrawer = true;
    },
  },
  methods: {
   
    
     
  },
  props: ["TransactionDetailProp"],
  async mounted() {
    // console.log("call CashflowDetail",this.TransactionDetailProp);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
#app {
  .transactions-wrap {
    padding: 0;
    #cashflow-scroll-table {
      overflow: auto;
    }
    .filter-sidebar{
      z-index: 99999 !important;
    }
    .custome-table {
      table {
        > tbody {
          > tr {
            > td {
              &.text-center {
                .teble-ellipsis-wrap {
                  margin: 0 auto !important;
                }
              }
              .teble-ellipsis-wrap {
                width: 120px !important;
              }
              &:last-child {
                .teble-ellipsis-wrap {
                  width: 100px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
