export default {
    items:[],
    statement_url:"",
    statement_name:"",
    item_details:[],
    mm_details:[],
    interest_details:[],
    trading_details: [],
    next_page_index: 1,
    start_record: 0,
    length_record: 40,
}