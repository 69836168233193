export default {
	getItem: state => (id) => {
		return state.items.find((item) => item.id === id)
	},
	getItems: state => {
		return state.items
	},
	getItemDetails: state => {
		return state.item_details
	},
	getItemSecondaryDetails: state => {
		return state.item_secondary_details
	},
	getNextPage: state => {
        return state.next_page_index
    },
	getLengthRecord: state => {
        return state.length_record
    },
	getStartRecord: state => {
		return state.start_record
	},
}