export default {
	setItems(state, payload) {
		// state.items = payload
		state.items.push(...payload)
	},
	setMMDetails(state, payload) {
		state.mm_details = payload
	},
	setInterestDetails(state, payload) {
		state.interest_details = payload
	},
	setTradingDetails(state, payload) {
		state.trading_details = payload
	},
	setNextPageIndex(state, payload) {
        state.next_page_index = payload
    },
	setStartRecord(state, payload) {
        state.start_record = payload
    },
	setupdateItems(state, payload){
		state.items = payload;
	},
	setUnRealizedItem(state, payload) {
		state.unrealized_item = payload
	},
	setUnRealizedNextPageIndex(state, payload) {
        state.unrealized_next_page_index = payload
    },
	setUnRealizedStartRecord(state, payload) {
        state.unrealized_start_record = payload
	},
	setRealizedItem(state, payload) {
		state.realized_item = payload
	},
	setRealizedNextPageIndex(state, payload) {
        state.realized_next_page_index = payload
    },
	setRealizedStartRecord(state, payload) {
        state.realized_start_record = payload
	},
}