<template>
  <div>
    <b-row class="admin-chat">
      <b-col
        class="admin-chat-list "
        v-show="
          activeUserIndex == null ||
            (activeUserIndex != null && is_maximize == true)
        "
      >
        <div class="chat-user-list">
          <!-- <form class="message-form user-list-search">
            <button type="submit" class="search-btn">
              <img alt="Chat" src="@/assets/images/search.svg" />
            </button>
            <input
              type="search"
              class="form-control chat-input"
              placeholder="Search or start new chat"
            />
          </form> -->
          <ul class="common-list" :style="{ height: CompChat2Height + 'px' }">
            <li
              v-for="(user, index) in onlineUsers"
              :key="index"
              @click="openChat(user.user_id, index)"
            >
              <a href="javascript:void(0)">
                <div class="chat-user-box">
                  <img
                    alt="Chat"
                    class="chat-img-light"
                    src="@/assets/images/chat-user.png"
                  />
                  <img
                    alt="Chat"
                    class="chat-img-dark"
                    src="@/assets/images/white-user.png"
                  />
                  <div class="chat-user-text">
                    <h3>{{ user.user_name }}</h3>
                    <p
                      v-show="
                        index !== activeUserIndex && user.unread_admin_count > 0
                      "
                    >
                      {{ user.unread_admin_count }} messages
                    </p>
                  </div>
                </div>
                <span class="date"
                  >{{ new Date(user.last_message_at).toLocaleDateString()
                  }}<br />{{ displayTime(user.last_message_at) }}</span
                >
              </a>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col
        class="maximize-w-75 admin-chat-message"
        v-show="activeUserIndex != null && is_maximize == false"
      >
        <div class="no-chat-history" v-show="activeUserIndex == null">
          <img alt="Chat" src="@/assets/images/chat-bg.png" />
        </div>
        <div class="message-box" v-if="activeUserIndex != null">
          <div class="chat-user-details">
            <a href="javascript:void(0)" @click="backChat()"
              ><font-awesome-icon class="back-icon" icon="chevron-left" />
              Back</a
            >
            <div class="chat-user-box chat-header-user">
              <!-- <img alt="Chat" src="@/assets/images/white-user.png" /> -->
              <div class="chat-user-text">
                <h3>{{ activeUser.user_name }}</h3>
              </div>
            </div>
            <!-- <font-awesome-icon class="chat-menu-icon" icon="ellipsis-v" /> -->
          </div>
          <div
            id="chatMessages"
            class="message-group admin-chat-user"
            :style="{ height: CompChatHeight + 'px' }"
          >
            <div
              v-for="(msg, index) in messages"
              :key="index"
              :class="{
                'message-send right': profiles.id == msg.user_id,
                'message-receive left': profiles.id != msg.user_id,
              }"
            >
              <!-- <img alt="Chat" src="@/assets/images/chat-user.png" v-if="profiles.id != msg.user_id" /> -->
              <span class="message-log" v-if="profiles.id != msg.user_id"
                >{{ msg.user_name }},
                {{ displayDateTime(msg.created_at) }}</span
              >
              <span class="message-log" v-if="profiles.id == msg.user_id">{{
                displayDateTime(msg.created_at)
              }}</span>
              <div class="message-text">
                <p>{{ msg.message }}</p>
              </div>
              <!-- <img alt="Chat" src="@/assets/images/chat-user.png" v-if="profiles.id == msg.user_id" /> -->
            </div>
            <div class="typing" v-if="typing == true">
              <div class="bubble">
                <div class="ellipsis dot_1"></div>
                <div class="ellipsis dot_2"></div>
                <div class="ellipsis dot_3"></div>
              </div>
            </div>
          </div>
          <form class="message-form" @submit="sendMessage">
            <input
              type="text"
              class="form-control chat-input"
              placeholder="Type to Chat"
              v-model="message"
            />
            <button type="submit" class="send-btn">
              <img
                alt="Chat"
                class="chat-img-light"
                src="@/assets/images/send.png"
              />
              <img
                alt="Chat"
                class="chat-img-dark"
                src="@/assets/images/white-send.png"
              />
            </button>
          </form>
        </div>
      </b-col>
    </b-row>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Chat",
  data() {
    return {
      user: "",
      message: "",
      messages: [],
      room: null,
      onlineUsers: [],
      isConnected: false,
      loading: true,
      activeUserIndex: null,
      activeUser: null,
      notification_count: 0,
      is_maximize: false,
      typing: false,
      page:1,
      previousScrollHeight: 0,
    };
  },
  props: ["CompChatHeight", "CompChat2Height"],
  created() {},
  mounted() {
    if (this.profiles.id) {
      this.loading = false;
    }
  },
  sockets: {
    connect: function() {
      // console.log("Socket connected");
      this.isConnected = true;
      //this.loading = false;
    },
    disconnect() {
      this.isConnected = false;
    },
    onlineUsers(data) {
      // console.log("Online users: ",data);
      this.onlineUsers = data;
      this.notification_count = this.notification_count + 1;
    },
    message(data) {
      // console.log(data);
      this.messages.push(data);
    },
    loadMessage(data) {
      //this.messages.push(data);
      this.loading = true;
      if(data.nextPage > this.page){
        this.page = data.nextPage;
        this.messages = [...data.messages,...this.messages];
      }
      this.loading = false;
    },
    typing(data) {
      this.typing = data;
    },
  },
  computed: {
    profiles() {
      let profile = this.$store.getters["dashboard/getProfiles"];
      if (profile.id) {
        this.sendSocketMessage("getOnlineUsers", {
          user_id: profile.id,
          user_name: profile.name,
          admin_id: profile.id,
        });
      }
      return profile;
    },
  },
  watch: {
    profiles(val) {
      if (val.id) {
        this.loading = false;
      }
    },
    // notification_count (current) {
    //   // if(current > 0){
    //   //   document.title = '(' + current + ')' + document.title.replace(/ *\([^)]*\) */g, "");
    //   // }
    // },
    message(value) {
      value
        ? this.sendSocketMessage("typing", { room: this.room, is_typing: true })
        : this.sendSocketMessage("typing", {
            room: this.room,
            is_typing: false,
          });
    },
    activeUser(value){
      if(value && value != null){
        let currentDom = this;
        
        let int = setInterval(() => {
          let container = document.getElementById("chatMessages");
          if(container){
            if(this.page == 1 || this.page == 2){
              container.scrollTop = container.scrollHeight;
            }
            
            container.removeEventListener('scroll',() => {
              // remove listener code
            });
            container.addEventListener("scroll", async function() {
              if(container.scrollTop == 0){
                currentDom.loadPreviousMessages(currentDom);
                let setScroll = container.scrollHeight - currentDom.previousScrollHeight;
                currentDom.previousScrollHeight = container.scrollHeight;
                container.scrollTop = setScroll;
              }
            });
            clearInterval(int);
          }
        }, 100);
      }
    }
  },
  methods: {
    loadPreviousMessages: (currentDom) => {
      currentDom.sendSocketMessage("loadMessage", {
        room: currentDom.room,
        markAsRead: true,
        is_admin: true,
        admin_id: currentDom.profiles.id,
        page: currentDom.page
      });
    },
    displayTime: (date) => {
      return new Date(date).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    displayDateTime: (date) => {
      var cd = new Date(date);
      var td = new Date();

      var chat_locale_date =
        cd.getFullYear() + "-" + (cd.getMonth() + 1) + "-" + cd.getDate();
      var chat_local_time = cd.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      var today_locale_date =
        td.getFullYear() + "-" + (td.getMonth() + 1) + "-" + td.getDate();
      var past_date = new Date(chat_locale_date);
      var current_date = new Date(today_locale_date);
      var diffTime = Math.abs(current_date - past_date);
      var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays == 0) {
        return chat_local_time;
      } else {
        return cd.toLocaleDateString() + " " + chat_local_time;
      }
    },
    sendSocketMessage: function(event, data) {
      this.$socket.emit(event, data);
    },
    sendMessage: function(e) {
      e.preventDefault();

      if (this.activeUserIndex == null || this.message.length <= 0) {
        // console.log("user not selected or empty message");
        return false;
      }

      let data = {
        room: this.room,
        user_id: this.profiles.id,
        user_name: this.profiles.name,
        message: this.message,
        is_admin: true,
        created_at: new Date(),
      };

      this.messages.push(data);
      this.message = "";

      this.sendSocketMessage("chatMessage", data);
    },
    openChat: function(user_id, index) {
      if (index == this.activeUserIndex) {
        return false;
      }
      this.activeUserIndex = index;
      //set room on click
      this.room = "room" + user_id;
      this.activeUser = this.onlineUsers.find((obj) => obj.user_id == user_id);

      if (user_id != "") {
        this.sendSocketMessage("joinRoom", {
          room: this.room,
          admin_id: this.profiles.id,
          admin_name: this.profiles.name,
        });

        this.sendSocketMessage("loadMessage", {
          room: this.room,
          markAsRead: true,
          is_admin: true,
          admin_id: this.profiles.id,
          page: this.page
        });
      }
    },
    backChat: function() {
      this.sendSocketMessage("leaveRoom", { room: this.room });
      this.activeUserIndex = null;
      this.activeUser = null;
      this.page = 1;
      this.messages = [];
      this.previousScrollHeight = 0;
    },
    onFocus: function() {
      // document.title = document.title.replace(/ *\([^)]*\) */g, "");
      this.notification_count = 0;
    },
  },
  destroyed() {
    this.$socket.removeAllListeners();
  },
};
</script>
