import axios from "axios";

let token ="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2dpbi5lbWZpLmxvY2FsXC9hcGlcL2F1dGhcL2xvZ2luIiwiaWF0IjoxNjExODExNjE5LCJleHAiOjE2MTE4OTgwMTksIm5iZiI6MTYxMTgxMTYxOSwianRpIjoic2pZUkFvT29haHE4T25OVyIsInN1YiI6NTg0LCJwcnYiOiIwMGUxODU5YWIzOGM0YzczOTdlMzQ1MmIxMWQ4OGEwMzk1NGExZjYyIn0.OYjqVKT0VokY0xfx3ad4CHZ5-Q0ekIWRf5SKULne0tA";

const baseUrl = "http://login.emfi.local/api/workstation/";

let Call = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  },
});

Call.defaults.withCredentials = true;

const Headers = {
  "Content-type": "application/json",
  Authorization: "Bearer " + token,
};

const HeaderMultipart = {
  headers: {
    "content-type": "multipart/form-data",
    Authorization: "Bearer " + token,
  },
};
export default {
  async Get(url, showLoader) {
    this.ShowLoader(showLoader);
    let responseData = await Call.get(url, Headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (this.checkToken(error.response.data)) {
          return false;
        }
        return error.response;
      });
    this.HideLoader(showLoader);
    return responseData;
  },

  async Post(url, Data, showLoader) {
    Data = typeof Data == typeof undefined || Data == "" ? null : Data;
    this.ShowLoader(showLoader);

    let responseData = await Call.post(url, Data, Headers)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (this.checkToken(error.response.data)) {
          return false;
        }
        return error.response;
      });
    this.HideLoader(showLoader);
    return responseData;
  },

  async PostFile(url, Data, showLoader) {
    Data = typeof Data == typeof undefined || Data == "" ? null : Data;
    this.ShowLoader(showLoader);
    let responseData = await Call.post(url, Data, HeaderMultipart)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (this.checkToken(error.response.data)) {
          return false;
        }
        return error.response;
      });
    this.HideLoader(showLoader);
    return responseData;
  },
  async checkToken(data) {
    if (data.message.toLowerCase() == "token has expired") {
      await localStorage.removeItem("jwt");
      window.location.reload();
      return true;
    }
    if (data.message.toLowerCase() == "the token has been blacklisted") {
      await localStorage.removeItem("jwt");
      window.location.reload();
      return true;
    }
    return false;
  },
  ShowLoader(showLoader) {
    if (
      typeof showLoader == typeof undefined ||
      showLoader == 1 ||
      showLoader == true ||
      showLoader == null ||
      showLoader == ""
    ) {
      // store.commit('ToggleLoader', true);
    }
  },
  HideLoader(showLoader) {
    if (
      typeof showLoader == typeof undefined ||
      showLoader == 1 ||
      showLoader == true ||
      showLoader == null ||
      showLoader == ""
    ) {
      // store.commit('ToggleLoader', false);
    }
  },
};
