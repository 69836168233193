export default {
	getItem: state => (id) => {
		return state.items.find((item) => item.id === id)
	},
	getItems: state => {
		return state.items
	},
	getKillResult: state => {
		return state.kill_result
	},
	getNextPage: state => {
        return state.next_page_index
    },
	getLengthRecord: state => {
        return state.length_record
    },
	getStartRecord: state => {
		return state.start_record
	},
	getKillRecord: state => {
		return state.kill_count
	},
	getIsPlaceOrder: state => {
		return state.is_place_order
	},
	getTransactionDetails: state => {
		return state.transaction_details
	},
	getTradeComments: state => {
		return state.trade_comments
	},
	getRecordType: state => {
		return state.record_type
	},
	getTotalRecord: state => {
		return state.total_record
	},
	getIsTransactionScanner: state => {
		return state.is_transaction_scanner
	},
}