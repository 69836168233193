import axios from '../../axios'

export default {
    fetchItems({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('news', payload)
                .then((response) => {
                    commit('setItems', response.data.data)
                    commit('setNextPageIndex', response.data.next_page_index)
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    fetchItemsWithSearch({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('news', payload)
                .then((response) => {
                    commit('setSearchItems', response.data.data)
                    commit('setNextPageIndex', response.data.next_page_index)
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}