import axios from 'axios'
export default {
    fetchIsVisibleNews({
        commit
    }, payload) {
        commit('setIsVisibleNews', payload)
    },
    fetchUserAccessType({
        commit
    }, user_access_type) {
        commit('setUserAccessType', user_access_type)
    },
    fetchIsVisibleOrderEntry({
        commit
    }, payload) {
        commit('setIsVisibleOrderEntry', payload)
    },
    updateFilterCurrecy({commit},payload) {
        return new Promise((resolve, reject) => {
            axios.post('/profile/currency', { currency: payload })
                .then(response =>{
                    if(response.data.status == 1){
                        //commit('setFilterCurrencyRate', response.data.data);
                        commit('setFilterCurrency', payload);
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                });
            
        })
    },
    fetchFilterCurrencyRate({commit},payload){
        return new Promise((resolve, reject) => {
            axios.post('/getfcrate', payload)
                .then(response =>{
                    if(response.data.status == 1){
                        commit('setFilterCurrencyRate', response.data.data);
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    fetchSetting({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/get-settings', payload)
                .then(response =>{
                    commit('setCounties', response.data.data.counties_with_region)
                    commit('setRegions', response.data.data.regions_list)
                    commit('setCountriesSecurity', response.data.data.countries_with_security)
                    commit('setCurrencySecurity', response.data.data.currency_with_security)
                    commit('setTickerSecurity', response.data.data.ticker_with_security)
                    commit('setSecondCountriesSecurity', response.data.data.second_countries_with_security)
                    commit('setSecondCurrencySecurity', response.data.data.second_currency_with_security)
                    commit('setExchange', response.data.data.exchange_with_security)
                    commit('setType', response.data.data.type_with_security)
                    commit('setGroup', response.data.data.group_with_security)
                    commit('setSector', response.data.data.sector_with_security)
                    commit('setArea', response.data.data.area_with_security)
                    commit('setGcategory', response.data.data.gcategory_with_security)
                    commit('setRating', response.data.data.rating_with_security)
                    commit('setCompany', response.data.data.company_with_security)
                    commit('setCategory', response.data.data.category_with_security)
                    commit('setPeer', response.data.data.peer_with_security)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    fetchProfile({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            axios.get('/profile', payload)
                .then(response =>{
                    commit('setProfile', response.data.data.profile)
                    commit('setPortfolios', response.data.data.portfolios)
                    commit('setTradingPortfolios', response.data.data.trading_portfolios)
                    commit('setAllPortfolio', response.data.data.portfoliosIds)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateCurrentTheme({
        commit
    }, payload) {
        commit('setCurrentTheme', payload)
    },
    updateFilterFortfolio({
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/profile/portfolio', { portfolio_ids: payload })
                .then(response =>{
                    if(response.data.status == 1){
                        //commit('setFilterCurrencyRate', response.data.data);
                        commit('setFilterFortfolio', payload);
                    }
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                });
            
        })
        
    },
    updateStartFilterDate({
        commit
    }, payload) {
        commit('setStartFilterDate', payload)
    },
    updateEndFilterDate({
        commit
    }, payload) {
        commit('setEndFilterDate', payload)
    },
    updateActiveTab({
        commit
    }, payload) {
        commit('setActiveTab', payload)
    },
    updateBLActiveTab({
        commit
    }, payload) {
        commit('setBLActiveTab', payload)
    },
    updateTransactionList({
        commit
    }, payload) {
        commit('setTransactionUpdate', payload)
    },
    updateIsMaximizeList({
        commit
    }, payload) {
        commit('setIsMaximizeUpdate', payload)
    },
    updateDefaultSecuritySet({
        commit
    }, payload) {
        commit('setDefaultSecuritySet', payload)
    },
    updateRFQSettings({commit},payload){
		commit('setRFQSettings', payload)
	},
}