<template>
  <section class="research-wrap" @click="searchFocus">
    <div class="research-minimize">
      <b-input-group class="search-bar">
        <autocomplete
          ref="autocomplete"
          input-class="search-box search-research"
          placeholder="Search by Keyword"
          :source="searchReportEndpoint"
          results-property="data"
          :results-display="formattedReportDisplay"
          :request-headers="authAPIHeaders"
          @selected="selectSearchedReport"
        >
        </autocomplete>
      </b-input-group>
      <ul
        class="research-list"
        id="research-list"
        :style="{ height: CompResearchHeight + 'px' }"
      >
        <li v-for="item in ResearchItems" :key="item.id">
          <a :href="item.details_url" target="_blank">
            <h3>
              {{ item.country_name }} {{ item.format_type }}:
              {{ item.report_ttl }}
            </h3>
            <span class="date">{{ item.date }}</span>
          </a>
        </li>
      </ul>
      <transition name="fade">
        <div class="loading full-page" v-show="loading">
          <div><span></span><span></span><span></span><span></span></div>
        </div>
      </transition>
    </div>
    <div class="maxmize-research-wrap">
      <b-input-group class="search-bar">
        <autocomplete
          ref="autocomplete"
          input-class="search-box search-research"
          placeholder="Search by Keyword"
          :source="searchReportEndpoint"
          results-property="data"
          :results-display="formattedReportDisplay"
          :request-headers="authAPIHeaders"
          @selected="selectSearchedReport"
        >
        </autocomplete>
      </b-input-group>
      <b-row
        class="research-wrap-list m-0"
        id="maxmize-research-wrap"
        :style="{ height: CompResearchHeight + 'px' }"
      >
        <b-col class="p-0">
          <div class="accordion research-accordion" role="tablist">
            <b-card
              no-body
              v-for="(report_item, index) in OtherItems"
              :key="index"
            >
              <b-card-header header-tag="header" role="tab">
                <b-button
                  block
                  v-b-toggle="`accordion-${report_item.id}`"
                  @click="openPanel(report_item.id)"
                >
                  <div>
                    <h3>
                      {{ report_item.country_name }}
                      {{ report_item.format_type }}:
                      {{ report_item.report_title }}
                    </h3>
                    <span class="date">{{ report_item.report_date }}</span>
                  </div>
                  <img
                    class="accordion-arrow"
                    alt="accordion icon"
                    src="@/assets/images/arrow.svg"
                  />
                </b-button>
              </b-card-header>
              <b-collapse
                :id="'accordion-' + report_item.id"
                visible
                accordion="accordion-one"
                role="tabpanel"
              >
                <b-card-body>
                  <b-row>
                    <b-col>
                      <div class="report-group">
                        <div class="home-s-title">
                          <div class="market-chart-title-1">
                            <span
                              class="display_title_first"
                              v-if="report_item.display_title_first != ''"
                              >{{ report_item.display_title_first }}</span
                            >
                            <span
                              class="display_sub_title"
                              v-if="report_item.display_sub_title != ''"
                            >
                              {{ report_item.display_sub_title }}</span
                            >
                          </div>
                          <div
                            class="market-chart-title-2"
                            v-if="
                              report_item.display_title_second != '' &&
                                report_item.display_sub_title != ''
                            "
                          >
                            <span
                              class="display_title_second"
                              v-if="report_item.display_title_second != ''"
                              >{{ report_item.display_title_second }}</span
                            ><span
                              class="display_sub_title"
                              v-if="
                                report_item.display_title_second != '' &&
                                  report_item.display_sub_title != ''
                              "
                            >
                              {{ report_item.display_sub_title }}</span
                            >
                          </div>
                        </div>
                        <div class="research-chart-wrap">
                          <div
                            class="chart-main"
                            :id="'chart' + report_item.id"
                          >
                            <apexchart
                              type="area"
                              width="100%"
                              :ref="'chart' + report_item.id"
                              :options="chartOptions"
                              :series="series"
                            ></apexchart>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="report-group">
                        <div
                          class="report-description"
                          v-html="report_item.description"
                        ></div>
                        <a
                          :href="report_item.report_url"
                          target="_blank"
                          class="submit_portfolio continue-btn"
                          >Continue Reading</a
                        >
                      </div>
                    </b-col>
                  </b-row>

                  <transition name="fade">
                    <div class="loading full-page" v-show="loading">
                      <div>
                        <span></span><span></span><span></span><span></span>
                      </div>
                    </div>
                  </transition>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <transition name="fade">
        <div class="loading full-page" v-show="Otherloading">
          <div><span></span><span></span><span></span><span></span></div>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import axios from "./../axios";
import VueApexCharts from "vue-apexcharts";
import themeconfig from '../themeconfig'
//  import MapChart from "vue-map-chart";
export default {
  name: "Research",
  // components: { apexchart: VueApexCharts, MapChart },
  components: { apexchart: VueApexCharts },
  data() {
    return {
      visible: true,
      query: "",
      next_page_index: 1,
      other_page_index: 0,
      spacial_page_index: 0,
      ResearchItems: [],
      OtherItems: [],
      SpacialItems: [],
      loading: false,
      Otherloading: false,
      Spacialloading: false,
      other_report_panel: [0],
      spacial_report_panel: [0],
      series: [
        {
          name: "",
          type: "area",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          id: "area-datetime",
          type: "area",
          foreColor:
            localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : "#333333",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
          //redrawOnParentResize: true,
          // redrawOnWindowResize: true,
        },
        annotations: {
          yaxis: [
            {
              y: 0,
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        colors: [
          localStorage.getItem("isDarkActive") == 1 ? "#ffffff" : "#001a34",
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#333333",
          localStorage.getItem("isDarkActive") == 1 ? "#7f7f7f" : "#404040",
          localStorage.getItem("isDarkActive") == 1 ? "#bfbfbf" : "#7f7f7f",
          localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : "#bfbfbf",
        ],

        // colors: ["#ffffff"],
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat",
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 6,
        },
        grid: {
          // borderColor: '#333333',
          borderColor:
            localStorage.getItem("isDarkActive") == 1 ? "#333333" : "#dbdbdb",
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.3,
            stops: [0, 100],
          },
        },
        legend: {
          show: false,
        },
      },
      selection: "one_month",
      time_drp_placeholder: "Month",
      price_drp_placeholder: "Price",
    };
  },
  computed: {
    filteredItems: function() {
      var self = this;
      return self.ResearchItems.filter(function(val) {
        return val.indexOf(self.query) !== -1;
      });
    },
    authAPIHeaders() {
      return {
        Authorization: "Bearer " + localStorage.getItem("user-token"),
      };
    },
    items: {
      get() {
        let AllItems = this.$store.getters["chart/getAllItems"];
        let price_type = this.$store.getters["chart/getPriceType"];
        let market_id = this.$store.getters["chart/getMarketId"];
        let items = new Array();
        for (var i in AllItems) {
          let one = new Array();
          one.push(new Date(AllItems[i].created).getTime());
          if (price_type == "3") {
            //one.push(AllItems[i].spread_price.toFixed(2));
            one.push(
              new Intl.NumberFormat("en-US").format(
                AllItems[i].spread_price.toFixed(2)
              )
            );
          } else if (price_type == "2") {
            //one.push(AllItems[i].yield_price.toFixed(2));
            one.push(
              new Intl.NumberFormat("en-US").format(
                AllItems[i].yield_price.toFixed(2)
              )
            );
          } else {
            if (market_id == "6") {
              one.push(AllItems[i].price.toFixed(5));
            } else {
              one.push(AllItems[i].price.toFixed(2));
              //one.push(new Intl.NumberFormat("en-US").format(AllItems[i].price.toFixed(2)));
            }
          }
          items.push(one);
        }
        return items;
      },
    },
    name() {
      return this.$store.getters["chart/getName"];
    },
    security_id: {
      get() {
        return this.$store.getters["chart/getSecurityId"];
      },
    },
    timeline: {
      get() {
        return this.$store.getters["chart/getTimeline"];
      },
    },
    currency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
    price_type: {
      get() {
        return this.$store.getters["chart/getPriceType"];
      },
    },
    market_id: {
      get() {
        return this.$store.getters["chart/getMarketId"];
      },
    },
    is_current_theme: {
      get() {
        return this.$store.getters["dashboard/getCurrentTheme"];
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
  },
  watch: {
    async is_maximize(val) {
      if (val == 1) {
        await this.fetchMaxOtherReports(0);
      }
    },
  },
  props: ["CompisResearchOpen","CompResearchHeight", "CompResearchHeightMaxi"],
  methods: {
    async fetchResearch() {
      this.loading = true;
      const requestData = new FormData();
      requestData.append("page", this.next_page_index);
      await axios.post("/research", requestData).then((response) => {
        this.next_page_index = response.data.next_page_index;
        let ResearchItems =
          typeof response.data.data == typeof undefined ||
          response.data.data == ""
            ? []
            : response.data.data;
        for (var i in ResearchItems) {
          this.ResearchItems.push(ResearchItems[i]);
        }
      });
      this.loading = false;
    },
    async fetchMaxOtherReports(is_load) {
      this.Otherloading = true;
      const requestData = new FormData();
      requestData.append("page", this.other_page_index);
      await axios
        .post("/get-other-report-api", requestData)
        .then((response) => {
          this.other_page_index = response.data.other_page_index;
          let OtherItems =
            typeof response.data.data.sliders == typeof undefined ||
            response.data.data.sliders == ""
              ? []
              : response.data.data.sliders;
          if (is_load == 0) {
            this.OtherItems = [];
          }
          for (var i in OtherItems) {
            this.OtherItems.push(OtherItems[i]);
          }
          if (this.other_page_index == 2) {
            let series1 = response.data.data.chart_data.series1;
            let series1_items = new Array();
            for (var j in series1) {
              let one = new Array();
              one.push(new Date(series1[j].date).getTime());
              one.push(series1[j].price);
              series1_items.push(one);
            }

            let series2 = response.data.data.chart_data.series2;
            let series2_items = new Array();
            for (var k in series2) {
              let one = new Array();
              one.push(new Date(series2[k].date).getTime());
              one.push(series2[k].price);
              series2_items.push(one);
            }
            let title1 = response.data.data.title1;
            let title2 = response.data.data.title2;
            this.series = [
              {
                name: title1,
                data: series1_items,
              },
              {
                name: title2,
                data: series2_items,
              },
            ];
          }
        });
      this.Otherloading = false;
    },

    searchReportEndpoint(input) {
      return themeconfig.get_vue_app_api_endpoint() + "research?query=" + input;
    },
    formattedReportDisplay(result) {
      return (
        result.country_name +
        " " +
        result.format_type +
        ": " +
        result.report_ttl
      );
    },
    async selectSearchedReport(secData) {
      this.$refs.autocomplete.clear();
      window.open(secData.selectedObject.details_url, "_blank");
    },
    searchFocus(e) {
      document
        .getElementsByClassName("search-bar")[1]
        .classList.remove("focus");
      if (e.target.matches(".search-research")) {
        document.getElementsByClassName("search-bar")[1].classList.add("focus");
      }
    },
    async updateTradingChartOption() {
      this.chartOptions = {
        chart: {
          id: "area-datetime",
          type: "area",
          foreColor:
            localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : "#333333",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
            },
          ],
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        colors: [
          localStorage.getItem("isDarkActive") == 1 ? "#ffffff" : "#001a34",
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#333333",
          localStorage.getItem("isDarkActive") == 1 ? "#7f7f7f" : "#404040",
          localStorage.getItem("isDarkActive") == 1 ? "#bfbfbf" : "#7f7f7f",
          localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : "#bfbfbf",
        ],
        style: {
          fontSize: "12px",
          fontFamily: "Montserrat",
        },
        stroke: {
          width: 2,
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 6,
          labels: {
            formatter: (val) =>
              this.market_id == "6"
                ? val.toFixed(5)
                : new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                  }).format(val.toFixed(2)),
          },
        },
        grid: {
          // borderColor: '#333333',
          borderColor:
            localStorage.getItem("isDarkActive") == 1 ? "#333333" : "#dbdbdb",
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.3,
            stops: [0, 100],
          },
        },
        legend: {
          show: false,
        },
      };
    },
    async openPanel(report_id) {
      this.loading = true;
      const requestData = new FormData();
      requestData.append("report_id", report_id);
      await axios
        .post("/get-report-chart-by-id", requestData)
        .then((response) => {
          let ChartItems =
            typeof response.data.data.chart_data == typeof undefined ||
            response.data.data.chart_data == ""
              ? []
              : response.data.data.chart_data;
          let title1 = "";
          let title2 = "";

          let series1 = ChartItems.series1;
          let series1_items = new Array();
          for (var j in series1) {
            let one = new Array();
            one.push(new Date(series1[j].date).getTime());
            one.push(series1[j].price);
            title1 = series1[j].title;
            series1_items.push(one);
          }

          let series2 = ChartItems.series2;
          let series2_items = new Array();
          for (var k in series2) {
            let one = new Array();
            one.push(new Date(series2[k].date).getTime());
            one.push(series2[k].price);
            title2 = series2[k].title;
            series2_items.push(one);
          }
          this.series = [
            {
              name: title1,
              data: series1_items,
            },
            {
              name: title2,
              data: series2_items,
            },
          ];
        });

      this.loading = false;
    },
  },
  async mounted() {
    if(this.CompisResearchOpen){
      // console.log("call Research component mounted order");
      await this.fetchResearch();
      let currentDom = this;
      // Detect when scrolled to bottom.
      const listElm = document.querySelector("#research-list");
      listElm.addEventListener("scroll", function() {
        // const load = document.getElementById("loading");
        // load.classList.remove("full-page");
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (currentDom.next_page_index != 0) {
            currentDom.fetchResearch();
          }
        }
      });

      const listElmMax = document.querySelector("#maxmize-research-wrap");
      listElmMax.addEventListener("scroll", function() {
        if (
          listElmMax.scrollTop + listElmMax.clientHeight >=
          listElmMax.scrollHeight
        ) {
          if (currentDom.is_maximize == 1) {
            if (currentDom.other_page_index != 0) {
              currentDom.fetchMaxOtherReports(1);
            }
          }
        }
      });
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.research-wrap {
  padding: 0px;
  .research-list {
    list-style: none;
    margin: 0 0 0;
    padding: 0;
    display: flex;
    justify-items: flex-start;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    height: 325px;
    li {
      margin: 0;
      padding: 0;
      width: 100%;
      a {
        width: 100%;
        padding: 0.25rem 0.5rem;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        h3 {
          color: var(--dark);
          font-size: 13px;
          font-weight: 400;
          margin-bottom: 5px;
          text-align: left;
          @media only screen and (min-width: 1799px) {
            font-size: 14px !important;
          }
          @media only screen and (max-width: 1399px) {
            font-size: 12px !important;
          }
        }
        .date {
          color: var(--grey);
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0;
          @media only screen and (min-width: 1799px) {
            font-size: 13px !important;
          }
          @media only screen and (max-width: 1399px) {
            font-size: 11px !important;
          }
        }
        &:hover {
          color: var(--white);
          background: var(--list-hover-bg);
          h3 {
            color: var(--white);
          }
          .date {
            color: var(--list-date-hover-bg);
          }
        }
      }
      &:nth-of-type(even) {
        a {
          background: rgba(var(--even-hover-rgba));
          &:hover {
            color: var(--white);
            background: var(--list-hover-bg);
            h3 {
              color: var(--white);
            }
            .date {
              color: var(--list-date-hover-bg);
            }
          }
        }
      }
    }
  }
  // .list-wrap {
  //   position: related;
  // }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
#app {
  .research-minimize {
    display: block;
  }
  .maxmize-research-wrap {
    display: none;
  }
  .lm_maximised {
    .research-minimize {
      display: none;
    }
    .maxmize-research-wrap {
      display: block;

      width: 100%;
      .search-bar {
        width: 75%;
        margin: 0 auto;
        @media only screen and (max-width: 1399px) {
          width: 90% !important;
        }
      }
      .research-wrap-list {
        overflow-y: auto;
      }
      .research-accordion {
        width: 75%;
        margin: 0 auto;
        position: relative;
        top: 0;
        @media only screen and (max-width: 1399px) {
          width: 90% !important;
        }
        .card {
          width: 100%;
          padding: 0;
          background: rgba(var(--even-hover-rgba)) !important;
          display: block;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          margin: 20px 0;
          border: 1px solid rgba(var(--even-hover-rgba));

          &::before {
            display: none;
          }
          // &:nth-of-type(even) {
          //   .collapse,
          //   header {
          //     background: rgba(var(--even-hover-rgba)) !important;
          //   }
          // }
          .card-header {
            margin-bottom: 0;
          }
          header {
            padding: 0.5rem 0.75rem;
            display: block;
            width: 100%;
            background-color: rgba(0, 0, 0, 0) !important;
            border-bottom: none !important;
            position: relative;
            &::before {
              content: "";
              width: 4px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 0;
              height: 40px;
              background-color: var(--select-list-bg);
            }
            &:focus {
              border: none;
              outline: none;
              box-shadow: none;
            }
            .not-collapsed {
              img {
                transform: rotate(180deg);
                transition: transform 0.5s linear;
              }
            }
            .btn-block {
              color: inherit;
              background-color: transparent;
              border-color: transparent;
              padding: 0 !important;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .accordion-arrow {
                width: 24px;
                height: 24px;
              }
              &:focus {
                border: none;
                outline: none;
                box-shadow: none;
              }
              > div {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                h3 {
                  color: var(--dark);
                  font-size: 13px;
                  font-weight: 400;
                  margin-bottom: 5px;
                  text-align: left;
                  @media only screen and (min-width: 1799px) {
                    font-size: 14px !important;
                  }
                  @media only screen and (max-width: 1399px) {
                    font-size: 12px !important;
                  }
                }
                .date {
                  color: var(--grey);
                  font-size: 12px;
                  font-weight: 400;
                  margin-bottom: 0;
                  @media only screen and (min-width: 1799px) {
                    font-size: 13px !important;
                  }
                  @media only screen and (max-width: 1399px) {
                    font-size: 11px !important;
                  }
                }
              }
              &::before {
                display: none;
              }
            }
          }
          .collapse {
            padding: 0.5rem;
            border-top: none !important;
            .card-body {
              padding: 0;
              text-align: left;
              width: 100% !important;
              margin: 0 auto;
              .report-group {
                width: 85%;
                margin: 0 auto;
              }
              .report-description {
                padding: 0 10px;
              }
              p {
                margin-bottom: 1rem;
                color: var(--dark);
                font-size: 12px;
                font-weight: 400;
                @media only screen and (min-width: 1799px) {
                  font-size: 13px !important;
                }
                @media only screen and (max-width: 1399px) {
                  font-size: 11px !important;
                }
              }
              .continue-btn {
                margin-right: 0 !important;
                margin-right: 10px !important;
                width: 175px !important;
              }
            }
          }
        }
      }
    }
  }
}
.home-s-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .market-chart-title-1 {
    .display_title_first {
      color: var(--primary);
      font-weight: 500;
      font-size: 13px;
      @media only screen and (min-width: 1799px) {
        font-size: 14px !important;
      }
      @media only screen and (max-width: 1399px) {
        font-size: 12px !important;
      }
    }
    .display_sub_title {
      color: var(--primary);
      font-weight: 500;
      font-size: 13px;
      @media only screen and (min-width: 1799px) {
        font-size: 14px !important;
      }
      @media only screen and (max-width: 1399px) {
        font-size: 12px !important;
      }
    }
    &:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 3px;
      background-color: var(--primary);
      position: relative;
      top: -3px;
      margin-right: 3px;
    }
  }

  .market-chart-title-2 {
    color: var(--gray);
    font-weight: 500;
    font-size: 13px;
    @media only screen and (min-width: 1799px) {
      font-size: 14px !important;
    }
    @media only screen and (max-width: 1399px) {
      font-size: 12px !important;
    }
    &:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 3px;
      background-color: var(--grey);
      position: relative;
      top: -3px;
      margin-right: 3px;
    }
    .display_sub_title {
      color: var(--gray);
      font-weight: 500;
      font-size: 13px;
      @media only screen and (min-width: 1799px) {
        font-size: 14px !important;
      }
      @media only screen and (max-width: 1399px) {
        font-size: 12px !important;
      }
    }

    .display_title_second {
      color: var(--gray);
      font-weight: 500;
      font-size: 13px;
      @media only screen and (min-width: 1799px) {
        font-size: 14px !important;
      }
      @media only screen and (max-width: 1399px) {
        font-size: 12px !important;
      }
    }
  }
}
.research-chart-wrap {
  padding: 5px 0;
  margin-bottom: 15px;
  .chart-main {
    position: relative;
    margin: 0 auto;
    width: 100% !important;
    .toolbar {
      position: absolute;
      top: 0;
      left: 15px;
      z-index: 9;
      &.position-right {
        left: inherit;
        right: 35px;
      }
      .btn {
        color: var(--toolbar-btn-color);
        font-size: 12px;
        font-weight: 400;
        height: inherit;
        padding: 5px 8px;
        border-radius: 0;
        line-height: 12px;
         margin:1px 0 0 8px;
        border: none;
        background-color: var(--toolbar-btn-bg);
        border: 1px solid var(--toolbar-btn-color);
        cursor: pointer;
         @media only screen and (max-width: 1599px) {
            padding: 3px 8px;
          }
        &:hover,
        &:focus,
        &.active {
          background-color: var(--toolbar-btn-color);
          border: 1px solid var(--toolbar-btn-color);
          color: var(--toolbar-btn-text);
        }
        &:first-child {
          margin-left: 0;
        }
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
    > div {
      min-height: inherit !important;
    }
    .apexcharts-canvas {
      padding-top: 10px;
      margin: 0 auto;
      width: 100% !important;
      .apexcharts-zoom-icon {
        display: none;
        &.apexcharts-selected {
          svg {
            fill: var(--apexchart-icon-color);
          }
        }
      }
    }
    .apexcharts-text {
      tspan {
        font-family: var(--font-family);
        color: var(--tspan-text) !important;
      }
    }
    .apexcharts-tooltip {
      background: var(--white-light);
      color: var(--primary);
      border-radius: 0;
      box-shadow: none;
      border-color: var(--grey-light);
      font-size: 12px;
      .apexcharts-tooltip-title {
        background: transparent;
        border: none;
        padding: 6px 6px 3px;
        margin-bottom: 0;
        font-size: 12px;
      }
      .apexcharts-tooltip-y-group {
        padding: 0;
      }
      .apexcharts-tooltip-series-group {
        padding: 3px 6px 6px;
      }
      .apexcharts-tooltip-marker {
        display: none;
      }
    }
    .apexcharts-menu {
      border: 1px solid var(--grey-light);
      border-radius: 0;
      padding: 0;
      right: 5px;
      background: var(--apexcharts-menu-bg);
      .apexcharts-menu-item {
        color: var(--primary);
        &:hover {
          background: var(--primary);
          color: var(--apexcharts-menu-hover-text);
        }
      }
    }
    .apexcharts-menu-icon,
    .apexcharts-zoomin-icon,
    .apexcharts-zoomout-icon,
    .apexcharts-reset-icon {
      color: var(--dark);
      margin: 0;
      svg {
        fill: var(--dark);
      }
      &:hover,
      &:focus {
        color: var(--primary);
        svg {
          fill: var(--primary);
        }
      }
    }
  }
  .vue-world-map {
    margin: 0 auto;
    width: 100% !important;
  }
  .vue-map-legend {
    background: var(--white-light);
    color: var(--primary);
    border-radius: 0;
    box-shadow: none;
    border-color: var(--grey-light);
    font-size: 12px;
    width: auto;
    min-width: 75px;
    margin: 0 auto;
    text-align: center;
    .vue-map-legend-header {
      background: transparent;
      border: none;
      padding: 6px 6px 3px;
      margin-bottom: 0;
      font-size: 12px;
      width: auto;
      margin: 0 auto;
      text-align: center;
      span {
        color: var(--primary);
        font-size: 12px;
        margin: 0 auto;
        text-align: center;
      }
    }
    .vue-map-legend-content {
      background: transparent;
      border: none;
      padding: 3px 6px 6px;
      margin-bottom: 0;
      font-size: 12px;
      width: auto;
      margin: 0 auto;
      text-align: center;
      span {
        color: var(--primary);
        font-size: 12px;
        font-weight: 600;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
.chart-main {
  .toolbar {
    &.position-right {
      left: inherit;
      right: 35px;
    }
  }
}
</style>
