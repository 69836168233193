export default {
	setCouponItems(state, data) {
		let coupon_items = new Array();
		let coupon_trades = new Array();
		let coupon_data=data.data;
		for (var i in coupon_data) {
			let one = new Array();
			one.push(new Date(coupon_data[i].created).getTime());
			one.push(coupon_data[i].total_market);
			coupon_items.push(one);
		}
		coupon_trades.push({
            name: data.name, 
            data:  coupon_items
        });
		state.coupon_items = coupon_trades;
	},
	setRedemptionItems(state, data) {
		let redemption_items = new Array();
		let redemption_trades = new Array();
		let redemption_data=data.data;
		for (var i in redemption_data) {
			let one = new Array();
			one.push(new Date(redemption_data[i].created).getTime());
			one.push(redemption_data[i].total_market);
			redemption_items.push(one);
		}
		redemption_trades.push({
            name: data.name, 
            data:  redemption_items
        });
		state.redemption_items = redemption_trades;
	},
	setTrailerItems(state, data) {
		let trailer_items = new Array();
		let trailer_trades = new Array();
		let trailer_data=data.data;
		for (var i in trailer_data) {
			let one = new Array();
			one.push(new Date(trailer_data[i].created).getTime());
			one.push(trailer_data[i].total_market);
			trailer_items.push(one);
		}
		trailer_trades.push({
            name: data.name, 
            data:  trailer_items
        });
		state.trailer_items = trailer_trades;
	},
	setAllItems(state, data) {
		let all_cashflows = new Array();
		let cashflows = new Array();
		let all_data=data.data;
		for (var i in all_data) {
			let one = new Array();
			one.push(new Date(all_data[i].created).getTime());
			one.push(all_data[i].total_market);
			all_cashflows.push(one);
		}
		cashflows.push({
            name: data.name, 
            data:  all_cashflows
		});
		state.all_items = cashflows;
	},
	setTimeline(state, timeline) {
		state.timeline = timeline
	},
	setPeriodicity(state, periodicity) {
		state.periodicity = periodicity
	},
	setMaxValue(state, max_value) {
		state.max_value = max_value
	},
	setMinValue(state, min_value) {
		state.min_value = min_value
	},
}