<template>
  <section class="notification-wrap">
    <ul
      class="common-list"
      id="infinite-ntlist"
      :style="{ height: CompNotiHeight + 'px' }"
    >
      <!-- <li v-for="item in items" :key="item.id" class="unread"> -->
      <li v-for="item in items" :key="item.id">
        <a href="javascript:void(0)" @click="notificationAlert(item.title)">
          <h3>{{ item.title }} ({{ item.description }})</h3>
          <span class="date">{{ item.date }}</span>
        </a>
      </li>
    </ul>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  name: "Notifications",
  data() {
    return {
      query: "",
      loading: false,
    };
  },
  computed: {
    items() {
      return this.$store.getters["notification/getItems"];
    },
    next_page_index() {
      return this.$store.getters["notification/getNextPage"];
    },
  },
  props: ["CompisNotificationOpen","CompNotiHeight"],
  methods: {
    async fetchNotifications() {
      this.loading = true;
      await this.$store.dispatch("notification/fetchItems", {
        page: this.next_page_index,
      });
      this.loading = false;
    },
    notificationAlert(message) {
      this.$swal({
        title: "",
        showCancelButton: false,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        text: message,
      });
    },
  },
  async mounted() {
    if(this.CompisNotificationOpen){
      // console.log("call Notification component mounted order");
      await this.fetchNotifications();
      let currentDom = this;
      // Detect when scrolled to bottom.
      const listElm = document.querySelector("#infinite-ntlist");
      listElm.addEventListener("scroll", function() {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (currentDom.next_page_index != 0) currentDom.fetchNotifications();
        } else {
          this.loading = false;
        }
      });
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.notification-wrap {
  padding: 0;
  .common-list {
    list-style: none;
    margin: 0 0 0;
    padding: 0;
    display: flex;
    justify-items: flex-start;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    height: 250px;
    li {
      margin: 0;
      padding: 0;
      width: 100%;
      a {
        width: 100%;
        padding: 0.25rem 0.5rem;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        h3 {
          color: var(--dark);
          font-size: 13px;
          font-weight: 400;
          margin-bottom: 5px;
          text-align: left;
          @media only screen and (min-width: 1799px) {
            font-size: 14px !important;
          }
          @media only screen and (max-width: 1399px) {
            font-size: 12px !important;
          }
        }
        .date {
          color: var(--grey);
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0;
          @media only screen and (min-width: 1799px) {
            font-size: 13px !important;
          }
          @media only screen and (max-width: 1399px) {
            font-size: 11px !important;
          }
        }
        &:hover {
          color: var(--white);
          background: var(--list-hover-bg);
          h3 {
            color: var(--white);
          }
          .date {
            color: var(--list-date-hover-bg);
          }
        }
      }
      &.unread {
        a {
          color: var(--white);
          background: var(--list-hover-bg);
          h3 {
            color: var(--white);
          }
          .date {
            color: var(--list-date-hover-bg);
          }
        }
        &:nth-of-type(even) {
          a {
            color: var(--white);
            background: var(--list-hover-bg);
            h3 {
              color: var(--white);
            }
            .date {
              color: var(--list-date-hover-bg);
            }
          }
        }
      }
      &:nth-of-type(even) {
        a {
          background: rgba(var(--even-hover-rgba));
          &:hover {
            color: var(--white);
            background: var(--list-hover-bg);
          }
        }
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
</style>
