import axios from 'axios'

export default {
	fetchItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/watchlist', payload)
				.then(response => {
					commit('setItems', response.data.data.all_data)
					commit('setMenus', response.data.data.watch_data)
					let tempCash = [];
                    let tempStock = [];
					for (let obj of response.data.data.all_data) {
                        if(obj.iex_symbol != undefined && obj.iex_symbol != null){
                            if(obj.market_id == 6 || obj.market_id == 'cash'){
                                tempCash.push(obj.iex_symbol);
                            }
                            else if(obj.market_id == 1){
                                tempStock.push(obj.iex_symbol);
                            }
						}
					}
					tempCash = [...new Set(tempCash)];
					tempStock = [...new Set(tempStock)];
					
					commit('setCashSymbols', tempCash)
					commit('setStockSymbols', tempStock)

					resolve(response)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	setSelectedItem({
		commit //, dispatch
	}, security) {
		commit('setSelectedItem', security)
		// dispatch('chart/fetchItems', {
		// 	security_id: security.id
		// })
	},
	removeWatch({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('remove-watchlist', payload)
				.then((response) => {
					commit('setRemoveResult', response.data)
					resolve(response.data)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	updateItems({
		commit
	}, payload){
		commit('setItems', payload)
	},
}