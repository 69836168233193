import Vue from 'vue'
import App from './App.vue'
// import themeconfig from './themeconfig'
import router from "./router/index";
import vuetify from '@/plugins/vuetify'
import {
  BootstrapVue,
  IconsPlugin
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vgl from 'vue-golden-layout'
import 'golden-layout/src/css/goldenlayout-light-theme.css'
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import store from './store/'
import Call from './axios/Call';
import Helpers from './helpers';
import {
  library
} from '@fortawesome/fontawesome-svg-core'
import {
  faUserSecret,
  faBell,
  faTrashAlt,
  faPlay,
  faEdit,
  faTimes,
  faHeart,
  faCalendarAlt,
  faMinusCircle,
  faPlusCircle,
  faChevronLeft,
  faEllipsisV,
  faBook,
  faSpinner
} from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import Autocomplete from 'vuejs-auto-complete'

import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

// themeconfig.setDomainBaseTheme();
// console.log("main get_vue_app_api_endpoint = >",themeconfig.get_vue_app_api_endpoint());
library.add(faUserSecret, faBell, faTrashAlt, faPlay, faEdit, faTimes, faHeart,faCalendarAlt,faMinusCircle,faPlusCircle,faChevronLeft, faEllipsisV, faBook, faSpinner)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
const options = {
  cancelButtonColor: '#001a34',
  confirmButtonColor: '#001a34',
}
Vue.use(VueSweetalert2, options)
Vue.use(VueApexCharts)
// Vue.use('toast',Toaster)
Vue.component('apexchart', VueApexCharts)
Vue.component('autocomplete', Autocomplete)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(vgl)
Vue.config.productionTip = false
Vue.mixin(Helpers)

let socket = new VueSocketIO({
  debug: false,
  connection: SocketIO(process.env.VUE_APP_SOCKET_URL,{secure: false}), //options object is Optional
  vuex: {
    store,
    actionPrefix: "",
    mutationPrefix: ""
  }
});
Vue.use(socket);


new Vue({
  router,
  store,
  Call,
  vuetify,
  render: h => h(App)
}).$mount('#app')