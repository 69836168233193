const formatDate = inputDate => {
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12"
  ];
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth();
  // const day = date.getDate();
  const day = ("0" + date.getDate()).slice(-2);
  const formattedDate = year + "-" + months[month] + "-" + day;
  return formattedDate;
};
const Call = {
    methods: {
      printmessage(key='',value=''){
        // let islocal = window.location.href.includes('localhost');
        let islive = window.location.href.includes('//workstation.emfi.uk');
        if(!islive){
          console.log(key,value);
        }
      },
        getTransactionStatusColor(status) {
            if (status === "Order Placed") return "#ffffb3";
            else if (status === "Order Working") return "#ffffb3";
            else if (status === "Order Filled") return "#32CD32";
            else if (status === "Order Settled") return "#32CD32";
            else if (status === "Order Killed" || status === "Order Cancelled")
                return "#e31d2d";
            else if (status === "Order Rejected") return "#e31d2d";
            else if (status === "Transaction Pending") return "#ffffb3";
            else if (status === "Transaction Submitted") return "#ffffb3";
            else if (status === "Transaction Matched") return "#ffff33";
            else if (status === "Transaction Settled") return "#32CD32";
            else if (status === "Transaction Cancelled" || status === "Transfer Cancelled") return "#e31d2d";
            else if (status === "Transaction Killed") return "#e31d2d";
            else if (status === "Cash Locked") return "#ffffb3";
            else if (status === "Cash Instructed") return "#ffff33";
            else if (status === "Cash Settled") return "#32CD32";
            else if (status === "Cash Cancelled") return "#e31d2d";
            else return "#ffffb3";
        },
        async loadSecurity(security_id, security_data) {
          
          //this.loadChartData(security_id);

          localStorage.setItem("current_security_id", security_id);
          localStorage.setItem("search_security_data", security_data);
          await this.$store.dispatch("chart/fetchSecurityId", security_id);
          await this.$store.dispatch("chart/fetchRate", { security_id });
        },
        async loadChartDataBak(security_id,type) {
          //this.loading=true;
          let old_security_id = this.$store.getters["chart/getSecurityId"];
          
          if(security_id != old_security_id 
            || (security_id == 11 && security_id == old_security_id) 
            || type === "direct"){

            let timeline = this.$store.getters["chart/getTimeline"];
            let candleTimeline = this.$store.getters["chart/getCandleTimeline"];
            let price_type = this.$store.getters["chart/getPriceType"];
            let payload = {
              security_id,
              timeline,
              price_type,
              candleTimeline
            };
            
            await this.$store.dispatch("chart/fetchItems", payload);
          }
          
        },
        async updateisVisibleNews(is_visible_news) {
            await this.$store.dispatch(
              "dashboard/fetchIsVisibleNews",
              is_visible_news
            );
        },
        async downloadPDFURL(url, name) {
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            link.setAttribute('target', "_blank");
            document.body.appendChild(link);
            link.click();
        },
        async updateFormQuantity(quantity) {
            await this.$store.dispatch(
              "holding/updateFormQuantity",
              quantity
            );
        },
        getTimeSelected(time) {
            if (time == "Month") return "one_month";
            else if (time == "Quarter") return "three_months";
            else if (time == "3 Month") return "three_months";
            else if (time == "6 Month") return "six_months";
            else if (time == "Year") return "one_year";
            else if (time == "1 Year") return "one_year";
            else if (time == "5 Years") return "five_year";
            else if (time == "10 Year") return "ten_year";
            else if (time == "30 Year") return "thirty_year";
            // else if (time == "5 Years") return "all";
            // else if (time == "MTD") return "mtd";
            else if (time == "YTD") return "ytd";
            else if (time == "Year End") return "year_to_end";
            else return "one_year";
        },
        getAverageSelected(average) {
            if (average == "7 Days") return "7";
            else if (average == "15 Days") return "15";
            else if (average == "30 Days") return "30";
            else if (average == "90 Days") return "90";
            else if (average == "None") return "none";
            else return "7";
        },
        getPeriodSelected(period) {
            if (period == "Daily") return "daily";
            else if (period == "Weekly") return "weekly";
            else if (period == "Monthly") return "monthly";
            else return "daily";
        },
        getPriceTypeSelected(type) {
            if (type === "Price") return "1";
            else if (type == "Yield") return "2";
            else if (type == "Spread") return "3";
            else return "1";
        },
        getMarketTypeSelected(market_type) {
            if (market_type === "Currencies") return "2";
            else if (market_type == "Equities") return "1";
            else if (market_type == "Funds") return "8";
            else if (market_type == "Bonds") return "5";
            else return "all";
        },
        getMailingPreferencesSelected(type) {
            if (type === 1) return "Realtime";
            else if (type === 2) return "Daily";
            else if (type === 3) return "Weekly";
            else return "No Reports";
        },
        getCountryPreferencesSelected(type) {
            if (type === 1) return "All";
            else if (type === 2) return "Selected";
            else return "";
        },
        async fetchAssetsChart() {
            this.loading = true;
            this.holdingseries = this.assets_items.amount;
            this.chartOptions = {
              chart: {
                type: "donut",
              },
              labels: this.assets_items.label,
              plotOptions: {
                pie: {
                  donut: {
                    size: "45%",
                    labels: {
                      show: false,
                    },
                  },
                },
              },
              colors: [
                  localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#001a33",
                  localStorage.getItem("isDarkActive") == 1 ? "#ca2330" : "#ca2330",
                  localStorage.getItem("isDarkActive") == 1 ? "#005842" : "#005842",
                  localStorage.getItem("isDarkActive") == 1 ? "#fbc117" : "#fbc117",
              ],
              legend: {
                show: false,
              },
              tooltip: {
                enabled: true,
                fillSeriesColor: false,
                style: {
                  fontSize: "12px",
                  fontFamily: "Montserrat",
                },
                y: {
                  formatter: function(value) {
                    return new Intl.NumberFormat("en-US").format(value.toFixed(0));
                  },
                },
              },
              style: {
                fontSize: "12px",
                fontFamily: "Montserrat",
              },
            };
          
            this.currencyseries = this.currency_chart_data.amount;
            this.currencyChartOptions = {
                chart: {
                  type: "donut",
                },
                labels: this.currency_chart_data.label,
                plotOptions: {
                  pie: {
                    donut: {
                      size: "41%",
                      labels: {
                        show: false,
                      },
                    },
                  },
                },
                colors: [
                  localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#001a34",
                  localStorage.getItem("isDarkActive") == 1 ? "#404040" : "#333333",
                  localStorage.getItem("isDarkActive") == 1 ? "#7f7f7f" : "#555555",
                  localStorage.getItem("isDarkActive") == 1 ? "#bfbfbf" : "#7f7f7f",
                ],
                legend: {
                  show: false,
                },
                tooltip: {
                  enabled: true,
                  fillSeriesColor: false,
                  style: {
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                  },
                  y: {
                    formatter: function(value) {
                      return new Intl.NumberFormat("en-US").format(value.toFixed(0));
                    },
                  },
                },
                style: {
                  fontSize: "12px",
                  fontFamily: "Montserrat",
                },
                animations: {
                  enabled: true,
                  easing: "easeout",
                  speed: 800,
                  animateGradually: {
                    enabled: true,
                    delay: 150,
                  },
                  dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                  },
                },
            };
            this.loading = false;
        },
        getFilterDateRange(){
          let today = new Date();
          let one_month = new Date();
          today.setHours(0,0,0,0);
          one_month.setMonth(one_month.getMonth() - 1);
          one_month.setHours(0,0,0,0);
          let three_month = new Date();
          three_month.setMonth(three_month.getMonth() - 3);
          three_month.setHours(0,0,0,0);
          let six_month = new Date();
          six_month.setMonth(six_month.getMonth() - 6);
          six_month.setHours(0,0,0,0);
          let one_year = new Date();
          one_year.setFullYear(one_year.getFullYear() - 1);
          one_year.setHours(0,0,0,0);
          let five_year = new Date();
          five_year.setFullYear(five_year.getFullYear() - 5);
          five_year.setHours(0,0,0,0);
          let ytd_date = new Date();
          ytd_date=new Date(ytd_date.getFullYear(), 0, 1);
          ytd_date.setHours(0,0,0,0);
          let ranges = {
            '1 Month': [one_month, today],
            '3 Months': [three_month, today],
            '6 Months': [six_month, today],
            '1 Year': [one_year, today],
            '5 Years': [five_year, today],
            'YTD': [ytd_date, today],
          }
          return ranges;
        },
        getTodayDate(){
          let today = new Date();
          let dates = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
          return dates;
        },
        getYMDDate(){
          let ytd_date = new Date();
          ytd_date = new Date(ytd_date.getFullYear(), 0, 1);
          let ytd_dates = ytd_date.getFullYear() + '-' + (ytd_date.getMonth() + 1) + '-' + ytd_date.getDate();
          return ytd_dates;
        },
        calculate_percentage(partialValue, totalValue) {
            return (100 * partialValue.replace(/,/g, '')) / totalValue.replace(/,/g, '');
        },
        formatDate,
        formatNumber(number,digit){
          if(number == undefined || number == null){
            return "";
          }

          number = ""+number;
          number = number.replace(/,/g, '');
          number = parseFloat(number);
          let options = {};
          if(digit != undefined){
            options.maximumFractionDigits = digit;
          }
          
          if(number == undefined || number == 0 || number == null || Number.isNaN(number)){
            return 0;
          }
          else{
            number = new Intl.NumberFormat("en-US",options).format(number);
            return number; 
          }
        },
        removeNumberFormat(number){
          if(number == undefined || number == null){
            return "";
          }
          number = ""+number;
          number = number.replace(/[(]/g, '');
          number = number.replace(/[)]/g, '');
          number = number.replace(/,/g, '');
          number = parseFloat(number);
          return number;
        },
        convertPositive(number,digit) {
          number = ""+number;
          number = number.replace(/,/g, '');
          number = parseFloat(number);
          let options = {};
          if(digit != undefined){
            options.maximumFractionDigits = digit;
          }
          if (number < 0) {  
            number = Math.abs(number);
            number = new Intl.NumberFormat("en-US",options).format(number);
            number = '('+number+')';
            return number;
          }
          else if(number>0){
              number = new Intl.NumberFormat("en-US",options).format(number);
              return number; 
          } 
          else {
              return 0;
          }
          
        },
        formatFundPrice(number,digit) {
          number = ""+number;
          number = number.replace(/,/g, '');
          number = parseFloat(number);
          let options = {};
          if(digit != undefined){
            options.maximumFractionDigits = digit;
          }
          if(number>0){
              number = new Intl.NumberFormat("en-US",options).format(number);
              return number; 
          } 
          else {
              return 0.00; 
          }
          
        },
        convertPositiveCurrency(number) {
          number = ""+number;
          let num_arr = number.split('.');
          if(num_arr[1] && num_arr[1].length > 0){
            let prec = num_arr[1].length;
            if(prec > 6){
              prec = 6;
            }
            return this.convertPositive(number,prec);
          }
          
          return this.convertPositive(number);
        },
        formatPrice(price,market_id,digit){
          price = parseFloat(price);

          if(price == undefined || price == 0 || price == null || Number.isNaN(price)){
            price = 0;
          }
          if(digit != undefined && digit != null && digit > 0){
            digit = parseInt(digit);
            return price.toFixed(digit);
          }
          if(market_id == 'cash' || market_id == 6){
            price = price.toFixed(5);
          }
          else if(market_id == 5){
            price = price.toFixed(3);
          }
          else{
            price = price.toFixed(2);
          }
          return price;
        },
        gridFirstPrice(price){
          price = parseFloat(price);
          price = price.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
          return price;
        },
        gridSecondPrice(price,from,to){
          price = parseFloat(price);
          var num=(price - Math.floor(price));
          price = num.toString().slice(from,to);
          return price;
        },
        dateMonthYearFromat(date){
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
        // let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        // console.log(`${mo} ${ye}`);
        return `${mo} ${ye}`;
        },
        dateFromat(date){
          let d = new Date(date);
          let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
          let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
          let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
          // console.log(`${mo} ${ye}`);
          return `${da}/${mo}/${ye}`;
        },        
        //   sendSocketMessage: function(event, data) {
        //   this.$socket.emit(event, data);
        // },
        async fetchTransactions(is_filter) {
        this.loading = true;
        if (is_filter == "1") {
          await this.updateTransactionItems();
        }
        await this.$store.dispatch("transaction/fetchItems", {
          filter_portfolio_ids: this.filter_portfolio,
          draw: this.next_page_index,
          start: this.start_record,
          length: this.record_length,
          start_date: '',
          end_date: '',
          is_filter: is_filter,
          reference: this.search_table.reference,
          trade_date: this.search_table.trade_date,
          security_name: this.search_table.security_name,
          status: this.search_table.status,
          settlement_date_oprator: this.search_table.settlement_date_oprator,
          settlement_date_val: this.search_table.settlement_date_val,
          trade_date_oprator: this.search_table.trade_date_oprator,
          trade_date_val: this.search_table.trade_date_val,
          security_name_oprator: this.search_table.security_name_oprator,
          security_name_val: this.search_table.security_name_val,
          identifier_oprator: this.search_table.identifier_oprator,
          identifier_val: this.search_table.identifier_val,
          amount_oprator: this.search_table.amount_oprator,
          amount_val: this.search_table.amount_val,
          price_oprator: this.search_table.price_oprator,
          price_val: this.search_table.price_val,
          portfolio_oprator: this.search_table.portfolio_oprator,
          portfolio_val: this.search_table.portfolio_val,
          counterparty_oprator: this.search_table.counterparty_oprator,
          counterparty_val: this.search_table.counterparty_val,
          status_oprator: this.search_table.status_oprator,
          status_val: this.search_table.status_val,
        });
        await this.fetchKillCounts();
        this.loading = false;
        },
      async fetchKillCounts() {
      await this.$store.dispatch("transaction/fetchKillCount", {
        filter_portfolio_ids: this.filter_portfolio,
      });
    },
      async updateTransactionItems() {
      var items_prm = [];
      await this.$store.dispatch("transaction/updateItems", items_prm);
    },
    async downloadURL(url, name) {
      var link = document.createElement('a');
      link.href = url;
      link.setAttribute("target", "_blank");
      link.setAttribute("download", name);
      link.click();
    },
    

  },
  data() {
    return {
        text_oprator_list: [
          { text: "Equal", value: "equal" },
          { text: "Not Equal", value: "not_equal" },
        ]
      };
  },
}

export default Call;