<template>
  <div class="performance-wrap-2">
   <template>
      <v-navigation-drawer
        class="order-form-sidebar filter-sidebar"
        width="62%"
        v-model="ViewDetailsDrawer"
        temporary
        absolute
        right
      >
        <div v-if="realizedSlide">
          <v-card class="custome-modal table-t-body">
            <v-card-title class="headline p-1">
              <div
                class="box-title d-flex justify-content-between align-items-center"
              >
                <h4>
                  Performance of {{ pnl_security_name }} ({{
                    dateFromat(this.start_filter_date)
                  }}
                  To {{ dateFromat(this.end_filter_date) }})
                </h4>
              </div>
            </v-card-title>
          </v-card>
          <v-simple-table
            class="custome-table child-table popup-table"
            fixed-header
            :height="realized_items.length > 25 ? '75vh' : ''"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-start" width="10%">
                    Report Date
                  </th>
                  <th class="text-center" width="23%">
                    Amount
                  </th>
                  <th class="text-center" width="13%">
                    Buy Price
                  </th>
                  <th class="text-center" width="12%">
                    Sell Price
                  </th>
                  <th class="text-center" width="15%">
                    Trading P&L
                  </th>
                  <th class="text-center" width="15%">
                    Paid Interest
                  </th>
                  <th class="text-center" width="12%">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(realized_item, index) in realized_items"
                  :key="realized_item.id+index"
                >
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      realized_item.date
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      realized_item.total_quantity
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      formatPrice(
                        realized_item.buy_price,
                        realized_item.market_id
                      )
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      formatPrice(
                        realized_item.sell_price,
                        realized_item.market_id
                      )
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      realized_item.nb
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      realized_item.paid_interest
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      realized_item.total
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          
        </div>
       
        
      </v-navigation-drawer>
     
    </template>
    
  </div>
</template>

<script>
export default {
  name: "PerformanceRealizeDetailProp",
  components: { },
  data() {
    return {
      realizedSlide: false,
      ViewDetailsDrawer:false,
      realized_items:[],
      pnl_security_name:'',
      CompPerformanceHeight:'',
      start_filter_date:'',
      end_filter_date:''
      
    };
  },
  computed: {
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
    
  },
  watch: {
    async PerformanceRealizeDetailProp() {
      this.realizedSlide = true;
      this.realized_items = this.PerformanceRealizeDetailProp.data;
      this.pnl_security_name = this.PerformanceRealizeDetailProp.pnl_security_name;
      this.start_filter_date = this.PerformanceRealizeDetailProp.start_filter_date;
      this.end_filter_date = this.PerformanceRealizeDetailProp.end_filter_date;
      this.CompPerformanceHeight = this.PerformanceRealizeDetailProp.CompPerformanceHeight;
      //console.log("call realized_items...",this.PerformanceRealizeDetailProp.data);
      this.ViewDetailsDrawer = true;
    },
    
  },
  methods: {
   
    
     
  },
  props: ["PerformanceRealizeDetailProp"],
  async mounted() {
    // console.log("call CashflowDetail",this.PerformanceRealizeDetailProp);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
#app {
  .performance-wrap-2 {
    padding: 0;
    #cashflow-scroll-table {
      overflow: auto;
    }
    .filter-sidebar{
      z-index: 999999 !important;
    }
    .custome-table {
      table {
        > tbody {
          > tr {
            > td {
              &.text-center {
                .teble-ellipsis-wrap {
                  margin: 0 auto !important;
                }
              }
              .teble-ellipsis-wrap {
                width: 120px !important;
              }
              &:last-child {
                .teble-ellipsis-wrap {
                  width: 100px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
