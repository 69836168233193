<template>
  <div class="cash-flows-chart-wrap">
    <div id="cashflowchart">
      <div class="toolbar position-right">
        <b-dropdown
          id="period-drp"
          right
          :text="this.period_drp_placeholder"
          variant="primary"
          class="custome-dropdown margin-r-10"
          title="Interval"
        >
          <b-dropdown-item
            href="javascript:void(0);"
            @click="setPeriodSelectedOption('Daily')"
            v-if="this.period_drp_placeholder != 'Daily'"
            >Daily</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0);"
            @click="setPeriodSelectedOption('Weekly')"
            v-if="this.period_drp_placeholder != 'Weekly'"
            >Weekly</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0);"
            @click="setPeriodSelectedOption('Monthly')"
            v-if="this.period_drp_placeholder != 'Monthly'"
            >Monthly</b-dropdown-item
          >
        </b-dropdown>
        <b-dropdown
          id="time-drp"
          right
          :text="this.time_drp_placeholder"
          variant="primary"
          class="custome-dropdown"
          title="Period"
        >
          <b-dropdown-item
            href="javascript:void(0);"
            @click="setTimeSelectedOption('Quarter')"
            v-if="this.time_drp_placeholder != 'Quarter'"
            >Quarter</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0);"
            @click="setTimeSelectedOption('Year')"
            v-if="this.time_drp_placeholder != 'Year'"
            >Year</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0);"
            @click="setTimeSelectedOption('5 Years')"
            v-if="this.time_drp_placeholder != '5 Years'"
            >5 Years</b-dropdown-item
          >
          <b-dropdown-item
            href="javascript:void(0);"
            @click="setTimeSelectedOption('Year End')"
            v-if="this.time_drp_placeholder != 'Year End'"
            >Year End</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <div id="cash-flows-chart">
        <apexchart
          type="line"
          :height="CompCashflowsHeight"
          :width="CompCashflowsWidth"
          ref="cashflowchart"
          :options="chartOptions"
          :series="cashseries"
        ></apexchart>
      </div>
    </div>
    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import themeconfig from '../themeconfig.js'
export default {
  name: "Cashflows",
  components: { apexchart: VueApexCharts },
  props: ["CompisCashFlowsChartOpen","CompCashflowsHeight", "CompCashflowsWidth"],
  data() {
    return {
      loading: false,
      cashseries: [],
      chartOptions: {
        chart: {
          type: "line",
          stacked: true,
          foreColor:'',
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        xaxis: {
          type: "datetime",
          //tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return new Intl.NumberFormat("en-US").format(val.toFixed(0));
            },
          },
          min: this.min_value,
          max: this.max_value,
        },
        stroke: {
          width: [0, 0, 5],
          curve: "smooth",
        },
        colors: [],
        legend: {
          show: true,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format: "MMM yyyy",
          },
          y: {
            formatter: function(value) {
              return new Intl.NumberFormat("en-US").format(value.toFixed(0));
            },
          },
        },
        fill: {
          opacity: 1,
          type: ["solid"],
        },
      },
      selection: "one_year",
      time_drp_placeholder: "Year",
      period_drp_placeholder: "Monthly",
    };
  },
  computed: {
    all_c_items() {
      let all_items = this.$store.getters["cashflows_chart/getAllItems"];
      return all_items[0] ?? [];
    },
    coupon_items() {
      let coupon_items = this.$store.getters["cashflows_chart/getCouponItems"];
      return coupon_items[0] ?? [];
    },
    redemption_items() {
      let redemption_items = this.$store.getters["cashflows_chart/getRedemptionItems"];
      return redemption_items[0] ?? [];
    },
    trailer_items() {
      let trailer_items = this.$store.getters["cashflows_chart/getTrailerItems"];
      return trailer_items[0] ?? [];
    },
    timeline: {
      get() {
        return this.$store.getters["cashflows_chart/getTimeline"];
      },
    },
    currency() {
      return this.$store.getters["dashboard/getFilterCurrency"];
    },
    is_current_theme: {
      get() {
        return this.$store.getters["dashboard/getCurrentTheme"];
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
    periodicity: {
      get() {
        return this.$store.getters["cashflows_chart/getPeriodicity"];
      },
    },
    max_value: {
      get() {
        return this.$store.getters["cashflows_chart/getMaxValue"];
      },
    },
    min_value: {
      get() {
        return this.$store.getters["cashflows_chart/getMinValue"];
      },
    },
  },
  watch: {
    CompCashflowsHeight(new_val, old_val) {
      if (new_val != old_val) {
        this.fetchCashflows();
      }
    },
    CompCashflowsWidth(new_val, old_val) {
      if (new_val != old_val) {
        // this.fetchCashflows();
      }
    },
    async is_current_theme() {
      await this.updateCashFlowChartOption();
      await this.updateCashFlowSeries();
    },
    async filter_portfolio() {
      this.fetchCashflows();
    },
  },
  methods: {
    async setThemeoption(){
      const curretn_theme = themeconfig.getTheme();
      this.chartOptions.chart.foreColor = localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : curretn_theme.chart_for_color;
      this.chartOptions.colors = [
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : curretn_theme.chart_primary_color,
          localStorage.getItem("isDarkActive") == 1 ? "#bfbfbf" : "#7f7f7f",
          localStorage.getItem("isDarkActive") == 1 ? "#404040" : "#333333",
        ];
    },
    async setTimeSelectedOption(selectedOption) {
      this.time_drp_placeholder = selectedOption;
      let time = this.getTimeSelected(selectedOption);
      this.updateCashFlowChartData(time);
    },
    async setPeriodSelectedOption(selectedOption) {
      this.period_drp_placeholder = selectedOption;
      let period = this.getPeriodSelected(selectedOption);
      await this.$store.dispatch("cashflows_chart/updatePeriodicity", period);
      await this.fetchCashflows();
      await this.updateCashFlowChartOption();
    },
    async updateCashFlowChartData(timeline) {
      this.selection = timeline;
      await this.$store.dispatch("cashflows_chart/fetchTimeline", timeline);
      await this.fetchCashflows();
    },
    async fetchCashflows() {
      this.loading = true;
      await this.$store.dispatch("cashflows_chart/fetchItems", {
        filter_portfolio_ids: this.filter_portfolio,
        timeline: this.timeline,
        currency_filter: this.currency,
        periodicity: this.periodicity,
      });
      await this.updateCashFlowChartOption();
      await this.updateCashFlowSeries();
      this.loading = false;
    },
    async updateCashFlowSeries() {
      this.$refs.cashflowchart.updateSeries([
        {
          name: this.coupon_items.name,
          type: "bar",
          data: this.coupon_items.data,
        },
        {
          name: this.redemption_items.name,
          type: "bar",
          data: this.redemption_items.data,
        },
        {
          name: this.trailer_items.name,
          type: "bar",
          data: this.trailer_items.data,
        },
        // {
        //   name: "Total",
        //   type: "line",
        //   data: this.all_c_items.data,
        // },
      ]);
    },
    async updateCashFlowChartOption() {
      const curretn_theme = themeconfig.getTheme();
      this.chartOptions = {
        chart: {
          type: "line",
          stacked: true,
          foreColor:
            localStorage.getItem("isDarkActive") == 1 ? "#a8a8a8" : curretn_theme.chart_for_color,
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
            },
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        xaxis: {
          type: "datetime",
          //tickAmount: 6,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return new Intl.NumberFormat("en-US").format(val.toFixed(0));
            },
          },
          min: this.min_value,
          max: this.max_value,
        },
        stroke: {
          width: [0, 0, 2],
          curve: "smooth",
        },
        colors: [
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : curretn_theme.chart_primary_color,
          localStorage.getItem("isDarkActive") == 1 ? "#bfbfbf" : "#7f7f7f",
          localStorage.getItem("isDarkActive") == 1 ? "#000000" : "#fbc117",
        ],//fbc117
        legend: {
          show: true,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
            fontFamily: "Montserrat",
            color: "#f00",
          },
          x: {
            format:
              this.periodicity && this.periodicity != "daily"
                ? "MMM yyyy"
                : "dd MMM yyyy",
          },
          y: {
            formatter: function(value) {
              return new Intl.NumberFormat("en-US").format(value.toFixed(0));
            },
          },
        },
      };
    },
  },
  async mounted() {
    if(this.CompisCashFlowsChartOpen){
      // console.log("call CashFlowsChart component mounted order");
      await this.setThemeoption();
      await this.fetchCashflows();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.cash-flows-chart-wrap {
  padding: 5px 0;
  #cashflowchart {
    position: relative;
    .toolbar {
      position: absolute;
      top: 0;
      left: 15px;
      z-index: 9;
      &.position-right {
        left: inherit;
        right: 35px;
      }
      .btn {
        color: var(--toolbar-btn-color);
        font-size: 12px;
        font-weight: 400;
        height: inherit;
        padding: 5px 8px;
        border-radius: 0;
        line-height: 12px;
        margin:1px 0 0 8px;
        border: none;
        background-color: var(--toolbar-btn-bg);
        border: 1px solid var(--toolbar-btn-color);
        cursor: pointer;
         @media only screen and (max-width: 1599px) {
            padding: 3px 8px;
          }
        &:hover,
        &:focus,
        &.active {
          background-color: var(--toolbar-btn-color);
          border: 1px solid var(--toolbar-btn-color);
          color: var(--toolbar-btn-text);
        }
        &:first-child {
          margin-left: 0;
        }
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
    > div {
      min-height: inherit !important;
    }
    .apexcharts-canvas {
      padding-top: 10px;
      margin-right: 0;
      width: 100% !important;
      .apexcharts-zoom-icon {
        display: none;
        &.apexcharts-selected {
          svg {
            fill: var(--apexchart-icon-color);
          }
        }
      }
    }
    .apexcharts-text {
      tspan {
        font-family: var(--font-family);
        color: var(--tspan-text) !important;
      }
    }
    .apexcharts-tooltip {
      background: var(--white-light);
      color: var(--primary);
      border-radius: 0;
      box-shadow: none;
      border-color: var(--grey-light);
      font-size: 12px;
      .apexcharts-tooltip-title {
        background: transparent;
        border: none;
        padding: 6px 6px 3px;
        margin-bottom: 0;
        font-size: 12px;
      }
      .apexcharts-tooltip-y-group {
        padding: 0;
      }
      .apexcharts-tooltip-series-group {
        padding: 3px 6px 6px;
      }
      .apexcharts-tooltip-marker {
        display: none;
      }
    }
    .apexcharts-menu {
      border: 1px solid var(--grey-light);
      border-radius: 0;
      padding: 0;
      right: 5px;
      background: var(--apexcharts-menu-bg);
      .apexcharts-menu-item {
        color: var(--primary);
        &:hover {
          background: var(--primary);
          color: var(--apexcharts-menu-hover-text);
        }
      }
    }
    .apexcharts-menu-icon,
    .apexcharts-zoomin-icon,
    .apexcharts-zoomout-icon,
    .apexcharts-reset-icon {
      color: var(--dark);
      margin: 0;
      svg {
        fill: var(--dark);
      }
      &:hover,
      &:focus {
        color: var(--primary);
        svg {
          fill: var(--primary);
        }
      }
    }
  }
}
</style>
