export default {
	realized_items: [],
	unrealized_items: [],
	all_items: [],
	timeline: "one_year",
	moving_avg: "90",
	periodicity: "monthly",
	max_value: 0,
	min_value: 0,
	stock_items: [],
	bond_items: [],
	fund_items: [],
	trading_items: [],
	interest_items: [],
}