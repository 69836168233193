// axios
import themeconfig from './themeconfig'
import axios from 'axios'
import router from './router'

// let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hZG1pbi5lbWZpLmxvY2FsXC9hcGlcL2F1dGhcL2xvZ2luIiwiaWF0IjoxNjExOTk2NDY3LCJleHAiOjE2MTIwODI4NjcsIm5iZiI6MTYxMTk5NjQ2NywianRpIjoiMHJ0eGFDUUE0RVhtV2dSZCIsInN1YiI6MzI1LCJwcnYiOiIwMGUxODU5YWIzOGM0YzczOTdlMzQ1MmIxMWQ4OGEwMzk1NGExZjYyIn0.NHKSkRdB7JrOmFO4md-Qndj8so82wyDx6GKZKk5bqR4";
let token = localStorage.getItem("user-token");
const baseURL = themeconfig.get_vue_app_api_endpoint();
export default axios.create({
	baseURL,
	headers: {
		"Content-type": "application/json",
		Authorization: "Bearer " + token,
	},
})

axios.defaults.baseURL = baseURL

axios.interceptors.request.use((req) => {
	// const accessToken = localStorage.getItem('accessToken')
	// if (accessToken !== undefined && accessToken !== null && accessToken) {
	// 	req.headers['Authorization'] = `Bearer ${token}`
	// }
	req.headers['Content-type'] = "application/json"
	req.headers['Authorization'] = `Bearer ${token}`
	return req
})

axios.interceptors.response.use((response) => {
	return response
}, (error) => {
	if (error.response.status === 403) {
		localStorage.removeItem('user-token')
		router.push('/login')
	} else if (error.response.status === 401) {
		// alert("TOKEN ERROR")
		localStorage.removeItem('user-token')
		router.push('/login')
	}
	if (error.response.status === 401) {
		// alert("TOKEN ERROR")
		localStorage.removeItem('user-token')
		router.push('/login')
	}
	
	return Promise.reject(error)
})
