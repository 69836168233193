export default {
	getItem: state => (id) => {
		return state.items.find((item) => item.id === id)
	},
	getItems: state => {
		return state.items
	},
	getUnrealizedItem: state => {
		return state.unrealized_item
	},
	getRealizedItem: state =>{
		return state.realized_item
	},
	getNextPage: state => {
        return state.next_page_index
    },
	getLengthRecord: state => {
        return state.length_record
    },
	getStartRecord: state => {
		return state.start_record
	},
	getUnrealizedNextPage: state => {
        return state.unrealized_next_page_index
    },
	getUnrealizedLengthRecord: state => {
        return state.unrealized_length_record
    },
	getUnrealizedStartRecord: state => {
		return state.unrealized_start_record
	},
	getRealizedNextPage: state => {
        return state.realized_next_page_index
    },
	getRealizedLengthRecord: state => {
        return state.realized_length_record
    },
	getRealizedStartRecord: state => {
		return state.realized_start_record
	},
}