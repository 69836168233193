export default {
	setAllItems(state,data){
		state.all_items=data
	},
	setItems(state, data) {
		let items = new Array();
		let chart_data = data.data.chart_data;
		let chart_type = data.chart_type;
		for (let item of chart_data) {
			if(chart_type == 'candlestick'){
				let one = {
					x: new Date(item.created).getTime(),
					y: [item.open_price, item.high_price, item.low_price, item.close_price]
				}
				items.push(one);
			}
			else{
				let one = new Array();
				one.push(new Date(item.created).getTime());
				one.push(item.price);
				items.push(one);
			}
		}
		state.chart_type = chart_type;
		state.items = items;
	},
	setName(state, name) {
		state.name = name
	},
	setTimeline(state, timeline) {
		state.timeline = timeline
	},
	setCandleTimeline(state, timeline) {
		state.candleTimeline = timeline
	},
	setIsLoading(state, isLoading) {
		state.isLoading = isLoading
	},
	setSecurityId(state, security_id) {
		state.security_id = security_id
	},
	setDefaultSecurity(state, security_id) {
		state.security_id = security_id
	},
	setBid(state, bid) {
		state.bid = bid
	},
	setAsk(state, ask) {
		state.ask = ask
	},
	setRate(state, rate) {
		state.rate = rate
	},
	setMarketId(state, market_id) {
		state.market_id = market_id
	},
	setLastPrice(state, last_price) {
		state.last_price = last_price
	},
	setAppUri(state, app_uri) {
		state.app_uri = app_uri
	},
	setCounterparty(state, counterparty) {
		state.counterparty = counterparty
	},
	setAddedWatchlist(state, added_watchlist) {
		state.added_watchlist = added_watchlist
	},
	setDefaultSecurities() {

	},
	setPriceType(state, price_type){
		state.price_type = price_type
	},
	setConId(state, con_id) {
		state.con_id = con_id
	}
}