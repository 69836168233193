export default {
	getAllTrades: state => state.all_trades,
	getBondsTrades: state => state.bonds_trades,
	getCashTrades: state => state.cash_trades,
	getEquitiesTrades: state => state.equities_trades,
	getFundsTrades: state => state.funds_trades,
	getTimeline: state => state.timeline,
	getMaxValue: state => state.max_value,
	getMovingAvg: state => state.moving_avg,
	getPeriodicity: state => state.periodicity,
	getEquitiesStatus: state => state.equities_status,
	getBondsStatus: state => state.bonds_status,
	getFundsStatus: state => state.funds_status,
	getCashStatus: state => state.cash_status,
}