<template>
  <section class="ticker-detail-wrap" @click="searchFocus">
    <b-input-group class="search-bar" v-if="!isRFQForm">
      <!-- <autocomplete
        ref="autocomplete"
        input-class="search-box search-ticker"
        placeholder="Search by ISIN or Ticker"
        :source="searchSecuritiyEndpoint"
        results-property="data"
        :results-display="formattedDisplay"
        :request-headers="authHeaders"
        @selected="selectSearchedSecurity"
      >
      </autocomplete> -->
      <div class="search-main">
        <!-- search loader   -->
        <img
          src="@/assets/images/spiner.svg"
          class="animate-spin"
          v-if="isLoading"
        />
        <v-autocomplete
          ref="autocomplete"
          v-model="search_security"
          :items="search_items"
          :search-input.sync="searching_security"
          :hide-no-data="hideNoResults"
          clearable
          item-text="text"
          placeholder="Search by ISIN or Ticker"
          prepend-icon="mdi-magnify"
          @change="selectSearchedSecurity()"
          return-object
          class="search-box search-ticker new-search-ui"
          attach
          :class="isLoading == true ? ' remove-serach-icon' : ''"
        >
          <template v-slot:item="data">
            <div class="v-list-item__content">
              <div
                class="cash-type"
                v-if="data.item.market_id == '6' || data.item.market_id == '2'"
                title="Currencies"
              ></div>
              <div
                class="equity-type"
                v-if="data.item.market_id == '1'"
                title="Equites"
              ></div>
              <div
                class="bond-type"
                v-if="data.item.market_id == '5'"
                title="Bonds"
              ></div>
              <div
                class="fund-type"
                v-if="data.item.market_id == '8'"
                title="Funds"
              ></div>
              <div class="v-list-item__title">{{ data.item.text }}</div>
            </div>
          </template>
        </v-autocomplete>
      </div>
    </b-input-group>
    <div class="order-entry-wrap" v-if="!isRFQForm" :style="{ height: OrderEntryHeight + 'px' }">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="ticker-title">
          {{ name }}
        </h2>
        <a
          v-if="displayWatchList"
          @click="addToWatchList"
          class="add-to-watchlist d-flex align-items-center"
          title="Add To Watchlist"
          id="btn-add-watchlist"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="bell"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            class="svg-inline--fa fa-bell fa-w-14 fa-5x"
          >
            <path
              d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
              class=""
            ></path>
          </svg>
        </a>
      </div>
      <div class="ticker-price-box">
        <div class="bid-price ticker-price">
          <span class="ticker-price-text">Bid</span>
          <span class="ticker-price-value">{{ bid }}</span>
        </div>
        <div class="ask-price ticker-price">
          <span class="ticker-price-text">Ask</span>
          <span class="ticker-price-value">{{ ask }}</span>
        </div>
        <div class="margin-price">{{ rate }}</div>
      </div>
      <b-form
        v-on:submit.prevent="onSubmit"
        class="d-flex justify-content-lg-between align-content-center flex-wrap ticker-detail-custom-form"
      >
        <b-form-group
          v-if="showVendor"
          class="select-group w-100"
          id="select-vendor-group"
          label-for="select-vendor"
          label="Vendor"
        >
          <b-form-select
            id="select-vendor"
            :options="vendors"
            v-model="form.vendor"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group
          class="select-group"
          id="input-group-1"
          label="Type"
          label-for="input-1"
        >
          <b-form-select
            id="input-1"
            v-model="form.order_type"
            :options="limits"
            @change="onChangeOrderType()"
            required
            label="Type"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          class="select-group"
          id="input-group-2"
          label-for="input-2"
          label="Time"
        >
          <b-form-select
            id="input-2"
            v-model="form.day"
            @change="UpdateNetConsideration()"
            :options="days"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group 
          class="w-100" 
          id="expire-date-group"
          v-if="expireDateFieldState"
          >
          <v-menu
            v-model="gtd_expire_date"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="expire-date"
                v-model="form.gtd_expire_date"
                readonly
                v-bind="attrs"
                v-on="on"
                class="form-control"
                type="text"
                label="Expire Date"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.gtd_expire_date"
              @input="gtd_expire_date = false"
              no-title
              scrollable
              actions
            ></v-date-picker>
          </v-menu>
        </b-form-group>
        <b-form-group :class="qtyCssClass" id="input-group-3">
          <v-text-field
            id="input-3"
            v-model="form.quantity"
            class="form-control"
            type="text"
            label="Quantity"
            @change="UpdateNetConsideration()"
            @keyup="updateButtonEvent()"
            hide-details="auto"
            required
            :value="quantity"
          ></v-text-field>
        </b-form-group>
        <b-form-group
          v-if="priceFieldState"
          id="input-group-4"
          label-for="input-4"
        >
          <v-text-field
            id="input-4"
            v-model="form.price"
            v-if="priceFieldState"
            @change="UpdateNetConsideration()"
            class="form-control"
            type="text"
            label="Price"
            required
          ></v-text-field>
        </b-form-group>
        <b-form-group
          v-if="stopPriceFieldState"
          id="input-group-4"
          label-for="input-4"
        >
          <v-text-field
            id="input-4"
            v-model="form.stop_price"
            v-if="stopPriceFieldState"
            class="form-control"
            type="text"
            label="Stop Price"
            @change="UpdateNetConsideration()"
            required
          ></v-text-field>
        </b-form-group>
        <b-form-group v-if="displayQuantityFieldState" class="w-100">
          <v-text-field
            v-model="form.display_quantity"
            class="form-control"
            type="text"
            label="Display Quantity"
            hide-details="auto"
          ></v-text-field>
        </b-form-group>
        
        <div class="w-100 d-flex justify-content-between">
          <b-button
            type="submit"
            id="sell-btn"
            :disabled="disabled == 1"
            variant="danger"
            @click="setDirection('Sell')"
            >Sell</b-button
          >
          <b-button
            type="submit"
            id="buy-btn"
            :disabled="disabled == 1"
            variant="success"
            @click="setDirection('Buy')"
            >Buy</b-button
          >

          <b-button
            type="submit"
            :id="finalButtonName"
            class="d-none"
          ></b-button>
        </div>
        <div v-if="displayShortSellState" class="w-100 mt-2">
          <b-button
            type="submit"
            id="short-sell-btn"
            :disabled="disabled == 1"
            variant="danger"
            @click="setDirection('Short')"
            class="w-100"
            >Short Sell</b-button
          >
        </div>
        <!-- <div class="w-100 net-consi d-flex justify-content-between">
              <span class="net-consi-text">Fees </span>
              <span class="net-consi-value">{{ fees }}</span>
            </div>
            <div class="w-100 net-consi d-flex justify-content-between">
              <span class="net-consi-text">Net Consideration </span>
              <span class="net-consi-value">{{ net_consideration }}</span>
            </div> -->
        <v-dialog v-model="order_confirm_dialog" max-width="800">
          <v-card class="custome-modal">
            <v-card-title class="headline">
              <div
                class="box-title d-flex justify-content-between align-items-center"
              >
                <h4>Order Confirmation</h4>
                <div
                  class="table-button-groups p-0 d-flex justify-content-end align-items-center"
                >
                  <a href="javascript:void(0)" @click="confirmClose">
                    <font-awesome-icon class="close-icon" icon="times" />
                  </a>
                </div>
              </div>
            </v-card-title>

            <v-card-text>
              <ul class="allocates_ul">
                <li>
                  <span class="text-title">Account:</span>
                  <span class="text-value" id="c_account_name">{{
                    confirm.account_name
                  }}</span>
                </li>
                <li>
                  <span class="text-title">Security Description:</span>
                  <span class="text-value" id="c_name">{{
                    confirm.security_name
                  }}</span>
                </li>
                <li>
                  <span class="text-title">ISIN:</span>
                  <span class="text-value" id="c_isin">{{ confirm.isin }}</span>
                </li>
                <li>
                  <span class="text-title">Quantity:</span>
                  <span class="text-value" id="c_quantity">{{
                    confirm.quantity
                  }}</span>
                </li>
                <li>
                  <span class="text-title">Direction:</span>
                  <span class="text-value" id="c_direction">{{
                    confirm.direction
                  }}</span>
                </li>
                <li>
                  <span class="text-title">Type:</span>
                  <span class="text-value" id="c_type">{{ confirm.type }}</span>
                </li>
                <li>
                  <span class="text-title">Time:</span>
                  <span class="text-value" id="c_time">{{ confirm.time }}</span>
                </li>
                <li v-if="this.is_global_fees_display == 1">
                  <span class="text-title">Fees:</span>
                  <span class="text-value" id="c_fees">{{ confirm.fees }}</span>
                </li>
              </ul>
              <div
                class="allocates_div"
                v-if="this.allocate_portfolio.length > 1"
              >
                <b-form
                  v-for="(allocates_input, k) in allocates_inputs"
                  :key="k"
                  class="d-flex justify-content-between align-items-center ticker-detail-custom-form"
                >
                  <b-form-group
                    class="select-group allocates-select"
                    id="input-group-1"
                    label="Client"
                    label-for="input-1"
                  >
                    <v-autocomplete
                      v-model="allocates_input.client_id"
                      :items="allocate_portfolio"
                      dense
                      filled
                      clearable
                      item-text="text"
                      item-value="value"
                      @change="renderClientFees(`${allocates_input.client_id}`)"
                    ></v-autocomplete>
                  </b-form-group>
                  <b-form-group id="input-group-2" class="allocates-qty">
                    <v-text-field
                      id="input-qty"
                      v-model="allocates_input.qty"
                      class="form-control"
                      @change="updateAllocation()"
                      type="text"
                      label="Qty"
                      required
                    ></v-text-field>
                  </b-form-group>
                  <b-form-group
                    v-if="is_fees_display != 1"
                    id="input-group-3"
                    class="allocates-percentage"
                  >
                    <v-text-field
                      id="input-percentage"
                      v-model="allocates_input.percentage"
                      class="form-control"
                      type="text"
                      label="Percentage"
                      readonly
                    ></v-text-field>
                  </b-form-group>
                  <b-form-group
                    v-if="is_commission == 1"
                    id="input-group-2"
                    class=""
                  >
                    <v-text-field
                      id="input-set-fees"
                      v-model="allocates_input.markup_fees"
                      class="form-control"
                      type="text"
                      :label="market_id == '1' ? 'Commission' : 'Mark-up'"
                    ></v-text-field>
                  </b-form-group>
                  <b-form-group
                    v-if="is_fees_display == 1"
                    id="input-group-2"
                    class=""
                  >
                    <v-text-field
                      id="input-fees"
                      v-model="allocates_input.fees"
                      class="form-control"
                      type="text"
                      label="Execution"
                    ></v-text-field>
                  </b-form-group>
                  <b-form-group
                    v-if="is_fees_display == 1"
                    id="input-group-2"
                    class=""
                  >
                    <v-text-field
                      id="input-set-fees"
                      v-model="allocates_input.set_fees"
                      class="form-control"
                      type="text"
                      label="Settlement"
                    ></v-text-field>
                  </b-form-group>
                  <b-form-group
                    v-if="is_fees_display == 1"
                    id="input-group-2"
                    class=""
                  >
                  </b-form-group>
                  <b-form-group class="allocates-button">
                    <a
                      href="javascript:void(0)"
                      @click="remove_allocate(k)"
                      v-show="k || (!k && allocates_inputs.length > 1)"
                    >
                      <font-awesome-icon
                        class="close-icon"
                        icon="minus-circle"
                      />
                    </a>
                    <a
                      href="javascript:void(0)"
                      @click="add_allocate(k)"
                      v-show="k == allocates_inputs.length - 1"
                    >
                      <font-awesome-icon
                        class="close-icon"
                        icon="plus-circle"
                      />
                    </a>
                  </b-form-group>
                </b-form>
              </div>
              <div
                class="table-button-groups p-0 d-flex justify-content-end align-items-center"
                style="margin-bottom:10px;margin-top:5px;"
              >
                <b-button
                  class="margin-r-10 margin-b-10 confirm-btn"
                  variant="success"
                  @click.prevent="submitOrderFrom"
                  :disabled="disabled_confirm == 1"
                >
                  {{ confirm_btn_labl }}
                </b-button>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </b-form>
    </div>
    <div class="order-entry-wrap request-form-wrap" v-if="isRFQForm">
      <b-form
        v-on:submit.prevent="onRFQSubmit"
        class="d-flex justify-content-lg-between align-content-center flex-wrap ticker-detail-custom-form"
      >
        <b-form-group
          class="select-group"
          id="input-group-1"
          label="Currency 1"
          label-for="input-1"
        >
          <b-form-select
            id="input-1"
            v-model="rfq_form.currencyOne"
            :options="fxspot_currency_list"
            required
            label="Currency 1"
            :disabled="disableRFQForm"
            @change="onChangeCurrencyOne"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          class="select-group"
          id="input-group-2"
          label-for="input-2"
          label="Currency 2"
        >
          <b-form-select
            id="input-2"
            v-model="rfq_form.currencyTwo"
            :options="currencies"
            required
            label="Currency 2"
            :disabled="(disableRFQForm || disableCurrencyTwo)"
            @change="onChangeCurrencyTwo"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          class="select-group"
          label="Currency"
          label-for="input-currency"
        >
          <b-form-select
            id="input-currency"
            v-model="rfq_form.currency"
            :options="selectedCurrencies"
            required
            label="Currency"
            :disabled="(disableRFQForm || (selectedCurrencies.length != 2))"
            @change="onChangeCurrency"
          ></b-form-select>
        </b-form-group>
        
        <b-form-group id="input-group-3" :disabled="disableRFQForm">
          <v-text-field
            id="input-3"
            v-model="rfq_form.quantity"
            class="form-control"
            type="text"
            :label="amountLabel"
            hide-details="auto"
            required
            @keyup="onChangeAmount"
          ></v-text-field>
        </b-form-group>
        <b-form-group
          class="select-group"
          id="input-group-4"
          label-for="input-4"
          label="Type"
        >
          <b-form-select
            id="input-4"
            v-model="rfq_form.type"
            :options="limits"
            required
            :disabled="true"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          class="select-group"
          id="input-group-5"
          label="Direction"
          label-for="input-5"
        >
          <b-form-select
            id="input-5"
            v-model="rfq_form.direction"
            :options="directions"
            :disabled="disableRFQForm"
          ></b-form-select>
        </b-form-group>
        <!-- <b-form-group id="input-group-3" :disabled="true" class="w-100">
          <v-text-field
            id="input-3"
            class="form-control"
            type="text"
            label="Timer"
            v-model="rfq_form.timer"
          ></v-text-field>
        </b-form-group> -->
        <div class="ticker-price-box request-price-box">
          <div class="ticker-price w-100">
            <span class="ticker-price-text">Timer</span>
            <span class="ticker-price-value">{{ rfq_form.timer }}</span>
          </div>
        </div>
        <div class="ticker-price-box request-price-box">
          <div class="bid-price ticker-price" :class="isRfqBtn ? 'disabled-box' : ''">
            <span class="ticker-price-text">Bid</span>
            <span class="ticker-price-value">{{ rfq_form.bid_price }}</span>
          </div>
          <div class="ask-price ticker-price" :class="isRfqBtn ? 'disabled-box' : ''">
            <span class="ticker-price-text">Ask</span>
            <span class="ticker-price-value">{{ rfq_form.ask_price }}</span>
          </div>
          <!-- <div class="margin-price" :class="isRfqBtn ? 'disabled-box' : ''">{{ rfq_form.rate }}</div> -->
        </div>
        <div class="w-100 d-flex justify-content-between" v-if="!isRfqBtn">
          <b-button
            v-if="rfq_form.direction == 'SELL' || rfq_form.direction == ''"
            type="submit"
            id="sell-btn"
            variant="danger"
            @click="setRFQDirection('Sell')"
            >Sell</b-button
          >
          <b-button
            v-if="rfq_form.direction == 'BUY' || rfq_form.direction == ''"
            type="submit"
            id="buy-btn"
            variant="success"
            @click="setRFQDirection('Buy')"
            :class="rfq_form.direction == 'BUY' ? ' order-2' : ''"
            >Buy</b-button
          >
          <b-button v-if="rfq_form.direction != ''"
            :class="rfq_form.direction == 'BUY' ? ' order-1' : ''"
            class="cancel-rfq-btn" 
            type="button"
            @click="cancelRFQ()"
            >Cancel</b-button
          >
        </div>
        <div class="w-100 d-flex justify-content-between" v-if="!isRfqBtn">
          <b-button v-if="rfq_form.direction == ''"
            class="cancel-rfq-btn w-100 mr-0 mt-custom"
            type="button"
            @click="cancelRFQ()"
            >Cancel</b-button
          >
        </div>
        <div class="w-100 d-flex justify-content-between" v-if="isRfqBtn">
          <b-button 
            class="submit_portfolio w-100 mr-0" 
            type="button" 
            @click="requestRFQ()"
            :disabled="disableRFQBtn"
            >{{ rfqLabel }}</b-button>
        </div>
        <b-button
          type="submit"
          :id="finalButtonName"
          class="d-none"
        ></b-button>
        <v-dialog v-model="order_confirm_dialog" max-width="800">
          <v-card class="custome-modal">
            <v-card-title class="headline">
              <div
                class="box-title d-flex justify-content-between align-items-center"
              >
                <h4>Order Confirmation</h4>
                <div
                  class="table-button-groups p-0 d-flex justify-content-end align-items-center"
                >
                  <a href="javascript:void(0)" @click="confirmClose">
                    <font-awesome-icon class="close-icon" icon="times" />
                  </a>
                </div>
              </div>
            </v-card-title>

            <v-card-text>
              <ul class="allocates_ul">
                <li>
                  <span class="text-title">Account:</span>
                  <span class="text-value" id="c_account_name">{{
                    confirm.account_name
                  }}</span>
                </li>
                <li>
                  <span class="text-title">Security Description:</span>
                  <span class="text-value" id="c_name">{{
                    confirm.security_name
                  }}</span>
                </li>
                <li>
                  <span class="text-title">ISIN:</span>
                  <span class="text-value" id="c_isin">{{ confirm.isin }}</span>
                </li>
                <li>
                  <span class="text-title">Quantity:</span>
                  <span class="text-value" id="c_quantity">{{
                    confirm.quantity
                  }}</span>
                </li>
                <li>
                  <span class="text-title">Direction:</span>
                  <span class="text-value" id="c_direction">{{
                    confirm.direction
                  }}</span>
                </li>
                <li>
                  <span class="text-title">Type:</span>
                  <span class="text-value" id="c_type">{{ confirm.type }}</span>
                </li>
                <li>
                  <span class="text-title">Timer:</span>
                  <span class="text-value" id="c_time">{{ rfq_form.timer }}</span>
                </li>
                <li v-if="this.is_global_fees_display == 1">
                  <span class="text-title">Fees:</span>
                  <span class="text-value" id="c_fees">{{ confirm.fees }}</span>
                </li>
              </ul>
              <div
                class="allocates_div"
                v-if="this.allocate_portfolio.length > 1"
              >
                <b-form
                  v-for="(allocates_input, k) in allocates_inputs"
                  :key="k"
                  class="d-flex justify-content-between align-items-center ticker-detail-custom-form"
                >
                  <b-form-group
                    class="select-group allocates-select"
                    id="input-group-1"
                    label="Client"
                    label-for="input-1"
                  >
                    <v-autocomplete
                      v-model="allocates_input.client_id"
                      :items="allocate_portfolio"
                      dense
                      filled
                      clearable
                      item-text="text"
                      item-value="value"
                      @change="renderClientFees(`${allocates_input.client_id}`)"
                    ></v-autocomplete>
                  </b-form-group>
                  <b-form-group id="input-group-2" class="allocates-qty">
                    <v-text-field
                      id="input-qty"
                      v-model="allocates_input.qty"
                      class="form-control"
                      @change="updateAllocation()"
                      type="text"
                      label="Qty"
                      required
                    ></v-text-field>
                  </b-form-group>
                  <b-form-group
                    v-if="is_fees_display != 1"
                    id="input-group-3"
                    class="allocates-percentage"
                  >
                    <v-text-field
                      id="input-percentage"
                      v-model="allocates_input.percentage"
                      class="form-control"
                      type="text"
                      label="Percentage"
                      readonly
                    ></v-text-field>
                  </b-form-group>
                  <b-form-group
                    v-if="is_commission == 1"
                    id="input-group-2"
                    class=""
                  >
                    <v-text-field
                      id="input-set-fees"
                      v-model="allocates_input.markup_fees"
                      class="form-control"
                      type="text"
                      label="Mark-up"
                    ></v-text-field>
                  </b-form-group>
                  <b-form-group
                    v-if="is_fees_display == 1"
                    id="input-group-2"
                    class=""
                  >
                    <v-text-field
                      id="input-fees"
                      v-model="allocates_input.fees"
                      class="form-control"
                      type="text"
                      label="Execution"
                    ></v-text-field>
                  </b-form-group>
                  <b-form-group
                    v-if="is_fees_display == 1"
                    id="input-group-2"
                    class=""
                  >
                    <v-text-field
                      id="input-set-fees"
                      v-model="allocates_input.set_fees"
                      class="form-control"
                      type="text"
                      label="Settlement"
                    ></v-text-field>
                  </b-form-group>
                  <b-form-group
                    v-if="is_fees_display == 1"
                    id="input-group-2"
                    class=""
                  >
                  </b-form-group>
                  <b-form-group class="allocates-button">
                    <a
                      href="javascript:void(0)"
                      @click="remove_allocate(k)"
                      v-show="k || (!k && allocates_inputs.length > 1)"
                    >
                      <font-awesome-icon
                        class="close-icon"
                        icon="minus-circle"
                      />
                    </a>
                    <a
                      href="javascript:void(0)"
                      @click="add_allocate(k)"
                      v-show="k == allocates_inputs.length - 1"
                    >
                      <font-awesome-icon
                        class="close-icon"
                        icon="plus-circle"
                      />
                    </a>
                  </b-form-group>
                </b-form>
              </div>
              <div
                class="table-button-groups p-0 d-flex justify-content-end align-items-center"
                style="margin-bottom:10px;margin-top:5px;"
              >
                <b-button
                  class="margin-r-10 margin-b-10 confirm-btn"
                  variant="success"
                  @click.prevent="submitOrderFrom"
                  :disabled="disabled_confirm == 1"
                >
                  {{ confirm_btn_labl }}
                </b-button>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </b-form>
    </div>

    <transition name="fade">
      <div class="loading full-page" v-show="loading">
        <div><span></span><span></span><span></span><span></span></div>
      </div>
    </transition>
  </section>
</template>

<script>
import axios from "./../axios";
import axios_new from "axios";
import themeconfig from '../themeconfig'
import _ from "lodash";
var cancel;
var CancelToken = axios_new.CancelToken;
export default {
  name: "Watchlist",
  // components: { VueAutosearch: VueAutosearch },
  data() {
    return {
      // bid: 0,
      // ask: 0,
      // rate: 1,
      hideNoResults: true,
      order_confirm_dialog: false,
      fees: 0,
      addedWathlistTitle: "Added in watchlist",
      addToWathlistTitle: "Add to watchlist",
      displayWatchList: false,
      disabled: 1,
      disabled_confirm: 1,
      confirm_btn_labl: "Place",
      security_title: "{Security Title}",
      priceFieldState: false,
      expireDateFieldState: false,
      stopPriceFieldState: false,
      displayQuantityFieldState: false,
      displayShortSellState: false,
      is_global_fees_display: 0,
      form: {
        order_type: null,
        day: null,
        quantity: 0.0,
        price: 0.0,
        stop_price: 0.0,
        portfolio_id: 0,
        bid: 0,
        ask: 0,
        rate: 0,
        last_price: 0,
        direction: "",
        security_id: 0,
        is_calculate: 1,
        allocate_portfolios: [],
        new_commission: 0,
        filter_client_id: 0,
        market_value_text: 0,
        vendor: null,
        gtd_expire_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        display_quantity: 0,
      },
      confirm: {
        account_name: null,
        security_name: null,
        isin: null,
        direction: null,
        type: null,
        quantity: 0.0,
        price: 0.0,
        fees: 0,
        net_consideration: 0,
        time: null,
      },
      allocates_inputs: [
        {
          client_id: null,
          qty: "",
          percentage: "",
          fees: 0,
          set_fees: 0,
          markup_fees: 0,
        },
      ],
      limits: [],
      days: [],
      qtyCssClass: "",
      net_consideration: 0,
      loading: false,
      is_fees_display: 0,
      is_commission: 0,
      search_table: {
        security_name: null,
      },
      isRFQForm: false,
      rfqFormType: null,
      rfq_form: {
        is_calculate: 1,
        currencyOne: null,
        currencyTwo: null,
        currency: null,
        settlementType: 'SPOT',
        bid_price: 0,
        ask_price: 0,
        bid_size: 0,
        ask_size: 0,
        bid_side: null,
        ask_side: null,
        rate: 0,
        direction: '',
        side: '',
        quote_side: null,
        timer: "00:00",
        quantity: null,
        bid_entry_id: null,
        ask_entry_id: null,
        symbol: null,
        lock_quote: null,
        type: 'market',
      },
      amountLabel: "Amount",
      disableRFQBtn: true,
      disableRFQForm: false,
      disableCurrencyTwo: true,
      currencies: [],
      selectedCurrencies: [],
      settlementTypes: ['TODAY', 'TOMORROW', 'SPOT'],
      directions: ['','BUY','SELL'],
      timer: 90,
      rfqLabel: "RFQ",
      isRfqBtn: true,
      counterIntervalId: null,
      search_security: null,
      searching_security: null,
      search_items: [],
      isLoading: false,
      vendors: ['IB','Instinet'],
      showVendor: false,
      gtd_expire_date: false,
    };
  },
  sockets: {
    connect: function() {
      //console.log("socket connected");
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["dashboard/getProfiles"];
    },
    finalButtonName() {
      if (this.formName != undefined && this.formName.length > 0) {
        return this.formName + "-btn";
      } else {
        return "finle-buy-btn";
      }
    },
    allocate_portfolio() {
      let portfolios = this.$store.getters["dashboard/getTradingPortfolios"];
      // let portfolios = this.$store.getters["dashboard/getPortfolios"];
      let portfolio_data = [];
      for (var i in portfolios) {
        portfolio_data.push({
          text: portfolios[i].name,
          value: portfolios[i].id,
        });
      }
      // console.log('portfolio_data',portfolio_data);
      return portfolio_data;
    },
    items() {
      let items = this.$store.getters["cash/getItems"];
      return items;
    },
    stocks_items() {
      let stocks_items = this.$store.getters["equities/getItems"];
      return stocks_items;
    },
    bonds_items() {
      let bonds_items = this.$store.getters["bonds/getItems"];
      return bonds_items;
    },
    security_id: {
      get() {
        return this.$store.getters["chart/getSecurityId"];
      },
    },
    con_id: {
      get() {
        return this.$store.getters["chart/getConId"];
      },
    },
    added_watchlist: {
      get() {
        return this.$store.getters["chart/getAddedWatchlist"];
      },
    },
    filter_portfolio() {
      return this.$store.getters["dashboard/getFilterFortfolio"];
    },
    next_page_index() {
      return this.$store.getters["transaction/getNextPage"];
    },
    record_length() {
      return this.$store.getters["transaction/getLengthRecord"];
    },
    start_record() {
      return this.$store.getters["transaction/getStartRecord"];
    },
    start_filter_date: {
      get() {
        return this.$store.getters["dashboard/getStartFilterDate"];
      },
    },
    end_filter_date: {
      get() {
        return this.$store.getters["dashboard/getEndFilterDate"];
      },
    },
    name() {
      return this.$store.getters["chart/getName"];
    },
    bid() {
      return this.$store.getters["chart/getBid"];
    },
    ask() {
      return this.$store.getters["chart/getAsk"];
    },
    rate() {
      return this.$store.getters["chart/getRate"];
    },
    last_price() {
      return this.$store.getters["chart/getLastPrice"];
    },
    market_id() {
      return this.$store.getters["chart/getMarketId"];
    },
    authHeaders() {
      return {
        Authorization: "Bearer " + localStorage.getItem("user-token"),
      };
    },
    quantity: {
      get() {
        return this.$store.getters["holding/getQuantity"];
      },
    },
    tradChartWidth: {
      get() {
        return parseInt(this.TraddingChartWidth) / 2.9;
      },
    },
    tradChartHeight: {
      get() {
        return parseInt(this.TraddingChartHeight) / 2;
      },
    },
    active_tab: {
      get() {
        return this.$store.getters["dashboard/getActiveTab"];
      },
    },
    is_place_order() {
      return this.$store.getters["transaction/getIsPlaceOrder"];
    },
    rfq_form_settings() {
      let settings = this.$store.getters["dashboard/getRFQSettings"];
      return settings;
    },
    fxspot_currency_list() {
      return this.$store.getters["cash/getFxspotCurrencyList"];
    },
  },
  watch: {
    'form.day'(val){
      if(val === 'good_till_date'){
        this.expireDateFieldState = true;
      }
      else{
        this.expireDateFieldState = false;
      }
    },
    'form.vendor'(val) {
      if(val === 'Instinet'){
        this.displayQuantityFieldState = true;
        this.displayShortSellState = true;
      }
      else{
        this.displayQuantityFieldState = false;
        this.displayShortSellState = false;
      }
      this.getOrderTypes();
      //this.onChangeOrderType();
    },
    security_id() {
      if (this.security_id > 0) {
        this.displayWatchList = true;
      } else {
        this.displayWatchList = false;
      }
      this.loading = true;
    },
    name() {
      this.loading = false;
    },
    added_watchlist() {
      var element = document.getElementById("btn-add-watchlist");
      if (this.added_watchlist > 0 && element) {
        element.classList.add("active");
        element.title = this.addedWathlistTitle;
      } else if (element) {
        element.classList.remove("active");
        element.title = this.addToWathlistTitle;
      }
    },
    searching_security: _.debounce(function(search_value) {
      if (search_value != "") {
        this.preSearchApiCall();
      } else {
        this.search_items = [];
        this.isLoading = false;
      }
    }, 10),
    rfq_form_settings(val){
      this.isRFQForm = val.is_rfq_order;
      this.rfqFormType = val.rfq_form_type;
      this.amountLabel = "Amount";
    },
    quantity(new_val,old_val){
      if(new_val!=old_val){
        this.form.quantity=new_val;
         this.checkButSellBtn(this.form.quantity);
      }
    },
    profiles(profile) {
      if(profile.id != undefined && profile.id == 2){
        this.showVendor = true;
      }
      else{
        this.showVendor = false;
      }
    },
  },
  props: ["CompisTickerDetailsOpen","OrderEntryHeight", "formName"],
  methods: {
    preSearchApiCall() {
      if (cancel != undefined) {
        cancel();
        console.log("cancelled");
      }
      this.searchApiCall();
    },
    async searchApiCall() {
      if (this.searching_security != "" && this.searching_security != null && this.searching_security.length >=2) {
        this.isLoading = true;
        let curDom = this;
        var asset_type = this.active_tab;
        axios({
          method: "get",
          url: "auto-securities",
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
          params: {
            q: this.searching_security,
            asset_type: asset_type,
          },
        })
          .then((response) => {
            if (
              curDom.searching_security != "" &&
              curDom.searching_security != null
            ) {
              curDom.search_items = response.data;
            }
            if (response.data.length == 0) {
              this.hideNoResults = false;
            }
            this.isLoading = false;
          })
          .catch((err) => {
            this.results = err.message;
          });
      } 
    },
    clearSearch() {
      this.isLoading = false;
      this.search_items = [];
      this.searching_security = null;
    },
    async addToWatchList() {
      let security_id = this.security_id;
      this.loading = true;
      let curDom = this;
      await axios
        .post("add-watchlist" + "?security_id=" + security_id)
        .then((response) => {
          this.loading = false;
          if (response.data.status == 1) {
            curDom.$store.dispatch("chart/fetchAddedWatchlist", 1);
            curDom.$store.dispatch("watchList/fetchItems");
            this.$swal({
              title: "Watchlist",
              text: response.data.message,
            });
          } else {
            this.$swal({
              showDenyButton: true,
              denyButtonText: `Ok`,
              showConfirmButton: false,
              title: "Error",
              text: response.data.message,
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$swal({
            showDenyButton: true,
            denyButtonText: `Ok`,
            showConfirmButton: false,
            title: "Error",
            text: "Something went wrong! Please try again later.",
          });
        });
    },
    async UpdateNetConsideration() {
      // Create an Array
      var selected = new Array();
      var portfolios = document.getElementById("portfolio-selection");
      var chks = portfolios.getElementsByTagName("INPUT");
      for (var i = 0; i < chks.length; i++) {
        if (chks[i].checked) {
          selected.push(chks[i].value);
        }
      }

      let validate = 1;

      if (validate) {
        this.form.security_id = localStorage.getItem("current_security_id");

        if (selected.length > 0) this.form.portfolio_id = selected[0];
        else this.form.portfolio_id = selected[0];

        this.checkButSellBtn(this.form.quantity);

        /*
        this.form.direction = "Buy";
        this.form.is_calculate = 1;

        this.form.bid = this.bid;
        this.form.ask = this.ask;
        this.form.last_price = this.last_price;

        let payload = this.form;

        this.net_consideration = "Loading...";

        await axios
          .post("place-order", payload)
          .then((response) => {
            this.net_consideration = "0";
            this.fees = 0;
            if (response.data.status == 1) {
              this.net_consideration = response.data.value;
              this.fees = response.data.fee;
            }
          })
          .catch((error) => {
            this.net_consideration = "0";
            console.log(error);
          });
          */
      }
    },
    onChangeOrderType() {
      this.loading = true;
      let order_type = this.form.order_type;
      let vendor = this.form.vendor;
      this.qtyCssClass = "";
      if (order_type == "market" || order_type == "market_close") {
        this.priceFieldState = false;
        this.qtyCssClass = "w-100";
        this.stopPriceFieldState = false;
      } else if (order_type == "stop") {
        this.priceFieldState = false;
        this.stopPriceFieldState = true;
        this.qtyCssClass = "";
      } else if (order_type == "stop_limit") {
        this.priceFieldState = true;
        this.stopPriceFieldState = true;
        this.qtyCssClass = "w-100";
      } else {
        this.stopPriceFieldState = false;
        this.priceFieldState = true;
      }
      axios
        .post("/get-order-time-types", { order_type, vendor })
        .then((response) => {
          this.days = response.data.data;
          for (var i in this.days) {
            this.form.day = i;
            break;
          }
          this.UpdateNetConsideration();
        });
      this.loading = false;
    },
    async loadWsPrices(payload) {
      await this.$store.dispatch("chart/fetchWs", payload);
    },
    async setDefaultChart(security_id) {
      this.loading = true;
      await this.$store.dispatch("chart/updateDefaultChart", {
        security_id: security_id,
      });
      this.loading = false;
    },
    getOrderTypes() {
      let vendor = this.form.vendor;
      axios.post("/get-order-types", { market_id: 1, vendor }).then((response) => {
        this.limits = response.data.data;
        for (var i in this.limits) {
          this.form.order_type = i;
          break;
        }
        this.onChangeOrderType();
      });
    },
    async onSubmit(event) {
      event.preventDefault();

      // Create an Array
      var selected = new Array();
      var portfolios = document.getElementById("portfolio-selection");
      var chks = portfolios.getElementsByTagName("INPUT");
      for (var i = 0; i < chks.length; i++) {
        if (chks[i].checked) {
          selected.push(chks[i].value);
        }
      }

      let validate = 1;
      let validate_message = "";

      if(parseInt(this.form.display_quantity) > parseInt(this.form.quantity)){
        validate = 0;
        validate_message = "Display quantity must be less than quantity or zero";
      }

      
      if (validate) {
        this.loading = true;
        this.disabled_confirm = 1;
        this.confirm_btn_labl = "Placing...";
        this.form.security_id = localStorage.getItem("current_security_id");
        this.form.portfolio_id = selected[0];
        this.form.bid = this.bid;
        this.form.ask = this.ask;
        this.form.last_price = this.last_price;
        this.form.allocate_portfolios = this.allocates_inputs;

        let payload = this.form;
        await axios
          .post("place-order", payload)
          .then(async (response) => {
            this.loading = false;
            this.disabled_confirm = 0;
            this.confirm_btn_labl = "Place";
            if (response.data.status == 1) {
              if (this.form.is_calculate == 0) {
                if (response.data.api_error == 1) {
                  this.$swal({
                    showDenyButton: true,
                    denyButtonText: `Ok`,
                    showConfirmButton: false,
                    title: "Error",
                    text: response.data.message,
                  });
                } else {
                  this.$swal({
                    title: "Order placed",
                    // text: response.data.message,
                  });

                  if (typeof response.data.trade_record !== "undefined") {
                    var transaction_record = [response.data.trade_record];
                    let items_new = await this.$store.getters[
                      "transaction/getItems"
                    ];
                    transaction_record.push(...items_new);
                    await this.$store.dispatch(
                      "transaction/updateItems",
                      transaction_record
                    );
                  } else {
                    this.fetchTransactions(1);
                  }
                  await this.$store.dispatch("transaction/updatePlaceOrder", 1);
                  this.form.price = 0;
                  this.form.quantity = 0;
                  this.form.stop_price = 0;
                  this.form.new_commission = 0;
                  this.net_consideration = 0;
                  this.fees = 0;
                  this.order_confirm_dialog = false;
                  this.allocates_inputs = [
                    {
                      client_id: null,
                      qty: "",
                      percentage: "",
                      fees: 0,
                      set_fees: 0,
                      markup_fees: 0,
                    },
                  ];
                }
              } else {
                // response.data.data
                this.confirm.account_name = response.data.data.account;
                this.confirm.security_name = response.data.data.security;
                this.confirm.isin = response.data.data.isin_code;
                this.confirm.direction = response.data.data.direction;
                this.confirm.quantity = response.data.data.quantity;
                this.confirm.price = response.data.data.price;
                this.confirm.type = response.data.data.type;
                this.confirm.time = response.data.data.time;
                this.confirm.fees = response.data.data.fees;
                this.form.market_value_text = this.confirm.net_consideration =
                  response.data.data.net_consideration;
                this.updateAllocation(1);
                this.disabled_confirm = 0;
                this.order_confirm_dialog = true;
                this.is_commission = response.data.data.is_commission;
                this.is_fees_display = response.data.data.is_fees;
                this.is_global_fees_display =
                  response.data.data.is_global_fees_display;
              }
            } else {
              this.$swal({
                showDenyButton: true,
                denyButtonText: `Ok`,
                showConfirmButton: false,
                title: "Error",
                text: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.$swal({
              showDenyButton: true,
              denyButtonText: `Ok`,
              showConfirmButton: false,
              title: "Error",
              text: "Something went wrong! Please try again later.",
            });
          });
      }
      else{
        this.$swal({
            showDenyButton: true,
            denyButtonText: `Ok`,
            showConfirmButton: false,
            title: "Error",
            text: validate_message,
          });
      }
    },

    async onRFQSubmit(event) {
      let selected = new Array();
      let portfolios = document.getElementById("portfolio-selection");
      let chks = portfolios.getElementsByTagName("INPUT");
      for (let i = 0; i < chks.length; i++) {
        if (chks[i].checked) {
          selected.push(chks[i].value);
        }
      }

      let validate = 1;

      event.preventDefault();
      if (validate) {
        this.loading = true;
        this.disabled_confirm = 1;
        this.confirm_btn_labl = "Placing...";
        this.rfq_form.portfolio_id = selected[0];
        this.rfq_form.allocate_portfolios = this.allocates_inputs;

        let payload = this.rfq_form;
        await axios
          .post("place-fxspot-order", payload)
          .then(async (response) => {
            this.loading = false;
            this.disabled_confirm = 0;
            this.confirm_btn_labl = "Place";
            if (response.data.status == 1) {
              if (this.rfq_form.is_calculate == 0) {
                if (response.data.api_error == 1) {
                  this.$swal({
                    showDenyButton: true,
                    denyButtonText: `Ok`,
                    showConfirmButton: false,
                    title: "Error",
                    text: response.data.message,
                  });
                  this.resetRFQForm();
                }
                else {
                  this.cancelRFQ();
                  this.$swal({
                    title: "Order placed",
                    // text: response.data.message,
                  });

                  if (typeof response.data.trade_record !== "undefined") {
                    var transaction_record = [response.data.trade_record];
                    let items_new = await this.$store.getters[
                      "transaction/getItems"
                    ];
                    transaction_record.push(...items_new);
                    await this.$store.dispatch(
                      "transaction/updateItems",
                      transaction_record
                    );
                  } else {
                    this.fetchTransactions(1);
                  }
                  await this.$store.dispatch("transaction/updatePlaceOrder", 1);
                  this.net_consideration = 0;
                  this.fees = 0;
                  this.order_confirm_dialog = false;
                  this.allocates_inputs = [
                    {
                      client_id: null,
                      qty: "",
                      percentage: "",
                      fees: 0,
                      set_fees: 0,
                      markup_fees: 0,
                    },
                  ];
                }
              } 
              else {
                // response.data.data
                this.confirm.account_name = response.data.data.account;
                this.confirm.security_name = response.data.data.security;
                this.confirm.isin = response.data.data.isin_code;
                this.confirm.direction = response.data.data.direction;
                this.confirm.quantity = response.data.data.quantity;
                this.confirm.price = response.data.data.price;
                this.confirm.type = response.data.data.type;
                this.confirm.time = response.data.data.time;
                this.confirm.fees = response.data.data.fees;
                this.confirm.net_consideration =
                  response.data.data.net_consideration;
                this.updateAllocation(1);
                this.disabled_confirm = 0;
                this.order_confirm_dialog = true;
                this.is_commission = response.data.data.is_commission;
                this.is_fees_display = response.data.data.is_fees;
                this.is_global_fees_display =
                  response.data.data.is_global_fees_display;
              }
            }
            else {
              this.$swal({
                showDenyButton: true,
                denyButtonText: `Ok`,
                showConfirmButton: false,
                title: "Error",
                text: response.data.message,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
            this.$swal({
              showDenyButton: true,
              denyButtonText: `Ok`,
              showConfirmButton: false,
              title: "Error",
              text: "Something went wrong! Please try again later.",
            });
          });
      }
    },
    setDirection(direction) {
      this.form.is_calculate = 1;
      this.form.direction = direction;
    },
    setRFQDirection(direction) {
      this.sockets.unsubscribe('fxspot-quotes');
      this.rfq_form.is_calculate = 1;
      this.rfq_form.side = direction;
      let quote_id = null;
      let size = 0;
      let price = 0;
      let provider = null;
      let quote_side = null;
      if(direction == "Buy"){
        if(this.rfq_form.currencyOne == this.rfq_form.currency){
          quote_id = this.rfq_form.ask_entry_id;
          size = this.rfq_form.ask_size;
          price = this.rfq_form.ask_price;
          provider = this.rfq_form.ask_provider;
          quote_side = this.rfq_form.ask_side;
        }
        else{
          quote_id = this.rfq_form.bid_entry_id;
          size = this.rfq_form.bid_size;
          price = this.rfq_form.bid_price;
          provider = this.rfq_form.bid_provider;
          quote_side = this.rfq_form.bid_side;
        }
      }
      else if(direction == "Sell"){
        if(this.rfq_form.currencyTwo == this.rfq_form.currency){
          quote_id = this.rfq_form.ask_entry_id;
          size = this.rfq_form.ask_size;
          price = this.rfq_form.ask_price;
          provider = this.rfq_form.ask_provider;
          quote_side = this.rfq_form.ask_side;
        }
        else{
          quote_id = this.rfq_form.bid_entry_id;
          size = this.rfq_form.bid_size;
          price = this.rfq_form.bid_price;
          provider = this.rfq_form.bid_provider;
          quote_side = this.rfq_form.bid_side;
        }
      }
      let settlement_type = this.rfq_form.settlement_type;
      let settlement_date = this.rfq_form.settlement_date;
      let request_uid = this.rfq_form.request_uid;
      let symbol = this.rfq_form.symbol;
      let currency = this.rfq_form.currency;
      this.rfq_form.lock_quote = { request_uid, symbol, direction, quote_id, size, price, settlement_type, settlement_date, provider, currency, quote_side };
    },
    searchSecuritiy(input) {
      alert(input);
    },
    searchSecuritiyEndpoint(input) {
      if (input.length > 1) {
        var asset_type = this.active_tab;
        return (
          themeconfig.get_vue_app_api_endpoint() +
          "auto-securities?q=" +
          input +
          "&asset_type=" +
          asset_type
        );
      }
    },
    async selectSearchedSecurity() {
      let option = this.search_security;

      await this.clearSearch();

      this.form.price = null;
      this.form.quantity = null;
      this.form.stop_price = null;
      this.form.order_type = "market";
      this.form.day = "day";
      this.form.allocate_portfolios = [];

      await this.onChangeOrderType();

      this.allocates_inputs = [
        {
          client_id: null,
          qty: "",
          percentage: "",
          fees: 0,
          set_fees: 0,
          markup_fees: 0,
        },
      ];

      this.updateButtonEvent();

      await this.$store.dispatch("dashboard/fetchIsVisibleOrderEntry", 0);
      await this.updateisVisibleNews("0");

      // load security and fetch related data

      let secData = option;
      let security_id = secData.id;
      let market_id = secData.market_id;
      let con_id = secData.ib_conid;
      let iex_symbol = secData.iex_symbol;
      this.net_consideration = 0;

      if (
        con_id != undefined &&
        con_id > 0 &&
        security_id != undefined &&
        security_id > 0
      ) {
        await this.$store.dispatch("dashboard/updateDefaultSecuritySet", {
          secuirty_id: security_id,
          conid: con_id,
          iex_symbol: iex_symbol,
          market_id: market_id,
        });
      }

      await this.$store.dispatch("chart/updateMarketId", {
        market_id: market_id,
      });

      if (secData.is_exante_symbol == 1 || secData.is_iex_symbol == 1) {
        await this.$store.dispatch("chart/getAutoCreateSecurity", secData);
        security_id = this.security_id;
      }

      await this.updateisVisibleNews("1");
      await this.loadSecurity(security_id, secData);

      if (con_id != "") {
        await this.$store.dispatch("chart/updateConId", {
          con_id: con_id,
        });
      }

      await this.$store.dispatch("chart/updateDefaultChart", {
        security_id: security_id,
      });
    },
    formattedDisplay(result) {
      if (result.market_id == "2" || result.market_id == "6") {
        return (
          '<div class="cash-type"></div><div class="search-result">' +
          result.text +
          "</div>"
        );
      } else if (result.market_id == "5") {
        return (
          '<div class="bond-type"></div><div class="search-result">' +
          result.text +
          "</div>"
        );
      } else if (result.market_id == "1") {
        return (
          '<div class="equity-type"></div><div class="search-result">' +
          result.text +
          "</div>"
        );
      } else if (result.market_id == "8") {
        return (
          '<div class="fund-type"></div><div class="search-result">' +
          result.text +
          "</div>"
        );
      }
    },
    searchFocus(e) {
      document
        .getElementsByClassName("search-bar")[0]
        .classList.remove("focus");
      if (e.target.matches(".search-ticker")) {
        document.getElementsByClassName("search-bar")[0].classList.add("focus");
      }
    },
    checkButSellBtn(quantity) {
      if (quantity != 0 && quantity != null) {
        console.log("quantity===",this.form.quantity);
        let quantity_set = parseFloat(this.form.quantity.replace(/[ ,]+/g, ""));
        quantity_set = new Intl.NumberFormat("en-US").format(
          quantity_set.toFixed(0)
        );
        this.form.quantity = quantity_set;
        this.disabled = 0;
        // document.getElementById('buy-btn').disabled = false;
        // document.getElementById('sell-btn').disabled = false;
      } else {
        this.disabled = 1;
        //alert(2);
        // const buy_btn_el = document.getElementById('buy-btn')[0];
        // buy_btn_el.disabled=false;
        // const sell_btn_el = document.getElementById('sell-btn')[0];
        // sell_btn_el.disabled=false;
      }
    },
    updateButtonEvent() {
      if (this.form.quantity > 0) {
        this.disabled = 0;
      } else {
        this.disabled = 1;
      }
    },
    confirmOpen() {
      this.order_confirm_dialog = true;
    },
    confirmClose() {
      this.order_confirm_dialog = false;

      this.allocates_inputs = [
        {
          client_id: null,
          qty: "",
          percentage: "",
          fees: 0,
          set_fees: 0,
          markup_fees: 0,
        },
      ];
    },
    submitOrderFrom() {
      this.form.is_calculate = 0;
      this.rfq_form.is_calculate = 0;
      // this.order_confirm_dialog = false;
      document.getElementById(this.finalButtonName).click();
    },
    add_allocate() {
      this.allocates_inputs.push({ client_id: null, qty: "", percentage: "" });
      this.updateAllocation();
    },
    remove_allocate(index) {
      this.allocates_inputs.splice(index, 1);
      this.updateAllocation();
    },
    async renderClientFees(client_id) {
      this.form.filter_client_id = client_id;
      let payload = this.form;
      let curDom = this;
      await axios.post("get-fees", payload).then(async (response) => {
        response.data.fees;
        curDom.allocates_inputs.map(function(item) {
          if (item.client_id == client_id) {
            item.set_fees = response.data.set_fees;
            item.fees = response.data.fees;
          }
          return item;
        });
      });
    },
    updateAllocation(is_auto) {
      var currenctDom = this;
      var allocationLength = this.allocates_inputs.length;
      this.allocates_inputs.map(function(item) {
        let total_qty = currenctDom.confirm.quantity;
        //let qty=total_qty-item.qty;
        if (is_auto == 1) {
          item.qty = total_qty;
        } else if (allocationLength > 1) {
          //item.qty=qty;
        }
        let percentage = currenctDom
          .calculate_percentage(item.qty, total_qty)
          .toFixed(0);
        item.percentage = percentage;
        if (item.qty > 0) {
          let quantity_set = parseFloat(item.qty.replace(/[ ,]+/g, ""));
          quantity_set = new Intl.NumberFormat("en-US").format(
            quantity_set.toFixed(0)
          );
          item.qty = quantity_set;
        }
        return item;
      });
    },
    async orderStreamingActive() {
      if (this.active_tab == "TickerDetails") {
        var user_id = localStorage.getItem("login-id");
        var user_name = localStorage.getItem("login-name");
        let securityList = [];
        securityList = [
          {
            security_id: this.security_id,
            conid: this.con_id,
            user_id,
          },
        ];
        var securitySet = {
          security_list: securityList,
          user_id: user_id,
        };
        // console.log("cash_user_join");
        await this.$socket.emit("user_join", { user_id, user_name });
        // console.log("cash_security_subscription", securitySet);
        await this.$socket.emit("security_subscription", securitySet);
      }
    },
    async requestRFQ(){
      this.rfqLabel = "Requesting...";
      this.disableRFQForm = true;
      this.disableRFQBtn = true;

      let payload = this.rfq_form;
      await axios
        .post("request-rfq", payload)
        .then(async (response) => {
          //this.disableRFQForm = false;
          
          if (response.data.status == 1) {
            let count = 0;
            let request_id = response.data.data.request_id;
            this.sockets.subscribe('fxspot-quotes',(data) => {
              if(request_id == data.request_uid){
                count++;
                if(count == 1){
                  this.startCountdown();
                  this.isRfqBtn = false;
                  this.rfqLabel = "RFQ";
                }
                if(data.bid_price != undefined && data.bid_price > 0){
                  //if(data.bid_price < this.rfq_form.bid_price || this.rfq_form.bid_price == 0){
                    this.rfq_form.bid_size = data.bid_size;
                    this.rfq_form.bid_price = data.bid_price;
                    this.rfq_form.bid_entry_id = data.entry_id;
                    this.rfq_form.bid_provider = data.provider;
                    this.rfq_form.bid_side = data.bid_side;
                  //}
                }
                if(data.offer_price != undefined && data.offer_price > 0){
                  //if(data.offer_price > this.rfq_form.ask_price){
                    this.rfq_form.ask_size = data.offer_size;
                    this.rfq_form.ask_price = data.offer_price;
                    this.rfq_form.ask_entry_id = data.entry_id;
                    this.rfq_form.ask_provider = data.provider;
                    this.rfq_form.ask_side = data.offer_side;
                  //}
                }
                this.rfq_form.symbol = data.symbol;
                this.rfq_form.currency = data.currency;
                this.rfq_form.request_uid = data.request_uid;
                this.rfq_form.settlement_type = data.settlement_type;
                this.rfq_form.settlement_date = data.settlement_date;
              }
            });
            //console.log(response.data);
          }
          else {
            this.resetRFQForm();
            this.$swal({
              showDenyButton: true,
              denyButtonText: `Ok`,
              showConfirmButton: false,
              title: "Error",
              text: response.data.message,
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.resetRFQForm();
          this.$swal({
            showDenyButton: true,
            denyButtonText: `Ok`,
            showConfirmButton: false,
            title: "Error",
            text: "Something went wrong! Please try again later.",
          });
        });
    },
    startCountdown(){
      const formatTime = (time) => {
        return time < 10 ? `0${time}`:time;
      };

      let counterIntervalId;
      let time_limit = this.timer;

      let currentDom = this;

      this.counterIntervalId = counterIntervalId = setInterval(function(){
        if(time_limit == 0){
          currentDom.disableRFQForm = false;
          currentDom.disableRFQBtn = true;
          currentDom.isRfqBtn = true;
          currentDom.sockets.unsubscribe('fxspot-quotes');
          clearInterval(counterIntervalId);
        }
        else{
          time_limit--;
        }
        let mins = Math.floor(time_limit / 60);
        let seconds = time_limit % 60;
        
        mins = formatTime(mins);
        seconds = formatTime(seconds);
        
        currentDom.rfq_form.timer = mins+":"+seconds;
      }, 1000);
    },
    stopCountdown(){
      clearInterval(this.counterIntervalId);
      this.rfq_form.timer = "00:00";
    },
    onChangeCurrencyOne(selected){
      this.currencies = [];
      this.rfq_form.currencyTwo = null;
      this.disableCurrencyTwo = false;
      this.amountLabel = "Amount ("+selected+")";
      let currentIndex = this.fxspot_currency_list.findIndex(
        (currency) => currency.currency == selected
      );
      let currencyObj = this.fxspot_currency_list[currentIndex];
      this.currencies = currencyObj.second_currency;
      this.removeAllArrayElement(this.selectedCurrencies);
      this.selectedCurrencies.push(selected);
      this.toggleRfqBtn();
    },
    onChangeCurrencyTwo(selected){
      if(selected != undefined && selected != null){
        this.$delete(this.selectedCurrencies, 1);
        this.selectedCurrencies.push(selected);
      }
      this.toggleRfqBtn();
    },
    onChangeCurrency(selected){
      this.amountLabel = "Amount ("+selected+")";
    },
    onChangeAmount(){
      this.rfq_form.quantity = this.formatNumber(this.rfq_form.quantity);
      this.toggleRfqBtn();
    },
    resetRFQForm(){
      this.disableRFQForm = false;
      this.disableRFQBtn = true;
      this.isRfqBtn = true;

      let rfq_form = {
        is_calculate: 1,
        currencyOne: null,
        currencyTwo: null,
        currency: null,
        settlementType: 'SPOT',
        bid_price: 0,
        ask_price: 0,
        bid_size: 0,
        ask_size: 0,
        bid_side: null,
        ask_side: null,
        rate: 0,
        direction: '',
        side: '',
        quote_side: null,
        timer: "00:00",
        quantity: null,
        bid_entry_id: null,
        ask_entry_id: null,
        symbol: null,
        lock_quote: null,
        type: 'market',
      }

      this.rfq_form = rfq_form;

      this.rfqLabel = "RFQ";
      this.sockets.unsubscribe('fxspot-quotes');
      this.stopCountdown();
    },
    async cancelRFQ(){
      this.loading = true;

      let payload = this.rfq_form;
      await axios
        .post("cancel-rfq", payload)
        .then(async (response) => {
          if (response.data.status == 1) {
            // code
          }
          else {
            this.$swal({
              showDenyButton: true,
              denyButtonText: `Ok`,
              showConfirmButton: false,
              title: "Error",
              text: response.data.message,
            });
          }
          this.resetRFQForm();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$swal({
            showDenyButton: true,
            denyButtonText: `Ok`,
            showConfirmButton: false,
            title: "Error",
            text: "Something went wrong! Please try again later.",
          });
        });
    },
    toggleRfqBtn(){
      if(this.rfq_form.currencyOne == undefined 
        || this.rfq_form.currencyOne == null 
        || this.rfq_form.currencyTwo == undefined 
        || this.rfq_form.currencyTwo == null
        || this.rfq_form.quantity == undefined 
        || this.rfq_form.quantity == null
        || this.rfq_form.quantity == ''
        || this.rfq_form.quantity <= 0) {
        this.disableRFQBtn = true;
      }
      else{
        this.disableRFQBtn = false;
      }
    },
    removeAllArrayElement(target){
      while (target.length > 0) {
        let index = target.length - 1;
        this.$delete(target, index);
      }
    }
  },
  async mounted() {
    if(this.CompisTickerDetailsOpen){
      // console.log("call ticker detail component mounted order");
      const channel = localStorage.getItem("stream-channel");
      this.sockets.subscribe(channel, (data) => {
        if (this.security_id == data.security_id) {
          let payload = {
            bid_price: data.bid,
            ask_price: data.ask,
            rate: data.rate,
          };

          if (this.market_id == 6) {
            payload.rate = (
              (payload.ask_price - payload.bid_price) *
              10000
            ).toFixed(2);
          } else {
            payload.rate = (payload.ask_price - payload.bid_price).toFixed(2);
          }

          this.loadWsPrices(payload);
        }
      });

      this.getOrderTypes();

      let user_id = localStorage.getItem("login-id");
      if(user_id == 2){
        this.showVendor = true;
      }
      let autocompleteInput = this.$refs.autocomplete.$refs.input;
      // let parentautocompleteInput = document.getElementsByClassName("search-bar");
      if (autocompleteInput) {
        autocompleteInput.addEventListener("focus", function() {
          // parentautocompleteInput.classList.add("focus");
          document.getElementsByClassName("search-bar")[0].classList.add("focus");
        });
      }
    }
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
.ticker-detail-wrap,
.setting-wrap {
  padding: 0;
  .ticker-title {
    color: var(--primary);
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    margin: 5px 0 5px;
    text-transform: capitalize;
    display: flex;
    justify-content: left;
    text-align: left !important;
    @media only screen and (min-width: 1799px) {
      font-size: 18px !important;
    }
  }
  .ticker-price-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 8px 0;
    @media only screen and (min-width: 1799px) {
      margin: 16px 0;
    }
    &.request-price-box {
      margin: 0 0 0.5rem;
      width: 100%;
      @media only screen and (min-width: 1799px) {
        margin: 0 0 0.5rem;
      }
    }
    .ticker-price {
      padding: 6px;
      border: 1px solid var(--ticker-price-border);
      font-size: 17px;
      font-weight: 600;
      line-height: 1;
      text-transform: capitalize;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 48%;
      @media only screen and (min-width: 1799px) {
        font-size: 20px !important;
      }
      .ticker-price-text {
        font-size: 11px;
        font-weight: 600;
        line-height: 1;
        margin: 0 0 4px;
        text-transform: capitalize;
        color: var(--primary);
        @media only screen and (min-width: 1799px) {
          font-size: 12px !important;
        }
      }
      &.bid-price {
        align-items: flex-start;
        color: var(--danger);
        padding-right: 12px;
      }
      &.ask-price {
        color: var(--success);
        align-items: flex-end;
        padding-left: 12px;
      }
    }
    .margin-price {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background: rgba(var(--margin-price-rgb), 1);
      border: 1px solid var(--margin-price-border);
      padding: 5px;
      width: 60px;
      height: 30px;
      font-size: 13px;
      font-weight: 600;
      line-height: 1;
      text-transform: capitalize;
      color: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      @media only screen and (min-width: 1799px) {
        font-size: 14px !important;
      }
    }
  }
  .ticker-detail-custom-form,
  .setting-form {
    .form-group {
      width: 48%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0.5rem;
      .v-input {
        margin: 0;
        .v-input__slot {
          &:before,
          &:after {
            display: none;
          }
          input {
            color: var(--form-control-text);
            padding: 12px 0 0;
            @media only screen and (min-width: 1799px) {
              padding: 20px 0 0;
            }
          }
        }
      }
      .v-input--is-focused {
        border: 1px solid var(--input-focus) !important;
        outline: 0 !important;
        box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
      }
      label {
        color: var(--grey);
        font-size: 13px;
        font-weight: 500;
        line-height: 1;
        margin: 0 0 0;
        text-transform: capitalize;
        top: 8px !important;
        height: auto;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
          top: 12px !important;
        }
        &.v-label--active {
          top: 18px !important;
          font-size: 11px;
          transform: translateY(-18px) scale(1);
          @media only screen and (min-width: 1799px) {
            font-size: 12px !important;
            top: 20px !important;
          }
        }
        &.primary--text {
          color: var(--grey) !important;
        }
      }
      div,
      select,
      input {
        width: 100%;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .form-control,
      select {
        color: var(--form-control-text);
        font-size: 13px;
        font-weight: 500;
        height: 42px;
        background-color: var(--form-control-bg);
        border: 1px solid transparent;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        box-shadow: none;
        -webkit-box-shadow: none;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
          height: 50px;
        }
        &:focus {
          border: 1px solid var(--input-focus) !important;
          outline: 0 !important;
          box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
        }
      }
    }
    button {
      width: 48%;
      min-height: 30px;
      font-size: 14px;
      font-weight: 500;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      box-shadow: none;
      -webkit-box-shadow: none;
      text-transform: capitalize;
      @media only screen and (min-width: 1799px) {
        font-size: 15px !important;
        min-height: 38px;
      }
      &.cancel-rfq-btn {
        color: var(--submit-portfolio-text);
        background-color: var(--grey);
        border: 1px solid var(--grey);
        width: 48%;
        min-height: 30px;
        font-size: 14px;
        font-weight: 500;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        box-shadow: none;
        -webkit-box-shadow: none;
        text-transform: capitalize;
        margin-bottom: 0;
        @media only screen and (min-width: 1799px) {
          font-size: 15px !important;
          min-height: 38px;
        }
        &:hover,
        &:focus {
          color: var(--grey);
          background-color: var(--submit-portfolio-text);
          border-color: var(--grey) !important;
        }
      }
    }
    .select-group {
      position: relative;
      label {
        position: absolute;
        top: 6px !important;
        left: 12px;
        font-size: 11px !important;
        font-size: 11px;
        @media only screen and (min-width: 1799px) {
          font-size: 12px !important;
        }
      }
      .custom-select {
        height: 42px;
        padding: 16px 1.75rem 0.375rem 0.75rem;
        @media only screen and (min-width: 1799px) {
          height: 50px;
        }
      }
    }
  }
  .net-consi {
    background: var(--form-control-bg);
    margin-top: 0.5rem !important;
    padding: 7px;
    @media only screen and (min-width: 1799px) {
      margin-top: 1rem;
    }
    span {
      color: var(--net-consi-text);
      font-size: 13px;
      font-weight: 400;
      line-height: 1;
      margin: 0 0 0;
      text-transform: capitalize;
      text-align: left;
      font-weight: 500;
      @media only screen and (min-width: 1799px) {
        font-size: 14px !important;
      }
      &.net-consi-value {
        font-weight: 700;
        margin-left: 5px;
      }
    }
  }
  .order-entry-wrap {
    padding: 5px;
    overflow: auto;
    &.request-form-wrap {
      .ticker-title {
        margin-bottom: 13px;
        @media only screen and (min-width: 1799px) {
          margin-bottom: 21px;
        }
      }
    }
  }
  button:disabled,
  select[disabled="disabled"],
  input[disabled="disabled"],
  fieldset[disabled="disabled"] div,
  fieldset[disabled="disabled"] input {
    cursor: not-allowed !important;
    &:hover,
    &:focus {
      cursor: not-allowed !important;
    }
  }
  .add-to-watchlist {
    position: relative;
    z-index: 9;
    svg {
      width: 18px;
      height: 18px;
      fill: var(--primary);
    }

    &:hover,
    &:focus,
    &.active {
      svg {
        fill: var(--success) !important;
      }
    }
  }
  .margin-b-10 {
    margin-bottom: 10px;
  }
}
.v-dialog {
  .custome-modal {
    &.v-card {
      .v-card__text {
        ul {
          &.allocates_ul {
            padding: 0 10px !important;
          }
        }
      }
    }
  }
}
.commission-box {
  width: 60%;
  padding: 0px !important;
}
.allocates_div,
.commission-box {
  padding: 10px;
  .ticker-detail-custom-form,
  .setting-form {
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;

      &.allocates-select {
        width: 38%;
      }

      &.allocates-button {
        width: 10%;
        margin-right: 0;
        a {
          padding: 0 3px;
        }
      }
      .v-input {
        margin: 0;
        .v-input__slot {
          &:before,
          &:after {
            display: none;
          }
          input {
            color: var(--form-control-text);
            padding: 12px 0 0;
            @media only screen and (min-width: 1799px) {
              padding: 20px 0 0;
            }
          }
        }
      }
      .v-input--is-focused {
        border: 1px solid var(--input-focus) !important;
        outline: 0 !important;
        box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
      }
      label {
        color: var(--grey);
        font-size: 13px;
        font-weight: 500;
        line-height: 1;
        margin: 0 0 0;
        text-transform: capitalize;
        top: 7.5px !important;
        height: auto;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
          top: 11px !important;
        }
        &.v-label--active {
          top: 18px !important;
          font-size: 11px;
          transform: translateY(-18px) scale(1);
          @media only screen and (min-width: 1799px) {
            font-size: 12px !important;
            top: 20px !important;
          }
        }
        &.primary--text {
          color: var(--grey) !important;
        }
      }
      div,
      select,
      input {
        width: 100%;
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .form-control,
      select {
        color: var(--form-control-text);
        font-size: 13px;
        font-weight: 500;
        height: 42px;
        background-color: var(--form-control-bg);
        border: 1px solid transparent;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        box-shadow: none;
        -webkit-box-shadow: none;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
          height: 50px;
        }
        &:focus {
          border: 1px solid var(--input-focus) !important;
          outline: 0 !important;
          box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
        }
      }
    }
    .select-group {
      position: relative;
      label {
        position: absolute;
        top: 6px !important;
        left: 12px;
        font-size: 11px !important;
        font-size: 11px;
        @media only screen and (min-width: 1799px) {
          font-size: 12px !important;
        }
      }
      &.allocates-select {
        color: var(--form-control-text);
        padding: 0.375rem 0.75rem;
        font-size: 13px;
        font-weight: 500;
        height: 42px;
        background-color: var(--form-control-bg);
        border: 1px solid transparent;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        box-shadow: none;
        -webkit-box-shadow: none;
        @media only screen and (min-width: 1799px) {
          font-size: 14px !important;
          height: 50px;
        }
        &:focus {
          border: 1px solid var(--input-focus) !important;
          outline: 0 !important;
          box-shadow: 0 0 6px 2px rgba(var(--input-focus-rgb), 0.15) !important;
        }
        .v-input {
          .v-input__control {
            .v-input__slot {
              margin-bottom: 0 !important;
              background: transparent !important;
              padding: 0;
              .v-select__slot {
                color: var(--form-control-text);
                padding: 12px 0 0;
                max-height: 32px;
                @media only screen and (min-width: 1799px) {
                  padding: 20px 0 0;
                }
                .v-input__append-inner {
                  margin-top: 0;
                  height: 10px;
                  justify-content: flex-end;
                  .v-input__icon {
                    height: 10px;
                    min-width: 10px;
                    justify-content: flex-end;
                    .v-icon.v-icon {
                      font-size: 20px;
                    }
                  }
                }
                input {
                  padding-top: 0 !important;
                  color: var(--form-control-text);
                  font-size: 13px;
                  font-weight: 500;
                  min-width: 112px;
                }
              }
            }
          }
          .v-text-field__details {
            display: none !important;
          }
          &.v-input--is-focused {
            border: none !important;
            outline: 0 !important;
            box-shadow: none !important;
          }
        }
        label {
          color: var(--grey);
          font-size: 13px;
          font-weight: 500;
          line-height: 1;
          margin: 0 0 0;
          text-transform: capitalize;
          top: 7.5px !important;
          height: auto;
          @media only screen and (min-width: 1799px) {
            font-size: 14px !important;
            top: 11px !important;
          }
          &.v-label--active {
            top: 18px !important;
            font-size: 11px;
            transform: translateY(-18px) scale(1);
            @media only screen and (min-width: 1799px) {
              font-size: 12px !important;
              top: 20px !important;
            }
          }
          &.primary--text {
            color: var(--grey) !important;
          }
        }
      }
      .custom-select {
        height: 42px;
        padding: 16px 1.75rem 0.375rem 0.75rem;
        @media only screen and (min-width: 1799px) {
          height: 50px;
        }
      }
    }
  }
  .marginright-8 {
    margin-right: 8px !important;
  }
}
#app {
  .table-button-groups {
    .btn {
      &.confirm-btn {
        color: var(--submit-portfolio-text);
        background-color: var(--submit-portfolio-bg);
        border: 1px solid var(--submit-portfolio-bg);
        line-height: 1.44 !important;
        display: block !important;
        margin-bottom: 0 !important;
        font-weight: 400 !important;
        text-align: center !important;
        cursor: pointer !important;
        white-space: nowrap !important;
        padding: 6px 12px !important;
        font-size: 14px !important;
        border-radius: 0 !important;
        width: 100px !important;
        margin: 0 7px 10px auto !important;
        &:hover,
        &:focus {
          color: var(--submit-portfolio-bg);
          background-color: var(--submit-portfolio-text);
          border-color: var(--submit-portfolio-bg) !important;
        }
      }
    }
  }
  li.autocomplete__results__item.autocomplete__results__item--error {
    display: none !important;
  }
  .disabled {
    cursor: not-allowed !important;
  }
  .disabled-box {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    cursor: not-allowed;
    .ticker-price-value {
      color: gray;
    }
    .ticker-price-text {
      color: gray;
    }
  }
  .mr-0 {
    margin-right: 0 !important;
  }
  .order-1 {
    order: 1 !important;
  }
  .mt-custom{
    margin-top: 0.5rem !important;
    margin-bottom: 0 !important;
  }
  .menuable__content__active{
    z-index:999999 !important;
  }
}
</style>
