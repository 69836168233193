import axios from "../../axios"

export default {
	fetchItems({
		commit
	}, payload) {
		return new Promise((resolve, reject) => {
			axios.post('/get-securities', payload)
				.then(response => {
					if(response.data.data.is_filter=='1'){
						commit('setupdateItems', response.data.data.securities)
					} else {
						commit('setItems', response.data.data.securities)
					}
					commit('setNextPageIndex', response.data.data.next_page_index)
					commit('setStartRecord', response.data.data.startPerPage)
					commit('setTotalRecord', response.data.data.total_records)
					commit('setIsCashFilter', response.data.data.is_scanner)

					let temp = [];
					for (let obj of response.data.data.securities) {
						if(obj.iex_symbol != undefined && obj.iex_symbol != null){
							temp.push(obj.iex_symbol);
						}
					}
					temp = [...new Set(temp)];
					
					commit('setCashSymbols', temp)
					resolve(response)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
	updateItems({
		commit
	}, payload){
		commit('setupdateItems', payload)
	},
	fetchFxspotCurrencyList({
		commit
	}) {
		return new Promise((resolve, reject) => {
			axios.get('/get-fxspot-currencies')
				.then(response => {
					commit('setFxspotCurrencyList', response.data.data)
					resolve(response)
				})
				.catch(error => {
					reject(error)
				})
		})
	},
}