<template>
  <div class="performance-wrap-1">
    
   <template>
      <v-navigation-drawer
        class="order-form-sidebar filter-sidebar"
        width="62%"
        v-model="ViewDetailsDrawer"
        temporary
        absolute
        right
      >
        <div >
          <v-card class="custome-modal table-t-body">
            <v-card-title class="headline p-1">
              <div
                class="box-title d-flex justify-content-between align-items-center"
              >
                <h4 v-if="ViewDetailsDrawer">
                  Performance of {{ pnl_security_name }} ({{
                    dateFromat(this.start_filter_date)
                  }}
                  To {{ dateFromat(this.end_filter_date) }})
                </h4>
              </div>
            </v-card-title>
          </v-card>
          <v-simple-table
            class="custome-table child-table popup-table"
            fixed-header
            :style="{ height: CompPerformanceHeight + 'px' }"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-start" width="12%">
                    Report Date
                  </th>
                  <th class="text-center" width="11">
                    Amount
                  </th>
                  <th class="text-center" width="14%">
                    Market Price
                  </th>
                  <th class="text-center" width="14%">
                    Mark-To-Market
                  </th>
                  <th class="text-center" width="14%">
                    New Business
                  </th>
                  <th class="text-center" width="14%">
                    Accrued Interest
                  </th>
                  <th class="text-center" width="11%">
                    Realized
                  </th>
                  <th class="text-center" width="10%">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(unrealized_item, index ) in unrealized_items"
                  :key="unrealized_item.id+index"
                >
                  <td class="text-start">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.date
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.total_quantity
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      formatPrice(
                        unrealized_item.market_price,
                        unrealized_item.market_id
                      )
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.mtm
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.nb
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.cost_of_cary
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.realized
                    }}</span>
                  </td>
                  <td class="text-center">
                    <span class="teble-ellipsis-wrap">{{
                      unrealized_item.total
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- <transition name="fade">
            <div class="loading full-page" v-show="popupLoading">
              <div><span></span><span></span><span></span><span></span></div>
            </div>
          </transition> -->
        </div>
       
        <!-- <transition name="fade">
          <div class="loading full-page" v-show="popupLoading">
            <div><span></span><span></span><span></span><span></span></div>
          </div>
        </transition> -->
      </v-navigation-drawer>
      <!-- <a class="filter-sticky-icon right-60 rightside" @click="DetailsDrawer()">
        <img alt="filter icon" src="@/assets/images/right-arrow.png" />
      </a> -->
    </template>
    
  </div>
</template>

<script>
export default {
  name: "PerformanceUnRealizeDetailProp",
  components: { },
  data() {
    return {
      unrealizedSlide: false,
      realizedSlide: false,
      ViewDetailsDrawer:false,
      unrealized_items:[],
      pnl_security_name:'',
      CompPerformanceHeight:'',
      start_filter_date:'',
      end_filter_date:''
      
    };
  },
  computed: {
    is_maximize: {
      get() {
        return this.$store.getters["dashboard/getIsMaximize"];
      },
    },
    
  },
  watch: {
    async PerformanceUnRealizeDetailProp() {
      this.unrealizedSlide = true;
      this.unrealized_items = this.PerformanceUnRealizeDetailProp.data;
      this.pnl_security_name = this.PerformanceUnRealizeDetailProp.pnl_security_name;
      this.start_filter_date = this.PerformanceUnRealizeDetailProp.start_filter_date;
      this.end_filter_date = this.PerformanceUnRealizeDetailProp.end_filter_date;
      this.CompPerformanceHeight = this.PerformanceUnRealizeDetailProp.CompPerformanceHeight;
      // console.log("call unrealized_items...",this.PerformanceUnRealizeDetailProp.data);
      this.ViewDetailsDrawer = true;
    },
    // start_filter_date: {
    //   get() {
    //     return this.$store.getters["dashboard/getStartFilterDate"];
    //   },
    // },
    // end_filter_date: {
    //   get() {
    //     return this.$store.getters["dashboard/getEndFilterDate"];
    //   },
    // },
  },
  methods: {
   
    
     
  },
  props: ["PerformanceUnRealizeDetailProp"],
  async mounted() {
    // console.log("call CashflowDetail",this.PerformanceUnRealizeDetailProp);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/abstracts/_variables.scss";
@import "@/assets/scss/common/_common.scss";
#app {
  .performance-wrap-1 {
    padding: 0;
    #cashflow-scroll-table {
      overflow: auto;
    }
    .filter-sidebar{
      z-index: 99999 !important;
    }
    .custome-table {
      table {
        > tbody {
          > tr {
            > td {
              &.text-center {
                .teble-ellipsis-wrap {
                  margin: 0 auto !important;
                }
              }
              .teble-ellipsis-wrap {
                width: 120px !important;
              }
              &:last-child {
                .teble-ellipsis-wrap {
                  width: 100px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
