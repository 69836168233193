export default {
	getAllItems: state => state.all_items,
	getItems: state => state.items,
	getChartType: state => state.chart_type,
	getName: state => state.name,
	getTimeline: state => state.timeline,
	getCandleTimeline: state => state.candleTimeline,
	getIsLoading: state => state.isLoading,
	getSecurityId: state => state.security_id,
	getConId: state => state.con_id,
	getMarketId: state => state.market_id,
	getLastPrice: state => state.last_price,
	getBid: state => state.bid,
	getAsk: state => state.ask,
	getAppUri: state => state.app_uri,
	getCounterparty: state => state.counterparty,
	getAddedWatchlist: state => state.added_watchlist,
	getRate: state => state.rate,
	getPriceType: state => state.price_type,
	getSecuritySet: state => {
		return {
			security_id: state.security_id,
			conid: state.con_id
		};
	}
}